import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { MatchingChallengeListRs } from '@domain/rs/challenge/MatchingChallengeListRs';
import { MatchingChallengeRs } from '@domain/rs/challenge/MatchingChallengeRs';
import { MatchingChallengeAdListRs } from '@domain/rs/challenge/MatchingChallengeAdListRs';
import { MatchingChallengePositionListRs } from '@domain/rs/challenge/MatchingChallengePositionListRs';
import { MatchingChallengeCompanyListRs } from '@domain/rs/challenge/MatchingChallengeCompanyListRs';

const matchChallengePrefix = '/match/challenge';

export default interface MatchChallengeRepo {
  // 매칭 챌린지 목록 조회
  fetchChallengeList(): Promise<AxiosResponse<MatchingChallengeListRs>>;

  // 매칭 챌린지 상세 조회
  fetchChallenge(challengeSn: number): Promise<AxiosResponse<MatchingChallengeRs>>;

  // 매칭 챌린지 광고 목록 조회
  fetchChallengeAdList(): Promise<AxiosResponse<MatchingChallengeAdListRs>>;

  // 매칭 챌린지 포지션 목록 조회
  fetchChallengePositionList(challengeSn: number): Promise<AxiosResponse<MatchingChallengePositionListRs>>;

  //매칭 챌린지 참여 기업 목록 조회
  fetchChallengeCompanyList(challengeSn: number): Promise<AxiosResponse<MatchingChallengeCompanyListRs>>;
}

export class RemoteMatchChallengeRepo implements MatchChallengeRepo {
  fetchChallengeList() {
    return axios.get<MatchingChallengeListRs>(`${matchChallengePrefix}/list`);
  }

  fetchChallenge(challengeSn: number) {
    return axios.get<MatchingChallengeRs>(`${matchChallengePrefix}/${challengeSn}`);
  }

  fetchChallengeAdList() {
    return axios.get<MatchingChallengeAdListRs>(`${matchChallengePrefix}/ad/list`);
  }

  fetchChallengePositionList(challengeSn: number, selectedCompanySns?: number[]) {
    return axios.get<MatchingChallengePositionListRs>(`${matchChallengePrefix}/${challengeSn}/positions`, {
      params: { companySns: selectedCompanySns },
    });
  }

  fetchChallengeCompanyList(challengeSn: number) {
    return axios.get<MatchingChallengeCompanyListRs>(`${matchChallengePrefix}/${challengeSn}/companies`);
  }
}
