import { MatchingHomePositionCardDto } from '@domain/rs/match/MatchingHomeApplicationPositionRs';
import {
  JobApplicationPositionType,
  JobApplicationProcess,
  JobApplicationProcessType,
  applicationStatusTextMap,
  applyStatusTextMap,
} from '@domain/constant/jobApplicationProcessType';
import { MILLISECONDS_PER_DAY } from '@domain/constant/Time';
import DateUtil from '@utils/date/util';

export default class MatchingHomePositionCardVO {
  positionSn: number;
  positionName: string;
  companySn: number;
  companyName: string;
  companyLogoUrl: string;
  applicationCompleteTime: string | null;
  jobApplicationProcessType: JobApplicationProcessType;
  jobApplicationProcessText: string;
  cardType: 'refuse' | 'accept' | 'complete' | '';
  closingDateTime: string | null;
  openingDateTime: string; //포지션 시작 시간
  beforeOpeningYn: boolean; // 포지션 시작 여부
  positionType: JobApplicationPositionType;
  openYn: boolean; //포지션 종료 여부
  terminatePosition: boolean; //ATS공고 종료 여부
  enableInsightMypage: boolean; //JF3공고 mypage접근 가능 여부
  matchingOfferYn: boolean; //매칭제안 여부
  applyStatus: string;

  constructor(rs: MatchingHomePositionCardDto) {
    const currentDate = DateUtil.getNow();

    this.applicationCompleteTime = rs.applicationCompleteTime;
    this.companySn = rs.companySn;
    this.companyName = rs.companyName;
    this.companyLogoUrl = rs.companyLogoUrl;
    this.positionName = rs.positionName;
    this.positionSn = rs.positionSn;
    this.jobApplicationProcessType = rs.jobApplicationProcessType;
    this.jobApplicationProcessText = applicationStatusTextMap[rs.jobApplicationProcessType];
    this.applyStatus = applyStatusTextMap[rs.jobApplicationProcessType];
    this.cardType = this.getCardType(rs.jobApplicationProcessType as JobApplicationProcess);
    this.closingDateTime =
      rs.closingDateTime && String(this.getLeftDaysTime(currentDate, new Date(rs.closingDateTime)));
    this.openingDateTime = rs.openingDateTime;
    this.beforeOpeningYn = DateUtil.compare(currentDate, new Date(rs.openingDateTime)) === -1;
    this.positionType = rs.positionType;
    this.openYn = rs.openYn;
    this.terminatePosition =
      !rs.openYn || (rs.closingDateTime ? DateUtil.isPastDate(new Date(rs.closingDateTime)) : false);
    this.enableInsightMypage = rs.openYn && rs.visibilityType === 'PUBLIC';
    this.matchingOfferYn = rs.matchingOfferYn;
  }

  getCardType(type: JobApplicationProcess) {
    switch (type) {
      case JobApplicationProcess.INTERVIEW_DENIED:
      case JobApplicationProcess.INTERVIEW_FAILED:
        return 'refuse';
      case JobApplicationProcess.ACC_SUBMIT:
      case JobApplicationProcess.PHS_SUBMIT:
        return 'complete';
      case JobApplicationProcess.INTERVIEW_ACCEPTED:
      case JobApplicationProcess.INTERVIEW_PASSED:
        return 'accept';
      default:
        return '';
    }
  }

  private getLeftDaysTime = (date: Date, endDate = DateUtil.getNow()): number => {
    const minuteInMilliSecond = 60 * 1000;
    const offset = -1 * date.getTimezoneOffset() * minuteInMilliSecond; // timezone offset으로 값 보정
    const getStartDate = date.valueOf() + offset;
    const getEndDate = endDate.valueOf() + offset;
    const clearedStartDate = Math.floor(getStartDate / MILLISECONDS_PER_DAY);
    const clearedEndDate = Math.floor(getEndDate / MILLISECONDS_PER_DAY);

    if (getStartDate > getEndDate) {
      return -1;
    }

    return clearedEndDate - clearedStartDate;
  };
}
