import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToast } from '@common/toast';
import { useUsersIdExist } from '@repository/users/useUsers';
import { FindPasswordStepType } from '@components/loginModal/find/password/index';
import styles from '@components/loginModal/find/find.module.scss';
import LabelInput from '@components/labelInput';
import { V2Route } from '@utils/routeUtil';
import { valueRequired } from '@utils/validators/validators';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface FindPasswordIdProps {
  setFindPasswordStep: (step: FindPasswordStepType) => void;
}

const FindPasswordId: FC<FindPasswordIdProps> = ({ setFindPasswordStep }) => {
  const Toast = useToast();
  const { mutateAsync: fetchUsersIdExist } = useUsersIdExist();

  const { register, watch } = useFormContext();

  const id = watch('id');

  const isDisabledConfirmIdBtn = !id;

  const checkValidId = async () => {
    try {
      const { isExist } = await fetchUsersIdExist(id);
      if (!isExist) {
        Toast({ type: 'error', iconType: 'info', content: '등록되지 않은 아이디입니다. 다시 한번 확인해 주세요.' });
        return false;
      }

      return true;
    } catch (err: any) {
      Toast({ type: 'error', iconType: 'info', content: err?.response?.data.message });
    }
  };

  const onClickMobile = async () => {
    if (isDisabledConfirmIdBtn) return;
    const result = await checkValidId();
    if (result) setFindPasswordStep('CONFIRM');
  };

  const onClickIpin = async () => {
    if (isDisabledConfirmIdBtn) return;
    const result = await checkValidId();
    if (result) {
      V2Route.openKcbIpinPage();
    }
  };

  return (
    <div className={cx('findPwArea')}>
      <div className={cx('infoWrap')}>
        <LabelInput
          {...register('id', valueRequired)}
          type="text"
          className={cx('input')}
          placeholder="아이디"
          maxLength={20}
          autoComplete="off"
        />
      </div>
      <div className={cx('selectArea', 'mobile')}>
        <div className={cx('findSelect')}>
          <a
            className={cx('btn', 'mobile', { disabled: isDisabledConfirmIdBtn })}
            role="button"
            onClick={onClickMobile}
          >
            휴대폰으로 찾기
          </a>
          <a className={cx('btn', 'ipin', { disabled: isDisabledConfirmIdBtn })} role="button" onClick={onClickIpin}>
            아이핀으로 찾기
          </a>
        </div>
      </div>
    </div>
  );
};

export default FindPasswordId;
