import React from 'react';
import { useController } from 'react-hook-form';
import { SkillsVO } from '@domain/vo/profile/ProfileProjectVO';

import classnames from 'classnames/bind';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);

interface ISkillKeywordChipItemProps {
  prefixName: string;
  skillKeyword: SkillsVO;
  onDelete: () => void;
}

const SkillKeywordChipItem = ({ prefixName, skillKeyword, onDelete }: ISkillKeywordChipItemProps) => {
  const { field: codeField } = useController({
    name: `${prefixName}.code`,
    defaultValue: skillKeyword.code,
  });

  const { field: levelField } = useController({
    name: `${prefixName}.level`,
    defaultValue: skillKeyword.level,
  });

  return (
    <li className={cx('listItem')}>
      {`${skillKeyword.keyword} - ${skillKeyword.skillLevelText}`}
      <a className={cx('btnDelete')} role="button" onClick={onDelete} />
    </li>
  );
};

interface ISkillKeywordChipListProps extends HookFormProps {
  skillKeywordChips: SkillsVO[];
  onDelete: (idx: number) => void;
}

const SkillKeywordChipList = ({ prefixName, skillKeywordChips, onDelete }: ISkillKeywordChipListProps) => {
  if (!skillKeywordChips.length) return null;

  const skillKeywordChipsEl = skillKeywordChips.map((item, idx) => {
    return (
      <SkillKeywordChipItem
        prefixName={`${prefixName}[${idx}]`}
        key={item.code}
        skillKeyword={item}
        onDelete={() => onDelete(idx)}
      />
    );
  });

  return (
    <div className={cx('selectListWrap')}>
      <ul className={cx('selectList')}>{skillKeywordChipsEl}</ul>
    </div>
  );
};

export default SkillKeywordChipList;
