import { CountryCodeDto } from '@domain/rs/code/CountryCodeRs';
import { BaseProfileActivityAndForeignDto, BaseProfileExperienceDto } from '@domain/rs/insight/InsightResumeGetRs';
import { ReactSelectOption } from '@domain/constant/ReactSelectOption';
import { ExperienceNameMap } from '@domain/constant/profile/ExperienceType';
import DateFormat from '@utils/date/format';

export default class ExperienceFormVO {
  activitiesAndForeignExperiences: BaseProfileExperienceVO[];

  constructor(dto: BaseProfileExperienceDto) {
    this.activitiesAndForeignExperiences = dto.activitiesAndForeignExperiences.map(
      (item) => new BaseProfileExperienceVO(item),
    );
  }
}

export class BaseProfileExperienceVO {
  tag: ReactSelectOption | null;
  code: CountryCodeDto | null;
  title: string | null;
  startDate: string | null;
  endDate: string | null;
  description: string | null;

  constructor(dto: BaseProfileActivityAndForeignDto) {
    this.tag = dto.tag ? { value: dto.tag, label: ExperienceNameMap[dto.tag] } : null;
    this.code = dto.code ? { code: dto.code, name: dto.title ?? '' } : null;
    this.title = dto.title;
    this.startDate = DateFormat.getDateFormatString(dto.startDate, 'yyyy-MM') ?? '';
    this.endDate = DateFormat.getDateFormatString(dto.endDate, 'yyyy-MM') ?? '';
    this.description = dto.description;
  }

  public static convertRqFormVO(vo: BaseProfileExperienceVO): BaseProfileActivityAndForeignDto {
    return {
      tag: vo.tag?.value ?? null,
      code: vo.code?.code ?? null,
      title: vo.title ?? null,
      startDate: vo.startDate,
      endDate: vo.endDate,
      description: vo.description,
    } as BaseProfileActivityAndForeignDto;
  }
}
