import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useSaveLogging } from '@repository/logging/useLogging';
import { LogTypeEnum } from './LogType';
import LoggingRq, {
  LoggingAction,
  LoggingPlatformTypeEnum,
  LoggingUserTypeEnum,
  UrlInfo,
} from '@domain/rq/logging/LoggingRq';
import useCheckAuthority from '@utils/hooks/useCheckAuthority';
import { RouteUrl } from '@utils/routeUtil';

// url 변환
const convertUrl = (url: string) => {
  const indexOfQuestionMark = url.indexOf('?');
  // query string 제거
  const removeQueryUrl = indexOfQuestionMark !== -1 ? url.substring(0, indexOfQuestionMark) : url;
  // jobda url인지 확인
  const jobdaIndex = removeQueryUrl.indexOf('jobda');
  // 외부 링크
  const isOutLink =
    removeQueryUrl.includes('www') || removeQueryUrl.includes('http') || removeQueryUrl.includes('https');

  //잡다 url이면 path만 추출
  if (jobdaIndex !== -1) {
    const pathStart = removeQueryUrl.indexOf('/', jobdaIndex);
    return removeQueryUrl.substring(pathStart);
  }

  // 외부 링크인지 확인
  if (jobdaIndex === -1 && isOutLink) {
    return 'outLink';
  }

  return removeQueryUrl;
};

const useLogging = () => {
  const router = useRouter();
  const currentUrl = convertUrl(router.asPath);
  const scrollValue = useRef(0);
  const actionData = useRef<LoggingAction[]>([]);
  const urlInfo = useRef<UrlInfo>();
  const { data: userInfo } = useUserInfo();
  const { mutateAsync } = useSaveLogging();
  // const { data: profileData } = useProfileDetailNoVO(!!userInfo?.id);
  const { isMatchingUser, isAuthorityUser, isReceivingMatchingUser } = useCheckAuthority(userInfo);

  const saveData = useCallback(
    async (data: LoggingRq) => {
      if (!userInfo?.id) return;
      await mutateAsync(data);
      // 초기화
      scrollValue.current = 0;
      actionData.current = [];
    },
    [userInfo, mutateAsync],
  );

  const getUserType = useCallback(() => {
    const userType: (keyof typeof LoggingUserTypeEnum)[] = [];

    if (isMatchingUser) {
      userType.push(LoggingUserTypeEnum.MATCHING_ON);
    }
    if (isAuthorityUser) {
      userType.push(LoggingUserTypeEnum.CC);
    }
    if (!isMatchingUser) {
      userType.push(LoggingUserTypeEnum.USER);
    }
    if (isReceivingMatchingUser) {
      userType.push(LoggingUserTypeEnum.RECEIVING_MATCHING);
    }

    return userType.length > 0 ? userType : undefined;
  }, [isMatchingUser, isAuthorityUser, isReceivingMatchingUser]);

  const handleRouteChangeStart = useCallback(
    (nextUrl: string) => {
      urlInfo.current = { currentUrl, nextUrl: convertUrl(nextUrl) };
    },
    [currentUrl],
  );

  const handleRouteChangeComplete = useCallback(() => {
    if (!urlInfo.current || urlInfo.current.currentUrl === urlInfo.current.nextUrl) return;
    const newData = {
      platform: LoggingPlatformTypeEnum.PC,
      ...urlInfo.current,
      actions: actionData.current,
      userType: getUserType(),
    };
    saveData(newData);
  }, [getUserType, saveData]);

  const handleBeforeUnload = useCallback(() => {
    let newData = {};
    //메인으로 이동할 시 새로고침이 발생함. 예외 처리
    if (urlInfo.current?.nextUrl === RouteUrl.INDEX) {
      if (currentUrl === urlInfo.current?.nextUrl) return;
      newData = {
        platform: LoggingPlatformTypeEnum.PC,
        currentUrl,
        nextUrl: urlInfo.current.nextUrl,
        actions: [...actionData.current, { actionType: LogTypeEnum.log_gnb_menu, scroll: scrollValue.current }],
        userType: getUserType(),
      };
    } else {
      newData = {
        platform: LoggingPlatformTypeEnum.PC,
        currentUrl,
        actions: actionData.current,
        userType: getUserType(),
      };
    }
    saveData(newData as LoggingRq);
  }, [currentUrl, getUserType, saveData]);

  const handleNewTab = useCallback(
    (nextUrl: string, targetElement: HTMLElement) => {
      const convertedNextUrl = convertUrl(nextUrl);
      if (currentUrl !== convertedNextUrl) {
        const isDuplicate = actionData.current.some((data) => data.actionType === targetElement.id);
        const isLoggingData = targetElement.id.startsWith('log');
        const newActionData = isDuplicate
          ? actionData.current
          : isLoggingData
          ? [...actionData.current, { actionType: targetElement.id, scroll: scrollValue.current }]
          : actionData.current;
        const newData = {
          platform: LoggingPlatformTypeEnum.PC,
          currentUrl,
          nextUrl: convertedNextUrl,
          actions: newActionData,
          userType: getUserType(),
        };
        saveData(newData);
      }
    },
    [currentUrl, getUserType, saveData],
  );

  const handleClick = useCallback(
    (event: MouseEvent, newLink?: string) => {
      event.stopPropagation(); //이벤트 전파 방지
      let targetElement = event.target as HTMLElement;
      let hrefElement: HTMLElement | null = targetElement;

      while (hrefElement && !hrefElement.hasAttribute('href')) {
        hrefElement = hrefElement.parentElement as HTMLElement;
      }

      while (targetElement && !targetElement.hasAttribute('id')) {
        targetElement = targetElement.parentElement as HTMLElement;
      }

      if (targetElement && targetElement.id.startsWith('log')) {
        const isDuplicate = actionData.current.some((data) => data.actionType === targetElement.id);
        const newActionData = [...actionData.current, { actionType: targetElement.id, scroll: scrollValue.current }];
        !isDuplicate && (actionData.current = newActionData);
      }

      // 새탭 + ctrl 클릭으로 새탭 여는 경우
      if (hrefElement && (hrefElement.getAttribute('target') === '_blank' || event.ctrlKey || event.metaKey)) {
        handleNewTab(hrefElement.getAttribute('href') ?? '', targetElement);
      }

      // window.open으로 새 탭 열때 처리
      if (newLink) {
        handleNewTab(newLink, targetElement);
      }
    },
    [currentUrl, getUserType, saveData, handleNewTab],
  );

  const handleScroll = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    const scrollPercent = Math.round((scrollTop / (scrollHeight - clientHeight)) * 100);
    scrollValue.current = scrollPercent;
  }, []);

  useEffect(() => {
    if (!userInfo?.id) return;
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    document.addEventListener('click', handleClick);
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('scroll', handleScroll);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      document.removeEventListener('click', handleClick);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    router,
    handleRouteChangeStart,
    handleRouteChangeComplete,
    handleClick,
    handleBeforeUnload,
    handleScroll,
    userInfo,
  ]);

  return { handleClick };
};

export default useLogging;
