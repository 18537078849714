import React from 'react';
import { useController } from 'react-hook-form';
import { handleMonthInput, MonthPicker } from '@common/datePicker';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const StartDate = ({ prefixName }: HookFormProps) => {
  const { field, fieldState } = useController({ name: `${prefixName}.startDate` });

  const handleChange = (date: Date | null) => {
    if (!date) return;
    field.onChange(DateFormat.format(date, 'yyyy-MM'));
  };

  return (
    <div>
      <MonthPicker
        selected={field.value ? new Date(field.value) : null}
        onChange={handleChange}
        onChangeRaw={handleMonthInput}
        className={cx('periodInput', { error: fieldState.invalid })}
        placeholderText="연도. 월"
        maxDate={new Date('9999-12')}
      />
      {fieldState.invalid && <div className={cx('errorMessage')}>{fieldState.error?.message}</div>}
    </div>
  );
};

export default StartDate;
