import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Link from 'next/link';
import Modal from '@components/common/modal';
import Icon from '@components/common/assets';
import { useRenewAccessToken, useUserInfo } from '@repository/auth/useUserInfo';
import LabelInput from '@components/labelInput';
import { useRefreshPasswordExpiration, useSavePassword } from '@repository/users/useUsers';
import { onInputPassword, passwordValidator, rePasswordValidator, valueRequired } from '@utils/validators/validators';
import { RouteUrl } from '@utils/routeUtil';
import useModal from '@utils/hooks/useModal';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface FindPasswordResetFormField {
  password: string;
  newPassword: string;
  renewPassword: string;
}

/*비밀번호 변경 90일이 지났을 때, 보여주는 모달  */
const PasswordSecureModal = ({ isVisible }: { isVisible?: boolean }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [renewPasswordVisible, setRenewPasswordVisible] = useState(false);
  const { data: userInfo } = useUserInfo();
  const { mutateAsync: refreshPasswordExpiration } = useRefreshPasswordExpiration();
  const { mutateAsync: savePassword } = useSavePassword();
  const { mutateAsync: renewAccessToken } = useRenewAccessToken();
  const { isModalOpen, openModal } = useModal();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<FindPasswordResetFormField>({
    mode: 'onChange',
  });
  const password = watch('password');
  const newPassword = watch('newPassword');
  const renewPassword = watch('renewPassword');
  const samePassword = newPassword && renewPassword && newPassword === renewPassword;

  /**새 비밀번호 확인이 오류가 난 상태에서(새 비밀번호와 다릅니다.)
   * 새 비밀번호를 수정하여 error가 해결되었을 때를 적용시켜줘야 함.
   */
  useEffect(() => {
    if (!renewPassword) return;
    trigger('renewPassword');
  }, [newPassword, trigger]);

  const changePassword = async () => {
    //비밀번호 변경 api호출
    await savePassword({
      currentPassword: password,
      newPassword: newPassword,
    });
    await renewAccessToken();
    location.reload();
  };

  // 비밀번호 다음에 변경
  const changeNextPassword = async () => {
    await refreshPasswordExpiration();
    await renewAccessToken();
    location.reload();
  };

  useEffect(() => {
    if (!isVisible) return;
    openModal();
  }, [isVisible]);

  return (
    <Modal visible={isModalOpen}>
      <div className={cx('layerLoginModal')}>
        <div className={cx('logoArea')}>
          <Link href={RouteUrl.INDEX}>
            <Icon name="logo" width={118} height={32} />
          </Link>
        </div>

        <div className={cx('secureArea')}>
          <a className={cx('icon')}>
            <Icon name="lockGold" width={80} height={80} />
          </a>
          <div className={cx('noticeArea')}>
            <div>
              비밀번호를 변경한지 <em className={cx('days')}>90일</em>이 지났습니다.
            </div>
            <div className={cx('subText')}>
              소중한 개인정보 보호를 위해
              <br />
              비밀번호를 변경해 주세요.
            </div>
          </div>

          <form className={cx('formWrap')} onSubmit={handleSubmit(changePassword)}>
            <div className={cx('infoWrap')}>
              <LabelInput
                type={passwordVisible ? 'text' : 'password'}
                className={cx('input', 'withButton', { error: errors.password })}
                placeholder={'현재 비밀번호'}
                minLength={6}
                maxLength={16}
                onInput={onInputPassword}
                {...register('password', {
                  ...valueRequired,
                  validate: (value: string) => passwordValidator(value),
                })}
                autoComplete="off"
              />
              {errors.password && <div className={cx('infoText')}>{errors.password.message}</div>}
              <button
                type="button"
                tabIndex={-1}
                onClick={() => setPasswordVisible((prev) => !prev)}
                className={cx('btnPassword', { on: passwordVisible })}
              />
            </div>

            <div className={cx('infoWrap')}>
              <LabelInput
                type={newPasswordVisible ? 'text' : 'password'}
                className={cx('input', 'withButton', { error: errors.newPassword })}
                placeholder={'새 비밀번호'}
                minLength={6}
                maxLength={16}
                onInput={onInputPassword}
                {...register('newPassword', {
                  ...valueRequired,
                  validate: (value: string) => passwordValidator(value, undefined, userInfo?.id, password),
                })}
                autoComplete="off"
              />
              {errors.newPassword && <div className={cx('infoText')}>{errors.newPassword.message}</div>}
              <button
                type="button"
                tabIndex={-1}
                onClick={() => setNewPasswordVisible((prev) => !prev)}
                className={cx('btnPassword', { on: newPasswordVisible })}
              />
            </div>

            <div className={cx('infoWrap', 'rePassword')}>
              <LabelInput
                type={renewPasswordVisible ? 'text' : 'password'}
                className={cx('input', 'withButton', { error: errors.renewPassword })}
                placeholder="새 비밀번호 확인"
                minLength={6}
                maxLength={16}
                onInput={onInputPassword}
                {...register('renewPassword', {
                  ...valueRequired,
                  validate: (value: string) => rePasswordValidator(value, newPassword),
                })}
                autoComplete="off"
              />
              {samePassword && <div className={cx('infoText', 'success')}>비밀번호가 일치합니다.</div>}
              {errors.renewPassword && <div className={cx('infoText', 'error')}>{errors.renewPassword.message}</div>}
              <button
                type="button"
                tabIndex={-1}
                onClick={() => setRenewPasswordVisible((prev) => !prev)}
                className={cx('btnPassword', { on: renewPasswordVisible })}
              />
            </div>

            <div className={cx('btnArea')}>
              <button type="submit" className={cx('btnChange')}>
                변경 하기
              </button>
              <a role="button" className={cx('btnNext')} onClick={changeNextPassword}>
                다음에 변경하기
              </a>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default PasswordSecureModal;
