import { useMutation } from 'react-query';
import { RemoteCodesRepo } from '@repository/codes/CodesRepo';
import { SchoolVO } from '@domain/vo/profile/ProfileEducationVO';
import { EducationLevelType } from '@domain/constant/profile/EducationType';

const remoteCodesRepo = new RemoteCodesRepo();

const fetchSchool = async (educationLevel?: EducationLevelType, name?: string) => {
  const { data } = await remoteCodesRepo.fetchSchoolList(educationLevel, name);
  return data.schools.map((dto) => new SchoolVO(dto));
};

export default function useSchool() {
  return useMutation(({ educationLevel, name }: { educationLevel?: EducationLevelType; name?: string }) =>
    fetchSchool(educationLevel, name),
  );
}
