import KcbIpinVerificationRs from '@domain/rs/verification/KcbIpinVerificationRs';
import KcbSmsVerificationRs from '@domain/rs/verification/KcbSmsVerificationRs';

export default class KcbIdentitySaveRq {
  token: string;
  name: string;
  mobile?: string;
  birthDate: string;

  constructor(rs: KcbSmsVerificationRs | KcbIpinVerificationRs) {
    this.token = rs.identificationToken;
    this.name = rs.name;
    this.birthDate = rs.birthDate;
    if ('mobile' in rs) this.mobile = rs.mobile;
  }
}
