import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import AccountLinkRq from '@domain/rq/accountLink/AccountLinkRq';

const matchApplicantsPrefix = '/match/applicants';

export default interface MatchApplicantsRepo {
  // ATS 수동 인재 계정 연동
  fetchApplicantsAccountLink(rq: AccountLinkRq): Promise<AxiosResponse<boolean>>;
}

export class RemoteMatchApplicantsRepo implements MatchApplicantsRepo {
  fetchApplicantsAccountLink(rq: AccountLinkRq): Promise<AxiosResponse<boolean>> {
    return axios.post(`/v2${matchApplicantsPrefix}/account/link`, rq);
  }
}
