import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Argument } from 'classnames';
import LabelInput from '@components/labelInput';
import { Country, CountryCodeMap, CountryEnglishNameMap, CountryType } from '@domain/constant/CountryType';
import { onlyNumber, valueRequired } from '@utils/validators/validators';
import useCountryCodeList from '@utils/hooks/useCountryCodeList';

interface CountryCodeProps {
  cx: (...arg: Argument[]) => string;
  disabled?: boolean;
  onClickItem?: (country: CountryType) => void;
  isLabelPlaceholder?: boolean;
}

const CountryCode = ({ cx, disabled, onClickItem, isLabelPlaceholder = true }: CountryCodeProps) => {
  const {
    inputWrapRef,
    isOpen,
    setIsOpen,
    setCountryCodeNumber,
    countryList,
    selectedIndex,
    setSelectedIndex,
    initCountryList,
    handleOnKeyDown,
    handleOnClick,
    handleOnChange,
  } = useCountryCodeList();

  const {
    register,
    trigger,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const countryCode = watch('countryCode');

  // 대한민국(+82)으로 초기화
  useEffect(() => {
    setValue('countryCode', CountryCodeMap[Country.REPUBLIC_OF_KOREA]);
    setCountryCodeNumber(CountryCodeMap[Country.REPUBLIC_OF_KOREA]);
  }, []);

  const handleClick = (country: CountryType) => {
    handleOnClick(country);
    setValue('countryCode', CountryCodeMap[country]);
    clearErrors('countryCode');
    onClickItem?.(country);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    handleOnKeyDown(e);
    if (!isOpen) return;

    if (e.key === 'Tab') {
      setIsOpen(false);
      return;
    }

    if (e.key === 'Enter') {
      if (selectedIndex === -1) return;
      const country = countryList[selectedIndex];
      setValue('countryCode', CountryCodeMap[country]);
      clearErrors('countryCode');
    }

    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      document.getElementById(`countryCodeItem-${selectedIndex.toString()}`)?.scrollIntoView({ block: 'center' });
    }
  };

  const countryCodeEls = countryList.map((country, index) => {
    return (
      <li
        id={`countryCodeItem-${index.toString()}`}
        key={country}
        className={cx('countryCodeItem', { selected: index === selectedIndex })}
        onClick={() => handleClick(country)}
        onMouseMove={() => setSelectedIndex(index)}
      >
        {CountryEnglishNameMap[country]} +{CountryCodeMap[country]}
      </li>
    );
  });

  const handleFocus = () => {
    initCountryList();
    setIsOpen(true);
  };

  const getInputEl = () => {
    if (isLabelPlaceholder)
      return (
        <LabelInput
          {...register('countryCode', { ...valueRequired })}
          type="text"
          className={cx(
            'input',
            'countryCode',
            { isvalue: countryCode },
            { error: errors.countryCode },
            { disabled: disabled },
          )}
          placeholder="국가"
          onInput={onlyNumber}
          onChange={handleOnChange}
          onFocus={handleFocus}
          onKeyDown={(e) => handleKeyDown(e)}
          maxLength={3}
          autoComplete="new-password"
          disabled={disabled}
          isvalue={countryCode}
        />
      );

    return (
      <input
        {...register('countryCode', valueRequired)}
        type="text"
        className={cx(
          'input',
          'countryCode',
          { isvalue: countryCode },
          { error: errors.countryCode },
          { disabled: disabled },
        )}
        placeholder="국가"
        onInput={onlyNumber}
        onChange={handleOnChange}
        onFocus={handleFocus}
        onKeyDown={(e) => handleKeyDown(e)}
        maxLength={3}
        disabled={disabled}
        autoComplete="new-password"
      />
    );
  };

  return (
    <div ref={inputWrapRef} className={cx('countryCodeWrap', { isvalue: countryCode }, { disabled: disabled })}>
      {getInputEl()}
      <ul className={cx('countryCodeList', { visible: isOpen })}>{countryCodeEls}</ul>
    </div>
  );
};

export default CountryCode;
