import DateUtil from '../util';
import NumberFormat from '@utils/number/format';

export default class DateFormat {
  //파이어폭스 관련 이슈로 인해 별도의 파싱작업 추가
  static getDateFormatString(date?: string | null, format = '') {
    if (!date) return undefined;
    const parserDateFormat = date.toString().replace(/\./g, '-');

    //파이어폭스 브라우저에서는 yyyy. mm. 형태이면 Invalid Date 출력하여 변환
    return DateFormat.format(new Date(parserDateFormat), format);
  }

  // ex) 2020년 08.20 21:07 => format(date, 'yyyy년 MM.dd HH:mm');
  static format(date: Date, format: string): string {
    const week = ['일', '월', '화', '수', '목', '금', '토'];

    return format.replace(/yyyy|yy|MM|M|dd|AP|HH|H|mm|ss|week/g, (format: string): string => {
      switch (format) {
        case 'yyyy': //year
          return date.getFullYear().toString();
        case 'yy': //year two digits (ex 2012 => 12)
          return date.getFullYear().toString().slice(2);
        case 'MM': //month
          return NumberFormat.formatPad(date.getMonth() + 1);
        case 'M': //month
          return (date.getMonth() + 1).toString();
        case 'dd': //date
          return NumberFormat.formatPad(date.getDate());
        case 'HH': //hours (0~24)
          return NumberFormat.formatPad(date.getHours());
        case 'H': //hours (0제외하고 1~12 표현)
          const hour = date.getHours();
          return (hour === 0 ? 12 : hour > 12 ? hour - 12 : hour).toString();
        case 'mm': //minutes
          return NumberFormat.formatPad(date.getMinutes());
        case 'ss':
          return NumberFormat.formatPad(date.getSeconds());
        case 'week':
          return week[date.getDay()];
        case 'AP':
          return date.getHours() >= 12 ? '오후' : '오전';
        default:
          return '';
      }
    });
  }

  /**두 날짜의 차이를 계산해주는 함수
   *@params1 Date
    @params2 Date
    @params3 string : 원하는 format
   *@return string
   */
  static formatDateDiff(startDate = DateUtil.getNow(), endDate: Date, format: string) {
    const ms = endDate.getTime() - startDate.getTime();
    const days: number = Math.floor(ms / (24 * 60 * 60 * 1000));
    const daysms: number = ms % (24 * 60 * 60 * 1000);
    const hours: number = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms: number = ms % (60 * 60 * 1000);
    const minutes: number = Math.floor(hoursms / (60 * 1000));
    const minutesms: number = ms % (60 * 1000);
    const sec: number = Math.floor(minutesms / 1000);

    let yearDiff = endDate.getFullYear() - startDate.getFullYear();
    let monthDiff = endDate.getMonth() - startDate.getMonth();

    if (monthDiff < 0) {
      yearDiff -= 1;
      monthDiff += 12;
    }

    return format.replace(/yyyy?|yy?|MM?|dd?|HH?|mm?|ss?|/g, (format: string) => {
      switch (format) {
        case 'yyyy': // year difference
          return yearDiff.toString();
        case 'yy': // year difference two digits (ex: 2012 => 12)
          return yearDiff.toString().slice(-2);
        case 'MM': // month difference
          return monthDiff.toString().padStart(2, '0');
        case 'M': // month difference
          return monthDiff.toString();
        case 'dd': // date difference
          return days.toString().padStart(2, '0');
        case 'd':
          return days.toString();
        case 'HH': // hours (0~24)
          return hours.toString().padStart(2, '0');
        case 'mm': // minutes
          return minutes.toString().padStart(2, '0');
        case 'ss': // second
          return sec.toString().padStart(2, '0');
        default:
          return '';
      }
    });
  }

  static formatPositionDDayText(endDate: string | Date | null): string {
    if (!endDate) return '상시채용';

    const tempDate = new Date(endDate);
    if (DateUtil.isPastDate(tempDate)) return '모집종료';

    const result = DateUtil.getLeftDays(DateUtil.getNow(), tempDate);

    if (isNaN(result)) return '';
    else if (result === 0) return 'D-Day';

    return `D-${result}`;
  }

  // 방금 전, 00분전, 00시간 전, 00일 전
  static formatNotificationTimeText(date: Date) {
    //@ts-ignore
    const milliSeconds = DateUtil.getNow() - date;

    const seconds = milliSeconds / 1000;
    if (seconds < 60) return '방금 전';

    const minutes = seconds / 60;
    if (minutes < 60) return `${Math.floor(minutes)}분 전`;

    const hours = minutes / 60;
    if (hours < 24) return `${Math.floor(hours)}시간 전`;

    const days = hours / 24;
    return `${Math.ceil(days)}일 전`;
  }

  // 오전, 오후 12시 보정 처리 추가
  static formatAmPmText(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    switch (hours) {
      case 0:
        return `오전 0:${minutes}`;

      case 12:
        return `오후 12:${minutes}`;

      default:
        return NumberFormat.formatAmPmText(hours, minutes);
    }
  }

  static formatPositionClosingDateText(closingDateTime: Date | string | null) {
    if (!closingDateTime) return '#상시 채용';
    const leftDays = DateUtil.getLeftDays(DateUtil.getNow(), new Date(closingDateTime));
    if (leftDays === 0) return '#오늘 마감';
    else if (leftDays < 0) return `#마감 ${Math.abs(leftDays)}일 지남`;
    return `#마감 ${leftDays}일 남음`;
  }

  /**공고 캘린더 관련 함수 */

  /**해당 날이 포함된 주의 모든 일을 반환하는 함수
   *@params Date
   *@return Date[]
   */
  static getWeekDates = (date: Date) => {
    const dateCopy = new Date(date);
    const sunday = new Date(dateCopy.setDate(dateCopy.getDate() - dateCopy.getDay()));
    const week = [];

    for (let i = 0; i < 7; i++) {
      const day = new Date(sunday);
      day.setDate(sunday.getDate() + i);
      const weekEl = DateFormat.format(day, 'yyyy-MM-dd');
      week.push(weekEl);
    }

    return week;
  };

  /**해당 날이 포함된 월의 모든 일을 반환하는 함수
   *@params Date
   *@return Date[]
   */
  static getWeeksOfMonth = (date: Date) => {
    const newDate = new Date(new Date(date).setDate(1));
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay(); //시작 요일
    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(); // 마지막 일
    const weeksCount = Math.floor((lastDate + firstDay - 1) / 7) + 1;
    const getWeeksOfMonth = [];

    for (let i = 0; i < weeksCount; i++) {
      getWeeksOfMonth.push(...DateFormat.getWeekDates(newDate));
      newDate.setDate(newDate.getDate() + 7);
    }

    return getWeeksOfMonth;
  };
}
