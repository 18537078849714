import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToast } from '@components/common/toast';
import { MobileWrap } from '@components/loginModal/find/form/mobile';
import styles from '@components/loginModal/find/find.module.scss';
import { useUsersPasswordVerification } from '@repository/users/useUsers';
import { FindPasswordStepType, FormTypes } from '.';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface Props {
  setFindPasswordStep: (step: FindPasswordStepType) => void;
}

const FindPasswordConfirm = ({ setFindPasswordStep }: Props) => {
  const Toast = useToast();
  const [isSuccessMobileAuth, setIsSuccessMobileAuth] = useState(false);
  const { mutateAsync: fetchPasswordVerification } = useUsersPasswordVerification();

  const methods = useFormContext<FormTypes>();
  const {
    formState: { errors },
    getValues,
  } = methods;

  const onSubmit = async () => {
    try {
      const { success } = await fetchPasswordVerification({
        ...getValues(),
        mobile: getValues('mobile')?.replace(/[^0-9]/g, ''),
      });
      if (success) {
        setFindPasswordStep('RESET');
      }
    } catch (err: any) {
      Toast({ type: 'error', iconType: 'info', content: err?.response?.data.message });
    }
  };
  const isDisabledConfirmIdBtn = !isSuccessMobileAuth || !!Object.values(errors).length;

  return (
    <div className={cx('userInfoArea')}>
      <MobileWrap setIsSuccessMobileAuth={setIsSuccessMobileAuth} type={'PASSWORD'} />
      <button className={cx('btnFindInfo')} disabled={isDisabledConfirmIdBtn} onClick={onSubmit}>
        비밀번호 재설정
      </button>
    </div>
  );
};

export default FindPasswordConfirm;
