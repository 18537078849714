import React, { FC } from 'react';
import { useController } from 'react-hook-form';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const Name: FC<HookFormProps> = ({ prefixName }) => {
  const { field, fieldState } = useController({
    name: `${prefixName}.name`,
  });

  return (
    <div className={cx('inputArea')}>
      <input
        {...field}
        className={cx('input', { error: fieldState.invalid })}
        type="text"
        placeholder={'수상명을 입력해 주세요.'}
        maxLength={21}
        autoComplete="off"
      />
      {fieldState.invalid && <div className={cx('errorMessage')}>{fieldState.error?.message}</div>}
    </div>
  );
};

export default Name;
