import React, { FC } from 'react';
import { useRecommendSkillKeywords } from '@components/profile/profileOverlay/overlays/skill/query/useSkillKeyword';
import { SkillsVO } from '@domain/vo/profile/ProfileProjectVO';
import SkillKeywordDto from '@domain/rs/profile/skill/SkillKeywordRs';

import classnames from 'classnames/bind';
import styles from '../index.module.scss';
const cx = classnames.bind(styles);

interface IRecommendSkillContentsProps {
  skillKeyword: SkillsVO | null;
  selectedSkillKeywords: SkillsVO[];
  onSelectSkill: (selected: SkillKeywordDto) => void;
}

const RecommendSkillContents: FC<IRecommendSkillContentsProps> = ({
  skillKeyword,
  selectedSkillKeywords,
  onSelectSkill,
}) => {
  const { data: recommendSkills, isLoading } = useRecommendSkillKeywords(skillKeyword?.code);

  if (!recommendSkills || isLoading) return null;

  const selectedSkillKeywordCodes = selectedSkillKeywords.map((item) => item.code);

  const recommendKeywordListsEl = recommendSkills.map((item, index) => {
    const recommendSkillsEl = item.skills
      .filter((item) => !selectedSkillKeywordCodes.includes(item.code))
      .map((item: SkillKeywordDto) => {
        return (
          <a key={item.code} className={cx('keywordItem')} role="button" onClick={() => onSelectSkill(item)}>
            {item.keyword}
          </a>
        );
      });

    return (
      <div className={cx('keywordListWrap')} key={item.categoryCode}>
        <em className={cx('keywordTitle')}>{item.category}</em>
        <div className={cx('keywordList')}>{recommendSkillsEl}</div>
      </div>
    );
  });

  if (!skillKeyword) return null;

  return (
    <div className={cx('recommendKeywordArea')}>
      <p className={cx('desc')}>Tips. {skillKeyword.keyword} 유사한 기술 키워드를 추가해보세요.</p>
      <div className={cx('keywordListInner')}>{recommendKeywordListsEl}</div>
    </div>
  );
};

export default RecommendSkillContents;
