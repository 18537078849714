import React from 'react';
import MatchingOfferModal from '@components/common/modals/MatchingOfferModal';
import ApplyCancelModal, {
  MATCHING_CANCEL_REASON_LIST,
} from '@components/common/matchSwitch/matchProposalModals/applyCancelModal';
import { useToast } from '@components/common/toast';
import { MATCH_CONFIG_STATUS_QUERY_KEY, useUpdateMatchConfigStatus } from '@repository/match';
import PreferenceModal from '@components/match/profile/matchProposal/PreferenceModal';
import NeedTodoModal from './needTodoModal';
import MatchingBenefitsModal from './MatchingBenefitsModal';
import PreferDemandModal from './PreferDemandModal';
import MatchingConfigConditionVO from '@domain/vo/match/MatchingConfigConditionVO';
import ProfilesTypeRs from '@domain/rs/match/ProfilesTypeRs';
import { queryClient } from '@utils/queryClient';

export type MatchProposalModalType =
  | 'MATCH_GUIDE' // 잡다매칭 가이드
  | 'MATCH_NEED_TODO' // 잡다매칭 제안 받기 신청 전
  | 'MATCH_APPLY_SUCCESS' // 잡다매칭 제안 받기 신청 성공
  | 'PREFER' // 선호정보 수정
  | 'MATCH_CANCEL_BENEFITS' // 잡다매칭 취소 시 혜택 안내
  | 'MATCH_APPLY_CANCEL' // 잡다매칭 제안 받기 취소, 사유 입력
  | 'MATCH_CANCEL_PREFER' // 잡다매칭 취소 시 선호정보 수정
  | null;

interface IMatchProposalModalsProps {
  modalState: MatchProposalModalType;
  setModalState: (type: MatchProposalModalType) => void;
  matchConfigStatus?: MatchingConfigConditionVO;
  profilesTypeData?: ProfilesTypeRs;
}

const MatchProposalModals = ({
  modalState,
  setModalState,
  matchConfigStatus,
  profilesTypeData,
}: IMatchProposalModalsProps) => {
  const Toast = useToast();
  const { mutate: updateMatchConfigStatusMutate } = useUpdateMatchConfigStatus(async () => {
    // 제안 받기 | 제안 안받기 후 제안 받기 여부 새로고침
    Toast({ type: 'success', iconType: 'info', content: '잡다 매칭 신청이 취소되었습니다.' });
    await queryClient.refetchQueries(MATCH_CONFIG_STATUS_QUERY_KEY, { active: true, exact: true });
  });

  const isVisible = (modalType: MatchProposalModalType) => modalType === modalState;
  const onClose = () => setModalState(null);

  const handleConfirmApplyCancelModal = (reason: string) => {
    updateMatchConfigStatusMutate({
      useYn: false,
      reason,
    });
  };

  if (!matchConfigStatus || !profilesTypeData) return null;

  return (
    <>
      <MatchingOfferModal
        visible={isVisible('MATCH_APPLY_SUCCESS')}
        onClose={onClose}
        openPreferModal={() => setModalState('PREFER')}
      />
      <PreferenceModal visible={isVisible('PREFER')} onClose={onClose} />
      <NeedTodoModal
        visible={isVisible('MATCH_NEED_TODO')}
        onClose={onClose}
        matchConfigStatus={matchConfigStatus}
        generalYn={profilesTypeData.generalYn}
        developerYn={profilesTypeData.developerYn}
      />

      {/* 매칭 취소 관련 모달 */}
      <MatchingBenefitsModal
        visible={isVisible('MATCH_CANCEL_BENEFITS')}
        onClose={onClose}
        onNext={() => setModalState('MATCH_APPLY_CANCEL')}
      />
      <ApplyCancelModal
        visible={isVisible('MATCH_APPLY_CANCEL')}
        onClose={onClose}
        onConfirm={handleConfirmApplyCancelModal}
        onNext={() => setModalState('MATCH_CANCEL_PREFER')}
      />
      <PreferDemandModal
        visible={isVisible('MATCH_CANCEL_PREFER')}
        onClose={() => {
          onClose();
          handleConfirmApplyCancelModal(MATCHING_CANCEL_REASON_LIST.MATCHING);
        }}
        onNext={() => setModalState('PREFER')}
      />
    </>
  );
};

export default MatchProposalModals;
