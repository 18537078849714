import { useEffect, useRef, useState } from 'react';
import DateFormat from '@utils/date/format';

/**
 * @param time: 타이머 설정 시간(seconds 단위)
 * @param start: 타이머 시작 여부
 * @param onCountDown: 타이머 카운트 다운시 실행되는 함수
 * @param onCountUp: 타이머 카운트 끝날 때 실행되는 함수
 * */

interface UseTimerProps {
  time: number;
  start?: boolean;
  onCountDown?: (remainSec: number) => void;
  onCountUp?: () => void;
}

const useTimer = ({ time, start = true, onCountDown, onCountUp }: UseTimerProps) => {
  const frameId = useRef<number>(0); // 타이머 requestAnimationFrame id 값
  const startDateRef = useRef<Date>(new Date()); // 최초 컴포넌트 마운트 Date
  const endDateRef = useRef<Date>(new Date()); // 타이머 종료 Date
  const [remainingTime, setRemainingTime] = useState(time); // 남은 시간 seconds

  //소수점 포함 남은 시간
  const getExactRemainingTime = () => {
    const elapsed = (new Date().getTime() - startDateRef.current.getTime()) / 1000;
    const remaining = time - elapsed;
    return Math.max(0, remaining);
  };

  const countDown = () => {
    const exactRemainingTime = getExactRemainingTime();
    const isOver = exactRemainingTime === 0;
    onCountDown?.(exactRemainingTime);

    if (isOver) {
      onCountUp?.();
      cancelAnimationFrame();
      return;
    }

    endDateRef.current = new Date(startDateRef.current.getTime() + exactRemainingTime * 1000);
    setRemainingTime(Math.floor(exactRemainingTime));
    requestAnimationFrame();
  };

  const reset = () => {
    cancelAnimationFrame();
    startDateRef.current = new Date();
    setRemainingTime(time);
    requestAnimationFrame();
  };

  const onStart = () => reset();

  const onStop = () => cancelAnimationFrame();

  const getRemainingTimeFormat = (format: string) =>
    DateFormat.formatDateDiff(startDateRef.current, endDateRef.current, format);

  const requestAnimationFrame = () => {
    frameId.current = window.requestAnimationFrame(countDown);
  };

  const cancelAnimationFrame = () => {
    if (frameId.current) window.cancelAnimationFrame(frameId.current);
  };

  useEffect(() => {
    if (start) requestAnimationFrame();
    return () => cancelAnimationFrame();
  }, [time]);

  return { getRemainingTimeFormat, remainingTime, onStart, onStop };
};

export default useTimer;
