import { useFieldArray, useFormContext } from 'react-hook-form';
import Icon from '@components/common/assets';
import Text from '@components/common/text';
import {
  BaseProfileExaminationDto,
  BaseProfileLicenseDto,
  BaseProfileSkillDto,
} from '@domain/rs/insight/InsightResumeGetRs';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import { SkillLevelNameMap } from '@domain/constant/profile/SkillType';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const LicenseContents = () => {
  const { fields, remove } = useFieldArray({
    name: 'knowledgeAndSkill.licenses',
  });

  if (!fields.length) return null;

  const licenseListEl = fields.map((field, idx) => {
    const convertField = field as unknown as BaseProfileLicenseDto;
    return (
      <li key={convertField?.code} className={cx('listItem')}>
        {convertField?.name ?? ''}
        <button onClick={() => remove(idx)}>
          <Icon name="closeLight" width={20} height={20} />
        </button>
      </li>
    );
  });

  return (
    <div className={cx('infoWrap')}>
      <em className={cx('infoTitle')}>자격증</em>

      <div className={cx('detailInfoWrap')}>
        <ul className={cx('detailInfoList')}>{licenseListEl}</ul>
      </div>
    </div>
  );
};

const ExaminationContents = () => {
  const { fields, remove } = useFieldArray({
    name: 'knowledgeAndSkill.examinations',
  });

  if (!fields.length) return null;

  const getLabelText = (dto: BaseProfileExaminationDto) => {
    if (dto.gradeYn && dto.grade) {
      return `${dto.name} - ${dto.grade}`;
    } else if (dto.scoreYn && dto.score !== null) {
      return `${dto.name}-${dto.score}`;
    }

    return dto.name;
  };

  const examinationListEl = fields.map((field, idx) => {
    const convertField = field as unknown as BaseProfileExaminationDto;
    const labelText = getLabelText(convertField);
    return (
      <li key={convertField.code} className={cx('listItem')}>
        {labelText}
        <button onClick={() => remove(idx)}>
          <Icon name="closeLight" width={20} height={20} />
        </button>
      </li>
    );
  });

  return (
    <div className={cx('infoWrap')}>
      <em className={cx('infoTitle')}>공인 어학 시험</em>

      <div className={cx('detailInfoWrap')}>
        <ul className={cx('detailInfoList')}>{examinationListEl}</ul>
      </div>
    </div>
  );
};

const SkillKeywordContents = () => {
  const { fields, remove } = useFieldArray({
    name: 'knowledgeAndSkill.skills',
  });
  if (!fields.length) return null;

  const skillKeywordListEl = fields.map((field, idx) => {
    const convertField = field as unknown as BaseProfileSkillDto;
    const skillLevel = convertField.level ? SkillLevelNameMap[convertField.level] : null;
    return (
      <li key={convertField.code} className={cx('listItem')}>
        {skillLevel ? `${convertField.keyword} - ${skillLevel}` : ''}
        <button onClick={() => remove(idx)}>
          <Icon name="closeLight" width={20} height={20} />
        </button>
      </li>
    );
  });

  return (
    <div className={cx('infoWrap')}>
      <em className={cx('infoTitle')}>기술 키워드</em>

      <div className={cx('detailInfoWrap')}>
        <ul className={cx('detailInfoList')}>{skillKeywordListEl}</ul>
      </div>
    </div>
  );
};

const License = () => {
  const { watch } = useFormContext();
  const data = watch('knowledgeAndSkill');
  if (data?.licenses.length === 0 && data?.examinations.length === 0 && data?.skills.length === 0) return null;

  return (
    <div className={cx('listWrap')}>
      <div className={cx('listTitleArea')}>
        <Icon name="designLight" width={32} height={32} />
        <div className={cx('titleWrap')}>
          <span className={cx('listTitle')}>지식 · 기술</span>
          <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_60}>
            동일한 자격증, 시험 정보는 불러온 정보로 교체됩니다.
          </Text>
        </div>
      </div>
      <LicenseContents />
      <ExaminationContents />
      <SkillKeywordContents />
    </div>
  );
};

export default License;
