import ProfileViewVO from '@domain/vo/profile/common/ProfileViewVO';
import { ProfileCareersRs, ProfileCareersDto } from '@domain/rs/profile/ProfileCareersRs';
import { JobGroupDto } from '@domain/rs/profile/career/JobGroupRs';
import JobTitleRs from '@domain/rs/profile/career/JobTitleRs';
import { BasicCompanyInfoDto } from '@domain/rs/profile/career/CompaniesRs';
import {
  EmploymentStatus,
  EmploymentStatusType,
  EmploymentType,
  EmploymentStatusNameMap,
  EmploymentNameMap,
} from '@domain/constant/profile/CareerType';
import { AuditItemsType } from '@domain/constant/profile/AuditItemsType';
import DateUtil from '@utils/date/util';
import DateFormat from '@utils/date/format';
import NumberFormat from '@utils/number/format';

export class CompanyVO {
  value: BasicCompanyInfoDto;
  label: string | null;

  constructor(rs: BasicCompanyInfoDto) {
    this.value = rs;
    this.label = rs.name;
  }
}

export class JobGroupVO {
  value: number;
  label: string;

  constructor(rs: JobGroupDto) {
    this.value = rs.code;
    this.label = rs.name;
  }
}

export class JobTitleVO {
  value: number;
  label: string;

  constructor(rs: JobTitleRs) {
    this.value = rs.code;
    this.label = rs.name;
  }
}

export default class ProfileCareersVO extends ProfileViewVO {
  careers: CareersVO[];
  totalCareerPeriodText: string;

  constructor(rs: ProfileCareersRs) {
    super(rs);
    this.careers = rs.profileCareers.map((item) => new CareersVO(item));
    this.totalCareerPeriodText = this.getTotalCareerPeriodText(this.careers);
  }

  //총 연차 계산
  getTotalCareerPeriodText(careers: CareersVO[]) {
    let totalCareerPeriod = 0;

    //총 근무 기간 계산
    careers.forEach((item) => {
      const endDate =
        item.employmentStatus === EmploymentStatus.RESIGN && item.endDate ? new Date(item.endDate) : new Date();
      totalCareerPeriod += DateUtil.getLeftMonth(new Date(item.startDate || ''), endDate);
    });

    //년 개월로 리턴
    return NumberFormat.formatMonthToYYMMText(totalCareerPeriod);
  }
}

export class CareersVO {
  sn: number | null;
  companySn: number | null;
  companyName: string;
  employmentStatus: EmploymentStatusType | null;
  employmentStatusText: string | null;
  startDate: string | null;
  endDate: string | null;
  jobGroupCode: number | null;
  jobGroupName: string | null;
  jobTitleCode: number | null;
  jobTitleName: string | null;
  employmentType: EmploymentType | null;
  employmentTypeText: string | null;
  performance: string | null;
  role: string | null;
  auditItems?: AuditItemsType[];
  verified: boolean | null;

  constructor(rs: ProfileCareersDto) {
    this.sn = rs.sn;
    this.companySn = rs.companySn;
    this.companyName = rs.companyName;
    this.employmentStatus = rs.employmentStatus;
    this.employmentStatusText =
      rs.employmentStatus === EmploymentStatus.WORK ? EmploymentStatusNameMap[rs.employmentStatus] : '';
    this.startDate = this.getDateFormat(rs.startDate);
    this.endDate = this.getDateFormat(rs.endDate);
    this.jobGroupCode = rs.jobGroupCode;
    this.jobGroupName = rs.jobGroupName;
    this.jobTitleCode = rs.jobTitleCode;
    this.jobTitleName = rs.jobTitleName;
    this.employmentType = rs.employmentType;
    this.employmentTypeText = rs.employmentType ? EmploymentNameMap[rs.employmentType] : '';
    this.performance = rs.performance;
    this.role = rs.role;
    this.auditItems = rs.auditItems;
    this.verified = rs.verified;
  }

  getDateFormat(data: string | null) {
    return DateFormat.getDateFormatString(data, 'yyyy.MM') ?? '';
  }
}
