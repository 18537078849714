import { EffectCallback, useEffect, useRef } from 'react';

const useOnMount = (cb: EffectCallback) => {
  const cbRef = useRef(cb);

  useEffect(() => {
    const cleanup = cbRef.current();

    return () => cleanup?.();
  }, []);
};

export default useOnMount;
