import React, { FC, useEffect, useState } from 'react';

import { useController, UseControllerProps } from 'react-hook-form';
import { ALL_Locations, LocationNameMap, LocationValue } from '@domain/constant/locationType';
import classnames from 'classnames/bind';
import styles from '../prefer.module.scss';
const cx = classnames.bind(styles);

interface LocationItemProps {
  name: string;
  on: boolean;
  toggleSelected: () => void;
}

const LocationItem: FC<LocationItemProps> = ({ name, on, toggleSelected }) => {
  return (
    <li className={cx('listItem')}>
      <a className={cx('btnLocation', { on })} role="button" onClick={toggleSelected}>
        {name}
      </a>
    </li>
  );
};

interface LocationProps extends UseControllerProps {
  visible: boolean;
}

// 초기값 지정.
const Location: FC<LocationProps> = (props) => {
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const { field } = useController(props);

  useEffect(() => {
    if (field.value.length === ALL_Locations.length) setIsSelectedAll(true);
  }, [field]);

  const isSelected = (location: LocationValue) => field.value?.includes(location);

  const toggleSelected = (location: LocationValue) => {
    if (field?.value?.includes(location)) {
      setIsSelectedAll(false);
      field.onChange(field.value.filter((value: LocationValue) => value !== location));
    } else {
      field.onChange([...field.value, location]);
    }
  };

  const handleSelectAll = () => {
    if (isSelectedAll) {
      setIsSelectedAll(false);
      field.onChange([]);
    } else {
      setIsSelectedAll(true);
      field.onChange(ALL_Locations);
    }
  };

  const locationListEl = ALL_Locations.map((location, index) => {
    return (
      <LocationItem
        key={index}
        name={LocationNameMap[location]}
        on={isSelected(location)}
        toggleSelected={() => toggleSelected(location)}
      />
    );
  });

  if (!props.visible) return null;

  return (
    <div className={cx('locationArea')}>
      <div className={cx('locationListWrap')}>
        <ul className={cx('locationList')}>
          <li className={cx('listItem')}>
            <a className={cx('btnLocation', { on: isSelectedAll })} role="button" onClick={handleSelectAll}>
              전체 지역
            </a>
          </li>
          {locationListEl}
        </ul>
      </div>
    </div>
  );
};

export default Location;
