import { baseURL } from '../common';
import { AccaRouteUrl, V2RouteUrl } from '@utils/routeUtil';

export type MatchPath = 'position' | 'dashboard' | 'profile' | 'introduce';
export type MatchProfilePagePath =
  | 'gate'
  | 'seek'
  | 'edu'
  | 'skills'
  | 'careers'
  | 'acc'
  | 'blind'
  | 'prefer'
  | 'phs_acc';

export class MatchRouteUtil {
  static getMatchPositionMainPage() {
    return `/match/position`;
  }

  static getMatchMenuStatus(pathname: string, path: MatchPath) {
    return pathname.includes(path);
  }

  static getMatchProfilePage(path?: MatchProfilePagePath) {
    if (path === 'acc') return AccaRouteUrl.ACCA_TEST_GATE;
    if (path === 'phs_acc') return V2RouteUrl.PHS_TEST_GATE;

    return `/match/profile/${path}`;
  }

  static getMatchPositionPage(positionSn: number) {
    return `/match/position/${positionSn}/jd`;
  }

  //기술 키워드 요청하기 페이지
  static getRequestSkillKeywordPage() {
    return 'https://docs.google.com/forms/d/1TXJVG4fDhWXFr_1D70oFBf2XBuV3Q9ygDJBDfPo7h9s/edit';
  }

  // KCB SMS 본인인증 페이지
  static openKcbSmsPage() {
    window?.open(`${baseURL}/kcb/verification/sms`, 'auth_popup', 'width=804,height=1245,scrollbar=yes');
  }

  // KCB IPIN 본인인증 페이지
  static openKcbIpinPage() {
    window?.open(`${baseURL}/kcb/verification/ipin`, 'kcbPop', 'left=200, top=100, status=0, width=450, height=550');
  }
}

export enum MatchRouteUrl {
  //매칭 관련
  MATCH_PROFILE = '/match/profile/gate',
  MATCH_PROFILE_SEEK = '/match/profile/seek',
  MATCH_PROFILE_EDU = '/match/profile/edu',
  MATCH_PROFILE_SKILLS = '/match/profile/skills',
  MATCH_PROFILE_CAREERS = '/match/profile/careers',
  MATCH_PROFILE_BLIND = '/match/profile/blind',
  MATCH_PROFILE_PREFER = '/match/profile/prefer',
  MATCH_PROFILE_PHS_ACC = '/match/profile/phs_acc',
  MATCH_PROFILE_ACC = '/match/profile/acc',
  MATCH_INTRODUCE = '/match/introduce',
  MATCH_JOBDA_IM = 'https://match.jobda.im',
}
