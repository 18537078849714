import _axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import ApiErrorRs from '@domain/rs/common/ApiErrorRs';
import { removeAccessToken, removeAutoLogin, setAccessToken } from '@repository/AccessToken';
import { RemoteAuthRepo } from '@repository/auth/AuthRepo';
import { UnauthorizedException } from '@domain/constant/UnauthorizedException';
import { baseURL } from '@utils/common';
import AuthUtil, { AuthErrorCode } from '@utils/AuthUtil';

// query param format 맞춰주는 함수
// 기본 axios param format = a[]=1&a[]=2
// ParamsSerializer format = a=1,2
export const defaultParamsSerializer = (paramObj: Record<string, any>) => {
  // corejs3에서 URLSearchParams polyfill 제공
  const params = new URLSearchParams();
  Object.entries(paramObj)
    .filter(([_, value]) => value !== undefined)
    .forEach(([key, value]) => {
      params.append(key, value);
    });

  return params.toString();
};

// TODO: accessToken 갱신 과정 로그 추후에 삭제 필요.
// TODO: accessToken 갱신 과정 test 추가 필요.
export class RequestClient {
  static expiredAccountRedirect = '/login?expired=true';

  private axios!: AxiosInstance;
  private accessTokenRefreshSubscribers: (() => void)[] = [];
  private get isRefreshing() {
    return this.accessTokenRefreshSubscribers.length > 0;
  }

  constructor() {
    this.init();
  }

  getAxios() {
    return this.axios;
  }

  private init() {
    this.axios = _axios.create({ baseURL });
    this.axios.defaults.paramsSerializer = defaultParamsSerializer;
    this.setResponseInterceptor();
  }

  private setResponseInterceptor() {
    this.axios.interceptors.response.use(undefined, (e: AxiosError<ApiErrorRs>) => this.formatErrorResponse(e));
  }

  private async formatErrorResponse(e: AxiosError<ApiErrorRs>): Promise<Error | AxiosResponse> {
    if (!e.response) return Promise.reject(e);

    // 공통 authError 판별
    const { data, status } = e.response;
    const errorCode: AuthErrorCode | string = data.errorCode;

    if (status === 401) {
      switch (errorCode) {
        case 'U001':
          return Promise.reject(new UnauthorizedException('authRequired'));
        // st <-> st2에서 같은 토큰 사용시 발생하는 에러 처리
        case 'J001':
          AuthUtil.resetAuth();
          return Promise.reject(e);
        // refresh token 갱신 시도에서 J003 에러가 발생하면 로그아웃 처리 (30일 이상 미접속 유저)
        case 'J003':
          if (e.config.url?.includes('reissue-token')) {
            removeAutoLogin();
            removeAccessToken();
            return Promise.reject(e);
          }
          if (!this.isRefreshing) return this.onRefreshAccessToken(e.config);
          return this.getRetryAfterRefreshAccessToken(e.config);
      }
    }

    return Promise.reject(e);
  }

  private async onRefreshAccessToken(requestConfig: AxiosRequestConfig) {
    const promise = this.getRetryAfterRefreshAccessToken(requestConfig);
    try {
      const { data: renewAccessTokenRs } = await new RemoteAuthRepo().renewAccessTokenRefreshToken();
      const accessToken = renewAccessTokenRs.accessToken;
      setAccessToken(accessToken);
      this.onAccessTokenRefreshEnd();
    } catch (e) {
      removeAutoLogin();
      removeAccessToken();
      setAxiosHeader('Authorization', '');
    }
    return promise;
  }

  private onAccessTokenRefreshEnd() {
    this.accessTokenRefreshSubscribers.forEach((v) => v());
    this.accessTokenRefreshSubscribers.splice(0);
  }

  private appendAccessTokenRefreshSubscriber(subscriber: () => void) {
    this.accessTokenRefreshSubscribers.push(subscriber);
  }

  private refreshExpiredTokenRequest(requestConfig: AxiosRequestConfig) {
    if (requestConfig.headers) {
      requestConfig.headers.Authorization = this.axios.defaults.headers.common.Authorization;
    }
  }

  private getRetryAfterRefreshAccessToken(requestConfig: AxiosRequestConfig) {
    return new Promise<AxiosResponse>((resolve) => {
      this.appendAccessTokenRefreshSubscriber(() => {
        this.refreshExpiredTokenRequest(requestConfig);
        resolve(this.axios.request(requestConfig));
      });
    });
  }
}

const axios = new RequestClient().getAxios();

type AxiosHeaderKeyType = 'Authorization';

export const setAxiosHeader = (key: AxiosHeaderKeyType, value: string) => {
  axios.defaults.headers.common[key] = value;
};

type ApiErrorHandler<T extends string, R> = (e: AxiosError<ApiErrorRs<T>>) => R;
export const handleErrorResponse = <T extends string, R>(e: unknown, handler?: ApiErrorHandler<T, R>) => {
  if (!handler) throw e;

  if (_axios.isAxiosError(e)) {
    return handler(e as AxiosError<ApiErrorRs<T>>);
  }

  throw e;
};

export { axios };
