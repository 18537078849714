import React, { ReactNode, useState, Children, isValidElement, ReactElement } from 'react';

interface FennelItemProps {
  children: ReactNode;
}

const FennelItem: React.FC<FennelItemProps> = ({ children }) => {
  return <>{children}</>;
};

interface FennelProps {
  startStep?: number;
  layout?: (content: ReactNode, step: number, prevStep: () => void, nextStep: () => void) => ReactNode;
  children: (props: { step: number; prevStep: () => void; nextStep: () => void }) => ReactNode;
}

const Fennel = ({ startStep, layout, children }: FennelProps) => {
  const [step, setStep] = useState(startStep ?? 0);

  const prevStep = () => setStep(Math.max(0, step - 1));
  const nextStep = () => setStep(step + 1);

  const renderedChildren = children({ step, prevStep, nextStep });

  //Fragment 태그 사용으로  Fennel.Item값 추출
  const findFennelItems = (node: ReactNode): ReactElement<FennelItemProps>[] => {
    let items: ReactElement<FennelItemProps>[] = [];

    Children.forEach(node, (child) => {
      if (isValidElement(child)) {
        if (child.type === FennelItem) {
          items.push(child as ReactElement<FennelItemProps>);
        } else if (child.props && child.props.children) {
          items = [...items, ...findFennelItems(child.props.children)];
        }
      }
    });

    return items;
  };

  const steps = findFennelItems(renderedChildren);
  const currentStepContent = steps[step];
  const contentToRender = layout ? layout(currentStepContent, step, prevStep, nextStep) : currentStepContent;

  return <>{contentToRender}</>;
};

Fennel.Item = FennelItem;

export default Fennel;
