import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useUserInfo } from '@repository/auth/useUserInfo';
import LoginModal from '@components/loginModal';
import useModal from '@utils/hooks/useModal';
import { V2RouteUrl } from '@utils/routeUtil';

interface OpenLoginModalProps {
  afterLogin?: () => void;
  redirectUrl?: string;
  closeOnBgClick?: boolean;
}

interface LoginModalContextType {
  openLoginModal: (options?: OpenLoginModalProps) => void;
  isLogin: boolean;
}

const LoginModalContext = React.createContext<LoginModalContextType>({
  openLoginModal: () => {},
  isLogin: false,
});

export const LoginModalProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const userInfo = useUserInfo();
  const isLogin = !!userInfo.data?.id;
  const { closeModal, isModalOpen, openModal } = useModal();
  const [onClickBg, setOnClickBg] = React.useState<undefined | (() => void)>(undefined);
  const [afterLogin, setAfterLogin] = React.useState<undefined | (() => void)>(undefined);
  const [redirectUrl, setRedirectUrl] = React.useState<undefined | string>(undefined);

  const openLoginModal = ({ afterLogin, redirectUrl, closeOnBgClick }: OpenLoginModalProps = {}) => {
    setAfterLogin(() => afterLogin);
    closeOnBgClick && setOnClickBg(() => onClose);
    setRedirectUrl(() => redirectUrl);
    openModal();
  };

  const onClose = () => {
    closeModal();
    setAfterLogin(undefined);
    setOnClickBg(undefined);
    setRedirectUrl(undefined);
  };

  useEffect(() => {
    if (router.pathname === V2RouteUrl.JOIN) closeModal();
  }, [router]);

  return (
    <LoginModalContext.Provider value={{ openLoginModal, isLogin }}>
      {children}
      <LoginModal
        subTitle="채용정보 · 역량검사 · 매칭까지"
        title="취업기회 지금 바로 잡다!"
        visible={isModalOpen}
        onClose={onClose}
        onClickBg={onClickBg}
        afterLogin={afterLogin}
        redirectUrl={redirectUrl}
      />
    </LoginModalContext.Provider>
  );
};

export const useLoginModal = () => {
  const context = React.useContext(LoginModalContext);
  if (context === undefined) {
    throw new Error('useLoginModal must be used within a LoginModalProvider');
  }
  return context;
};
