import { FC } from 'react';
import { useRouter } from 'next/router';
import Text from '@components/common/text';
import CloseButton from '@components/common/closeButton';
import Icon from '@components/common/assets';
import ProgressBar from '@components/progressBar';
import { useMatchProfile } from '@repository/match';
import { useInsightResumeLog } from '@repository/insight/useInsight';
import Education from './education';
import Career from './career';
import Project from './project';
import Experience from './experience';
import Prize from './prize';
import License from './license';
import { CheckListType, EditViewType } from '..';
import { FontStyle } from '@domain/constant/fontStyle';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const ComponentLogId = 'btn_exit_insight_resume';

interface ListViewProps {
  isEmptyResumeItem: boolean;
  checkList: Map<CheckListType, number[]>;
  updateCheckList: (type: CheckListType, index: number) => void;
  onClose: () => void;
  nextStep: () => void;
  prevStep: () => void;
  openEditView: (type: EditViewType, formName: string) => void;
  isFillConditions: boolean;
}

const ListView: FC<ListViewProps> = ({
  isEmptyResumeItem,
  checkList,
  updateCheckList,
  onClose,
  nextStep,
  prevStep,
  openEditView,
  isFillConditions,
}) => {
  const { pathname } = useRouter();
  const { data: matchProfile } = useMatchProfile();
  const { mutateAsync: fetchLog } = useInsightResumeLog();
  const getEmptyCheckList = (checkList: Map<CheckListType, number[]>) => {
    const valuesArray = Array.from(checkList.values());
    for (const valueArray of valuesArray) {
      if (valueArray?.length !== 0) {
        return false;
      }
    }
    return true;
  };

  const isEmptyCheckList = getEmptyCheckList(checkList);
  const handleClickExit = async () => {
    await fetchLog({
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: ComponentLogId,
      data: {
        profilePercent: matchProfile?.profile.completionRate ?? 0,
      },
    });
    onClose();
  };

  return (
    <>
      <Text className={cx('modalTitle')} fontStyle={FontStyle.H4_B}>
        지원서 불러오기
      </Text>
      <CloseButton className={cx('btnClose')} onClick={handleClickExit} />
      <ProgressBar
        className={cx('progressBar')}
        startProgress={isFillConditions ? 2 * 25 : 2 * 20}
        progress={isFillConditions ? 3 * 25 : 3 * 20}
      />

      <div className={cx('stepWrap')}>
        <div>
          {!isEmptyResumeItem && (
            <div className={cx('titleArea')}>
              성공적으로 지원서 정보를 불러왔어요!
              <br /> 내 프로필에 가져올 지원서 정보를 선택해 주세요.
            </div>
          )}

          {isEmptyResumeItem ? (
            <div className={cx('scrollArea')}>
              <div className={cx('scrollInner')}>
                <div className={cx('emptyArea')}>
                  <Icon name="joyEmpty2" width={104} height={104} />
                  <Text fontStyle={FontStyle.H5_B}>불러올 정보가 없어요.</Text>
                </div>
              </div>
            </div>
          ) : (
            <div className={cx('scrollArea')}>
              <div className={cx('scrollInner')}>
                <div className={cx('contents')}>
                  <Education
                    checkList={checkList.get('education')}
                    updateCheckList={updateCheckList}
                    openEditView={openEditView}
                  />
                  <Career
                    checkList={checkList.get('career')}
                    updateCheckList={updateCheckList}
                    openEditView={openEditView}
                  />
                  <Project
                    checkList={checkList.get('project')}
                    updateCheckList={updateCheckList}
                    openEditView={openEditView}
                  />
                  <License />
                  <Experience
                    checkList={checkList.get('experience')}
                    updateCheckList={updateCheckList}
                    openEditView={openEditView}
                  />
                  <Prize
                    checkList={checkList.get('prize')}
                    updateCheckList={updateCheckList}
                    openEditView={openEditView}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={cx('btnArea')}>
          <button className={cx('prev')} onClick={prevStep}>
            이전
          </button>
          <button className={cx('confirm')} onClick={nextStep} disabled={isEmptyCheckList}>
            가져오기
          </button>
        </div>
      </div>
    </>
  );
};

export default ListView;
