import { useState } from 'react';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from 'react-datepicker';
import { ko } from 'date-fns/locale';
import Header from '@common/datePicker/common/Header';

import classnames from 'classnames/bind';
import style from './datePicker.module.scss';
const cx = classnames.bind(style);

export type PickerType = 'DAY' | 'MONTH';
export type ModeType = 'DAY' | 'MONTH' | 'YEAR';

type DatePickerProps = ReactDatePickerProps & {};
type setModeType = (mode: ModeType | null) => void;

const isMode = (mode: ModeType | null, modeType: ModeType) => mode === modeType;

const handleClickOutside = (setMode: setModeType) => setMode(null);

const renderDayContents = (dayOfMonth: number, date?: Date) => (
  <span className={`day ${date?.getDay() === 0 && 'sunday'}`}>{dayOfMonth}</span>
);

const renderCustomHeader = (
  props: ReactDatePickerCustomHeaderProps,
  mode: ModeType | null,
  setMode: setModeType,
  pickerType: PickerType,
) => <Header mode={mode} setMode={setMode} pickerType={pickerType} {...props} />;

const DatePicker = (props: DatePickerProps) => {
  const [mode, setMode] = useState<ModeType | null>(null);

  const handleInputClick = () => setMode('DAY');

  const handleSelect = () => {
    switch (mode) {
      case 'YEAR':
      case 'MONTH':
        setMode('DAY');
        break;
      case 'DAY':
        setMode(null);
        break;
    }
  };

  return (
    <div className={cx('wrap')}>
      <ReactDatePicker
        open={mode !== null}
        locale={ko}
        dateFormat={'yyyy. MM. dd'}
        renderDayContents={renderDayContents}
        renderCustomHeader={(props) => renderCustomHeader(props, mode, setMode, 'DAY')}
        showYearPicker={isMode(mode, 'YEAR')}
        showMonthYearPicker={isMode(mode, 'MONTH')}
        disabledKeyboardNavigation
        showFourColumnMonthYearPicker
        shouldCloseOnSelect={false}
        showPopperArrow={false}
        yearItemNumber={12}
        onFocus={handleInputClick}
        onSelect={handleSelect}
        onBlur={() => setMode(null)}
        onClickOutside={() => handleClickOutside(setMode)}
        {...props}
      />
    </div>
  );
};

const MonthPicker = (props: DatePickerProps) => {
  const [mode, setMode] = useState<ModeType | null>(null);

  const handleInputClick = () => {
    if (props.readOnly) return;
    setMode('MONTH');
  };

  const handleSelect = () => {
    if (props.readOnly) return;

    switch (mode) {
      case 'YEAR':
        setMode('MONTH');
        break;
      case 'MONTH':
        setMode(null);
        break;
    }
  };

  return (
    <div className={cx('wrap')}>
      <ReactDatePicker
        open={mode !== null}
        locale={ko}
        dateFormat={'yyyy. MM'}
        renderDayContents={renderDayContents}
        renderCustomHeader={(props) => renderCustomHeader(props, mode, setMode, 'MONTH')}
        showYearPicker={isMode(mode, 'YEAR')}
        showMonthYearPicker={isMode(mode, 'MONTH')}
        disabledKeyboardNavigation
        showFourColumnMonthYearPicker
        shouldCloseOnSelect={false}
        showPopperArrow={false}
        yearItemNumber={12}
        onFocus={handleInputClick}
        onSelect={handleSelect}
        onClickOutside={() => handleClickOutside(setMode)}
        {...props}
      />
    </div>
  );
};

/**DatePicker dateFormat={'yyyy. MM. dd'}형식일 때, input 처리 */
const handleYearInput = (event: React.FocusEvent<HTMLInputElement>) => {
  if (!event.target.value) return;
  let convertDate = '';
  const onlyNumber = event.target.value.replace(/[^0-9]/g, '');

  if (onlyNumber.length < 5) {
    convertDate = onlyNumber;
  } else if (onlyNumber.length < 7) {
    const month = onlyNumber.substring(4, 6);
    convertDate += onlyNumber.substring(0, 4);
    convertDate += '. ';
    /**12월 초과인 경우 앞에 0추가. ex) 2 -> 02 */
    if (month > '12') {
      convertDate += `0${month[0]}`;
    } else {
      convertDate += month;
    }
  } else {
    const day = onlyNumber.substring(6, 8);
    convertDate += onlyNumber.substring(0, 4);
    convertDate += '. ';
    convertDate += onlyNumber.substring(4, 6);
    convertDate += '. ';
    /**31일 초과인 경우 앞에 0추가. ex) 4 -> 04 */
    if (day > '31') {
      convertDate += `0${day[0]}`;
    } else {
      convertDate += day;
    }
  }

  event.target.value = convertDate;
};

/**DatePicker dateFormat={'yyyy. MM'}형식일 때, input 처리 */
const handleMonthInput = (event: React.FocusEvent<HTMLInputElement>) => {
  if (!event.target.value) return;
  let convertDate = '';
  const onlyNumber = event.target.value.replace(/[^0-9]/g, '');

  if (onlyNumber.length < 5) {
    convertDate = onlyNumber;
  } else {
    const month = onlyNumber.substring(4, 6);
    convertDate += onlyNumber.substring(0, 4);
    convertDate += '. ';
    if (month > '12') {
      convertDate += `0${month[0]}`;
    } else {
      convertDate += month;
    }
  }

  event.target.value = convertDate;
};

export { DatePicker, MonthPicker, handleYearInput, handleMonthInput };
