import { AxiosResponse } from 'axios';
import { ProfileFileUploadRs } from '@domain/rs/common/v1/FileRs';
import { axios } from '@repository/RequestClient';
import ProfileProjectListSaveRq from '@domain/rq/profile/ProfileProjectListSaveRq';
import JobGroupSTitleRq from '@domain/rq/match/JobGroupSTitleRq';
import { ProfileBasicInfoRq } from '@domain/rq/profile/ProfileBasicInfoRq';
import ProfileEducationRq from '@domain/rq/profile/ProfileEducationRq';
import ProfileSkillSaveRq from '@domain/rq/profile/ProfileSkillSaveRq';
import ProfileExperienceSaveRq from '@domain/rq/profile/ProfileExperienceSaveRq';
import ProfilePrizeSaveRq from '@domain/rq/profile/ProfilePrizeSaveRq';
import ProfilePreferentialSaveRq from '@domain/rq/profile/ProfilePreferentialSaveRq';
import ProfileAttachFileSaveRq from '@domain/rq/profile/ProfileAttachFileSaveRq';
import MatchingConditionLocationRq from '@domain/rq/profile/matchingConditions/MatchingConditionLocationRq';
import MatchingConditionWelfareRq from '@domain/rq/profile/matchingConditions/MatchingConditionWelfareRq';
import MatchingConditionWorkingPreferenceRq from '@domain/rq/profile/matchingConditions/MatchingConditionWorkingPreferenceRq';
import MatchingConditionJobGroupRq from '@domain/rq/profile/matchingConditions/MatchingConditionJobGroupRq';
import MatchingConditionSalaryRq from '@domain/rq/profile/matchingConditions/MatchingConditionSalaryRq';
import ProfilesTypeRs from '@domain/rs/match/ProfilesTypeRs';
import BlindCompanyListRs from '@domain/rs/profile/blind/BlindCompanyListRs';
import { ProfileProjectRs } from '@domain/rs/profile/ProfileProjectRs';
import { ProfileCompleteRs } from '@domain/rs/position/jd/ProfileCompleteRs';
import { ProfileEnforceStepStatusRs } from '@domain/rs/profile/ProfileEnforceStepStatusRs';
import { PictureRs } from '@domain/rs/profile/PictureRs';
import { ProfileExperienceRs } from '@domain/rs/profile/ProfileExperienceRs';
import { ProfileBasicRs } from '@domain/rs/profile/ProfileBasicRs';
import { ProfileConditionsRs } from '@domain/rs/profile/ProfileConditionsRs';
import { ProfileEducationRs } from '@domain/rs/profile/ProfileEducationRs';
import { ProfileSkillRs } from '@domain/rs/profile/ProfileSkillRs';
import { ProfilePrizeRs } from '@domain/rs/profile/ProfilePrizeRs';
import { ProfilePreferentialRs } from '@domain/rs/profile/ProfilePreferentialRs';
import { ProfileFileRs } from '@domain/rs/profile/ProfileFileRs';
import ProfileDetailRs from '@domain/rs/position/jd/ProfileDetailRs';

export default interface ProfilesRepo {
  // 프로필 파일 업로드
  uploadProfileFile(file: File): Promise<AxiosResponse<ProfileFileUploadRs>>;

  // 프로필 타입 조회(일반/개발자)
  fetchProfilesType(): Promise<AxiosResponse<ProfilesTypeRs>>;

  //취준정보 > 선호 직군/직무 저장
  fetchSaveJobGroupTitles(jobGroups: JobGroupSTitleRq[]): Promise<AxiosResponse<boolean>>;

  // 프로필의 지식기술/경력정보 강화문구 필요여부 조회
  fetchProfileEnforceStepStatus(): Promise<AxiosResponse<ProfileEnforceStepStatusRs>>;

  // 프로필 사진 > 조회
  fetchProfilePicture(): Promise<AxiosResponse<PictureRs>>;

  // 기본 정보 > 조회
  fetchProfileBasic(): Promise<AxiosResponse<ProfileBasicRs>>;

  // 기본 정보 > 저장
  fetchSaveProfileBasic(rq: ProfileBasicInfoRq): Promise<AxiosResponse<boolean>>;

  //선호 정보 > 조회
  fetchProfileConditions(): Promise<AxiosResponse<ProfileConditionsRs>>;

  // 선호 정보 > 희망 직군, 직무 > 저장
  fetchSaveJobGroups(rq: MatchingConditionJobGroupRq): Promise<AxiosResponse<boolean>>;

  // 선호 정보 > 희망 근무 지역 > 저장
  fetchSaveLocations(rq: MatchingConditionLocationRq): Promise<AxiosResponse<boolean>>;

  // 선호 정보 > 희망 연봉 > 저장
  fetchSaveSalary(rq: MatchingConditionSalaryRq): Promise<AxiosResponse<boolean>>;

  // 선호 정보 > 희망 복지 > 저장
  fetchSaveWelfare(rq: MatchingConditionWelfareRq): Promise<AxiosResponse<boolean>>;

  // 선호 정보 > 희망 근무 조건 > 저장
  fetchSaveWorkingPreference(rq: MatchingConditionWorkingPreferenceRq): Promise<AxiosResponse<boolean>>;

  // 학력정보 > 조회
  fetchProfileEducation(): Promise<AxiosResponse<ProfileEducationRs>>;

  // 학력정보 > 저장
  fetchSaveProfileEducation(rq: ProfileEducationRq): Promise<AxiosResponse<boolean>>;

  // 지식·기술 정보 > 조회
  fetchProfileSkill(): Promise<AxiosResponse<ProfileSkillRs>>;

  // 지식·기술 정보 > 저장
  fetchSaveProfileSkill(rq: ProfileSkillSaveRq): Promise<AxiosResponse<boolean>>;

  // 경험 정보 > 조회
  fetchProfileExperience(): Promise<AxiosResponse<ProfileExperienceRs>>;

  // 경험 정보 > 저장
  fetchSaveProfileExperience(rq: ProfileExperienceSaveRq): Promise<AxiosResponse<boolean>>;

  // 수상 내역 > 조회
  fetchProfilePrize(): Promise<AxiosResponse<ProfilePrizeRs>>;

  // 수상 내역 > 저장
  fetchSaveProfilePrize(rq: ProfilePrizeSaveRq): Promise<AxiosResponse<boolean>>;

  // 병역·우대사항 정보 > 조회
  fetchProfilePreferential(): Promise<AxiosResponse<ProfilePreferentialRs>>;

  // 병역·우대사항 정보 > 저장
  fetchSaveProfilePreferential(rq: ProfilePreferentialSaveRq): Promise<AxiosResponse<boolean>>;

  // 첨부자료 > 조회
  fetchProfileFile(): Promise<AxiosResponse<ProfileFileRs>>;

  // 첨부자료 > 저장
  fetchSaveProfileFile(rq: ProfileAttachFileSaveRq): Promise<AxiosResponse<boolean>>;

  // 블라인드 기업 > 나의 블라인드 기업 조회
  fetchBlindCompanies(): Promise<AxiosResponse<BlindCompanyListRs>>;

  // 블라인드 기업 > 나의 블라인드 기업 저장
  fetchSaveBlindCompanies(blindCompanySnSet: number[]): Promise<AxiosResponse<boolean>>;

  //프로젝트 조회
  fetchProfileProjects(): Promise<AxiosResponse<ProfileProjectRs>>;

  //프로젝트 저장
  fetchSaveProfileProjects(rq: ProfileProjectListSaveRq): Promise<AxiosResponse<boolean>>;

  //프로필 완성 여부 조회
  fetchMatchProfilesComplete(): Promise<AxiosResponse<ProfileCompleteRs>>;

  // 프로필 이미지 저장
  fetchProfilesFileImage(profileImageUrl: string | null): Promise<AxiosResponse>;

  // 내 프로필 상세 조회 (미리보기)
  fetchProfileDetail(): Promise<AxiosResponse<ProfileDetailRs>>;

  // 프로필 검토 확인 완료
  fetchProfileAuditConfirmAll(): Promise<AxiosResponse<boolean>>;
}

export class RemoteProfilesRepo implements ProfilesRepo {
  uploadProfileFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return axios.post(`profiles/file/upload`, formData);
  }

  fetchProfilesType(accessToken?: string): Promise<AxiosResponse<ProfilesTypeRs>> {
    const header = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {};

    return axios.get<ProfilesTypeRs>('/profiles/type', header);
  }

  fetchSaveJobGroupTitles(jobGroups: JobGroupSTitleRq[]) {
    return axios.put<boolean>('/profiles/job-group', { jobGroups });
  }

  fetchProfileEnforceStepStatus(): Promise<AxiosResponse<ProfileEnforceStepStatusRs>> {
    return axios.get<ProfileEnforceStepStatusRs>('/profiles/enforce');
  }

  fetchProfilePicture(): Promise<AxiosResponse<PictureRs>> {
    return axios.get<PictureRs>('/profiles/picture');
  }

  fetchProfileBasic(): Promise<AxiosResponse<ProfileBasicRs>> {
    return axios.get<ProfileBasicRs>('/profiles/basic-info');
  }

  fetchSaveProfileBasic(rq: ProfileBasicInfoRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/profiles/basic-info', rq);
  }

  fetchProfileConditions(): Promise<AxiosResponse<ProfileConditionsRs>> {
    return axios.get<ProfileConditionsRs>('/v2/match/conditions');
  }

  fetchSaveJobGroups(rq: MatchingConditionJobGroupRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/v3/match/conditions/job-groups', rq);
  }

  fetchSaveLocations(rq: MatchingConditionLocationRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/v3/match/conditions/locations', rq);
  }

  fetchSaveSalary(rq: MatchingConditionSalaryRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/v3/match/conditions/salary', rq);
  }

  fetchSaveWelfare(rq: MatchingConditionWelfareRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/v3/match/conditions/welfare', rq);
  }

  fetchSaveWorkingPreference(rq: MatchingConditionWorkingPreferenceRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/v3/match/conditions/working-preference', rq);
  }

  fetchProfileEducation(): Promise<AxiosResponse<ProfileEducationRs>> {
    return axios.get<ProfileEducationRs>('/profiles/education');
  }

  fetchSaveProfileEducation(rq: ProfileEducationRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/v2/profiles/education', rq);
  }

  fetchProfileSkill(): Promise<AxiosResponse<ProfileSkillRs>> {
    return axios.get<ProfileSkillRs>('/profiles/skill');
  }

  fetchSaveProfileSkill(rq: ProfileSkillSaveRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/profiles/skill', rq);
  }

  fetchProfileExperience(): Promise<AxiosResponse<ProfileExperienceRs>> {
    return axios.get<ProfileExperienceRs>('/profiles/experience');
  }

  fetchSaveProfileExperience(rq: ProfileExperienceSaveRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/profiles/experience', rq);
  }

  fetchProfilePrize(): Promise<AxiosResponse<ProfilePrizeRs>> {
    return axios.get<ProfilePrizeRs>('/profiles/prize');
  }

  fetchSaveProfilePrize(rq: ProfilePrizeSaveRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/profiles/prize', rq);
  }

  fetchProfilePreferential(): Promise<AxiosResponse<ProfilePreferentialRs>> {
    return axios.get<ProfilePreferentialRs>('/profiles/preferential');
  }

  fetchSaveProfilePreferential(rq: ProfilePreferentialSaveRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/profiles/preferential', rq);
  }

  fetchProfileFile(): Promise<AxiosResponse<ProfileFileRs>> {
    return axios.get<ProfileFileRs>('/profiles/file');
  }

  fetchSaveProfileFile(rq: ProfileAttachFileSaveRq): Promise<AxiosResponse<boolean>> {
    return axios.put<boolean>('/profiles/file', rq);
  }

  fetchBlindCompanies() {
    return axios.get<BlindCompanyListRs>('/profiles/my-blind-companies');
  }

  fetchSaveBlindCompanies(blindCompanySnSet: number[]) {
    return axios.put<boolean>('/profiles/my-blind-companies', {
      blindCompanySnSet,
    });
  }

  fetchProfileProjects() {
    return axios.get<ProfileProjectRs>('/profiles/projects');
  }

  fetchSaveProfileProjects(rq: ProfileProjectListSaveRq) {
    return axios.put<boolean>('/profiles/projects', rq);
  }

  fetchMatchProfilesComplete(): Promise<AxiosResponse<ProfileCompleteRs>> {
    return axios.get('/profiles/v2/complete');
  }

  fetchProfilesFileImage(profileImageUid: string | null): Promise<AxiosResponse> {
    return axios.put('/profiles/file/image', { profileImageUid });
  }

  fetchProfileDetail(): Promise<AxiosResponse<ProfileDetailRs>> {
    return axios.get<ProfileDetailRs>('/profiles/details');
  }

  fetchProfileAuditConfirmAll() {
    return axios.post<boolean>('/profiles/audit/confirm/all');
  }
}
