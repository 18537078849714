import { useMutation, useQuery } from 'react-query';
import { RemoteAccRepo } from '@repository/acc/AccRepo';
import { RemoteAccApplyRepo } from '@repository/acc/apply/AccApplyRepo';
import { ProgressStatusList } from '@domain/constant/acca/ProgressType';

const accApplyRepo = new RemoteAccApplyRepo();
const accRepo = new RemoteAccRepo();

export const keys = ['acca', 'test', 'apply'];
export const accResultsKeys = ['acca', 'test', 'accResults'];

const fetchAccApply = async () => {
  const { data } = await accApplyRepo.fetchAccApply();
  return data;
};

const fetchApplyTrial = async () => {
  const { data } = await accApplyRepo.fetchApplyTrial();
  return data;
};

const fetchAccResults = async () => {
  const { data } = await accRepo.fetchAccResults();
  const isProgress = data.applyStatus ? ProgressStatusList.includes(data.applyStatus.progressType) : false;

  return {
    ...data,
    isProgress,
  };
};

export function useAccResults(isLogin: boolean) {
  return useQuery(accResultsKeys, fetchAccResults, {
    enabled: isLogin,
  });
}

export function useApplyTest(onSuccess?: () => void) {
  return useMutation(fetchAccApply, {
    onSuccess,
  });
}

export function useApplyTutorial(isLogin: boolean) {
  return useQuery([...keys, 'tutorial'], fetchApplyTrial, {
    enabled: isLogin,
  });
}
