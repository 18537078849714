export enum Project {
  TEAM = 'TEAM',
  PERSONAL = 'PERSONAL',
}

export type ProjectType = keyof typeof Project;

export const ProjectTypeNameMap: Record<ProjectType, string> = {
  [Project.TEAM]: '팀 프로젝트',
  [Project.PERSONAL]: '개인 프로젝트',
};

export const ContributionOptions = [
  { value: 100, label: '100%' },
  { value: 90, label: '90%' },
  { value: 80, label: '80%' },
  { value: 70, label: '70%' },
  { value: 60, label: '60%' },
  { value: 50, label: '50%' },
  { value: 40, label: '40%' },
  { value: 30, label: '30%' },
  { value: 20, label: '20%' },
  { value: 10, label: '10%' },
];
