import MatchingProfileRs, {
  ApplyType,
  MatchingApplyResultDto,
  MatchingProfileDto,
} from '@domain/rs/match/MatchingProfileRs';
import DateFormat from '@utils/date/format';

export default class MatchingProfileVO {
  profile: MatchingProfileDto;
  applyResults: MatchingApplyResultDto[];

  constructor(rs: MatchingProfileRs) {
    this.profile = rs.profile;
    this.applyResults = rs.applyResults.map((dto) => new MatchingApplyResultsVO(dto));
  }
}

export class MatchingApplyResultsVO {
  code: string;
  applyType: ApplyType;
  applyEndDateTime: string;

  constructor(dto: MatchingApplyResultDto) {
    this.code = dto.code;
    this.applyType = dto.applyType;
    this.applyEndDateTime = DateFormat.format(new Date(dto.applyEndDateTime), 'yyyy.MM.dd');
  }
}
