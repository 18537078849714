export const FontStyle = {
  H1_B: {
    fontSize: '48px',
    fontWeight: '700',
    lineHeight: '62px',
  },
  H2_B: {
    fontSize: '40px',
    fontWeight: '700',
    lineHeight: '52px',
  },
  H3_B: {
    fontSize: '32px',
    fontWeight: '700',
    lineHeight: '42px',
  },
  H4_R: {
    fontSize: '24px',
    lineHeight: '32px',
  },
  H4_B: {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '32px',
  },
  H5_R: {
    fontSize: '20px',
    lineHeight: '26px',
  },
  H5_M: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '26px',
  },
  H5_B: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '26px',
  },
  B1_M: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '24px',
  },
  B1_B: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '24px',
  },
  B1P_M: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '28px',
  },
  B1P_B: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '28px',
  },
  B2_M: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  B2_B: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '20px',
  },
  B2P_R: {
    fontSize: '16px',
    lineHeight: '26px',
  },
  B2P_M: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '26px',
  },
  B2P_B: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '26px',
  },
  B3_M: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
  },
  B3_B: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '18px',
  },
  B3P_M: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
  },
  B3P_B: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '22px',
  },
  B4_R: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  B4_M: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
  },
  B4_B: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px',
  },
  B4P_M: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  B4P_B: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '20px',
  },
};

export type FontStyleValueType = (typeof FontStyle)[keyof typeof FontStyle];
export type FontStyleType = keyof typeof FontStyle;
