import { AxiosResponse } from 'axios';
import DuplicateRs from '@domain/rs/common/v1/DuplicateRs';
import SuccessRs from '@domain/rs/common/SuccessRs';
import { axios } from '@repository/RequestClient';
import UsersIdRq from '@domain/rq/find/UsersIdRq';
import UsersPasswordVerificationRq from '@domain/rq/find/UsersPasswordVerificationRq';
import { UserBasicInfoRq } from '@domain/rq/myPage/UserBasicInfoRq';
import { MarketingInfoModifyRq } from '@domain/rq/users/MarketingInfoModifyRq';
import { UserBirthDateUpdateRq } from '@domain/rq/users/UserBirthDateUpdateRq';
import UserPasswordResetRq from '@domain/rq/users/UsersPasswordResetRq';
import { NotificationSettingItemUpdateVO } from '@domain/vo/myPage/NotificationSettingItemUpdateVO';
import UserBasicInfoRs, { CheckDuplicateEmailRs } from '@domain/rs/myPage/UserBasicInfoRs';
import UsersIdRs from '@domain/rs/find/UsersIdRs';
import UsersIdExistRs from '@domain/rs/find/UsersIdExistRs';
import UserPasswordResetRs from '@domain/rs/find/UserPasswordResetRs';
import UsersPasswordVerificationRs from '@domain/rs/find/UsersPasswordVerificationRs';
import MarketingInfoRs from '@domain/rs/users/MarketingInfoRs';
import UserAccountInfoRs from '@domain/rs/myPage/UserAccountInfoRs';

export default interface UsersRepo {
  usersId(usersIdRq: UsersIdRq): Promise<AxiosResponse<UsersIdRs>>;

  usersIdExist(id: string): Promise<AxiosResponse<UsersIdExistRs>>;

  usersPasswordReset(userPasswordResetRq: UserPasswordResetRq): Promise<AxiosResponse<UserPasswordResetRs>>;

  usersPasswordVerification(
    usersPasswordVerificationRq: UsersPasswordVerificationRq,
  ): Promise<AxiosResponse<UsersPasswordVerificationRs>>;

  fetchCheckDuplicationEmail(email: string): Promise<AxiosResponse<CheckDuplicateEmailRs>>;

  // 핸드폰 중복 체크  : 로그인용 : ex) 마이페이지
  fetchDuplicationMobileCheckWhenLogin({
    mobile,
    countryCode,
  }: {
    mobile: string;
    countryCode: string;
  }): Promise<AxiosResponse<DuplicateRs>>;

  // 마케팅 정보 조회 - 알림 관리
  fetchMarketingInfo(): Promise<AxiosResponse<MarketingInfoRs>>;

  // 마케팅 정보 수정
  fetchModifyMarketingInfo(rq: MarketingInfoModifyRq): Promise<AxiosResponse<SuccessRs>>;

  // 매칭포지션 정보 수정
  fetchModifyNotificationInfo(notification: NotificationSettingItemUpdateVO): Promise<AxiosResponse<SuccessRs>>;

  // 회원가입 완료 후 알림 설정
  fetchNotificationProfileRemind(): Promise<AxiosResponse<SuccessRs>>;

  // 계정 정보 조회
  fetchAccountInfo(): Promise<AxiosResponse<UserAccountInfoRs>>;

  // 계정 삭제
  deleteAccount(): Promise<AxiosResponse<SuccessRs>>;

  // 비밀번호 변경
  fetchSavePassword(currentPassword: string, newPassword: string): Promise<AxiosResponse<SuccessRs>>;

  // 기본 정보 조회
  fetchBasicInfo(): Promise<AxiosResponse<UserBasicInfoRs>>;

  // 기본 정보 수정
  fetchSaveBasicInfo(rq: UserBasicInfoRq): Promise<AxiosResponse<SuccessRs>>;

  // 사용자 활동 이력 수 조회
  fetchUsersWorkLogCounts(): Promise<AxiosResponse<UserWorkLogCountsRs>>;

  //생년월일 수정
  fetchUsersBirthDate(rq: UserBirthDateUpdateRq): Promise<AxiosResponse<SuccessRs>>;

  //비밀번호 변경 다음에 하기
  refreshPasswordExpiration(): Promise<AxiosResponse<boolean>>;
}

export class RemoteUsersRepo implements UsersRepo {
  //아이디 찾기
  usersId(usersIdRq: UsersIdRq) {
    return axios.post<UsersIdRs>(`/v2/users/id`, usersIdRq);
  }

  //아이디 존재 유무
  usersIdExist(id: string) {
    return axios.get<UsersIdExistRs>(`/users/id/exist`, { params: { id } });
  }

  usersPasswordReset(userPasswordResetRq: UserPasswordResetRq) {
    return axios.post<UserPasswordResetRs>(`/v2/users/password/reset`, userPasswordResetRq);
  }

  usersPasswordVerification(usersPasswordVerificationRq: UsersPasswordVerificationRq) {
    return axios.post<UsersPasswordVerificationRs>(`/v2/users/password/verification`, usersPasswordVerificationRq);
  }

  fetchCheckDuplicationEmail(email: string) {
    return axios.get<CheckDuplicateEmailRs>(`/users/duplication-check/email`, { params: { email } });
  }

  fetchDuplicationMobileCheckWhenLogin({ mobile, countryCode }: { mobile: string; countryCode: string }) {
    return axios.get(`/users/duplication-check/mobile`, { params: { mobile, countryCode } });
  }

  fetchMarketingInfo() {
    return axios.get<MarketingInfoRs>(`/users/marketing-consent`);
  }

  fetchModifyMarketingInfo(rq: MarketingInfoModifyRq) {
    return axios.put<SuccessRs>(`/users/notification-settings`, rq);
  }

  fetchModifyNotificationInfo(notification: NotificationSettingItemUpdateVO) {
    return axios.put<SuccessRs>(`/users/notification-setting-item`, notification);
  }

  fetchNotificationProfileRemind() {
    return axios.post<SuccessRs>(`/users/notification-setting-item/profile-input-remind`);
  }

  fetchAccountInfo() {
    return axios.get<UserAccountInfoRs>(`/users/account`);
  }

  deleteAccount() {
    return axios.delete<SuccessRs>(`/users`);
  }

  fetchSavePassword(currentPassword: string, newPassword: string) {
    return axios.put<SuccessRs>(`/users/password`, { currentPassword, newPassword });
  }

  fetchBasicInfo(): Promise<AxiosResponse<UserBasicInfoRs>> {
    return axios.get<UserBasicInfoRs>(`/users/default`);
  }

  fetchSaveBasicInfo(rq: UserBasicInfoRq) {
    return axios.put<SuccessRs>(`/users/default`, rq);
  }

  fetchUsersWorkLogCounts() {
    return axios.get(`/users/work-log/counts`);
  }

  fetchUsersBirthDate(rq: UserBirthDateUpdateRq) {
    return axios.put(`/users/birth-date`, rq);
  }

  refreshPasswordExpiration() {
    return axios.put<boolean>('/users/password/expiration/refresh');
  }
}
