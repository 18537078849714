import { SourcePage } from '@domain/rq/DataLog';

export default class MatchingConditionLocationRq {
  locations: number[];
  sourcePage?: SourcePage;

  constructor(rq: MatchingConditionLocationRq) {
    this.locations = rq.locations;
    this.sourcePage = rq.sourcePage;
  }
}
