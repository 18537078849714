import React, { forwardRef } from 'react';
import useTransitionStatus from '@utils/hooks/useTransitionStatus';

interface TransitionProps extends React.HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  duration?: number;
  children: React.ReactNode;
  timingFunction?: React.CSSProperties['transitionTimingFunction'];
}

const Transition = forwardRef<HTMLDivElement, TransitionProps>(
  ({ visible, duration = 0, children, timingFunction = 'ease-in-out', style, ...props }: TransitionProps, ref) => {
    const status = useTransitionStatus(visible, duration);

    const transitionStyle: React.CSSProperties = {
      transition: `opacity ${duration}ms ${timingFunction}`,
      opacity: status === 'entered' || status === 'entering' ? 1 : 0,
      pointerEvents: status === 'exited' ? 'none' : 'auto',
    };

    return (
      <div style={{ ...transitionStyle, ...style }} {...props} ref={ref}>
        {children}
      </div>
    );
  },
);

Transition.displayName = 'Transition';

export default Transition;
