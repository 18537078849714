import React, { FC } from 'react';
import { FindIdStepType } from '@components/loginModal/find/id/index';
import styles from '@components/loginModal/find/find.module.scss';
import { V2Route } from '@utils/routeUtil';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface FindSelectProps {
  setFindIdStep: (state: FindIdStepType) => void;
}

const FindSelect: FC<FindSelectProps> = ({ setFindIdStep }) => {
  const onClickMobile = () => setFindIdStep('MOBILE');
  const onClickIpin = () => V2Route.openKcbIpinPage();

  return (
    <div className={cx('selectArea', 'id')}>
      <div className={cx('noticeWrap')}>찾으실 방법을 선택해 주세요.</div>
      <div className={cx('findSelect', 'id')}>
        <a className={cx('btn', 'mobile')} role="button" onClick={onClickMobile}>
          휴대폰으로 찾기
        </a>
        <a className={cx('btn', 'ipin')} role="button" onClick={onClickIpin}>
          아이핀으로 찾기
        </a>
      </div>
    </div>
  );
};

export default FindSelect;
