import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToast } from '@common/toast';
import { useIdentityPasswordReset } from '@repository/identity/useIdentity';
import { useUsersPasswordReset } from '@repository/users/useUsers';
import { ModalStateType } from '@components/loginModal';
import styles from '@components/loginModal/find/find.module.scss';
import LabelInput from '@components/labelInput';
import { FormTypes } from '.';
import IdentificationPasswordRq from '@domain/rq/verification/IdentificationPasswordRq';
import KcbIpinVerificationRs from '@domain/rs/verification/KcbIpinVerificationRs';
import { onInputPassword, passwordValidator, rePasswordValidator, valueRequired } from '@utils/validators/validators';
import { hasErrorMessage } from '@utils/typeGuard';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface FindPasswordResetProps {
  verificationRs: KcbIpinVerificationRs | null;
  setModalState: (state: ModalStateType) => void;
}

const FindPasswordReset: FC<FindPasswordResetProps> = ({ verificationRs, setModalState }) => {
  const Toast = useToast();
  const { mutateAsync: mobilePasswordReset } = useUsersPasswordReset();
  const { mutateAsync: ipinPasswordReset } = useIdentityPasswordReset();
  const {
    register,
    watch,
    getValues,
    formState: { errors },
    trigger,
  } = useFormContext<FormTypes>();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rePasswordVisible, setRePasswordVisible] = useState(false);

  const [id, password, rePassword] = watch(['id', 'password', 'rePassword']);
  const samePassword = password && rePassword && password === rePassword;

  const isDisabledConfirmPasswordBtn = !(samePassword && !errors.password && !errors.rePassword);

  const identityPasswordReset = async (verificationRs: KcbIpinVerificationRs) => {
    try {
      const rq = new IdentificationPasswordRq({
        token: verificationRs.identificationToken,
        id,
        password,
      });
      const result = await ipinPasswordReset(rq);
      if (!result) {
        Toast({
          type: 'success',
          iconType: 'check',
          content: '변경사항이 저장되었습니다.',
        });
        setModalState('LOGIN');
        return;
      }
    } catch (error: any) {
      let content;
      switch (error.response.data.errorCode) {
        case 'B901':
          content = '아이디를 찾을 수 없습니다.';
          break;
        case 'B902':
          content = '본인인증 세션이 만료되었습니다.';
          break;
        case 'B903':
          content = '계정 아이디와 본인인증된 계정 아이디가 다릅니다.';
          break;
        default:
          content = error.response.data.message;
      }
      Toast({ type: 'error', iconType: 'info', content });
    }
  };

  const onSubmit = async () => {
    //아이핀 인증으로 비밀번호 변경 시
    if (verificationRs) {
      await identityPasswordReset(verificationRs);
      return;
    }
    //휴대폰 인증으로 비밀번호 변경 시
    await mobilePasswordReset(
      { ...getValues(), password, mobile: getValues('mobile')?.replace(/[^0-9]/g, '') },
      {
        onSuccess: () => {
          Toast({
            type: 'success',
            iconType: 'check',
            content: '변경사항이 저장되었습니다.',
          });

          setModalState('LOGIN');
        },
        onError: (err: any) => {
          if (hasErrorMessage(err))
            Toast({
              type: 'error',
              iconType: 'info',
              content: err.response.data.message,
            });
        },
      },
    );
  };

  /**비밀번호 확인이 오류가 난 상태에서(새 비밀번호와 다릅니다.)
   * 비밀번호를 수정하여 error가 해결되었을 때를 적용시켜줘야 함.
   */
  useEffect(() => {
    if (!rePassword) return;
    trigger('rePassword');
  }, [password, trigger]);

  return (
    <div className={cx('resetPwArea')}>
      <em className={cx('text')}>비밀번호를 재설정해주세요.</em>

      <div className={cx('infoArea')}>
        <div className={cx('infoWrap')}>
          <LabelInput
            type={passwordVisible ? 'text' : 'password'}
            className={cx('input', 'withButton', { error: errors.password })}
            placeholder={'비밀번호'}
            minLength={6}
            maxLength={16}
            onInput={onInputPassword}
            {...register('password', {
              ...valueRequired,
              validate: (value: string) => passwordValidator(value, undefined, id),
            })}
            autoComplete="new-password"
          />
          {errors.password && <div className={cx('infoText', 'error')}>{`${errors.password?.message}`}</div>}
          <button
            type="button"
            tabIndex={-1}
            onClick={() => setPasswordVisible((prev) => !prev)}
            className={cx('btnConfirmPassword', { on: passwordVisible })}
          />
        </div>
        <div className={cx('infoWrap', 'rePassword')}>
          <LabelInput
            type={rePasswordVisible ? 'text' : 'password'}
            className={cx('input', 'withButton', { error: errors.rePassword })}
            placeholder="비밀번호 확인"
            minLength={6}
            maxLength={16}
            onInput={onInputPassword}
            {...register('rePassword', {
              ...valueRequired,
              validate: (value: string) => rePasswordValidator(value, password),
            })}
            autoComplete="off"
          />
          {samePassword && <div className={cx('infoText', 'success')}>비밀번호가 일치합니다.</div>}
          {errors.rePassword && <div className={cx('infoText', 'error')}>{`${errors.rePassword.message}`}</div>}
          <button
            type="button"
            tabIndex={-1}
            onClick={() => setRePasswordVisible((prev) => !prev)}
            className={cx('btnConfirmPassword', { on: rePasswordVisible })}
          />
        </div>
      </div>
      <button className={cx('btnFindInfo')} disabled={isDisabledConfirmPasswordBtn} onClick={onSubmit}>
        설정 완료
      </button>
    </div>
  );
};

export default FindPasswordReset;
