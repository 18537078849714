import { useEffect } from 'react';
import Lottie from 'react-lottie';
import Modal from '@components/common/modal';

import Text from '@components/common/text';
import Button from '@components/common/button';
import autoSaveProfle from '@graphic/autoSaveProfile.json';
import InsightResumeAutoSavedGetRs from '@domain/rs/insight/InsightResumeAutoSavedGetRs';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import { MILLISECONDS_PER_DAY } from '@domain/constant/Time';
import useModal from '@utils/hooks/useModal';
import useLocalStorageExpires from '@utils/hooks/useLocalStorageExpires';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface Props {
  visible: boolean;
  data?: InsightResumeAutoSavedGetRs;
}

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: autoSaveProfle,
};

const AutoSaveProfileModalKey = 'autoSaveProfileModal';

const AutoSaveProfileModal = ({ visible, data }: Props) => {
  const { openModal, closeModal, isModalOpen } = useModal();
  const { getIsExpired, setExpires } = useLocalStorageExpires();

  useEffect(() => {
    if (getIsExpired(AutoSaveProfileModalKey) && visible) openModal();
  }, [visible]);

  const handleClose = () => {
    setExpires({ key: AutoSaveProfileModalKey, expires: MILLISECONDS_PER_DAY });
    closeModal();
  };

  return (
    <Modal visible={isModalOpen} dimmed>
      <div className={cx('wrapper')}>
        <div>
          <Text fontStyle={FontStyle.H5_B}>
            {data?.companyName} 제출했던 <br />
            지원서가 프로필에 입력되었어요.
          </Text>
          <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70} styles={{ margin: '16px 0 34px' }}>
            확인이 필요한 정보가 {data?.auditCount}개 있어요
          </Text>
        </div>

        <div className={cx('lottieArea')}>
          <Lottie options={lottieOptions} />
        </div>

        <Button
          fontStyle={FontStyle.B2_B}
          styles={{ width: 180, height: 48, padding: '14px 24px', marginTop: 42, zIndex: 1 }}
          onClick={handleClose}
        >
          내용 확인하기
        </Button>
      </div>
    </Modal>
  );
};

export default AutoSaveProfileModal;
