import { UseMutationOptions, useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { RemoteMatchRepo } from '@repository/match/dashboard/MatchDashboardRepo';
import MatchingRejectRq from '@domain/rq/match/MatchingRejectRq';
import MatchingConfigUpdateRq from '@domain/rq/match/MatchingConfigUpdateRq';
import MatchingStatusListRq, { MatchingStatusListTabType } from '@domain/rq/match/MatchingStatusListRq';
import MatchingAcceptRq from '@domain/rq/match/MatchingAccept';
import MatchingConfigJobSearchRq from '@domain/rq/match/MatchingConfigJobSearchRq';
import MatchingStatusListVO from '@domain/vo/match/MatchingStatusListVO';
import MatchingProfileVO from '@domain/vo/match/MatchingProfileVO';
import MatchingConfigConditionVO from '@domain/vo/match/MatchingConfigConditionVO';
import ProfileDetailVO from '@domain/vo/profile/ProfileDetailVO';
import MatchingCompaniesVO from '@domain/vo/match/MatchingCompaniesVo';

export const MATCH_LIST_QUERY_KEY = 'matchList';
export const MATCH_CONFIG_STATUS_QUERY_KEY = 'matchConfigStatus';
export const MATCHING_STATUS_QUERY_KEY = 'matchingStatus';
export const MATCHING_COMPANY_LIST_KEY = 'matchingCompanyList';
export const MATCHING_RESPONSE_STATUS_QUERY_KEY = 'matchingResponseStatus';

const MATCH_PROFILE_QUERY_KEY = 'matchProfile';
const REJECT_MATCHING_MUTATION_KEY = 'rejectMatching';
const UPDATE_MATCH_CONFIG_MUTATION_KEY = 'updateMatchConfigStatus';
const READ_MATCHING_POSITION_MUTATION_KEY = 'readMatchingPosition';
const READ_MATCHING_POSITION_ALL_MUTATION_KEY = 'readMatchingPositionAll';
const SAVE_JOB_SEARCH_STATUS_MUTATION_KEY = 'saveJobSearchStatus';
const ACCEPT_MATCHING_MUTATION_KEY = 'acceptMatching';
const MATCHING_POSITION_DETAIL_QUERY_KEY = 'matchingPositionDetail';
const LIKE_FAVORITE_POSITION_MUTATION_KEY = 'likeFavoritePosition';
const UNLIKE_FAVORITE_POSITION_MUTATION_KEY = 'unlikeFavoritePosition';

const remoteMatchRepo = new RemoteMatchRepo();

const fetchMatchingStatusList = async (params?: MatchingStatusListRq) => {
  const { data } = await remoteMatchRepo.getMatchingStatusList(params);
  return {
    ...data,
    matchingList: new MatchingStatusListVO(data),
  };
};

const fetchMatchingProfile = async () => {
  const { data } = await remoteMatchRepo.getMatchingProfile();
  return new MatchingProfileVO(data);
};

const fetchRejectMatching = async (rq: MatchingRejectRq) => {
  await remoteMatchRepo.fetchRejectMatching(rq);
};

const fetchMatchingResponseStatus = async () => {
  const { data } = await remoteMatchRepo.fetchMatchingResponseStatus();
  return data;
};

const fetchMatchConfigStatus = async () => {
  const { data } = await remoteMatchRepo.fetchMatchConfigStatus();
  return new MatchingConfigConditionVO(data);
};

const updateMatchConfigStatus = async (rq: MatchingConfigUpdateRq) => {
  await remoteMatchRepo.updateMatchConfigStatus(rq);
};

const fetchReadMatchingPosition = async (matchingSn: number) => {
  await remoteMatchRepo.fetchReadMatchingPosition(matchingSn);
};

const fetchReadMatchingPositionAll = async (tabType: MatchingStatusListTabType) => {
  await remoteMatchRepo.fetchReadMatchingPositionAll(tabType);
};

const fetchMatchingStatus = async () => {
  const { data } = await remoteMatchRepo.getMatchingStatus();
  return data;
};

const saveJobSearchStatus = async (rq: MatchingConfigJobSearchRq) => {
  await remoteMatchRepo.saveJobSearchStatus(rq);
};

const fetchAcceptMatching = async (rq: MatchingAcceptRq) => {
  await remoteMatchRepo.fetchAcceptMatching(rq);
};

const fetchAcceptCBOMatching = async (rq: MatchingAcceptRq) => {
  await remoteMatchRepo.fetchAcceptCBOMatching(rq);
};

export const fetchMatchingPositionDetail = async (positionSn: number) => {
  const { data } = await remoteMatchRepo.fetchMatchingPositionDetail(positionSn);
  return new ProfileDetailVO(data);
};

export const useMatchList = (enabled?: boolean, params?: MatchingStatusListRq) => {
  return useInfiniteQuery([MATCH_LIST_QUERY_KEY, params], () => fetchMatchingStatusList(params), {
    getNextPageParam: ({ pages: { page, totalPages } }) => (page < totalPages ? page + 1 : undefined),
    enabled,
    keepPreviousData: true,
  });
};

export const useMatchProfile = (enabled = true) => {
  return useQuery([MATCH_PROFILE_QUERY_KEY], fetchMatchingProfile, {
    initialData: new MatchingProfileVO({
      profile: {
        profileRequiredCompleteYn: false,
        completionRate: 0,
      },
      applyResults: [],
    }),
    refetchOnWindowFocus: true,
    enabled,
  });
};

export const useRejectMatching = (onSuccess?: () => void, onError?: (err: AxiosError) => void) => {
  return useMutation([REJECT_MATCHING_MUTATION_KEY], fetchRejectMatching, {
    onSuccess,
    onError,
  });
};

export const useMatchingResponseStatus = () => {
  return useQuery([MATCHING_RESPONSE_STATUS_QUERY_KEY], fetchMatchingResponseStatus, {
    initialData: { responseRate: 0 },
  });
};

export const useGetMatchConfigStatus = (enabled?: boolean) => {
  return useQuery([MATCH_CONFIG_STATUS_QUERY_KEY], fetchMatchConfigStatus, {
    initialData: {
      matchingConfigYn: false,
      generalMatchingConfigAvailableYn: false,
      developerMatchingConfigAvailableYn: false,
      profileRequiredCompleteYn: false,
      accCompleteYn: false,
      paccCompleteYn: false,
      pstCompleteYn: false,
      isPosibleMatching: false,
      isNeedTodo: false,
      jobSearchStatus: 'ACTIVE_SEARCH' as const,
      lastProfileUpdateDateTime: null,
    },
    enabled,
  });
};

export const useUpdateMatchConfigStatus = (onSuccess?: () => void, onError?: (err: AxiosError) => void) => {
  return useMutation([UPDATE_MATCH_CONFIG_MUTATION_KEY], updateMatchConfigStatus, {
    onSuccess,
    onError,
  });
};

export const useReadMatchingPosition = (onSuccess?: () => void, onError?: (err: AxiosError) => void) => {
  return useMutation([READ_MATCHING_POSITION_MUTATION_KEY], fetchReadMatchingPosition, {
    onSuccess,
    onError,
  });
};

export const useReadMatchingPositionAll = (onSuccess?: () => void, onError?: (err: AxiosError) => void) => {
  return useMutation([READ_MATCHING_POSITION_ALL_MUTATION_KEY], fetchReadMatchingPositionAll, {
    onSuccess,
    onError,
  });
};

export const useMatchingStatus = (enabled?: boolean) => {
  return useQuery([MATCHING_STATUS_QUERY_KEY], fetchMatchingStatus, {
    initialData: {
      totalCount: 0,
      recommendCount: 0,
      interviewOfferCount: 0,
      offerCount: 0,
      newRecommendYn: false,
      newInterviewOfferOpenYn: false,
      newOfferYn: false,
    },
    enabled,
  });
};

export const useSaveJobSearchStatus = (onSuccess?: () => void, onError?: (err: AxiosError) => void) => {
  return useMutation([SAVE_JOB_SEARCH_STATUS_MUTATION_KEY], saveJobSearchStatus, {
    onSuccess,
    onError,
  });
};

export const useAcceptMatching = ({
  onSuccess,
  onError,
}: { onSuccess?: () => void; onError?: (err: AxiosError) => void } = {}) => {
  return useMutation([ACCEPT_MATCHING_MUTATION_KEY], fetchAcceptMatching, {
    onSuccess,
    onError,
  });
};

export const useAcceptCBOMatching = ({
  onSuccess,
  onError,
}: { onSuccess?: () => void; onError?: (err: AxiosError) => void } = {}) => {
  return useMutation([ACCEPT_MATCHING_MUTATION_KEY], fetchAcceptCBOMatching, {
    onSuccess,
    onError,
  });
};

export const useMatchingPositionDetail = (positionSn: number) => {
  return useQuery([MATCHING_POSITION_DETAIL_QUERY_KEY, positionSn], () => fetchMatchingPositionDetail(positionSn), {
    initialData: {
      basicInfo: null,
      educations: null,
      career: null,
      projects: null,
      knowledgeAndSkill: null,
      experience: null,
      prize: null,
      preferential: null,
      file: null,
      addition: null,
    },
  });
};

const fetchLikeFavoritePosition = async (positionSn: number) => await remoteMatchRepo.likeFavoritePosition(positionSn);

export const useLikeFavoritePosition = (
  options?: Omit<UseMutationOptions<AxiosResponse<void>, AxiosError, number>, 'mutateKey' | 'mutateFn'>,
) => {
  return useMutation([LIKE_FAVORITE_POSITION_MUTATION_KEY], fetchLikeFavoritePosition, options);
};

const fetchUnlikeFavoritePosition = async (positionSn: number) =>
  await remoteMatchRepo.unlikeFavoritePosition(positionSn);

export const useUnlikeFavoritePosition = (
  options?: Omit<UseMutationOptions<AxiosResponse<void>, AxiosError, number>, 'mutateKey' | 'mutateFn'>,
) => {
  return useMutation([UNLIKE_FAVORITE_POSITION_MUTATION_KEY], fetchUnlikeFavoritePosition, options);
};

const fetchMatchingCompanyList = async () => {
  const { data } = await remoteMatchRepo.getMatchingCompanyList();
  return new MatchingCompaniesVO(data);
};

export const useMatchingCompanyList = () => {
  return useQuery(MATCHING_COMPANY_LIST_KEY, fetchMatchingCompanyList, {
    initialData: {
      matchingCompanies: [],
    },
  });
};
