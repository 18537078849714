import ApplyHistoryListRs, { ApplyHistoryDto } from '@domain/rs/acca/test/ApplyHistoryListRs';
import DateFormat from '@utils/date/format';

export default class ApplyHistoryListVO {
  url: string;
  chanceCount: number;
  applies: ApplyHistoryVO[];

  constructor(rs: ApplyHistoryListRs) {
    this.url = rs.url;
    this.chanceCount = rs.chanceCount;
    this.applies = rs.applies.map((item) => new ApplyHistoryVO(item));
  }
}

export class ApplyHistoryVO {
  sn: number;
  code: string;
  name: string;
  externalYn: boolean;
  createdDateTime: string;

  constructor(rs: ApplyHistoryDto) {
    this.sn = rs.sn;
    this.code = rs.code;
    this.name = rs.name;
    this.externalYn = rs.externalYn;
    this.createdDateTime = DateFormat.format(new Date(rs.createdDateTime), 'yyyy년 MM월 dd일 HH:mm');
  }
}
