import { isServer } from '@utils/common';

export default class UrlUtil {
  // %가 포함될 시 URI malformed로 인한 조치
  static decodeURIComponentSafe = (url: string) => {
    if (url == null || url == undefined) return url;

    let out = '',
      i = 0,
      l,
      x;
    const arr = url.split(/(?!%20)((%[0-9A-Z]{2}){3})/g);
    for (l = arr.length; i < l; i++) {
      if (arr[i].length < 9) {
        const temp = arr[i];
        if (!/^%[0-9a-zA-Z]/g.test(temp)) {
          out += temp;
        }
        if (temp.includes('%20')) {
          x = temp.replace(/%20/g, ' ');
          out += x;
        }
      } else if (arr[i].length == 9) {
        try {
          x = decodeURIComponent(arr[i]);
          out += x;
        } catch (e) {
          out += arr[i];
        }
      } else {
        if (arr[i].includes('%20')) {
          x = arr[i].replace(/%20/g, ' ');
          out += x;
        } else {
          out += arr[i];
        }
      }
    }

    return out;
  };

  // 파라미터 key-value 생성(utl String > json object)
  static getParamFromUrl<T extends Record<string, string | undefined>>(url = ''): T {
    if (isServer) return {} as T;
    if (!url) url = location.search;

    const result = url
      .substr(1)
      .split('&')
      .reduce((result, v) => {
        if (!v && v == '') return result;
        const [key, value] = v.split('=');
        try {
          result[key] = decodeURIComponent(value);
        } catch (e) {
          result[key] = UrlUtil.decodeURIComponentSafe(value);
        }
        return result;
      }, {} as Record<string, string | undefined>);
    return result as T;
  }

  // 전달된 URL에 포함된 쿼리 스트링 JSON 객체로 리턴함
  static getQueryStringObjectFromURL = (url = location.href) => {
    const result = UrlUtil.getParamFromUrl(new URL(url).searchParams.toString());
    return result ?? {};
  };

  // URL에 특정 쿼리 파라미터가 있을 경우 제거
  static removeQueryStringAndReplace = (query: string | string[], onAfterReplace?: () => void): void => {
    if (isServer) return;

    const url = new URL(window.location.href);
    const queries = Array.isArray(query) ? query : [query];
    let hasQuery = false;
    queries.forEach((q) => {
      if (url.searchParams.has(q)) {
        url.searchParams.delete(q);
        hasQuery = true;
      }
    });

    if (hasQuery) {
      const newUrl = url.toString();
      history.replaceState(null, '', newUrl);
      onAfterReplace?.();
    }
  };

  /**
   * post 방식으로 새창을 띄우기 위한 코드
   *  isOpen : 새창 표시 여부 false: 페이지 전환됨
   * */
  static openWindowWithPost = (url: string, data: Record<string, string>, isOpen = true) => {
    const form = document.createElement('form');
    if (isOpen) form.target = '_blank';
    form.method = 'POST';
    form.action = url;
    form.style.display = 'none';

    for (const key in data) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };
}
