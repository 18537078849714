import {
  getAccessToken,
  removeAccessToken,
  removeAutoLogin,
  removeGAId,
  setAccessToken,
  setGAId,
} from '@repository/AccessToken';
import { setAxiosHeader } from '@repository/RequestClient';
import AuthRepo, { RemoteAuthRepo } from '@repository/auth/AuthRepo';
import { RouteUrl } from './routeUtil';

export type AuthErrorCode =
  | 'J001' //유효하지 않은 토큰
  | 'J002' //손상된 토큰
  | 'J003' //만료된 토큰
  | 'J008' //refresh 토큰 없음
  | 'U001' //access 토큰 없음
  | 'U003'; //비밀번호 5회 이상 잘못 입력

export default class AuthUtil {
  static repo: AuthRepo = new RemoteAuthRepo();

  static resetAuth = () => {
    removeAutoLogin();
    removeAccessToken();
    removeGAId();
    setAxiosHeader('Authorization', '');
  };

  private static checkValidAccessToken = async () => {
    const { data: userData } = await this.repo.getUser();
    setGAId(userData.id);
  };

  private static renewAccessToken = async () => {
    const { data: renewAccessTokenRs } = await this.repo.renewAccessToken();
    const accessToken = renewAccessTokenRs.accessToken;
    setAccessToken(accessToken);
    this.checkAuthorization();
  };

  static checkAuthorization = async () => {
    try {
      const accessToken = getAccessToken();
      if (accessToken) {
        setAxiosHeader('Authorization', `Bearer ${accessToken}`);
        setAccessToken(accessToken);
        this.checkValidAccessToken();
      }
    } catch (e: any) {
      const data = e.response?.data;
      const status = e.response?.status;
      const errorCode: AuthErrorCode = data?.errorCode;

      //만료된 토큰
      if (status === 401 && errorCode === 'J003') {
        this.renewAccessToken();
      } else {
        this.resetAuth();
        location.href = RouteUrl.INDEX;
      }
    }
  };
}
