import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@components/common/checkbox';
import Icon from '@components/common/assets';
import styles from '../../../commonStyle.module.scss';
import { CheckListType, EditViewType } from '../..';
import InvalidMessage from '../invalidMessage';
import ProjectFormVO from '@domain/vo/insight/resume/ProjectFormVO';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface Props {
  checkList?: number[];
  updateCheckList: (type: CheckListType, index: number) => void;
  openEditView: (type: EditViewType, formName: string) => void;
}

const Project: FC<Props> = ({ checkList, updateCheckList, openEditView }) => {
  const { watch, getFieldState } = useFormContext();
  const data = watch('project') as ProjectFormVO;

  const listEls = data?.projects?.map((project, index) => {
    const isChecked = checkList?.includes(index);
    const invalid = getFieldState(`project.projects.${index}`).invalid;
    const handleCheckBtn = () => {
      if (invalid) return;
      updateCheckList('project', index);
    };
    const handleEditBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      openEditView('project', `project.projects.${index}`);
    };
    const startDate = DateFormat.getDateFormatString(project.startDate, 'yyyy.MM');
    const endDate = DateFormat.getDateFormatString(project.endDate, 'yyyy.MM');

    return (
      <button key={index} className={cx('cardWrap', { invalid }, { isChecked })} onClick={handleCheckBtn}>
        <Checkbox
          className={cx('checkbox')}
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
          disabled={invalid}
          width={24}
          height={24}
          margin={0}
        />

        <div className={cx('infoArea')}>
          <div className={cx('textArea')}>
            <span className={cx('listText')}>{project.title}</span>
            <div className={cx('listDesc')}>
              {invalid ? (
                <InvalidMessage />
              ) : (
                <>
                  <span>{`${startDate} ~ ${endDate}`}</span>
                  <span className={cx('listLabel')}>{project.institution}</span>
                </>
              )}
            </div>
          </div>
          <button className={cx('editBtn', { invalid })} onClick={handleEditBtn}>
            <Icon name="guideLight" width={32} height={32} />
          </button>
        </div>
      </button>
    );
  });

  if (!data?.projects?.length) return null;

  return (
    <div className={cx('listWrap')}>
      <div className={cx('listTitleArea')}>
        <Icon name="projectLight" width={32} height={32} />
        <span className={cx('listTitle')}>프로젝트</span>
      </div>
      <div className={cx('cardList')}>{listEls}</div>
    </div>
  );
};

export default Project;
