import FileRs from '@domain/rs/common/FileRs';
import ProfileViewVO from '@domain/vo/profile/common/ProfileViewVO';
import { ProfileFileRs } from '@domain/rs/profile/ProfileFileRs';

export default class ProfileFileVO extends ProfileViewVO {
  portfolioAttachFiles: FileRs[];
  referenceUrls: string[];
  careerAttachFiles: FileRs[];

  constructor(rs: ProfileFileRs) {
    super(rs, false);

    this.portfolioAttachFiles = rs.portfolioAttachFiles;
    this.referenceUrls = rs.referenceUrls;
    this.careerAttachFiles = rs.careerAttachFiles;
  }
}
