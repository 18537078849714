import LayerModal from './layerModal';
import Popup from './popup';

const AdPopup = () => {
  return (
    <>
      <Popup />
      <LayerModal />
    </>
  );
};

export default AdPopup;
