import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import NhisProfileCareerRq from '@domain/rq/profile/NhisProfileCareerRq';
import { ProfileCareerSaveV2Rq } from '@domain/rq/profile/ProfileCareerSaveV2Rq';
import { ProfileCareersRs } from '@domain/rs/profile/ProfileCareersRs';

export default interface ProfileCareerRepo {
  // 경력 정보 > 조회
  fetchProfileCareer(): Promise<AxiosResponse<ProfileCareersRs>>;

  // 경력 정보 > NHIS 경력 정보 업데이트
  fetchProfileCareerNHIS(rq: NhisProfileCareerRq): Promise<AxiosResponse>;

  // 경력 정보 > 등록
  fetchPostProfilesCareer(rq: ProfileCareerSaveV2Rq): Promise<AxiosResponse>;

  // 경력 정보 > 수정
  fetchPutProfilesCareer({ sn, rq }: { sn: number; rq: ProfileCareerSaveV2Rq }): Promise<AxiosResponse>;

  // 경력 정보 > 삭제
  fetchDeleteProfilesCareer(sn: number): Promise<AxiosResponse>;
}

export class RemoteProfileCareerRepo implements ProfileCareerRepo {
  fetchProfileCareer(): Promise<AxiosResponse<ProfileCareersRs>> {
    return axios.get<ProfileCareersRs>('/profiles/career');
  }

  fetchProfileCareerNHIS(rq: NhisProfileCareerRq): Promise<AxiosResponse> {
    return axios.put('/profiles/career/nhis', rq);
  }

  fetchPostProfilesCareer(rq: ProfileCareerSaveV2Rq): Promise<AxiosResponse> {
    return axios.post('/v2/profiles/career', rq);
  }

  fetchPutProfilesCareer({ sn, rq }: { sn: number; rq: ProfileCareerSaveV2Rq }): Promise<AxiosResponse> {
    return axios.put(`/v2/profiles/career/${sn}`, rq);
  }

  fetchDeleteProfilesCareer(sn: number): Promise<AxiosResponse> {
    return axios.delete(`/v2/profiles/career/${sn}`);
  }
}
