import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import Text from '@components/common/text';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import useActiveTabFlag from '@utils/hooks/useActiveTabFlag';
import classnames from 'classnames/bind';
import styles from '../prefer.module.scss';
const cx = classnames.bind(styles);

interface Props {
  name: string;
  visible: boolean;
}

const maxLength = 500;
const placeholder = `· 금융, IT 등 선호하는 업종을 작성해주세요.
· 스타트업, 공기업 등 원하는 기업 형태를 작성해주세요.
· 커리어 성장, 사내 분위기 등 그 외의 중요하게 생각하는 조건을 적어주세요.`;

const rollingText = [
  '스타트업에서 일하는 것도 괜찮아요.',
  '경기남부와 서울 강남권을 선호해요.',
  '최소 연봉은 4천 이상이면 좋겠어요.',
  'AI 쪽 업종을 선호해요.',
  'UI/UX 프로덕트 디자인 직무로 일하고 싶어요.',
  'JAVA 기술을 위주로 사용하는 곳이면 좋겠어요.',
  'B2C 인하우스 기업에 취업하고 싶어요.',
  '연봉보다는 커리어 성장이 더 중요해요.',
];
const ANGLE = 360 / rollingText.length;

const WorkingPreference: FC<Props> = ({ name, visible }) => {
  const { field } = useController({ name });
  const length = field.value?.length ?? 0;
  const [rotateX, setRotateX] = useState(0);
  const initFlag = useActiveTabFlag();

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > maxLength) {
      return;
    }

    field.onChange(e.target.value);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotateX((prev) => prev - ANGLE);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  if (!visible || !initFlag) return null;

  return (
    <div className={cx('activityWrap')}>
      <div className={cx('textareaWrap')}>
        <textarea
          {...field}
          className={cx('textArea')}
          placeholder={placeholder}
          maxLength={maxLength}
          rows={8}
          onChange={onChange}
        />
      </div>

      <div className={cx('exampleArea')}>
        <div className={cx('rollingArea')}>
          <Text fontStyle={FontStyle.B4_B} color={Colors.C_GREEN_150}>
            예시
          </Text>

          <div className={cx('rotatorArea')}>
            <div
              className={cx('rotator')}
              style={{
                transform: `rotateX(${rotateX}deg)`,
              }}
            >
              {rollingText.map((text, idx) => (
                <div
                  key={idx}
                  className={cx('item')}
                  style={{ transform: `rotateX(${idx * ANGLE}deg) translateZ(50px)` }}
                >
                  {text}
                </div>
              ))}
            </div>
          </div>
        </div>

        <Text fontStyle={FontStyle.B4P_M} color={Colors.C_COOL_GRAY_60}>
          {`${length} / 500`}
        </Text>
      </div>
    </div>
  );
};

export default WorkingPreference;
