import React from 'react';
import CloseButton from '@common/closeButton';
import { FindStateType, LoginForm, ModalStateType, SocialLogin } from '@components/loginModal';

import classnames from 'classnames/bind';
import styles from './login.module.scss';
const cx = classnames.bind(styles);

interface LoginTitleProps {
  subTitle: string | React.ReactElement;
  title: string | React.ReactElement;
}

const LoginTitle = ({ subTitle, title }: LoginTitleProps) => {
  return (
    <div className={cx('titleArea')}>
      <div className={cx('text')}>{subTitle}</div>
      <strong className={cx('title')}>{title}</strong>
    </div>
  );
};

interface LoginProps {
  subTitle?: string;
  title?: string;
  onClose?: () => void;
  afterLogin?: () => void;
  redirectUrl?: string;
  setModalState: (state: ModalStateType) => void;
  setFindState: (state: FindStateType) => void;
}

const Login = ({
  subTitle = '',
  title = '',
  onClose,
  afterLogin,
  redirectUrl,
  setModalState,
  setFindState,
}: LoginProps) => {
  return (
    <div className={cx('login')}>
      <LoginTitle subTitle={subTitle} title={title} />
      <LoginForm
        redirectUrl={redirectUrl}
        afterLogin={afterLogin}
        setModalState={setModalState}
        setFindState={setFindState}
        onClose={onClose}
      />
      <SocialLogin redirectUrl={redirectUrl} />
      {onClose && <CloseButton className={cx('btnClose')} onClick={onClose} />}
    </div>
  );
};
export default Login;
