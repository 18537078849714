export default class UserPasswordResetRq {
  id: string;
  countryCode: string;
  mobile: string;
  token: string;
  password: string;

  constructor(findUserInfo: UserPasswordResetRq) {
    this.id = findUserInfo.id;
    this.countryCode = findUserInfo.countryCode;
    this.mobile = findUserInfo.mobile;
    this.token = findUserInfo.token;
    this.password = findUserInfo.password;
  }
}
