import * as yup from 'yup';
import educationSchema from './educationSchema';
import careerFormSchema from './careerSchema';
import projectFormSchema from './projectSchema';
import experienceFormSchema from './experienceSchema';
import prizeFormSchema from './prizeSchema';

const editFormSchema = yup.object({
  education: yup.object({
    educations: yup.array().of(educationSchema),
  }),
  career: yup.object({
    careers: yup.array().of(careerFormSchema),
  }),
  project: yup.object({
    projects: yup.array().of(projectFormSchema),
  }),
  experience: yup.object({
    activitiesAndForeignExperiences: yup.array().of(experienceFormSchema),
  }),
  prize: yup.object({
    prizes: yup.array().of(prizeFormSchema),
  }),
});

export default editFormSchema;
