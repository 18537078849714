import { UseQueryOptions, useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { PageState } from '@domain/rs/common/PageableRs';
import PageableRq from '@domain/rq/common/PageableRq';
import { PositionBookmarkListItemVO } from '@domain/vo/common/PositionBookmarkListItemVO';
import { RemoteMatchHomeRepo } from '@repository/match/home/MatchHomeRepo';
import { MatchingHomeHiddenPositionVO } from '@domain/vo/match/MatchingHomeHiddenPositionVO';
import ApplicationPositionVO from '@domain/vo/match/ApplicationPositionVO';
import { MatchingApplicationStatusType } from '@domain/rs/match/MatchingHomeApplicationPositionRs';

const defaultPage: PageState = {
  page: 0,
  size: 12,
  totalPages: 0,
  totalElements: 0,
};

const applicationPositionListKeys = ['match', 'applicationPosition', 'list'];
const hiddenPositionListKeys = ['match', 'hidden', 'position'];
const keys = ['match', 'home'];

const remoteMatchHomeRepo = new RemoteMatchHomeRepo();

const fetchApplicationPositionList = async (tabType: MatchingApplicationStatusType) => {
  const { data } = await remoteMatchHomeRepo.fetchApplicationPositionList(tabType);

  return data.jobApplicationProcesses.map((item) => new ApplicationPositionVO(item));
};

const fetchHiddenPositions = async () => {
  const { data } = await remoteMatchHomeRepo.fetchHiddenPositions();
  return new MatchingHomeHiddenPositionVO(data);
};

const fetchPositionsHide = async (positionSn: number) => {
  const { data } = await remoteMatchHomeRepo.fetchPositionsHide(positionSn);
  return data;
};

const fetchPositionsShow = async (positionSns: number[]) => {
  const { data } = await remoteMatchHomeRepo.fetchPositionsShow(positionSns);
  return data;
};

const fetchStatusEnd = async (positionSn: number) => {
  const { data } = await remoteMatchHomeRepo.fetchStatusEnd(positionSn);
  return data;
};

const fetchBookmarkPositionList = async (page = 0) => {
  const rq = new PageableRq({ page, size: defaultPage.size });
  const { data } = await remoteMatchHomeRepo.fetchBookmarkPositionList(rq);
  return {
    ...data,
    positions: data.bookmarks.map(
      (item) =>
        new PositionBookmarkListItemVO({
          ...item,
          sn: item.positionSn,
          title: item.positionName,
          bookmarkYn: true,
        }),
    ),
  };
};

function useApplicationPositionList(tabType: MatchingApplicationStatusType = 'ALL', options?: UseQueryOptions) {
  return useQuery([...applicationPositionListKeys, tabType], () => fetchApplicationPositionList(tabType), {
    ...(options as any),
  });
}

function useHiddenPositions(enabled: boolean) {
  return useQuery(hiddenPositionListKeys, fetchHiddenPositions, { cacheTime: 0, enabled });
}

function usePositionHide(onSuccess: () => void) {
  return useMutation((positionSn: number) => fetchPositionsHide(positionSn), { onSuccess });
}

function usePositionShow(onSuccess?: () => void) {
  return useMutation((positionSns: number[]) => fetchPositionsShow(positionSns), { onSuccess });
}

function useStatusEnd(onSuccess: () => void) {
  return useMutation((positionSn: number) => fetchStatusEnd(positionSn), { onSuccess });
}

function useBookmarkList(enabled = true) {
  return useInfiniteQuery([...keys, 'bookmark', 'list'], ({ pageParam }) => fetchBookmarkPositionList(pageParam), {
    getNextPageParam: ({ pages: { page, totalPages } }) => (page < totalPages ? page + 1 : undefined),
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: 1,
    cacheTime: 0, //필터 초기화시 0 페이지만 조회 하기 위함
    enabled,
  });
}

const getApplicationStatus = async () => {
  const { data } = await remoteMatchHomeRepo.getApplicationStatus();
  return data;
};

const useApplicationStatus = (options?: UseQueryOptions) => {
  return useQuery([...keys, 'applicationStatus'], getApplicationStatus, options as any);
};

const getWritingResumePositions = async () => {
  const { data } = await remoteMatchHomeRepo.getWritingResumePositions();
  return data.writingResumes.map((item) => new ApplicationPositionVO(item));
};

const useWritingResumePositions = (options?: UseQueryOptions) => {
  return useQuery([...keys, 'writingResumePositions'], getWritingResumePositions, options as any);
};

export {
  hiddenPositionListKeys as MatchHomeHiddenPositionListKeys,
  keys as MatchHomeKeys,
  useApplicationPositionList,
  useHiddenPositions,
  usePositionHide,
  usePositionShow,
  useStatusEnd,
  useBookmarkList,
  useApplicationStatus,
  useWritingResumePositions,
};
