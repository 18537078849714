import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useController } from 'react-hook-form';
import NoSsr from '@common/noSsr';
import { ReactSelectInputStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import { useCompanies } from '@repository/companies/useCompanies';
import { LoadOptions } from '@domain/constant/ReactSelectOption';
import useDebounce from '@utils/hooks/useDebounce';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const CompanyName = ({ prefixName }: HookFormProps) => {
  const { mutateAsync: mutateAsyncCompany } = useCompanies();
  const { field, fieldState } = useController({ name: `${prefixName}.companyName` });

  const loadOptions = useDebounce((keyword: string, callback: LoadOptions) => {
    mutateAsyncCompany({ keyword: keyword, size: 10000, page: 0 }).then((schools) => {
      callback(schools);
    });
  }, 500);

  return (
    <>
      <NoSsr>
        <AsyncCreatableSelect
          {...field}
          className={cx('select', { error: fieldState.invalid })}
          loadOptions={loadOptions}
          placeholder={'직장을 검색해주세요.'}
          openMenuOnClick={false}
          styles={ReactSelectInputStyles}
          isClearable={true}
          formatCreateLabel={() => `직접 입력`}
        />
      </NoSsr>
      {fieldState.invalid && <div className={cx('errorMessage')}>{fieldState.error?.message}</div>}
    </>
  );
};

export default CompanyName;
