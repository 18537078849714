import Icon from '@components/common/assets';
import Colors from '@domain/constant/colors';

import { AccaRouteUrl, V2RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './needTodoModal.module.scss';
const cx = classnames.bind(styles);

const Todo = ({ title, checked, href }: { title: string; checked: boolean; href: string }) => {
  return (
    <div className={cx('todoContainer')}>
      <a className={cx('todoTitle')} href={`${href}?source_page=MATCH`} target="_blank" rel="noreferrer">
        {title}
        <Icon name="arrowRightLight" width={24} height={24} />
      </a>
      <div className={cx('todoCheck')}>
        <Icon
          name="checkCircleLightFilled"
          width={32}
          height={32}
          fill={checked ? Colors.C_GREEN_150 : Colors.C_COOL_GRAY_50}
        />
        <span className={cx('checkText', { checked: checked })}>{checked ? '완료' : '미완료'}</span>
      </div>
    </div>
  );
};

interface IGeneralMatchProps {
  profileRequiredCompleteYn: boolean;
  accCompleteYn: boolean;
  developerYn: boolean;
}

const GeneralMatch = ({ profileRequiredCompleteYn, accCompleteYn, developerYn }: IGeneralMatchProps) => {
  return (
    <div className={cx('matchContents', { allContents: developerYn })}>
      <div className={cx('subTitle')}>일반 매칭</div>
      <div className={cx('todoArea')}>
        <Todo checked={profileRequiredCompleteYn} title="프로필 필수 항목 입력" href={V2RouteUrl.PROFILE} />
        <Todo checked={accCompleteYn} title="역량검사 응시" href={AccaRouteUrl.ACCA_TEST_GATE} />
      </div>
    </div>
  );
};

interface IDeveloperMatchProps {
  profileRequiredCompleteYn: boolean;
  paccCompleteYn: boolean;
  pstCompleteYn: boolean;
}

const DeveloperMatch = ({ profileRequiredCompleteYn, paccCompleteYn, pstCompleteYn }: IDeveloperMatchProps) => {
  return (
    <div className={cx('matchContents')}>
      <div className={cx('subTitle')}>개발자 매칭</div>
      <div className={cx('todoArea')}>
        <Todo checked={profileRequiredCompleteYn} title="프로필 필수 항목 입력" href={V2RouteUrl.PROFILE} />
        <Todo checked={paccCompleteYn} title="개발자 역량검사 응시" href={V2RouteUrl.PHS_TEST_GATE} />
        <Todo checked={pstCompleteYn} title="개발구현능력검사 응시" href={V2RouteUrl.PHS_TEST_GATE} />
      </div>
    </div>
  );
};

interface IMatchTodoContentProps {
  generalYn: boolean;
  developerYn: boolean;
  profileRequiredCompleteYn: boolean;
  accCompleteYn: boolean;
  paccCompleteYn: boolean;
  pstCompleteYn: boolean;
}

const MatchTodoContent = ({ generalYn, developerYn, ...props }: IMatchTodoContentProps) => {
  if (generalYn && developerYn) {
    return (
      <>
        <GeneralMatch {...props} developerYn={developerYn} />
        <DeveloperMatch {...props} />
      </>
    );
  }

  if (developerYn && !generalYn) {
    return <DeveloperMatch {...props} />;
  }

  return <GeneralMatch {...props} developerYn={developerYn} />;
};

export default MatchTodoContent;
