import { useMutation, useQuery } from 'react-query';
import { RemoteSkillsRepo } from '@repository/skills/SkillsRepo';

const remoteSkillsRepo = new RemoteSkillsRepo();

const fetchSkillKeywords = async (keyword: string) => {
  const { data } = await remoteSkillsRepo.fetchSkillKeywords(keyword);
  return data.skills;
};

const fetchRecommendSkillKeywords = async (code?: number) => {
  if (!code) return [];

  const { data } = await remoteSkillsRepo.fetchRecommendSkillKeywords(code);
  return data.recommendSkills;
};

export default function useSkillKeyword() {
  return useMutation((keyword: string) => fetchSkillKeywords(keyword));
}

export function useRecommendSkillKeywords(code?: number) {
  return useQuery(['profile', 'overlays', 'skill', 'RecommendSkill', code], () => fetchRecommendSkillKeywords(code), {
    enabled: !!code,
  });
}
