export enum TERMS {
  INTERVIEW_PRIVACY_COLLECTION = 'INTERVIEW_PRIVACY_COLLECTION', // 면접을 잡다 개인정보이용 동의 약관
  PRIVACY_COLLECTION = 'PRIVACY_COLLECTION', // 개인정보 수집이용안내
  PRIVACY_POLICY = 'PRIVACY_POLICY', // 개인정보 처리방침
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE', // 서비스 이용약관
  MARKETING_POLICY = 'MARKETING_POLICY', // 마케팅 약관
  PROFILE_PRIVACY_COLLECTION = 'PROFILE_PRIVACY_COLLECTION', // 마이페이지용 개인정보 수집이용안내
  ACC_PRIVACY_POLICY = 'ACC_PRIVACY_POLICY', // 역량검사 약관
  ACC_TRIAL_PRIVACY_POLICY = 'ACC_TRIAL_PRIVACY_POLICY', // 역량검사 튜토리얼 약관
  PRIVACY_PROVISION = 'PRIVACY_PROVISION', // 개인정보 제3자 제공동의
  POSITION_PRIVACY_POLICY = 'POSITION_PRIVACY_POLICY', // 추가 서류 제출 동의
  POSITION_PRIVACY_PROVISION = 'POSITION_PRIVACY_PROVISION', // 추가 서류 제 3자 제공 동의
  SENSITIVE_PRIVACY_POLICY = 'SENSITIVE_PRIVACY_POLICY', // 매칭 프로필 > 민감정보 수집 약관

  PHS_PRIVACY_POLICY = 'PHS_PRIVACY_POLICY', //PHS 개인정보 수집 및 이용 동의
  PHS_COPYRIGHT_PROTECTION = 'PHS_COPYRIGHT_PROTECTION', //PHS 문제 저작권 보호 및 유출금지 동의
  PHS_PRACTICE_PRIVACY_POLICY = 'PHS_PRACTICE_PRIVACY_POLICY', //phs 체험판 개인정보 수집 및 이용 동의

  RESUME_PRIVACY_PROVISION = 'RESUME_PRIVACY_PROVISION ', //제 3자 동의 (지원서사이트 > 불러오기 > 약관)
  NHIS_PRIVACY_PROVISION = 'NHIS_PRIVACY_PROVISION', // 건강보험공단 개인정보 제 3자 동의처리
}

export type TERMS_TYPE = keyof typeof TERMS;

export enum POSITION_TERMS_TYPE {
  POSITION_PRIVACY_POLICY = 'MATCHING_PRIVACY_POLICY', // 추가 서류 제출 개인정보 수집 및 이용 동의
  POSITION_PRIVACY_PROVISION = 'MATCHING_PRIVACY_PROVISION', // 추가 서류 제출 개인정보 제3자 제공 동의
}

export const positionTermsStringMap: Record<POSITION_TERMS_TYPE, string> = {
  [POSITION_TERMS_TYPE.POSITION_PRIVACY_POLICY]: '개인정보 수집 및 이용 동의(필수)',
  [POSITION_TERMS_TYPE.POSITION_PRIVACY_PROVISION]: '개인정보 제3자 제공 동의(필수)',
};

export const positionTermsSortMap: Record<POSITION_TERMS_TYPE, number> = {
  [POSITION_TERMS_TYPE.POSITION_PRIVACY_POLICY]: 0,
  [POSITION_TERMS_TYPE.POSITION_PRIVACY_PROVISION]: 1,
};

export type ProfileSensitiveInfoAgreeTermsType = TERMS.SENSITIVE_PRIVACY_POLICY;

export const profileSensitiveInfoAgreeTerms: ProfileSensitiveInfoAgreeTermsType[] = [TERMS.SENSITIVE_PRIVACY_POLICY];

//phs 응시 전 약관 동의
export enum PHS_APPLY_TERMS_TYPE {
  PHS_COPYRIGHT_PROTECTION = 'PHS_COPYRIGHT_PROTECTION', //PHS 문제 저작권 보호 및 유출금지 동의
  PHS_PRIVACY_POLICY = 'PHS_PRIVACY_POLICY', //PHS 개인정보 수집 및 이용 동의
}

export const phsTestTermsSortMap: Record<PHS_APPLY_TERMS_TYPE, number> = {
  [PHS_APPLY_TERMS_TYPE.PHS_COPYRIGHT_PROTECTION]: 0,
  [PHS_APPLY_TERMS_TYPE.PHS_PRIVACY_POLICY]: 1,
};

export const phsApplyTermsStringMap: Record<PHS_APPLY_TERMS_TYPE, string> = {
  [PHS_APPLY_TERMS_TYPE.PHS_PRIVACY_POLICY]: '문제 저작권 보호 및 유출금지 동의, 부정행위 처리 동의 (필수)',
  [PHS_APPLY_TERMS_TYPE.PHS_COPYRIGHT_PROTECTION]: '개인정보 수집 및 이용 동의 (필수)',
};

export enum ResultSubmitTermsEnum {
  ACC_RESULT_PRIVACY_PROVISION = 'ACC_RESULT_PRIVACY_PROVISION',
  PACC_RESULT_PRIVACY_PROVISION = 'PACC_RESULT_PRIVACY_PROVISION',
  PST_RESULT_PRIVACY_PROVISION = 'PST_RESULT_PRIVACY_PROVISION',
}

export type resultSubmitTermsType = keyof typeof ResultSubmitTermsEnum;

//개인정보 제3자 제공 타입
export enum THIRD_PARTY_TERMS_TYPE_ENUM {
  INSIGHT_PRIVACY_PROVISION = 'INSIGHT_PRIVACY_PROVISION',
}
export type THIRD_PARTY_TERMS_TYPE = keyof typeof THIRD_PARTY_TERMS_TYPE_ENUM;
