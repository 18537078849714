import React from 'react';
import Icon from '@components/common/assets';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface ChipProps {
  name?: string;
  onClick?: () => void;
}

const Chip = ({ name, onClick }: ChipProps) => {
  return (
    <div className={cx('chip')}>
      {name}
      <a className={cx('btnDelete')} role="button" onClick={onClick}>
        <Icon name="closeLight" width={16} height={16} />
      </a>
    </div>
  );
};

export default Chip;
