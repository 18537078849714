import React from 'react';
import Select from 'react-select';
import { useController } from 'react-hook-form';
import NoSsr from '@common/noSsr';
import { ReactSelectStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import { employmentOptions } from '@domain/constant/profile/CareerType';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const EmploymentType = ({ prefixName }: HookFormProps) => {
  const { field, fieldState } = useController({ name: `${prefixName}.employmentType` });

  return (
    <>
      <NoSsr>
        <Select
          {...field}
          className={cx('select', { error: fieldState.invalid })}
          placeholder={'계약 형태를 선택해 주세요.'}
          styles={ReactSelectStyles}
          isSearchable={false}
          options={employmentOptions}
        />
      </NoSsr>
      {fieldState.invalid && <div className={cx('errorMessage')}>계약 형태를 선택해 주세요.</div>}
    </>
  );
};

export default EmploymentType;
