import React from 'react';
import dynamic from 'next/dynamic';

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => <>{children}</>;

const NoSsr = dynamic(() => Promise.resolve(Wrapper), {
  ssr: false,
});

export default NoSsr;
