import React from 'react';
import { useController } from 'react-hook-form';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const maxLength = 1000;

const Description = ({ prefixName }: HookFormProps) => {
  const name = `${prefixName}.description`;
  const { field, fieldState } = useController({
    name,
  });

  const length = field.value?.length ?? 0;

  return (
    <div className={cx('textareaWrap', { error: fieldState.invalid })}>
      <textarea {...field} placeholder={'수상 내용 및 결과를 간단히 입력해 주세요.'} maxLength={maxLength} rows={4} />
      <div className={cx('infoText')}>
        최대 글자수 : <span className={cx('value')}>{length}</span> / {maxLength}
        {fieldState.invalid && <div className={cx('errorMessage')}>{fieldState.error?.message}</div>}
      </div>
    </div>
  );
};

export default Description;
