import React from 'react';
import Icon from '@components/common/assets';
import AppleLogin from './AppleLogin';
import SocialLoginUtil from '@utils/SocialLoginUtil';
import classnames from 'classnames/bind';
import styles from './socialLogin.module.scss';
const cx = classnames.bind(styles);

interface ISocialLoginProps {
  type?: 'PAGE' | 'MODAL';
  redirectUrl?: string;
}

const SocialLogin = ({ type = 'MODAL', redirectUrl }: ISocialLoginProps) => {
  const kakaoLoginUrl = SocialLoginUtil.getKakaoLoginUrl(redirectUrl);
  const googleLoginUrl = SocialLoginUtil.getGoogleLoginUrl(redirectUrl);

  return (
    <div className={cx('socialBtnWrap')}>
      <span className={cx('socialLoginText')}>간편 로그인</span>
      <div className={cx('socialBtnArea', { page: type === 'PAGE' })}>
        <a href={kakaoLoginUrl} className={cx('socialLoginButton', 'kakaoLogin')} role="button">
          <Icon name="loginSocialKakao" />
          <span className={cx('hidden')}>카카오 로그인</span>
        </a>
        <a href={googleLoginUrl} className={cx('socialLoginButton', 'googleLogin')} role="button">
          <Icon name="loginSocialGoogle" />
          <span className={cx('hidden')}>Google 계정으로 로그인</span>
        </a>
        <AppleLogin />
      </div>
    </div>
  );
};

export default SocialLogin;
