import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const getPrevPath = () => {
  return sessionStorage.getItem('prevPath');
};

//url session Storage에 저장
const useUrlSessionStorage = () => {
  const { asPath } = useRouter();

  useEffect(() => {
    if (!sessionStorage) return;

    const prevPath = sessionStorage.getItem('currentPath');
    const currentPath = location.href;

    sessionStorage.setItem('prevPath', prevPath ?? currentPath);
    sessionStorage.setItem('currentPath', currentPath);
  }, [asPath]);
};

export default useUrlSessionStorage;
