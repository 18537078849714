import Link from 'next/link';
import { useRouter } from 'next/router';
import { V2RouteUrl } from '@utils/routeUtil';
import { UtmEventQuery } from '@utils/routeUtil/UtmEventQuery';
import DateUtil from '@utils/date/util';
import classnames from 'classnames/bind';
import styles from './join.module.scss';

const cx = classnames.bind(styles);

interface LoginJoinProps {
  label?: React.ReactNode;
  query?: Record<string, string> | null;
}

const LoginJoin = ({ label = <>회원가입</>, query }: LoginJoinProps) => {
  const { pathname } = useRouter();

  //이벤트 페이지 유입일 경우 utm 데이터 포함해서 회원 가입페이지로 이동
  const addQuery =
    UtmEventQuery.find(({ incomingUrls, startDate, endDate }) => {
      //이벤트 기간에 포함되어 있는지 여부 확인
      if (!DateUtil.getWhetherIncludedInThePeriod(startDate, endDate)) return false;
      //현재 페이지가 이벤트 유입 페이지인지 확인
      return incomingUrls.some((url) => url.includes(pathname));
    })?.queryString ?? {};

  return (
    <div className={cx('joinBtnArea')}>
      <span className={cx('text')}>잡다가 처음이세요?</span>
      <Link
        href={{
          pathname: V2RouteUrl.JOIN,
          query: {
            ...addQuery,
            ...query,
          },
        }}
        legacyBehavior
      >
        <a className={cx('btnJoin')} role="button">
          {label}
        </a>
      </Link>
    </div>
  );
};

export default LoginJoin;
