import { SourcePage } from '@domain/rq/DataLog';

export default class MatchingConditionSalaryRq {
  salary: number | null;
  sourcePage?: SourcePage;

  constructor(rq: MatchingConditionSalaryRq) {
    this.salary = rq.salary;
    this.sourcePage = rq.sourcePage;
  }
}
