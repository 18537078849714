import { useMutation } from 'react-query';
import { RemoteCodesRepo } from '@repository/codes/CodesRepo';
import { MajorAliasVO } from '@domain/vo/profile/ProfileEducationVO';

const remoteCodesRepo = new RemoteCodesRepo();

const fetchMajor = async (name: string | undefined) => {
  const { data } = await remoteCodesRepo.fetchMajorList(name);
  return data.majorAliases.map((dto) => new MajorAliasVO(dto));
};

export default function useMajor() {
  return useMutation((name: string | undefined) => fetchMajor(name));
}
