import React from 'react';
import { Hydrate, QueryClientProvider } from 'react-query';
import { AppProps } from 'next/app';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastProvider } from '@components/common/toast';
import { IndexCustomHead } from '@components/common/customHead';
import AppContextModalComponent from '@components/AppContextModalComponent';
import { LoginModalProvider } from '@components/loginModal/LoginModalContext';
import '@styles/base.scss';
import '@styles/common.scss';
import { LoggingProvider } from '../logging';
import { queryClient } from '@utils/queryClient';
import useUrlSessionStorage from '@utils/hooks/useUrlSessionStorage';
import useOnMount from '@utils/hooks/useOnMount';
import AuthUtil from '@utils/AuthUtil';
import useActiveTabFlag from '@utils/hooks/useActiveTabFlag';

//전역에서 사용할 Provider
const RootProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <LoggingProvider>
      <ToastProvider>
        <LoginModalProvider>{children}</LoginModalProvider>
      </ToastProvider>
    </LoggingProvider>
  );
};

type CustomAppProps = AppProps & {
  err?: Error;
};

const CustomApp = ({ Component, pageProps, err }: CustomAppProps) => {
  useOnMount(() => {
    AuthUtil.checkAuthorization();
  });

  useOnMount(() => {
    document.body.classList.add('scrollbar');

    return () => {
      document.body.classList.remove('scrollbar');
    };
  });

  useUrlSessionStorage(); //session Storage에 url저장
  useActiveTabFlag(); //다른 탭에서 로그인 한 경우 로그인 업데이트를 위해 재렌더링.

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <RootProvider>
          <IndexCustomHead />
          <Component {...pageProps} err={err} />
          {/* 전역에서 사용되는 컴포넌트 - 모달*/}
          <AppContextModalComponent />
        </RootProvider>
      </Hydrate>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
};

export default CustomApp;
