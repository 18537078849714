import ProfileViewVO from '@domain/vo/profile/common/ProfileViewVO';
import {
  ProfilePreferentialRs,
  MilitaryDto,
  DisabilityDto,
  VeteranDto,
} from '@domain/rs/profile/ProfilePreferentialRs';
import {
  MilitaryStatusType,
  MilitaryType,
  MilitaryClassType,
  MilitaryDischargeType,
  DisabilityGradeType,
  DisabilityType,
  MilitaryClassNameMap,
  MilitaryNameMap,
  MilitaryStatusNameMap,
  MilitaryDischargeNameMap,
  DisabilityNameMap,
  DisabilityGradeShortNameMap,
  VeteranStatusType,
  VeteranSTatusUINameMap,
} from '@domain/constant/profile/PreferentialType';
import DateFormat from '@utils/date/format';

export default class ProfilePreferentialVO extends ProfileViewVO {
  military: MilitaryVO | null;
  disability: DisabilityVO | null;
  veteran: VeteranVO | null;

  constructor(rs: ProfilePreferentialRs) {
    super(rs, false);

    this.military = rs.military ? new MilitaryVO(rs.military) : null;
    this.disability = rs.disability ? new DisabilityVO(rs.disability) : null;
    this.veteran = rs.veteran ? new VeteranVO(rs.veteran) : null;
  }
}

export class MilitaryVO {
  status: MilitaryStatusType;
  statusText: string;
  type: MilitaryType;
  typeText: string;
  militaryClass: MilitaryClassType;
  militaryClassText: string;
  dischargeType: MilitaryDischargeType;
  dischargeTypeText: string;
  startDate: string;
  endDate: string;

  constructor(rs: MilitaryDto) {
    this.status = rs.status;
    this.statusText = MilitaryStatusNameMap[rs.status];
    this.type = rs.type;
    this.typeText = MilitaryNameMap[rs.type];
    this.militaryClass = rs.militaryClass;
    this.militaryClassText = MilitaryClassNameMap[rs.militaryClass];
    this.dischargeType = rs.dischargeType;
    this.dischargeTypeText = MilitaryDischargeNameMap[rs.dischargeType];

    this.startDate = this.getDateFormat(rs.startDate);
    this.endDate = this.getDateFormat(rs.endDate);
  }

  getDateFormat(data: string | null) {
    return DateFormat.getDateFormatString(data, 'yyyy.MM') ?? '';
  }
}

export class DisabilityVO {
  grade: DisabilityGradeType;
  gradeText: string;
  type: DisabilityType | null;
  typeText: string;

  constructor(rs: DisabilityDto) {
    this.grade = rs.grade;
    this.gradeText = DisabilityGradeShortNameMap[rs.grade];
    this.type = rs.type;
    this.typeText = rs.type ? DisabilityNameMap[rs.type] : '';
  }
}

export class VeteranVO {
  status: VeteranStatusType | null;
  statusText: string | null;
  reason: string | null;

  constructor(rs: VeteranDto) {
    this.status = rs.status;
    this.statusText = rs.status ? VeteranSTatusUINameMap[rs.status] : null;
    this.reason = rs.reason;
  }
}
