import React, { useRef, useState } from 'react';
import { Country, CountryType, CountryCodeMap } from '@domain/constant/CountryType';
import useClickAway from '@utils/hooks/useClickOutside';

// TODO useRecommendEmailList.ts 와 같이 추상화 되면 좋을 듯

const useCountryCodeList = () => {
  const inputWrapRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false); // 리스트 출력 여부
  const [countryCodeNumber, setCountryCodeNumber] = useState<number>(-1); // input 내 입력중인 국가 코드
  const [countryList, setCountryList] = useState<Country[]>(Object.keys(Country) as Country[]); // 국가 리스트
  const [selectedIndex, setSelectedIndex] = useState(-1); //키보드 선택 index

  useClickAway(() => {
    if (isOpen) setIsOpen(false);
  }, inputWrapRef);

  const handleOnClick = (country: CountryType) => {
    setCountryCodeNumber(CountryCodeMap[country]);
    setIsOpen(false);
    setSelectedIndex(-1);
    handleCountryCodeList(CountryCodeMap[country].toString());
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isOpen) return;
    if (e.key === 'ArrowDown') {
      // 리스트가 있을 때 input 내 커서 이동 방지
      e.preventDefault();
      if (selectedIndex + 1 > countryList.length - 1) return;
      setSelectedIndex(selectedIndex + 1);
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (selectedIndex - 1 < 0) return;
      setSelectedIndex(selectedIndex - 1);
    }

    if (e.key === 'Enter' && selectedIndex >= 0) {
      // form 에서 enter 시 submit 방지
      e.preventDefault();
      handleOnClick(countryList[selectedIndex]);
      handleCountryCodeList(CountryCodeMap[countryList[selectedIndex]].toString());
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCountryCodeNumber(Number(value));
    setSelectedIndex(-1);
    setIsOpen(true);
    handleCountryCodeList(value);
  };

  const handleCountryCodeList = (code: string) => {
    const filteredCountryCodeList = Object.entries(CountryCodeMap)
      .filter(([, countryCode]) => countryCode.toString().includes(code))
      .map(([country]) => country);
    const newCountryNameList = Object.keys(Country).filter((country) =>
      filteredCountryCodeList.includes(country),
    ) as Country[];
    setCountryList(newCountryNameList);
  };

  const initCountryList = () => {
    setCountryList(Object.keys(Country) as Country[]);
  };

  return {
    inputWrapRef,
    isOpen,
    setIsOpen,
    countryCodeNumber,
    setCountryCodeNumber,
    countryList,
    setCountryList,
    selectedIndex,
    setSelectedIndex,
    initCountryList,
    handleOnKeyDown,
    handleOnClick,
    handleOnChange,
  };
};

export default useCountryCodeList;
