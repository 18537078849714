import { FC } from 'react';
import { PreferTabType } from '@components/profile/profileOverlay/overlays/prefer';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface Props {
  selectedTabType: PreferTabType;
  onClose: () => void;
  onConfirm?: () => void;
  onNext: () => void;
  onSave: () => void;
}

const ButtonArea: FC<Props> = ({ selectedTabType, onClose, onConfirm, onNext, onSave }) => {
  return (
    <div className={cx('btnArea')}>
      {selectedTabType ? (
        <>
          <button className={cx('prev')} type="button" onClick={onClose}>
            이전
          </button>
          <button className={cx('btn', 'confirm')} type="submit" onClick={onConfirm}>
            저장
          </button>
        </>
      ) : (
        <>
          <button className={cx('prev')} type="button" onClick={onNext}>
            건너뛰기
          </button>
          <button className={cx('btn', 'confirm')} type="button" onClick={onSave}>
            저장하기
          </button>
        </>
      )}
    </div>
  );
};

export default ButtonArea;
