import React from 'react';
import Lottie from 'react-lottie';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Modal from '@common/modal';
import Text from '@common/text';
import Icon from '@common/assets';
import confetti from '@graphic/confetti.json';
import { useUserInfo } from '@repository/auth/useUserInfo';
import ProgressBar from '@components/progressBar';
import { MATCH_CONFIG_STATUS_QUERY_KEY, useGetMatchConfigStatus, useUpdateMatchConfigStatus } from '@repository/match';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import { MILLISECONDS_PER_DAY } from '@domain/constant/Time';
import useLocalStorageExpires from '@utils/hooks/useLocalStorageExpires';
import { AccaRouteUrl, V2RouteUrl } from '@utils/routeUtil';
import { queryClient } from '@utils/queryClient';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const options = {
  loop: false,
  autoplay: true,
  animationData: confetti,
};

export const MatchingGuideModalExpiresKey = 'matchingGuideModalExpires';

const CompleteCheckbox = ({ checked }: { checked: boolean }) => {
  const text = checked ? '완료' : '미완료';
  const fill = checked ? Colors.C_GREEN_150 : Colors.C_COOL_GRAY_50;
  const color = checked ? Colors.C_JOBDA_BLACK : Colors.C_COOL_GRAY_60;

  return (
    <div className={cx('checkboxArea')}>
      <Icon name={'checkCircleLightFilled'} width={32} height={32} fill={fill} />
      <Text fontStyle={FontStyle.B3P_M} color={color}>
        {text}
      </Text>
    </div>
  );
};

interface MatchingGuideModalProps {
  visible: boolean;
  onClose: () => void;
}

const MatchingGuideModal = ({ visible, onClose }: MatchingGuideModalProps) => {
  const router = useRouter();
  const { data: userData } = useUserInfo();
  const isLogin = !!userData?.id;

  const { setExpires, getIsExpired } = useLocalStorageExpires();
  const { data: matchConfigStatus } = useGetMatchConfigStatus(isLogin);

  const { mutateAsync: updateMatchConfigStatusMutateAsync } = useUpdateMatchConfigStatus(async () => {
    // 제안 받기 | 제안 안받기 후 제안 받기 여부 새로고침
    await queryClient.refetchQueries(MATCH_CONFIG_STATUS_QUERY_KEY, { active: true, exact: true });
  });

  const isModalExpired = getIsExpired(MatchingGuideModalExpiresKey);
  const name = userData?.name || '회원';
  const isProfileRequiredCompleteYn = !!matchConfigStatus?.profileRequiredCompleteYn;
  const isAccCompleteYn = !!matchConfigStatus?.accCompleteYn;
  const isComplete = isProfileRequiredCompleteYn && isAccCompleteYn;

  const getProgress = () => {
    if (isProfileRequiredCompleteYn && isAccCompleteYn) return 100;
    if (isProfileRequiredCompleteYn || isAccCompleteYn) return 50;
    return 0;
  };

  const handleClick = async () => {
    await updateMatchConfigStatusMutateAsync({ useYn: true });
    await router.push(`${V2RouteUrl.MATCH_APPLY}?matchConfigStatus=true`);
    onClose();
  };

  const getBtnEl = () => {
    if (!isProfileRequiredCompleteYn) {
      return (
        <Link href={V2RouteUrl.PROFILE} legacyBehavior>
          <a className={cx('btn')}>1분만에 프로필 작성하기</a>
        </Link>
      );
    }
    if (!isAccCompleteYn)
      return (
        <Link href={AccaRouteUrl.ACCA_TEST_GATE} legacyBehavior>
          <a className={cx('btn')}>역량검사 알아보기</a>
        </Link>
      );
    return (
      <button className={cx('btn')} onClick={handleClick}>
        입사제안 받기
      </button>
    );
  };

  const handleExpires = () => {
    setExpires({ key: MatchingGuideModalExpiresKey, expires: MILLISECONDS_PER_DAY * 7 });
    onClose();
  };

  if (!isModalExpired) return null;

  return (
    <Modal visible={visible} dimmed={true}>
      <div className={cx('modalArea')}>
        <Text className={cx('label')} fontStyle={FontStyle.B1_B} color={Colors.C_WHITE}>
          MISSION 보상
        </Text>

        <div className={cx('titleArea')}>
          <Text fontStyle={FontStyle.H4_B} color={Colors.C_JOBDA_BLACK}>
            750개 기업의 입사 제안
          </Text>
        </div>
        {isComplete && (
          <div className={cx('descArea')}>
            <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
              {name}님, 미션 달성을 축하해요!
            </Text>
            <Text fontStyle={FontStyle.B2P_B} color={Colors.C_JOBDA_BLACK}>
              아래 버튼을 누르면 바로 제안이 시작돼요.
            </Text>
          </div>
        )}

        <div className={cx('contentsArea')}>
          <div className={cx('progressArea')}>
            <div className={cx('progressTextArea')}>
              <Text fontStyle={FontStyle.B3P_M} color={Colors.C_COOL_GRAY_70}>
                미션 달성률 :{' '}
              </Text>
              <Text fontStyle={FontStyle.B3P_M} color={Colors.C_JOBDA_BLACK}>{`${getProgress()}%`}</Text>
            </div>
            <div className={cx('progressWrap')}>
              <ProgressBar
                className={cx('progressBar')}
                fillClassName={cx('progressFill')}
                progress={getProgress()}
                height={16}
                transitionDuration={'1.5s'}
              />
              {getProgress() > 0 && (
                <div className={cx('lottieArea')} style={{ right: `${100 - getProgress()}%` }}>
                  <Lottie options={options} isClickToPauseDisabled />
                </div>
              )}
            </div>
          </div>

          <div className={cx('listArea')}>
            <div className={cx('itemArea')}>
              <Text fontStyle={FontStyle.B1_B} color={Colors.C_JOBDA_BLACK}>
                프로필 필수 항목 작성
              </Text>
              <CompleteCheckbox checked={isProfileRequiredCompleteYn} />
            </div>
            <div className={cx('itemArea')}>
              <Text fontStyle={FontStyle.B1_B} color={Colors.C_JOBDA_BLACK}>
                역량검사 응시
              </Text>
              <CompleteCheckbox checked={isAccCompleteYn} />
            </div>
          </div>
        </div>

        <div className={cx('btnArea')}>
          {!isComplete && (
            <button className={cx('btn', 'close')} onClick={onClose}>
              닫기
            </button>
          )}
          {getBtnEl()}
        </div>

        <div className={cx('expiresArea')}>
          <button className={cx('expiresBtn')} onClick={handleExpires}>
            일주일 동안 보지 않기
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MatchingGuideModal;
