export default class ProfileViewVO {
  saveYn: boolean;
  allSaveYn: boolean;

  constructor(vo: Object, isRequired = true) {
    this.saveYn = this.getSaveYn(vo);
    //선택 값인 경우 하나만 입력되어도 전체 저장되었음 처리
    this.allSaveYn = isRequired ? this.getAllSaveYn(vo) : this.getSaveYn(vo);
  }

  //하나라도 저장되어 있는지 여부
  getSaveYn(obj: Object): boolean {
    return Object.values(obj).some((value) => {
      return Array.isArray(value) ? value.length > 0 : value !== null;
    });
  }

  //모든 정보 저장되어 있는지 여부
  getAllSaveYn(obj: Object): boolean {
    const isNull = Object.values(obj).some((value) => {
      return Array.isArray(value) ? value.length === 0 : value === null;
    });

    return !isNull;
  }
}
