import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Icon from '@components/common/assets';
import Checkbox from '@components/common/checkbox';
import styles from '../../../commonStyle.module.scss';
import { CheckListType, EditViewType } from '../..';
import InvalidMessage from '../invalidMessage';
import CareerFormVO from '@domain/vo/insight/resume/CareerFormVO';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface Props {
  checkList?: number[];
  updateCheckList: (type: CheckListType, index: number) => void;
  openEditView: (type: EditViewType, formName: string) => void;
}

const Career: FC<Props> = ({ checkList, updateCheckList, openEditView }) => {
  const { watch, getFieldState } = useFormContext();
  const data = watch('career') as CareerFormVO;

  const listEls = data?.careers?.map((career, index) => {
    const isChecked = checkList?.includes(index);
    const invalid = getFieldState(`career.careers.${index}`).invalid;
    const handleCheckBtn = () => {
      if (invalid) return;
      updateCheckList('career', index);
    };
    const handleEditBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      openEditView('career', `career.careers.${index}`);
    };
    const startDate = DateFormat.getDateFormatString(career.startDate, 'yyyy.MM');
    const endDate = DateFormat.getDateFormatString(career.endDate, 'yyyy.MM');

    return (
      <button key={index} className={cx('cardWrap', { invalid }, { isChecked })} onClick={handleCheckBtn}>
        <Checkbox
          className={cx('checkbox')}
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
          disabled={invalid}
          width={24}
          height={24}
          margin={0}
        />

        <div className={cx('infoArea')}>
          <div className={cx('textArea')}>
            <div className={cx('listTextArea')}>
              <span className={cx('listText')}>{career?.companyName?.label}</span>
            </div>

            <span className={cx('listDesc')}>
              {invalid ? <InvalidMessage /> : <span>{`${startDate} ~ ${endDate ? endDate : '재직중'}`}</span>}
            </span>
          </div>

          <button className={cx('editBtn', { invalid })} onClick={handleEditBtn}>
            <Icon name="guideLight" width={32} height={32} />
          </button>
        </div>
      </button>
    );
  });

  if (!data?.careers?.length) return null;

  return (
    <div className={cx('listWrap')}>
      <div className={cx('listTitleArea')}>
        <Icon name="workLight" width={32} height={32} />
        <span className={cx('listTitle')}>경력</span>
      </div>
      <div className={cx('cardList')}>{listEls}</div>
    </div>
  );
};

export default Career;
