import { useMutation } from 'react-query';
import { RemoteLoggingRepo } from './LoggingRepo';
import LoggingRq from '@domain/rq/logging/LoggingRq';

const repo = new RemoteLoggingRepo();

const saveLogging = async (rq: LoggingRq) => {
  const { data } = await repo.saveLogging(rq);
  return data;
};

export function useSaveLogging() {
  return useMutation(saveLogging);
}
