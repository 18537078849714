import ProfileViewVO from '@domain/vo/profile/common/ProfileViewVO';
import { ProfilePrizeRs, PrizeDto } from '@domain/rs/profile/ProfilePrizeRs';
import { AuditItemsType } from '@domain/constant/profile/AuditItemsType';
import DateFormat from '@utils/date/format';

export default class ProfilePrizeVO extends ProfileViewVO {
  prizes: PrizeVO[];

  constructor(rs: ProfilePrizeRs) {
    super(rs);

    this.prizes = rs.prizes.map((item) => new PrizeVO(item));
  }
}

export class PrizeVO {
  institution: string;
  name: string;
  date: string;
  description: string;
  auditItems?: AuditItemsType[];

  constructor(rs: PrizeDto) {
    this.institution = rs.institution;
    this.name = rs.name;
    this.date = this.getDateFormat(rs.date);
    this.description = rs.description;
    this.auditItems = rs.auditItems;
  }

  getDateFormat(data: string | null) {
    return DateFormat.getDateFormatString(data, 'yyyy.MM') ?? '';
  }
}
