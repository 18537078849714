import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import NoSsr from '@common/noSsr';
import { ReactSelectInputStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import useCountries from '@components/profile/profileOverlay/overlays/experience/query/useCountries';
import { LoadOptions } from '@domain/constant/ReactSelectOption';
import useDebounce from '@utils/hooks/useDebounce';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const CountryName = ({ prefixName }: HookFormProps) => {
  const { data: countriesData } = useCountries();

  const { field, fieldState } = useController({
    name: `${prefixName}.code`,
  });

  const loadOptions = useDebounce((keyword: string, callback: LoadOptions) => {
    callback(countriesData?.filter((item) => item.name.includes(keyword)) ?? []);
  }, 100);

  useEffect(() => {
    const countryName = countriesData?.find((item) => item.code === field.value?.code);
    field.onChange({ code: countryName?.code, name: countryName?.name });
  }, []);

  return (
    <>
      <NoSsr>
        <AsyncSelect
          {...field}
          loadOptions={loadOptions}
          getOptionValue={(option) => option.code}
          getOptionLabel={(option) => option.name}
          className={cx('select', { error: fieldState.invalid })}
          placeholder={'국가를 검색해주세요.'}
          styles={ReactSelectInputStyles}
          isSearchable
          isClearable={true}
          defaultOptions
        />
      </NoSsr>
      {fieldState.invalid && <div className={cx('errorMessage')}>{fieldState.error?.message}</div>}
    </>
  );
};

export default CountryName;
