import { FC } from 'react';
import Text from '@components/common/text';
import Icon from '@components/common/assets';
import { useUserInfo } from '@repository/auth/useUserInfo';
import useProfileConditions from '@repository/profiles/useProfileConditions';
import { PreferTabType } from '../..';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface Props {
  visible: boolean;
  setSelectedTabType: (tabType: PreferTabType) => void;
  errorCheck?: boolean;
}

const NotSelect: FC<Props> = ({ visible, setSelectedTabType, errorCheck }) => {
  const { data: userInfo } = useUserInfo();
  const { data: conditionsData } = useProfileConditions(!!userInfo?.id && visible);
  if (!visible) return null;

  const getSubText = (length: number) => {
    return length > 1 ? `외 ${length - 1}개` : '';
  };

  return (
    <div className={cx('wrapper')}>
      <Card
        title="직군 · 직무"
        label={
          conditionsData?.allJobTitleNames.length
            ? `${conditionsData.allJobTitleNames[0]} ${getSubText(conditionsData.allJobTitleNames.length)}`
            : '희망하는 직군 · 직무를 선택해 주세요.'
        }
        required
        onClick={() => setSelectedTabType('jobGroup')}
        errorCheck={errorCheck && conditionsData?.jobGroups.length === 0}
      />
      <Card
        title="근무 지역"
        label={
          conditionsData?.locationTexts.length
            ? conditionsData.locationTexts.length === 17
              ? '전체 지역'
              : `${conditionsData.locationTexts[0]} ${getSubText(conditionsData.locationTexts.length)}`
            : '희망하는 근무 지역을 선택해 주세요.'
        }
        required
        onClick={() => setSelectedTabType('location')}
        errorCheck={errorCheck && conditionsData?.locations.length === 0}
      />
      <Card
        title="연봉"
        label={conditionsData?.salary ? conditionsData.salaryText : '선호하는 연봉 수준을 입력해 주세요.'}
        required
        onClick={() => setSelectedTabType('salary')}
        errorCheck={errorCheck && !conditionsData?.salary}
      />
      <Card
        title="복지 혜택"
        label={
          conditionsData?.allWelfareNames.length
            ? `${conditionsData?.allWelfareNames[0]} ${getSubText(conditionsData.allWelfareNames.length)}`
            : '선호하는 복지 키워드를 선택해 주세요.'
        }
        onClick={() => setSelectedTabType('welfare')}
      />
      <Card
        title="기타 희망사항"
        label={
          conditionsData?.workingPreference
            ? conditionsData.workingPreference
            : '어떤 회사에서 일하고 싶은지 자유롭게 작성해 주세요.'
        }
        onClick={() => setSelectedTabType('working_preference')}
      />
    </div>
  );
};

interface CardProps {
  title: string;
  label: string;
  required?: boolean;
  onClick: () => void;
  errorCheck?: boolean;
}

const Card: FC<CardProps> = ({ title, label, required, onClick, errorCheck }) => {
  return (
    <button className={cx('card', { isError: errorCheck })} onClick={onClick}>
      <div className={cx('textArea')}>
        <div className={cx('title')}>
          <Text fontStyle={FontStyle.B1_B}>{title}</Text>
          {required && (
            <Text fontStyle={FontStyle.B4_M} color={Colors.C_ERROR}>
              필수
            </Text>
          )}
        </div>
        <Text className={cx('label')} fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_70}>
          {label}
        </Text>
      </div>

      <Icon name="arrowRightLight" width={32} height={32} />
    </button>
  );
};
export default NotSelect;
