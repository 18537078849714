declare global {
  interface Window {
    Kakao: any;
  }
}

export const REACT_APP_KAKAO_JS_KEY = '901e4996648a9fd50b6ff5c3ada4f6f2';

export interface IShareKakaoParams {
  title: string;
  description: string;
  imageUrl: string;
  webUrl: string;
  mobileWebUrl: string;
}

export const shareKakaoTalk = async (params: IShareKakaoParams) => {
  const { title, description, imageUrl, webUrl, mobileWebUrl } = params;

  try {
    const kakao = window.Kakao;

    if (!window.Kakao) {
      console.error('Kakao is not initialized');
      return;
    }

    if (!window.Kakao.Link) {
      await kakao.init(REACT_APP_KAKAO_JS_KEY);
    }

    await window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title,
        description,
        imageUrl,
        link: {
          mobileWebUrl,
          webUrl,
        },
      },
      social: {
        likeCount: 0,
        commentCount: 0,
        sharedCount: 0,
      },
      buttons: [
        {
          title: '자세히 보기', // 버튼 제목
          link: {
            mobileWebUrl,
            webUrl,
          },
        },
      ],
    });
  } catch (e) {
    console.error(e);
  }
};

/**카카오맵 길찾기 주소 url
 * @param address: 주소
 * @param Ma: 위도
 * @param La: 경도
 * */
export const kakaoFindMapUrl = (address?: string, Ma?: number, La?: number) => {
  if (!address || !La || !Ma) return '';
  return `https://map.kakao.com/link/to/${address},${Ma},${La}`;
};
