import ProfileViewVO from './common/ProfileViewVO';
import { ProfileConditionsRs, JobGroupsDto, WelfareDto } from '@domain/rs/profile/ProfileConditionsRs';
import { AuditItemsType } from '@domain/constant/profile/AuditItemsType';
import NumberFormat from '@utils/number/format';

export default class ProfileConditionsVO extends ProfileViewVO {
  jobGroups: JobGroupVO[];
  locations: number[];
  locationTexts: string[];
  salary: number | null;
  welfare: WelfareDto[];
  workingPreference: string;
  allJobTitleNames: string[];
  allWelfareNames: string[];
  salaryText: string;
  allSaveYn: boolean;
  auditItems: AuditItemsType[];

  constructor(rs: ProfileConditionsRs) {
    super(rs, false);
    this.jobGroups = rs.jobGroups.map((item) => new JobGroupVO(item));
    this.locations = rs.locations;
    this.locationTexts = rs.locations.map((code) => NumberFormat.formatLocationFullName(code));
    this.salary = rs.salary;
    this.welfare = rs.welfare;
    this.workingPreference = rs.workingPreference;
    this.allJobTitleNames = this.jobGroups
      .map((item) => item.jobTitles)
      .flat()
      .map((item) => item.jobTitleNames);
    this.allWelfareNames = this.welfare.map((item) => item.name);
    this.salaryText = this.salary ? `${NumberFormat.formatDecimal(this.salary)}만원 이상` : '';

    this.allSaveYn = this.getAllSaveYn({
      jobGroups: this.jobGroups,
      locations: this.locations,
      salary: this.salary,
    });
    this.auditItems = rs.auditItems;
  }

  getAllSaveYn(obj: Object): boolean {
    return Object.values(obj).every((value) => {
      return Array.isArray(value) ? value.length > 0 : value !== null;
    });
  }
}

export class JobGroupVO {
  jobGroupCode: number;
  jobGroupName: string;
  jobTitles: JobTitleVO[];

  constructor(rs: JobGroupsDto) {
    this.jobGroupCode = rs.jobGroupCode;
    this.jobGroupName = rs.jobGroupName;
    this.jobTitles = rs.jobTitleCodes.map((code, idx) => new JobTitleVO(code, rs.jobTitleNames[idx]));
  }
}

export class JobTitleVO {
  jobTitleCodes: number;
  jobTitleNames: string;

  constructor(sn: number, name: string) {
    this.jobTitleCodes = sn;
    this.jobTitleNames = name;
  }
}
