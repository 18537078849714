import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { AccApplyResultRs } from '@domain/rs/acca/v1/test/result/apply/AccApplyResultRs';
import { AccSelfReportResultRs } from '@domain/rs/acca/v1/test/result/apply/AccSelfReportResultRs';
import { AccVideoResultRs } from '@domain/rs/acca/v1/test/result/apply/AccVideoResultRs';
import { AccGameResultRs } from '@domain/rs/acca/v1/test/result/apply/AccGameResultRs';
import { AccApplyTipRs } from '@domain/rs/acca/v1/test/result/apply/AccApplyTipRs';
import { AccApplyCreateRs } from '@domain/rs/acca/test/AccApplyCreateRs';
import { AccTrialApplyCreateRs } from '@domain/rs/acca/test/AccTrialApplyCreateRs';

const accApplyPrefix = '/acc/apply';

export default interface AccApplyRepo {
  // 응시 정보 생성(응시하기)
  fetchAccApply(): Promise<AxiosResponse<AccApplyCreateRs>>;

  // 체험판 응시 정보 생성
  fetchApplyTrial(): Promise<AxiosResponse<AccTrialApplyCreateRs>>;

  /** 역량검사 응시 분석 결과 **/
  // 종합 응시 결과, 과정별 피드백
  fetchAccApplyResult(sn: number): Promise<AxiosResponse<AccApplyResultRs>>;

  // 성향 파악 응시 결과
  fetchAccApplySelfReport(sn: number): Promise<AxiosResponse<AccSelfReportResultRs>>;

  // 영상 면접 응시 결과
  fetchAccApplyVideo(sn: number): Promise<AxiosResponse<AccVideoResultRs>>;

  // 전략 게임 응시 결과
  fetchAccApplyGame(sn: number): Promise<AxiosResponse<AccGameResultRs>>;

  // 맞춤형 꿀팁
  fetchAccApplyTip(sn: number): Promise<AxiosResponse<AccApplyTipRs>>;
}

export class RemoteAccApplyRepo implements AccApplyRepo {
  fetchAccApply(): Promise<AxiosResponse<AccApplyCreateRs>> {
    return axios.post(`/v2${accApplyPrefix}`);
  }

  fetchApplyTrial() {
    return axios.get(`/v2${accApplyPrefix}/trial`);
  }

  /** 역량검사 응시 분석 결과 **/
  fetchAccApplyResult(sn: number): Promise<AxiosResponse<AccApplyResultRs>> {
    return axios.get(`${accApplyPrefix}/result`, { params: { sn } });
  }

  fetchAccApplySelfReport(sn: number): Promise<AxiosResponse<AccSelfReportResultRs>> {
    return axios.get(`${accApplyPrefix}/self-report`, { params: { sn } });
  }

  fetchAccApplyVideo(sn: number): Promise<AxiosResponse<AccVideoResultRs>> {
    return axios.get(`${accApplyPrefix}/video`, { params: { sn } });
  }

  fetchAccApplyGame(sn: number): Promise<AxiosResponse<AccGameResultRs>> {
    return axios.get(`${accApplyPrefix}/game`, { params: { sn } });
  }

  fetchAccApplyTip(sn: number): Promise<AxiosResponse<AccApplyTipRs>> {
    return axios.get(`${accApplyPrefix}/tip`, { params: { sn } });
  }
}
