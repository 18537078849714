import React, { CSSProperties, useEffect, useState } from 'react';

import classnames from 'classnames/bind';
import styles from './progressBar.module.scss';
const cx = classnames.bind(styles);

interface IProgressBarProps {
  startProgress?: number;
  progress: number;
  color?: CSSProperties['color'];
  height?: CSSProperties['height'];
  className?: string;
  fillColor?: CSSProperties['color'];
  fillHeight?: CSSProperties['height'];
  fillClassName?: string;
  transitionDuration?: CSSProperties['transitionDuration'];
}

const ProgressBar = ({
  startProgress,
  progress,
  color,
  height,
  className,
  fillColor,
  fillHeight,
  fillClassName,
  transitionDuration = '0.3s',
}: IProgressBarProps) => {
  const [progressValue, setProgressValue] = useState(startProgress ?? 0);

  const containerStyle = {
    backgroundColor: color,
    height,
  };

  const fillStyle = {
    backgroundColor: fillColor,
    height: fillHeight,
    transitionDuration,
  };

  useEffect(() => {
    setTimeout(() => {
      setProgressValue(progress);
    }, 0);
  }, [progress]);

  return (
    <div className={cx('progressBar', className)} style={containerStyle}>
      <div
        className={cx('progressBarFill', fillClassName)}
        style={{ ...fillStyle, transform: `translateX(${progressValue}%)` }}
      />
    </div>
  );
};

export default ProgressBar;
