import { getOptions } from '@domain/constant/ReactSelectOption';

// 계약 형태
export enum Employment {
  FULL_TIME = 'FULL_TIME',
  INTERNSHIP = 'INTERNSHIP',
  PART_TIME = 'PART_TIME',
  TEMPORARY = 'TEMPORARY',
}

export type EmploymentType = keyof typeof Employment;

export const EmploymentNameMap: Record<EmploymentType, string> = {
  [Employment.FULL_TIME]: '정규직',
  [Employment.TEMPORARY]: '계약직',
  [Employment.INTERNSHIP]: '인턴',
  [Employment.PART_TIME]: '아르바이트',
};

export const employmentOptions = getOptions(EmploymentNameMap);

// 재직 상태
export enum EmploymentStatus {
  WORK = 'WORK',
  RESIGN = 'RESIGN',
}

export type EmploymentStatusType = keyof typeof EmploymentStatus;

export const EmploymentStatusNameMap: Record<EmploymentStatusType, string> = {
  [EmploymentStatus.WORK]: '재직 중',
  [EmploymentStatus.RESIGN]: '퇴사',
};

export const employmentStatusOptions = getOptions(EmploymentStatusNameMap);
