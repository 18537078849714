import { PostJobCardDto } from '@domain/rs/postJob/PostJobCardListRs';
import { PostJobContentType } from '@domain/constant/postJob/PostJobContentType';
import { PostJobCategoryIconType } from '@domain/constant/postJob/PostJobCategoryIconType';
import { PrivacyType } from '@domain/constant/privacyType';

export class PostJobCardVO {
  sn: number;
  categoryIconType: PostJobCategoryIconType;
  categoryName: string;
  type: PostJobContentType;
  title: string;
  label: string;
  link: string;
  thumbnailUrl: string;
  privacy: PrivacyType;

  constructor(rs: PostJobCardDto) {
    this.sn = rs.sn;
    this.categoryIconType = rs.categoryIconType;
    this.categoryName = rs.categoryName;
    this.type = rs.type;
    this.title = rs.title;
    this.label = rs.label;
    this.link = rs.link;
    this.thumbnailUrl = rs.thumbnailUrl ?? '/img/info/list/imgDefaultThumbnail.png';
    this.privacy = rs.privacy;
  }
}
