import { useFormContext } from 'react-hook-form';
import { useToast } from '@components/common/toast';
import styles from '@components/loginModal/find/find.module.scss';
import LabelInput from '@components/labelInput';
import { useVerificationSmsSendId, useVerificationSmsSendPassword } from '@repository/verification/useMobile';
import { IError } from '@domain/constant/errorType';
import { MobileCertStateType } from '@domain/constant/MobileCertStateType';
import { cellphoneValidator, valueRequired } from '@utils/validators/validators';
import TextFormat from '@utils/text/format';
import { hasErrorMessage } from '@utils/typeGuard';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

export type MobileCertificateType = 'ID' | 'PASSWORD';
interface MobileProps {
  type?: MobileCertificateType;
  remainingTime: number;
  resetTimer: () => void;
  mobileCertState: MobileCertStateType | null;
  setMobileCertState: (mobileCertState: MobileCertStateType | null) => void;
  setIsSuccessMobileAuth: (isSuccessMobileAuth: boolean) => void;
}

const Mobile = ({
  type = 'ID',
  remainingTime,
  resetTimer,
  mobileCertState,
  setMobileCertState,
  setIsSuccessMobileAuth,
}: MobileProps) => {
  const {
    watch,
    register,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const Toast = useToast();
  const id = watch('id');
  const birthDate = watch('birthDate')?.replace(/[^0-9]/g, '');
  const mobile = watch('mobile')?.replace(/[^0-9]/g, '');
  const countryCode = watch('countryCode');

  const isDisabledRequestCertBtn =
    type === 'ID'
      ? !!errors.birthDate || !birthDate || !!errors.mobile || !mobile || !!errors.countryCode || !countryCode
      : !!errors.mobile || !mobile || !!errors.countryCode || !countryCode;

  // SMS 인증 전달
  const { mutateAsync: verificationSmsSend } = useVerificationSmsSendId();
  const { mutateAsync: verificationSmsSendPassword } = useVerificationSmsSendPassword();

  const handleError = (e: any) => {
    if (hasErrorMessage(e)) Toast({ type: 'error', iconType: 'info', content: e.response.data.message });
  };
  //핸드폰 상태값 초기화
  const onMobileInit = async () => {
    clearErrors(['token', 'mobile']);
    setValue('token', '');
    setIsSuccessMobileAuth(false);
    resetTimer();
    await onClickRequestCert();
  };

  // 핸드폰 인증
  const onClickRequestCert = async () => {
    const result =
      type === 'ID'
        ? await verificationSmsSend(
            { birthDate, countryCode, mobile },
            {
              onError: handleError,
            },
          )
        : await verificationSmsSendPassword(
            { id, countryCode, mobile },
            {
              onError: handleError,
            },
          );
    setMobileCertState('ING');
    resetTimer();
    if (result !== true) {
      result?.errors?.forEach((item: IError) => {
        setError(item.field, { message: item.reason });
      });
    }
  };

  return (
    <>
      <LabelInput
        {...register('mobile', {
          ...valueRequired,
          validate: (value: string) => cellphoneValidator(value.replace(/[^0-9]/g, '')),
        })}
        type="text"
        className={cx('input', 'mobile', { error: errors.mobile })}
        placeholder="휴대폰 번호"
        onInput={(e: Record<string, any>) => setValue('mobile', TextFormat.hypenMobile(e.target.value))}
        maxLength={13}
        autoComplete="off"
        isvalue={mobile}
      />
      {mobileCertState === 'ING' && (
        <button type={'button'} className={cx('btnInfo')} onClick={() => onMobileInit()} disabled={remainingTime > 120}>
          재인증
        </button>
      )}
      {mobileCertState !== 'ING' && (
        <button
          type={'button'}
          className={cx('btnInfo')}
          onClick={onClickRequestCert}
          disabled={isDisabledRequestCertBtn || mobileCertState === 'SUCCESS'}
        >
          인증
        </button>
      )}
    </>
  );
};

export default Mobile;
