var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5.13.A"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// import * as Sentry from '@sentry/nextjs';
// import getConfig from 'next/config';
// import { hasErrorCode } from '@utils/typeGuard';

// // Sentry에서 무시할 에러 코드
// const IGNORE_ERROR_CODE_LIST = [
//   'J001', // 유효하지 않은 토큰
//   'J002', // 손상된 토큰
//   'J003', // 만료된 토큰
//   'J008', // refresh 토큰 없음
//   'U001', // access 토큰 없음
//   'U003', // 비밀번호 5회 이상 잘못 입력
// ];

// // Sentry에서 무시할 URL
// const IGNORE_URL_LIST = [
//   /https:\/\/px\.ads\.linkedin\.com\//, // LinkedIn Ads 관련 에러, 채팅 라이브러리에서 호출하는 것으로 추정
//   /jobflex\/login\/?.*/, //잡플렉스 로그인 에러 무시
//   /acc\/experience\/.*/, //구 역량검사 에러 무시
// ];

// Sentry.init({
//   dsn: 'https://8665773d259c6627c533b900a4791eaf@o4506698483564544.ingest.us.sentry.io/4506749533552643',
//   enabled: getConfig()?.publicRuntimeConfig?.['ROOT_URL'] === 'jobda.im',
//   tracesSampleRate: 1,
//   debug: false,
//   replaysOnErrorSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   integrations: [
//     Sentry.replayIntegration({
//       maskAllText: true,
//       blockAllMedia: true,
//     }),
//   ],
//   denyUrls: IGNORE_URL_LIST,
//   beforeSend(event, hint) {
//     const error = hint.originalException as any;

//     // if (error && error?.response?.status === 401) {
//     //   return null; // Ignore errors with a 401 status code
//     // }

//     if(IGNORE_URL_LIST.some((url) => url.test(error?.config?.url))) {
//       return null;
//     }

//     if (hasErrorCode(error)) {
//       if (IGNORE_ERROR_CODE_LIST.includes(error.response.data.errorCode)) {
//         return null;
//       }
//     }
    
//     return event;
//   },
// });
