import { useCallback } from 'react';
import { MILLISECONDS_PER_DAY } from '@domain/constant/Time';
import LocalStorageUtil from '@utils/storage/localStorage';

const useLocalStorageExpires = () => {
  const setExpires = useCallback(
    ({ key, value = 'true', expires = MILLISECONDS_PER_DAY }: { key: string; value?: string; expires?: number }) => {
      LocalStorageUtil.setItem(key, value, { expires: new Date(Date.now() + expires) });
    },
    [],
  );

  const getIsExpired = useCallback((key: string) => !LocalStorageUtil.getItem(key), []);

  return {
    setExpires,
    getIsExpired,
  };
};

export default useLocalStorageExpires;
