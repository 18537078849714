import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import Select from 'react-select';
import NoSsr from '@common/noSsr';
import { ReactSelectStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import { experienceOptions } from '@domain/constant/profile/ExperienceType';

import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface IActivityTypeProps {
  prefixName: string;
}

const ActivityType: FC<IActivityTypeProps> = ({ prefixName }) => {
  const typeFieldName = `${prefixName}.tag`;
  const { field: activityTypeField, fieldState: activityTypeMeta } = useController({
    name: typeFieldName,
  });

  const placeFieldName = `${prefixName}.title`;
  const { field: activityPlaceField, fieldState: activityPlaceState } = useController({
    name: placeFieldName,
  });

  return (
    <div className={cx('selectArea')}>
      <div className={cx('selectInner')}>
        <NoSsr>
          <Select
            {...activityTypeField}
            className={cx('select', { error: activityTypeMeta.invalid })}
            placeholder="선택"
            styles={ReactSelectStyles}
            isSearchable={false}
            options={experienceOptions}
          />
        </NoSsr>
      </div>

      <div className={cx('selectInner')}>
        <input
          {...activityPlaceField}
          type="text"
          className={cx('input', { error: activityPlaceState.invalid })}
          placeholder="기관/장소를 입력해 주세요."
          maxLength={25}
          autoComplete="off"
        />
        {activityPlaceState.invalid && <div className={cx('errorMessage')}>{activityPlaceState.error?.message}</div>}
      </div>
    </div>
  );
};

export default ActivityType;
