import React from 'react';
import Head from 'next/head';
import CustomHeadVO from '@domain/vo/common/CustomHeadVO';
import { PositionMetadataVO } from '@domain/vo/common/PositionMetadataVO';
import CompanyInfoVO from '@domain/vo/company/CompanyInfoVO';

interface CustomHeadProps {
  customHeadData: CustomHeadVO;
}

// og image는 비율 2:1, 800 400 권장
const CustomHead = ({ customHeadData }: CustomHeadProps) => {
  const { title, description, url, image, keywords } = customHeadData;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content="잡다" />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content="JOBDA" />
      <meta property="og:type" content={url.includes('position') || url.includes('company') ? 'article' : 'website'} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={url} />
    </Head>
  );
};

const CustomHeadWithNoIndex = ({ description, title }: { title: string; description: string }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content="잡다" />
      <meta name="robots" content="noindex, nofollow" />
    </Head>
  );
};

//메인페이지
export const IndexCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 ㅣ 역량검사만으로 취업되는 매칭 플랫폼',
    description:
      '역량검사 한 번으로 쏟아지는 채용 제안. 기업별 채용 정보, 실시간 채팅방, 역량검사 연습, 개발 구현 능력 검사 연습까지 한 곳에서 만나보세요.',
    url: 'https://www.jobda.im',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//채용공고
export const PositionListCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 채용공고',
    description: '나에게 맞는 추천 기업부터 연봉, 매출, 복지 정보까지 채용공고를 빠르게 확인해보세요.',
    url: 'https://www.jobda.im/position',
    image: 'https://www.jobda.im/img/og/imgOgPositionList.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//채용 캘린더
export const JobsCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 채용 캘린더',
    description: '신입 공채, 대기업 공채 등 다양한 채용공고를 달력 형태로 한 번에 확인해보세요.',
    url: 'https://www.jobda.im/jobs',
    image: 'https://www.jobda.im/img/og/imgOgPositionList.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//채용이벤트 - 리스트
export const ChallengeListCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 채용이벤트',
    description: '대규모 공채, 개발자 채용 등 취준생을 위한 채용 이벤트. 지금 당장 도전해보세요.',
    url: 'https://www.jobda.im/challenge',
    image: 'https://www.jobda.im/img/og/imgOgChallenge.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//채용이벤트 - 상세
export const ChallengeCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 채용이벤트',
    description: '대규모 공채, 개발자 채용 등 취준생을 위한 채용 이벤트. 지금 당장 도전해보세요.',
    url: 'https://www.jobda.im/challenge',
    image: 'https://www.jobda.im/img/og/imgOgChallenge.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 지원현황
export const ApplyingCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 작성 중인 지원서',
    description: '채용공고별 지원서를 한 번에 확인하고 해당 기업 인사담당자와 개별적으로 소통할 수 있어요.',
    url: 'https://www.jobda.im/mypage/applying',
    image: 'https://www.jobda.im/img/og/imgOgMatch.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

export const ApplyCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 지원현황',
    description: '채용공고별 지원현황을 한 번에 확인하고 해당 기업 인사담당자와 개별적으로 소통할 수 있어요.',
    url: 'https://www.jobda.im/myapge/apply',
    image: 'https://www.jobda.im/img/og/imgOgMatch.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

export const BookmarkCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 북마크',
    description: '채용공고별 북마크를 한 번에 확인하고 해당 기업 인사담당자와 개별적으로 소통할 수 있어요.',
    url: 'https://www.jobda.im/mypage/bookmark',
    image: 'https://www.jobda.im/img/og/imgOgMatch.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//프로필
export const ProfileCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 내 프로필',
    description: '자소서는 이제 그만! 역량검사 응시하고 프로필만 입력하면 다양한 채용공고에 바로 지원할 수 있어요.',
    url: 'https://www.jobda.im/profile',
    image: 'https://www.jobda.im/img/og/imgOgProfile.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//역량검사 결과
export const AccaResultsCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 역량검사 결과',
    description: '자소서는 이제 그만! 역량검사 응시하고 프로필만 입력하면 다양한 채용공고에 바로 지원할 수 있어요.',
    url: 'https://www.jobda.im/acca/results',
    image: 'https://www.jobda.im/img/og/imgOgProfile.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//개발자 검사 결과
export const PaccResultsCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 개발자 검사 결과',
    description: '자소서는 이제 그만! 역량검사 응시하고 프로필만 입력하면 다양한 채용공고에 바로 지원할 수 있어요.',
    url: 'https://www.jobda.im/phs/pacc/results',
    image: 'https://www.jobda.im/img/og/imgOgProfile.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 잡다매칭
export const MatchApplyCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 잡다매칭',
    description: '내게 관심있는 기업으로부터 먼저 제안 받을 수 있어요.',
    url: 'https://www.jobda.im/match/apply',
    image: 'https://www.jobda.im/img/og/imgOgMatch.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//포지션 상세 JD
interface PositionJdCustomHead {
  metaData: PositionMetadataVO;
}

export const PositionJdCustomHead = ({ metaData }: PositionJdCustomHead) => {
  const {
    positionSn,
    companyName,
    positionTitle,
    jobGroup,
    jobTitle,
    recruitmentTypeText,
    closingDateTime,
    thumbnailImageUrl,
  } = metaData;

  const imageUrl = thumbnailImageUrl ? thumbnailImageUrl : 'https://www.jobda.im/img/og/imgOgPositionJd.png';
  const jobGroupJobTitleText = jobGroup ? `${jobGroup} 채용.` : `${jobTitle} 채용.`;
  const closingDateTimeText = closingDateTime ? ` 마감일 : ${closingDateTime}` : '';

  const customHeadData = new CustomHeadVO({
    title: `${companyName} 채용 | ${positionTitle} | 잡다`,
    description: `${companyName}에서 ${positionTitle} ${recruitmentTypeText} 지원자를 모집하고 있어요. ${jobGroupJobTitleText}${closingDateTimeText}`,
    url: `https://www.jobda.im/position/${positionSn}/jd`,
    image: imageUrl,
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//기업 채널
interface CompanyChannelCustomHeadProps {
  metaData: CompanyInfoVO;
}

export const CompanyChannelCustomHead = ({ metaData }: CompanyChannelCustomHeadProps) => {
  const { companySn, companyName, logoImageUrl } = metaData;

  const imageUrl = logoImageUrl ? logoImageUrl : 'https://www.jobda.im/img/og/imgOg.png';

  const customHeadData = new CustomHeadVO({
    title: `${companyName} | 연봉, 직원수, 복지 등 | 잡다`,
    description: `${companyName}이 현재 채용중인 포지션, 연봉, 직원수, 복지 등 더 많은 기업 정보를 잡다에서 확인해보세요.`,
    url: `https://www.jobda.im/company/${companySn}`,
    image: imageUrl,
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//역량검사 응시
export const AccaTestCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 역량검사',
    description:
      '역량검사 게임, 역량검사 연습, 역량검사 후기까지 역량검사에 대한 모든 것을 확인하고 역량검사에 응시해보세요.',
    url: 'https://www.jobda.im/acca/test',
    image: 'https://www.jobda.im/img/og/imgOgAcca.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//역량검사 응시 리스트
export const AccaTestListCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 최신 역량검사 응시',
    description:
      '역량검사 게임, 역량검사 연습, 역량검사 후기까지 역량검사에 대한 모든 것을 확인하고 역량검사에 응시해보세요.',
    url: 'https://www.jobda.im/acca/test/list',
    image: 'https://www.jobda.im/img/og/imgOgAcca.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//역량검사 튜토리얼(구 역량검사)
export const AccTutorialCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 구 역량검사 연습',
    description: '구 역량검사를 연습해볼 수 있어요. 예전 모습의 역량검사가 궁금하시다면 한번 경험해보세요.',
    url: 'https://www.jobda.im/acc/tutorial',
    image: 'https://www.jobda.im/img/og/imgOgAcca.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//역량검사 튜토리얼 결과표
export const AcctutorialResultCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | JOBDA 역량검사 결과표 예시',
    description: '결과표를 통해 나의 숨겨진 역량을 확인해 보세요.',
    url: 'https://www.jobda.im/acc/tutorial',
    image: 'https://www.jobda.im/img/og/imgOgAcca.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//개발자 검사
export const PhsTestCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 개발자 검사',
    description: '개발자 취업 준비엔 잡다, 개발자 검사 응시하고 내 개발 역량과 구현 능력을 확인해보세요.',
    url: 'https://www.jobda.im/phs/test',
    image: 'https://www.jobda.im/img/og/imgOgPhsTest.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//개발자 역량검사
export const PhsPaccTestCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 개발자 역량검사',
    description: '개발자 취업 준비엔 잡다, 개발자 검사 응시하고 내 개발 역량과 구현 능력을 확인해보세요.',
    url: 'https://www.jobda.im/phs/pacc/test',
    image: 'https://www.jobda.im/img/og/imgOgPacc.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//개발구현능력 검사
export const PhsPstTestCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 개발 구현 능력 검사',
    description: '개발자 취업 준비엔 잡다, 개발자 검사 응시하고 내 개발 역량과 구현 능력을 확인해보세요.',
    url: 'https://www.jobda.im/phs/pst/test',
    image: 'https://www.jobda.im/img/og/imgOgPst.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 개발 구현 능력 검사 결과
export const PhsPstResultsCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 개발 구현 능력 검사',
    description: '개발자 취업 준비엔 잡다, 개발자 검사 응시하고 내 개발 역량과 구현 능력을 확인해보세요.',
    url: 'https://www.jobda.im/phs/pst/results',
    image: 'https://www.jobda.im/img/og/imgOgPst.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 디챔스 결과
export const PhsDchampsResultsCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 디챔스',
    description: '디챔스 결과를 확인해보세요.',
    url: 'https://www.jobda.im/phs/dchamps/results',
    image: 'https://www.jobda.im/img/og/imgOgPhsTest.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//기출 면접 연습
export const PassInterviewCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 기출 면접 연습',
    description: '기업별 예상 질문 확인하고 영상면접 연습까지! 면접을 완벽하게 대비해보세요.',
    url: 'https://www.jobda.im/pass/interview',
    image: 'https://www.jobda.im/img/og/imgOgPassInterview.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//잡다선언문
export const JobdaDeclarationCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 잡다선언문',
    description: '역량 중심 사회를 지향하는 잡다! 능력과 기회를 매칭하는 잡다의 목표를 확인해 보세요.',
    url: 'https://www.jobda.im/declaration',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//역량검사 소개
export const AccaIntroduceCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 역량검사 소개',
    description:
      '역량검사 질문, 역량검사 게임, 역량검사 연습, 역량검사 후기까지 역량검사에 대한 모든 것을 확인해 보세요.',
    url: 'https://www.jobda.im/acca/introduce',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

//취업 콘텐츠
export const InfoListCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 취업 콘텐츠',
    description: '재직자들의 취업 비법, 역량검사 합격 후기, 역량검사 게임 안내 등 생생한 취업 정보를 얻어 가세요.',
    url: 'https://www.jobda.im/info/list',
    image: 'https://www.jobda.im/img/og/imgOgInfoList.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

export const PassboardCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 취업 합격 후기 게시판',
    description:
      '역량검사 합격, 최종 합격 등 선배들의 취업 성공 후기를 들어보세요. 생생한 합격 수기 속에 취업 꿀팁이 모두 담겨있어요.',
    url: 'https://www.jobda.im/passboard',
    image: 'https://www.jobda.im/img/og/imgOgPassboard.png',
    keywords:
      '잡다, 취업, 채용공고, 채용정보, 역량검사, 역량검사, 공채, 수시, 취준, 기업, 합격후기, 취업후기, 최종합격후기, 취업성공후기, 후기이벤트, 네이버페이, 잡다매칭',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 인재풀 전환 이벤트(챌린지)
export const EventChallengeCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | JOBDA 챌린지',
    description: '채용정보 확인, 역량검사 연습, 역량검사 응시, 지원서 작성 등 취업 준비를 한 곳에서 할 수 있어요.',
    url: 'https://www.jobda.im/event/challenge',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 인재풀 전환 이벤트(챌린지)
export const EventDChampsCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 디챔스',
    description: '디챔스는 JOBDA에서 주관하는 개발자 경진대회입니다.',
    url: 'https://www.jobda.im/event/dchamps2309',
    image: 'https://www.jobda.im/img/og/imgOgPhsTest.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 추석 이벤트 (이벤트)
export const EventChuseokCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 추석이벤트',
    description: '채용정보 확인, 역량검사 연습, 역량검사 응시, 지원서 작성 등 취업 준비를 한 곳에서 할 수 있어요.',
    url: 'https://www.jobda.im/event/holiday2309',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 프로필 완성 이벤트 (이벤트)
export const EventProfileCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 프로필 완성 이벤트',
    description: '채용정보 확인, 역량검사 연습, 역량검사 응시, 지원서 작성 등 취업 준비를 한 곳에서 할 수 있어요.',
    url: 'https://www.jobda.im/event/profile',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 메일 프로필 이벤트 (이벤트)
export const EventMailProfileCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 프로필 이벤트',
    description: '채용정보 확인, 역량검사 연습, 역량검사 응시, 지원서 작성 등 취업 준비를 한 곳에서 할 수 있어요.',
    url: 'https://www.jobda.im/event/mailProfile',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 기획매칭
export const PlanMatchingCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: 'JOBDA | 마케터 채용 타임어택',
    description:
      '지금이 바로 일잘러 주니어 마케터 채용의 기회! 검증된 인재를 한곳에 모았어요. 인재를 확인하고 빠르게 선점하세요.',
    url: 'https://www.jobda.im/event/timeattack_marketer',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 취업 준비
export const PreparationCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 취업 준비',
    description: '취업 준비를 위한 다양한 콘텐츠를 만나보세요.',
    url: 'https://www.jobda.im/preparation',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 계정정보 관리
export const AccountCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 계정정보 관리',
    description: '잡다 계정정보를 관리해보세요.',
    url: 'https://www.jobda.im/mypage/account',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 제휴서비스 신청
export const PremiumCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 제휴서비스 신청',
    description: '제휴서비스를 신청해보세요.',
    url: 'https://www.jobda.im/mypage/premium',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 알림 관리
export const NotificationCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 알림 관리',
    description: '잡다에서 보내는 알림을 설정해보세요.',
    url: 'https://www.jobda.im/mypage/notice',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 취업 콘텐츠
export const InfoDetailCustomHead = ({ title, sn }: { title: string; sn: number }) => {
  const customHeadData = new CustomHeadVO({
    title: `잡다 | ${title}`,
    description: '재직자들의 취업 비법, 역량검사 합격 후기, 역량검사 게임 안내 등 생생한 취업 정보를 얻어 가세요.',
    url: `https://www.jobda.im/info/${sn}`,
    image: 'https://www.jobda.im/img/og/imgOgInfoList.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 검색 결과
export const SearchResultCustomHead = ({ keyword = '' }: { keyword?: string }) => {
  const customHeadData = new CustomHeadVO({
    title: keyword ? `${keyword} - 잡다 검색` : '잡다 검색',
    description: `${keyword} 검색`,
    url: `https://www.jobda.im/search/result?keyword=${keyword}`,
    image: 'https://www.jobda.im/img/og/imgOgInfoList.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 자주 묻는 질문
export const FaqCustomHead = () => {
  const customHeadData = new CustomHeadVO({
    title: '잡다 | 고객센터',
    description: '잡다 고객센터에서 회원가입, 역량검사, 제휴 서비스 등 자주 묻는 질문들을 확인해보세요.',
    url: 'https://www.jobda.im/jobdafaq',
    image: 'https://www.jobda.im/img/og/imgOg.png',
  });

  return <CustomHead customHeadData={customHeadData} />;
};

// 만 15세 미만 생년월일 입력
export const UpdateBirthdayCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 생년월일 입력',
    description: '잡다에서는 만 15세 미만 회원가입이 불가능합니다. 생년월일을 입력해주세요.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 마케팅 정보 수신 동의 약관(선택)
export const MarketingPolicyCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 마케팅 정보 수신 동의',
    description: '잡다에서 제공하는 서비스 유형에 따라 개인정보를 수집, 이용, 보유에 대한 동의를 받습니다.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 잡다 응시 결과 저장
export const AccaResultSaveCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 응시 결과 저장',
    description: '역량검사 응시 결과를 저장하세요.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 서비스 이용 약관
export const TermsOfServiceCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 서비스 이용 약관',
    description: '잡다 서비스 이용 약관을 확인하세요.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 개인정보 처리방침
export const PrivacyPolicyCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 개인정보 처리방침',
    description: '잡다 개인정보 처리방침을 확인하세요.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 공지사항
export const NoticeCustomHead = ({ title }: { title: string }) => {
  const customHeadData = {
    title: `잡다 | ${title}`,
    description: `${title} 공지사항`,
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 회원가입
export const JoinCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 회원가입',
    description: '잡다 회원가입 페이지입니다.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 역량검사 튜토리얼 응시 로그인
export const AccaTutorialLoginCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 역량검사 튜토리얼 응시 로그인',
    description: '역량검사 튜토리얼 응시를 위해 잡다 계정으로 로그인해주세요.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 존재하지 않는 기업 정보 페이지
export const CompanyNotFoundCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 기업 정보',
    description: '존재하지 않는 기업 정보 페이지입니다.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 존재하지 않는 포지션 페이지
export const PositionNotFoundCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 채용 공고',
    description: '존재하지 않는 채용공고 페이지입니다.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 로그인
export const LoginCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 로그인',
    description: '채용정보 · 역량검사 · 매칭까지 취업기회 지금 바로 잡다!',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 개인정보 수집 및 이용안내
export const PrivacyCollectionCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 개인정보 수집 및 이용안내',
    description: '잡다 개인정보 수집 및 이용안내 페이지입니다.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 개인정보 제3자 제공 동의
export const PrivacyThirdPartyCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 개인정보 제3자 제공 동의',
    description: '잡다 개인정보 제3자 제공 동의 페이지입니다.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

// 본인 인증하고 잡다 시작하기
export const UpdateAccountCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 본인 인증',
    description: 'JOBDA에 오랜만에 방문하셨네요. 본인 인증하고 잡다 시작해보세요.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

//선호정보 유도 페이지
export const PreferredCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 선호정보 입력',
    description: '자소서는 이제 그만! 역량검사 응시하고 프로필만 입력하면 다양한 채용공고에 바로 지원할 수 있어요.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};

export const ProfilePreviewCustomHead = () => {
  const customHeadData = {
    title: '잡다 | 프로필',
    description: '프로필 미리보기.',
  };

  return <CustomHeadWithNoIndex {...customHeadData} />;
};
