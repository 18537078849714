import ResumeAdditionInfoRs, { ResumeAdditionInfoDto } from '@domain/rs/match/ResumeAdditionInfoRs';
import { AttachFileDto } from '@domain/rs/position/jd/ProfileDetailRs';
import { AdditionalItemType } from '@domain/constant/profileRequirementType';

export type AttachFileDtoWithFileText = AttachFileDto & {
  fileNameText: string;
};

export default class ResumeAdditionInfoVO {
  additionInfo: AdditionInfoVO[];
  isAdditionalExist: boolean;

  constructor(rs: ResumeAdditionInfoRs) {
    this.additionInfo = (rs.additionInfo ?? []).map((dto) => new AdditionInfoVO(dto));
    this.isAdditionalExist = this.additionInfo.length > 0;
  }
}

export class AdditionInfoVO {
  additionInfoSn: number;
  question: string;
  required: boolean;
  choices: string[];
  files: AttachFileDtoWithFileText[];
  formType: AdditionalItemType;
  maxAnswerSize?: number | null;

  constructor(dto: ResumeAdditionInfoDto) {
    this.additionInfoSn = dto.additionInfoSn;
    this.question = dto.question;
    this.required = dto.required;
    this.choices = dto.choices;
    this.files = dto.files?.map((file) => {
      const splitedFileName = file.fileName.split('.');
      const fileName = splitedFileName[0];
      const fileExtention = splitedFileName[1];
      return {
        ...file,
        fileNameText: fileName.length > 9 ? `${fileName.slice(0, 9)}...${fileExtention}` : file.fileName,
      };
    });
    this.formType = dto.type;
    this.maxAnswerSize = dto.maxAnswerSize;
  }
}
