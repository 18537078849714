import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToast } from '@common/toast';
import { ModalStateType } from '@components/loginModal';
import FindPasswordId from '@components/loginModal/find/password/FindPasswordId';
import FindPasswordConfirm from '@components/loginModal/find/password/FindPasswordConfirm';
import FindPasswordReset from '@components/loginModal/find/password/FindPasswordReset';
import KcbIpinVerificationRs from '@domain/rs/verification/KcbIpinVerificationRs';
import { baseURL } from '@utils/common';

export type FindPasswordStepType = 'ID' | 'CONFIRM' | 'RESET';

interface FindPasswordProps {
  setModalState: (state: ModalStateType) => void;
}

export interface FormTypes {
  id: string;
  birthDate: string;
  countryCode: string;
  mobile: string;
  token: string;
  password: string;
  rePassword: string;
}

const FindPassword = ({ setModalState }: FindPasswordProps) => {
  const [findPasswordStep, setFindPasswordStep] = useState<FindPasswordStepType>('ID');
  const [verificationRs, setVerivicationRs] = useState<KcbIpinVerificationRs | null>(null);
  const Toast = useToast();

  const methods = useForm<FormTypes>({ mode: 'onChange' });

  /**아이핀 인증 완료 후 비밀번호 찾기 로직 */
  const handleFindVerificationEvent = (verificationRs: KcbIpinVerificationRs) => {
    if (!verificationRs) return;

    setVerivicationRs(verificationRs);
    setFindPasswordStep('RESET');
  };

  //아이핀 인증 관련 child window 에서 넘어오는 완료 상태값 처리
  const handleVerificationEvent = (event: MessageEvent) => {
    try {
      // 신뢰할 수 없는 origin 처리
      if (baseURL !== event.origin) return;
      const verificationRs: KcbIpinVerificationRs = JSON.parse(event.data);

      //ipin 인증 성공 : T000, sms 인증 성공 : B000
      if (!['T000'].includes(verificationRs.code)) {
        Toast({ type: 'error', iconType: 'info', content: '본인인증 실패하였습니다.' });
        return;
      }
      handleFindVerificationEvent(verificationRs);
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    //아이핀 인증 완료시 이벤트 리스너 등록
    window.addEventListener('message', handleVerificationEvent, false);
    return () => window.removeEventListener('message', handleVerificationEvent);
  }, []);

  return (
    <FormProvider {...methods}>
      {findPasswordStep === 'ID' && <FindPasswordId setFindPasswordStep={setFindPasswordStep} />}
      {findPasswordStep === 'CONFIRM' && <FindPasswordConfirm setFindPasswordStep={setFindPasswordStep} />}
      {findPasswordStep === 'RESET' && (
        <FindPasswordReset verificationRs={verificationRs} setModalState={setModalState} />
      )}
    </FormProvider>
  );
};

export default FindPassword;
