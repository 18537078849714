import ProfileViewVO from '@domain/vo/profile/common/ProfileViewVO';
import FileRs from '@domain/rs/common/FileRs';
import { ProfileEducationRs, EducationDto, MajorDto, ResearchDto } from '@domain/rs/profile/ProfileEducationRs';
import SchoolRs from '@domain/rs/profile/edu/SchoolRs';
import MajorRs from '@domain/rs/profile/edu/MajorRs';
import {
  EducationLevelNameMap,
  EducationLevelShortNameMap,
  EducationNameMap,
  MajorNameMap,
  ScoreNameMap,
  EducationLevelType,
  EducationStatusType,
  MajorType,
  ScoreType,
  Score,
} from '@domain/constant/profile/EducationType';
import { AuditItemsType } from '@domain/constant/profile/AuditItemsType';
import DateFormat from '@utils/date/format';

export class SchoolVO {
  value: number;
  label: string;

  constructor(rs: SchoolRs) {
    this.value = rs.code;
    this.label = rs.name;
  }
}

export class MajorAliasVO {
  value: MajorRs;
  label: string;

  constructor(rs: MajorRs) {
    this.value = rs;
    this.label = rs.name;
  }
}

export default class ProfileEducationVO extends ProfileViewVO {
  educationLevel: EducationLevelType | null;
  educationLevelText: string;
  educations: EducationVO[];

  constructor(rs: ProfileEducationRs) {
    super(rs);

    this.educationLevel = rs.educationLevel;
    this.educationLevelText = rs.educationLevel ? EducationLevelShortNameMap[rs.educationLevel] : '';
    this.educations = rs.educations.map((item) => new EducationVO(item));
  }
}

export class EducationVO {
  schoolLevel: EducationLevelType;
  schoolLevelText: string;
  educationType: EducationStatusType;
  transferYn: boolean;
  educationTypeText: string;
  startDate: string;
  endDate: string | null;
  schoolCode: number;
  schoolName: string;
  profileMajors: MajorVO[] | [];
  profileResearches: ResearchVO[] | [];
  auditItems?: AuditItemsType[];

  constructor(rs: EducationDto) {
    this.schoolLevel = rs.schoolLevel;
    this.schoolLevelText = rs.schoolLevel ? EducationLevelNameMap[rs.schoolLevel] : '';
    this.educationType = rs.educationType;
    this.transferYn = rs.transferYn;
    this.educationTypeText = rs.educationType ? EducationNameMap[rs.educationType] : '';
    this.startDate = this.getDateFormat(rs.startDate);
    this.endDate = this.getDateFormat(rs.endDate);
    this.schoolCode = rs.schoolCode;
    this.schoolName = rs.schoolName;
    this.profileMajors = rs.profileMajors.map((item) => new MajorVO(item));
    this.profileResearches = rs.profileResearches.map((item) => new ResearchVO(item));
    this.auditItems = rs.auditItems;
  }

  getDateFormat(data: string | null) {
    return DateFormat.getDateFormatString(data, 'yyyy.MM') ?? '';
  }
}

export class MajorVO {
  majorType: MajorType;
  majorTypeText: string;
  majorCode: number;
  majorName: string;
  scoreType: ScoreType | null;
  scoreTypeText: string;
  score: number | null;
  scoreText: string;
  perfectScore: number | null;

  constructor(rs: MajorDto) {
    this.majorType = rs.majorType;
    this.majorTypeText = rs.majorType ? MajorNameMap[rs.majorType] : '';
    this.majorCode = rs.majorCode;
    this.majorName = rs.majorName;
    this.scoreType = rs.scoreType;
    this.scoreTypeText = rs.scoreType ? ScoreNameMap[rs.scoreType] : '';
    this.score = rs.score;
    this.scoreText = rs.scoreType ? this.getScoreText(rs.scoreType, rs.score, rs.perfectScore) : '';
    this.perfectScore = rs.perfectScore;
  }

  getScoreText = (scoreType: ScoreType, score: number | null, perfectScore: number | null) => {
    if (scoreType === Score.SCORE && score && perfectScore) {
      return ` (${ScoreNameMap[scoreType]} ${score}/${perfectScore})`;
    } else if (scoreType === Score.PERCENTAGE && score) {
      return ` (${ScoreNameMap[scoreType]} ${score})`;
    }

    return '';
  };
}

export class ResearchVO {
  attachFiles: FileRs[];
  description: string;
  descriptionList: string[];

  constructor(rs: ResearchDto) {
    this.attachFiles = rs.attachFiles;
    this.description = rs.description;
    this.descriptionList = rs.description ? rs.description.split('\n') : [];
  }
}
