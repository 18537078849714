import React, { HTMLProps } from 'react';
import classnames from 'classnames/bind';

import styles from './switch.module.scss';

const cx = classnames.bind(styles);

export interface SwitchProps extends HTMLProps<HTMLInputElement> {
  label?: string;
}

const Switch = React.forwardRef((props: SwitchProps, ref: React.Ref<HTMLInputElement>) => {
  const { className, label, ...inputProps } = props;
  return (
    <div className={cx('switchArea', className)}>
      <label className={cx('switch')}>
        {label !== undefined && <span className={cx('label')}>{label}</span>}
        <input className={cx('hidden', 'input')} type="checkbox" {...inputProps} ref={ref} />
        <span className={cx('slider')} />
      </label>
    </div>
  );
});

export default Switch;
