import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import JobGroupsWithTitlesRs, { JobGroupsWithTitlesDescRs } from '@domain/rs/code/JobGroupsWithTitlesRs';
import WelfareCategoryWithWelfaresListRs from '@domain/rs/code/WelfareCategoryWithWelfaresListRs';
import { CountryCodeListRs } from '@domain/rs/code/CountryCodeRs';
import { JobGroupListRs } from '@domain/rs/profile/career/JobGroupRs';
import { JobTitleListRs } from '@domain/rs/profile/career/JobTitleRs';
import { SchoolListRs } from '@domain/rs/profile/edu/SchoolRs';
import { MajorListRs } from '@domain/rs/profile/edu/MajorRs';
import { EducationLevelType } from '@domain/constant/profile/EducationType';

const codePrefix = '/codes';

export default interface CodesRepo {
  //직군 직무 전체 조회
  fetchJobGroupsWithTitles(): Promise<AxiosResponse<JobGroupsWithTitlesRs>>;

  //직군 직무(설명 포함) 전체 조회
  fetchJobGroupsWithTitlesDesc(): Promise<AxiosResponse<JobGroupsWithTitlesDescRs>>;

  //직군 리스트 조회
  fetchJobGroupList(): Promise<AxiosResponse<JobGroupListRs>>;

  //직무 리스트 조회
  fetchJobTitleList(jobGroupSn: number): Promise<AxiosResponse<JobTitleListRs>>;

  //복지 카테고리 복지 전체 조회
  fetchWelfareCategoriesWithWelfares(): Promise<AxiosResponse<WelfareCategoryWithWelfaresListRs>>;

  //국가 전체 리스트 조회
  fetchCountryList(): Promise<AxiosResponse<CountryCodeListRs>>;

  //학교 리스트 조회
  fetchSchoolList(educationLevel: EducationLevelType, name?: string): Promise<AxiosResponse<SchoolListRs>>;

  //학과 리스트 조회
  fetchMajorList(name?: string): Promise<AxiosResponse<MajorListRs>>;
}

export class RemoteCodesRepo implements CodesRepo {
  fetchJobGroupsWithTitles() {
    return axios.get<JobGroupsWithTitlesRs>(`${codePrefix}/v2/job-groups/job-titles`);
  }

  fetchJobGroupsWithTitlesDesc() {
    return axios.get<JobGroupsWithTitlesDescRs>(`${codePrefix}/job-title-descriptions`);
  }

  fetchJobGroupList() {
    return axios.get<JobGroupListRs>(`${codePrefix}/job-groups`);
  }

  fetchJobTitleList(jobGroupSn: number) {
    return axios.get<JobTitleListRs>(`${codePrefix}/job-titles`, { params: { jobGroupSn } });
  }

  fetchWelfareCategoriesWithWelfares() {
    return axios.get<WelfareCategoryWithWelfaresListRs>(`${codePrefix}/welfare-categories/welfares`);
  }

  fetchCountryList() {
    return axios.get<CountryCodeListRs>(`${codePrefix}/countries`);
  }

  fetchSchoolList(educationLevel?: EducationLevelType, name?: string) {
    return axios.get<SchoolListRs>(`${codePrefix}/schools`, { params: { educationLevel, name } });
  }

  fetchMajorList(name?: string) {
    return axios.get<MajorListRs>(`${codePrefix}/major-aliases`, { params: { name } });
  }
}
