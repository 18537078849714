export enum BusinessSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  STARTUP = 'STARTUP',
  GOVERNMENT = 'GOVERNMENT',
  ETC = 'ETC,',
}

export const businessSizeTextMap: Record<BusinessSizeType, string> = {
  SMALL: '중소기업',
  MEDIUM: '중견기업',
  LARGE: '대기업',
  STARTUP: '스타트업',
  GOVERNMENT: '공기업',
  ETC: '기타',
};

export type BusinessSizeType = keyof typeof BusinessSize;

export enum BusinessSizeEnumKo {
  LARGE = '대기업',
  MEDIUM = '중견기업',
  GOVERNMENT = '공공기관',
  ETC = '기타',
}
