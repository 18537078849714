import * as yup from 'yup';
import { EducationStatusType, ScoreType } from '@domain/constant/profile/EducationType';
import { ReactSelectOption } from '@domain/constant/ReactSelectOption';

const majorSchema = yup.object({
  majorAlias: yup.object().required(),
  scoreType: yup.string().nullable(),
  score: yup
    .string()
    .nullable()
    .when('scoreType', {
      is: (val: ScoreType) => val === 'SCORE',
      then: yup
        .string()
        .nullable()
        .test({
          test(value, ctx) {
            if (!value && ctx.parent.perfectScore) return ctx.createError({ message: '평점을 입력해주세요.' });
            if (value && Number(value) < 0) return ctx.createError({ message: '평점이 0보다 작습니다.' });
            if (value && Number(value) > 100) return ctx.createError({ message: '평점이 100보다 큽니다.' });
            return true;
          },
        }),
    })
    .when('scoreType', {
      is: (val: ScoreType) => val === 'PERCENTAGE',
      then: yup
        .string()
        .nullable()
        .test({
          test(value, ctx) {
            if (value && Number(value) < 0) return ctx.createError({ message: '백분위가 0보다 작습니다.' });
            if (value && Number(value) > 100) return ctx.createError({ message: '백분위가 100보다 큽니다.' });
            return true;
          },
        }),
    }),
  perfectScore: yup.string().when('scoreType', {
    is: (val: ScoreType) => val === 'SCORE',
    then: yup
      .string()
      .test({
        test(value, ctx) {
          if (!value && ctx.parent.score) return ctx.createError({ message: '만점을 입력해주세요.' });
          if (value && Number(value) < 0) return ctx.createError({ message: '만점이 0보다 작습니다.' });
          if (value && Number(value) > 100) return ctx.createError({ message: '만점이 100보다 큽니다.' });
          return true;
        },
      })
      .when('score', (score, schema) => {
        return schema.test({
          test: (perfectScore: number) => {
            if (score && !perfectScore) return false;
            else return (!score && !perfectScore) || Number(perfectScore) >= Number(score);
          },
          message: '만점이 평점보다 작습니다.',
        });
      }),
    otherwise: yup.string().nullable().notRequired(),
  }),
});

const educationSchema = yup.object({
  schoolLevel: yup.object(),
  schoolName: yup.lazy((value) => {
    if (!value) return yup.object().nullable().required('학교를 검색해주세요.');
    return yup
      .object()
      .shape({
        value: yup.string(),
      })
      .test('value-length', '30자 이내로 입력해주세요.', (obj) => {
        return obj.value ? obj.value.length <= 30 : true;
      });
  }),
  educationType: yup.object().nullable().required(),
  startDate: yup.string().required(),
  endDate: yup
    .string()
    .when('educationType', {
      is: (val: ReactSelectOption<EducationStatusType>) => val === null,
      then: yup
        .string()
        .when('startDate', (startDate, schema) => {
          return schema.test({
            test: (endDate: Date, ctx: yup.TestContext) => {
              if (endDate <= startDate) {
                return ctx.createError({ message: '졸업 일자가 입학 일자보다 빠릅니다.' });
              } else return true;
            },
          });
        })
        .required('졸업 일자를 입력해주세요.'),
      otherwise: yup.string().notRequired(),
    })
    .when('educationType', {
      is: (val: ReactSelectOption<EducationStatusType>) =>
        !['ATTENDING', 'COMPLETE', 'LEAVE'].includes(String(val?.value)),
      then: yup
        .string()
        .when('startDate', (startDate, schema) => {
          return schema.test({
            test: (endDate: Date, ctx: yup.TestContext) => {
              if (endDate <= startDate) {
                if (
                  ctx?.parent.educationType?.value === 'GRADUATION' ||
                  ctx?.parent.educationType?.value === 'GRADUATION_CANDIDATE'
                )
                  return ctx.createError({ message: '졸업 일자가 입학 일자보다 빠릅니다.' });
                if (ctx?.parent.educationType?.value === 'DROP')
                  return ctx.createError({ message: '중퇴 일자가 입학 일자보다 빠릅니다.' });
              } else return true;
            },
          });
        })
        .test({
          test(value, ctx) {
            if (!value) {
              if (
                ctx?.parent.educationType?.value === 'GRADUATION' ||
                ctx?.parent.educationType?.value === 'GRADUATION_CANDIDATE'
              )
                return ctx.createError({ message: '졸업 일자를 입력해주세요.' });
              if (ctx?.parent.educationType?.value === 'DROP')
                return ctx.createError({ message: '중퇴 일자를 입력해주세요.' });
            }
            return true;
          },
        }),
    }),
  major: yup.lazy((_, ctx) => {
    const schollLevel = ctx?.parent?.schoolLevel?.value;
    return schollLevel === 'HIGHSCHOOL' ? yup.mixed().notRequired() : majorSchema;
  }),
  minor: yup.lazy((value) =>
    value?.minorUseYn !== true
      ? yup.object().nullable()
      : majorSchema.when('minorUseYn', {
          is: (val: boolean) => val,
          then: majorSchema,
          otherwise: yup.object().nullable(),
        }),
  ),
  double: yup.lazy((value) =>
    value?.doubleUseYn !== true
      ? yup.object().nullable()
      : majorSchema.when('doubleUseYn', {
          is: (val: boolean) => val,
          then: majorSchema,
          otherwise: yup.object().nullable(),
        }),
  ),
});

export default educationSchema;
