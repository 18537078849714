import React from 'react';
import Checkbox from '@common/checkbox';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface ListItemType {
  isOn?: boolean;
  name?: string;
  onClick?: () => void;
}

interface CheckboxListItemType {
  isChecked?: boolean;
  name?: string;
  onChange?: (checked: boolean) => void;
}

interface CheckboxListProps {
  list?: ListItemType[];
  checkboxList?: CheckboxListItemType[];
}

const CheckboxList = ({ list, checkboxList }: CheckboxListProps) => {
  const listEls = list?.map((item, index) => (
    <li key={index} className={cx('listBtn', { on: item.isOn })} onClick={item.onClick}>
      {item?.name}
    </li>
  ));

  const checkboxListEls = checkboxList?.map((item, index) => {
    return (
      <div key={index} className={cx('checkboxArea')}>
        <div className={cx('checkboxWrap')}>
          <Checkbox
            type={'square'}
            checked={!!item?.isChecked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => item.onChange?.(e.target.checked)}
          >
            <span className={cx('labelText')}>{item.name}</span>
          </Checkbox>
        </div>
      </div>
    );
  });

  return (
    <div className={cx('area')}>
      <div className={cx('listArea')}>
        <ul className={cx('listWrap')}>{listEls}</ul>
      </div>

      <div className={cx('checkboxListArea')}>
        <div className={cx('checkboxListWrap')}>{checkboxListEls}</div>
      </div>
    </div>
  );
};

export default CheckboxList;
