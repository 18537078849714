import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import CheckboxList from '@common/checkboxList';
import Chip from '@common/checkboxList/chip';
import { useWelfareCategoriesWithWelfares } from '@repository/codes/useCodes';

import classnames from 'classnames/bind';
import styles from '../prefer.module.scss';

const cx = classnames.bind(styles);

interface WelfareProps {
  name: string;
  visible: boolean;
}

const Welfare = ({ name, visible }: WelfareProps) => {
  const [selectedWelfareCode, setSelectedWelfareCode] = useState<number>(11);

  const { field: welfareFields } = useController({ name });
  const { data: welfareData, isLoading } = useWelfareCategoriesWithWelfares();
  if (!welfareData || isLoading || !visible) return null;

  const getWelFareName = (code: number) =>
    welfareData?.welfareCategoryWithWelfares
      ?.flatMap((welfareCategoryWithWelfare) => welfareCategoryWithWelfare.children)
      ?.flatMap((item) => item.welfares)
      ?.find((item) => item?.code === code)?.name || undefined;

  const handleAddWelfare = (code: number) => {
    welfareFields.onChange([...welfareFields?.value, code]);
  };

  const handleDeleteWelfare = (code: number) => {
    welfareFields.onChange([...welfareFields?.value?.filter((welfareField: number) => welfareField !== code)]);
  };

  const welfareCategoryList = welfareData?.welfareCategoryWithWelfares
    ?.flatMap((welfareCategoryWithWelfare) => welfareCategoryWithWelfare.children)
    ?.map((item) => {
      return {
        isOn: selectedWelfareCode === item.code,
        name: item.name,
        onClick: () => setSelectedWelfareCode(item.code),
      };
    });

  const welfareItemList = welfareData.welfareCategoryWithWelfares
    ?.flatMap((welfareCategoryWithWelfare) => welfareCategoryWithWelfare.children)
    ?.filter((item) => item.code === selectedWelfareCode)
    ?.flatMap((item) => item.welfares)
    ?.map((item) => {
      const isChecked = welfareFields?.value?.includes(item.code);
      return {
        isChecked,
        name: item.name,
        onChange: () => (isChecked ? handleDeleteWelfare(item.code) : handleAddWelfare(item.code)),
      };
    });

  const chipEls = welfareFields?.value?.map((code: number) => {
    const name = getWelFareName(code);
    return <Chip key={code} name={name} onClick={() => handleDeleteWelfare(code)} />;
  });

  return (
    <div className={cx('listArea', 'welfare')}>
      <CheckboxList list={welfareCategoryList} checkboxList={welfareItemList} />
      <div className={cx('chipArea')}>{chipEls}</div>
    </div>
  );
};

export default Welfare;
