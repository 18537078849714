import React, { FC, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@common/toast';
import NoIdModal from '@components/loginModal/find/noIdModal';
import { FindStateType, ModalStateType } from '@components/loginModal';
import { useIdentityIdFind } from '@repository/identity/useIdentity';
import FindSelect from '@components/loginModal/find/id/FindSelect';
import FindIdMobile from '@components/loginModal/find/id/FindIdMobile';
import FindIdResult from '@components/loginModal/find/id/FindIdResult';
import IdentificationIdRs from '@domain/rs/verification/IdentificationIdRs';
import KcbIpinVerificationRs from '@domain/rs/verification/KcbIpinVerificationRs';
import { baseURL } from '@utils/common';

export type FindIdStepType = 'SELECT' | 'MOBILE' | 'RESULT';

interface FindIdProps {
  setModalState: (state: ModalStateType) => void;
  setFindState: (state: FindStateType) => void;
}

const FindId: FC<FindIdProps> = ({ setModalState, setFindState }) => {
  const Toast = useToast();
  const [findIdStep, setFindIdStep] = useState<FindIdStepType>('SELECT');
  const [findIdResult, setFindIdResult] = useState<IdentificationIdRs | null>(null);
  const [visibleNoIdModal, setVisibleNoIdModal] = useState(false);

  const { mutateAsync: identityIdFind } = useIdentityIdFind();

  /**아이핀 인증 완료 후 아이디찾기 로직 */
  const handleFindVerificationEvent = async (verificationRs: KcbIpinVerificationRs) => {
    if (!verificationRs) return;
    try {
      const res = await identityIdFind(verificationRs.identificationToken);
      setFindIdResult(res);
      setFindIdStep('RESULT');
    } catch (error: any) {
      handleIdFindError(error);
    }
  };

  const handleIdFindError = (error: AxiosError) => {
    let content;
    switch (error?.response?.data.errorCode) {
      case 'B901':
        content = '본인인증 세션이 만료되었습니다.';
        break;
      case 'B902':
        setVisibleNoIdModal(true);
        return;
      default:
        content = error?.response?.data.message;
    }
    Toast({ type: 'error', iconType: 'info', content });
  };

  //아이핀 인증 관련 child window 에서 넘어오는 완료 상태값 처리
  const handleVerificationEvent = (event: MessageEvent) => {
    try {
      // 신뢰할 수 없는 origin 처리
      if (baseURL !== event.origin) return;
      const verificationRs: KcbIpinVerificationRs = JSON.parse(event.data);

      //ipin 인증 성공 : T000, sms 인증 성공 : B000
      if (!['T000'].includes(verificationRs.code)) {
        Toast({ type: 'error', iconType: 'info', content: '본인인증 실패하였습니다.' });
        return;
      }
      handleFindVerificationEvent(verificationRs);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    //아이핀 인증 완료시 이벤트 리스너 등록
    window.addEventListener('message', handleVerificationEvent, false);
    return () => window.removeEventListener('message', handleVerificationEvent);
  }, []);

  return (
    <>
      {findIdStep === 'SELECT' && <FindSelect setFindIdStep={setFindIdStep} />}
      {findIdStep === 'MOBILE' && <FindIdMobile setFindIdStep={setFindIdStep} setFindIdResult={setFindIdResult} />}
      {findIdStep === 'RESULT' && (
        <FindIdResult findIdData={findIdResult} setModalState={setModalState} setFindState={setFindState} />
      )}
      {/* [D] 아이디 없는 경우 노출 모달 */}
      <NoIdModal visible={visibleNoIdModal} onConfirm={() => setVisibleNoIdModal(false)} />
    </>
  );
};

export default FindId;
