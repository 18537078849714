import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMatch } from '@components/common/matchSwitch';
import MatchingGuideModal from './matchingGuideModal';
import { RouteUrl } from '@utils/routeUtil';

const MatchingProposalModal = () => {
  const { pathname } = useRouter();
  const { matchConfigStatus, profilesTypeData, modalState, setModalState } = useMatch();
  const isVisiblePath = [RouteUrl.PROFILE, RouteUrl.MATCH_APPLY, RouteUrl.ACCA_RESULTS].includes(pathname as any);

  const onClose = () => setModalState(null);

  useEffect(() => {
    if (!matchConfigStatus) return;
    if (!isVisiblePath) return;
    if (
      (!matchConfigStatus.accCompleteYn && matchConfigStatus.profileRequiredCompleteYn) ||
      (matchConfigStatus.accCompleteYn && !matchConfigStatus.profileRequiredCompleteYn) ||
      (matchConfigStatus.accCompleteYn &&
        matchConfigStatus.profileRequiredCompleteYn &&
        !matchConfigStatus.matchingConfigYn)
    ) {
      setModalState('MATCH_GUIDE');
    }
  }, [matchConfigStatus, pathname]);

  if (!matchConfigStatus || !profilesTypeData || !isVisiblePath) return null;

  return <MatchingGuideModal visible={modalState === 'MATCH_GUIDE'} onClose={onClose} />;
};

export default MatchingProposalModal;
