import { useRouter } from 'next/router';
import Text from '@components/common/text';
import Modal from '@components/common/modal';
import {
  useConditionsLastModified,
  useConditionsLastModifiedSkip,
} from '@repository/match/conditions/useMatchConditions';
import { useLog } from '@repository/log/useLog';
import PreferDemandModal from './PreferDemandModal';
import PreferenceModal from './PreferenceModal';
import { FontStyle } from '@domain/constant/fontStyle';
import useModals from '@utils/hooks/useModals';
import DateUtil from '@utils/date/util';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const skipBtnLogId = 'btn_skip_conditional_modal';
const nextBtnLogId = 'btn_edit_conditional_modal';

const UpdatePreferModal = ({ visible }: { visible: boolean }) => {
  const { pathname } = useRouter();
  const { data: modifiedData, refetch } = useConditionsLastModified(false);
  const { mutateAsync: updateLastModified } = useConditionsLastModifiedSkip();
  const { mutateAsync: saveLog } = useLog();
  const { openModal, closeModal, isVisible, modalState } = useModals(
    ['PREFER_DEMAND', 'PREFER'],
    visible ? 'PREFER_DEMAND' : undefined,
  );

  const handleSkip = async () => {
    await updateLastModified();
    await saveLog({
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: skipBtnLogId,
    });
    refetch();
    closeModal();
  };

  const handleNext = async () => {
    await saveLog({
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: nextBtnLogId,
    });
    openModal('PREFER');
  };

  if (!modifiedData) return null;

  return (
    <Modal visible={!!modalState}>
      <PreferDemandModal
        visible={isVisible('PREFER_DEMAND')}
        onNext={handleNext}
        onCancle={handleSkip}
        title={
          <Text fontStyle={FontStyle.H4_B}>
            <>
              선호 근무 조건을
              <br />
              업데이트한 지{' '}
              <span className={cx('emphasis')}>
                {DateFormat.formatDateDiff(new Date(modifiedData?.lastModifiedDateTime), DateUtil.getNow(), 'dd')}
              </span>
              일 지났어요!
            </>
          </Text>
        }
        subtitle="이 선호 정보로 인사담당자에게 제안 받으시겠어요?"
        closeBtnText="이대로 제안 받기"
        confirmBtnText="업데이트 하기"
      />
      <PreferenceModal visible={isVisible('PREFER')} onClose={closeModal} />
    </Modal>
  );
};

export default UpdatePreferModal;
