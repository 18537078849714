import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import Text from '@components/common/text';
import Checkbox from '@components/common/checkbox';
import Icon from '@components/common/assets';
import { useInsightResumeList, useInsightResumeLog } from '@repository/insight/useInsight';
import ProgressBar from '@components/progressBar';
import { InsightResumeListDto } from '@domain/rs/insight/InsightResumeListGetRs';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface ItemProps {
  data: InsightResumeListDto;
  isSelected: boolean;
  selectItem: () => void;
}

const Item: FC<ItemProps> = ({ data, isSelected, selectItem }) => {
  return (
    <button className={cx('resumeWrap', { isSelected })} onClick={selectItem}>
      <div className={cx('textArea')}>
        <Text className={cx('positionName')} fontStyle={FontStyle.B1_B}>
          {data.positionName}
        </Text>
        <Text className={cx('dateArea')} fontStyle={FontStyle.B3_M} color={Colors.C_WARM_GRAY_70}>
          제출일 : {DateFormat.format(new Date(data.submitDateTime), 'yyyy.MM.dd')}{' '}
          <div className={cx('divider')}>|</div> {data.companyName}
        </Text>
      </div>

      <Checkbox className={cx('checkbox')} checked={isSelected} width={24} height={24} />
    </button>
  );
};

const ComponentLogId = 'modal_list_insight_resume';

interface Step2Props {
  onClose: () => void;
  nextStep: () => void;
  selected: number | null;
  setSelected: (sn: number | null) => void;
}

const Step2: FC<Step2Props> = ({ onClose, nextStep, selected, setSelected }) => {
  const { data } = useInsightResumeList();
  const selectItem = (sn: number) => setSelected(sn);

  const { pathname } = useRouter();
  const { mutateAsync: fetchLog } = useInsightResumeLog();

  useEffect(() => {
    fetchLog({
      action: 'VIEW',
      actionSource: pathname,
      actionComponent: ComponentLogId,
    });
  }, []);

  if (!data) return null;

  if (!data?.resumes?.length)
    return (
      <>
        <ProgressBar className={cx('progressBar')} startProgress={25} progress={100} />
        <div className={cx('stepWrap')}>
          <div className={cx('scrollArea')}>
            <div className={cx('scrollInner')}>
              <div className={cx('emptyArea')}>
                <Icon name="joyEmpty2" width={104} height={104} />
                <Text fontStyle={FontStyle.H5_B}>저장된 지원서가 없어요.</Text>
              </div>
            </div>
          </div>

          <div className={cx('btnArea', 'end')}>
            <button className={cx('confirm')} onClick={onClose}>
              완료
            </button>
          </div>
        </div>
      </>
    );

  return (
    <div className={cx('stepWrap')}>
      <div>
        <div className={cx('titleArea')}>지원서를 선택해 주세요.</div>

        <div className={cx('scrollArea')}>
          <div className={cx('scrollInner')}>
            <div className={cx('noticeArea')}>
              <Text className={cx('noticeAreaText')} fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_70}>
                <Icon name="checkLight" width={24} height={24} />
                지원서마다 불러올 수 있는 정보가 다를 수 있어요.
              </Text>
              <Text className={cx('noticeAreaText')} fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_70}>
                <Icon name="checkLight" width={24} height={24} />
                불러오기 서비스에 대해 동의한 지원서만 불러올 수 있어요.
              </Text>
            </div>

            <div className={cx('contents')}>
              {data.resumes.map((item) => (
                <Item
                  key={item.sn}
                  data={item}
                  isSelected={selected === item.sn}
                  selectItem={() => selectItem(item.sn)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={cx('btnArea', 'end')}>
        <button className={cx('confirm')} onClick={nextStep} disabled={!selected}>
          선택 완료
        </button>
      </div>
    </div>
  );
};

export default Step2;
