import React from 'react';
import { useController } from 'react-hook-form';
import { employmentStatusOptions } from '@domain/constant/profile/CareerType';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const EmploymentStatus = ({ prefixName }: HookFormProps) => {
  const { field, fieldState } = useController({ name: `${prefixName}.employmentStatus` });

  const radioEls = employmentStatusOptions.map(({ label, value }, index) => {
    const handleChange = () => {
      field.onChange(value);
    };

    return (
      <label className={cx('label')} key={index}>
        <input
          {...field}
          name="companyType"
          type="radio"
          className={cx('input', 'hidden')}
          value={value}
          onChange={handleChange}
          checked={field.value === value}
        />
        <span className={cx('mark')} />
        {label}
      </label>
    );
  });

  return (
    <>
      <div className={cx('companyTypeArea', { error: fieldState.invalid })}>
        <div className={cx('companyTypeInner')}>{radioEls}</div>
      </div>
      {fieldState.invalid && <div className={cx('errorMessage')}>상태를 선택해 주세요.</div>}
    </>
  );
};

export default EmploymentStatus;
