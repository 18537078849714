import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { RemoteMatchApplicantsRepo } from './MatchApplicantsRepo';
import AccountLinkRq, { IAccountLinkRq } from '@domain/rq/accountLink/AccountLinkRq';

const repo = new RemoteMatchApplicantsRepo();

// ATS 수동 인재 계정 연동
const fetchApplicantsAccountLink = async (formData: IAccountLinkRq) => {
  const rq = new AccountLinkRq(formData);
  await repo.fetchApplicantsAccountLink(rq);
};

function useApplicantsAccountLink(onSuccess: () => void, onError: (err: AxiosError) => void) {
  return useMutation((rq: IAccountLinkRq) => fetchApplicantsAccountLink(rq), {
    onError,
    onSuccess,
  });
}

export { useApplicantsAccountLink };
