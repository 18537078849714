import React from 'react';
import classnames from 'classnames/bind';
import styles from './closeButton.module.scss';

const cx = classnames.bind(styles);

export interface CloseButtonProps {
  onClick?: React.MouseEventHandler;
  className?: string;
}

const CloseButton = (props: CloseButtonProps) => {
  const { onClick, className } = props;
  return (
    <button className={cx('btnClose', className)} onClick={onClick}>
      <span className={cx('hidden')}>닫기</span>
    </button>
  );
};

export default CloseButton;
