import { useRef } from 'react';
import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { ProfileUploadFileVO } from '@domain/vo/common/FileVO';
import { RemoteProfilesRepo } from '@repository/profiles/ProfilesRepo';
import ProfileBlindCompanyVO from '@domain/vo/profile/ProfileBlindCompanyVO';
import ProfileDetailVO from '@domain/vo/profile/ProfileDetailVO';

const FAILD_FILE_UID = 0;
const keys = ['profile'];
const remoteProfilesRepo = new RemoteProfilesRepo();

const fetchProfilesType = async () => {
  const { data } = await remoteProfilesRepo.fetchProfilesType();
  return data;
};

const fetchMatchProfilesComplete = async () => {
  try {
    const { data } = await remoteProfilesRepo.fetchMatchProfilesComplete();
    return data;
  } catch (e) {
    console.error(e);
  }
};

const fetchSaveBlindCompanies = async (snList: number[]) => {
  const { data } = await remoteProfilesRepo.fetchSaveBlindCompanies(snList);
  return data;
};

const fetchBlindCompanies = async () => {
  const { data } = await remoteProfilesRepo.fetchBlindCompanies();
  return new ProfileBlindCompanyVO(data);
};

const fetchProfilesFileImage = async (profileImageUid: string | null) => {
  const { data } = await remoteProfilesRepo.fetchProfilesFileImage(profileImageUid);
  return data;
};

export function useProfilesType(enabled: boolean) {
  return useQuery([...keys, 'type'], fetchProfilesType, {
    enabled,
  });
}

export function useBlindCompanies(enabled: boolean) {
  return useQuery([...keys, 'blind'], fetchBlindCompanies, {
    enabled,
  });
}

export function useMatchProfilesComplete(enabled = true) {
  return useQuery([...keys, 'complete'], fetchMatchProfilesComplete, {
    enabled,
    refetchOnWindowFocus: true,
  });
}

export function useSaveBlindCompanies(onSuccess?: () => void, onError?: (err: AxiosError) => void) {
  return useMutation((snList: number[]) => fetchSaveBlindCompanies(snList), {
    onError,
    onSuccess,
  });
}

export function useProfilesFileImage(onSuccess?: () => void, onError?: (err: AxiosError) => void) {
  return useMutation((profileImageUid: string | null) => fetchProfilesFileImage(profileImageUid), {
    onError,
    onSuccess,
  });
}

const fetchProfileDetail = async () => {
  const { data } = await remoteProfilesRepo.fetchProfileDetail();
  return new ProfileDetailVO(data);
};

export function useProfileDetail(isLogin = false) {
  return useQuery([...keys, 'details'], fetchProfileDetail, {
    enabled: isLogin,
    refetchOnWindowFocus: true,
  });
}

const fetchProfileDetailNoVO = async () => {
  const { data } = await remoteProfilesRepo.fetchProfileDetail();
  return data;
};

export function useProfileDetailNoVO(isLogin = false) {
  return useQuery([...keys, 'details', 'no'], fetchProfileDetailNoVO, {
    enabled: isLogin,
  });
}

const fetchUploadProfileFile = async (file: File) => {
  const { data } = await remoteProfilesRepo.uploadProfileFile(file);
  return new ProfileUploadFileVO(data, 'done');
};

export function useUploadProfileFile(options?: UseQueryOptions) {
  const fileRef = useRef<File | null>(null);
  return useMutation(
    (file: File) => {
      fileRef.current = file;
      return fetchUploadProfileFile(file);
    },
    {
      ...(options as any),
      onError: (e) => {
        options?.onError?.(e);
        const temp = { fileName: fileRef?.current?.name, fileUid: `${FAILD_FILE_UID + 1}` } as ProfileUploadFileVO;
        return new ProfileUploadFileVO(temp, 'error');
      },
    },
  );
}

const fetchProfileAuditConfirmAll = async () => {
  const { data } = await remoteProfilesRepo.fetchProfileAuditConfirmAll();
  return data;
};

export const useProfileAuditConfirmAll = (onSuccess?: () => void, onError?: (err: AxiosError) => void) => {
  return useMutation(fetchProfileAuditConfirmAll, {
    onSuccess,
    onError,
  });
};
