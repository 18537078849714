import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import SchoolLevel from './items/SchoolLevel';
import EduCationType from './items/EduCationType';
import SchoolName from './items/SchoolName';
import EndDate from './items/EndDate';
import StartDate from './items/StartDate';
import Major from './items/Major';
import Minor from './items/Minor';
import Double from './items/Double';
import classnames from 'classnames/bind';
import styles from '../commonEdit.module.scss';
const cx = classnames.bind(styles);

const Education: FC<HookFormProps> = ({ prefixName }) => {
  const { watch } = useFormContext();
  const isHighSchool = watch(`${prefixName}.schoolLevel`).value === 'HIGHSCHOOL';

  return (
    <div>
      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>학교 구분</span>
        {/* 학교 구분 */}
        <SchoolLevel prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>학교명</span>
        {/* 학교 명, 학교 코드 */}
        <SchoolName prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>상태</span>
        {/* 상태 */}
        <EduCationType prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>재학 기간</span>
        {/* 재학 기간 */}
        <div className={cx('periodArea')}>
          <StartDate prefixName={prefixName} />
          <mark>~</mark>
          <EndDate prefixName={prefixName} />
        </div>
      </div>

      {!isHighSchool && (
        <div className={cx('writeWrap')}>
          <span className={cx('writeTitle', 'required')}>학과·전공</span>
          {/* 학과·전공 */}
          <Major prefixName={prefixName} />
          <Minor prefixName={prefixName} />
          <Double prefixName={prefixName} />
        </div>
      )}
    </div>
  );
};

export default Education;
