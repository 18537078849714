import React, { ChangeEvent, FC } from 'react';
import { useController } from 'react-hook-form';
import Select from 'react-select';
import NoSsr from '@common/noSsr';
import { ReactSelectStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import { educationTypeOptions } from '@domain/constant/profile/EducationType';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const EduCationType: FC<HookFormProps> = ({ prefixName }) => {
  const { field, fieldState } = useController({ name: `${prefixName}.educationType` });

  //편입 여부
  const { field: transferYnField } = useController({
    name: `${prefixName}.transferYn`,
  });

  const onChangeTransferYn = (e: ChangeEvent<HTMLInputElement>) => {
    transferYnField.onChange(e.target.checked);
  };

  return (
    <div className={cx('eduTypeArea', { error: fieldState.invalid })}>
      <div className={cx('eduTypeInner')}>
        <NoSsr>
          <Select
            {...field}
            className={cx('select', { error: fieldState.invalid })}
            placeholder="재학상태"
            styles={ReactSelectStyles}
            isSearchable={false}
            options={educationTypeOptions}
            onChange={(option) => field.onChange(option)}
          />
        </NoSsr>
        {fieldState.invalid && <div className={cx('errorMessage')}>상태를 선택해 주세요.</div>}
      </div>
      <div className={cx('eduTypeInner')}>
        <label className={cx('label')}>
          <input
            type="checkbox"
            className={cx('input', 'hidden')}
            onChange={onChangeTransferYn}
            checked={transferYnField.value ?? false}
          />
          <span className={cx('checkbox')} />
          편입
        </label>
      </div>
    </div>
  );
};

export default EduCationType;
