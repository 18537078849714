import Tooltip from '@components/common/tooltip';
import { useUserInfo } from '@repository/auth/useUserInfo';
import useProfileConditions from '@repository/profiles/useProfileConditions';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const PreferInfo = () => {
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: conditionsData } = useProfileConditions(isLogin);

  return (
    <article className={cx('preferInfoWrap')}>
      <div className={cx('preferInfoArea')}>
        <h2 className={cx('preferInfoTitle')}>직군·직무</h2>
        <CustomTooltip
          values={conditionsData?.allJobTitleNames ?? []}
          length={conditionsData?.allJobTitleNames?.length ?? 0}
        />
      </div>
      <div className={cx('preferInfoArea')}>
        <h2 className={cx('preferInfoTitle')}>근무지</h2>
        <CustomTooltip
          values={conditionsData?.locationTexts ?? []}
          length={conditionsData?.locationTexts?.length ?? 0}
        />
      </div>
      <div className={cx('preferInfoArea')}>
        <h2 className={cx('preferInfoTitle')}>연봉</h2>
        <p className={cx('preferInfoContent')}>{conditionsData?.salaryText}</p>
      </div>
      <div className={cx('preferInfoArea')}>
        <h2 className={cx('preferInfoTitle')}>복지</h2>
        <CustomTooltip
          values={conditionsData?.allWelfareNames ?? []}
          length={conditionsData?.allWelfareNames?.length ?? 0}
        />
      </div>
      <div className={cx('preferInfoArea')}>
        <h2 className={cx('preferInfoTitle')}>희망사항</h2>
        <p className={cx('preferInfoContent', 'workingPreference')}>{conditionsData?.workingPreference ?? '-'}</p>
      </div>
    </article>
  );
};

export default PreferInfo;

interface CustomTooltipProps {
  values: string[];
  length: number;
}

const CustomTooltip = ({ values, length }: CustomTooltipProps) => {
  return (
    <Tooltip
      label={
        length === 0 ? (
          '-'
        ) : (
          <p>
            {values?.slice(0, 3).join(', ')}
            <span className={cx('plusContent', { hidden: length <= 3 })}> +{length - 3}</span>
          </p>
        )
      }
      content={<p className={cx('tooltipContent')}>{values?.join(', ') ?? ''}</p>}
      className={cx('preferInfoContent', 'widthFull')}
      placement="bottom-center"
      width="100%"
      theme="gray"
      condition={length > 3}
      duration={200}
    />
  );
};
