import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import InsightResumeSaveRq from '@domain/rq/insight/InsightResumeSaveRq';
import ResumeLogRq from '@domain/rq/insight/ResumeLogRq';
import AccessTokenRs from '@domain/rs/insight/AccessTokenRs';
import { InsightResumeListGetRs } from '@domain/rs/insight/InsightResumeListGetRs';
import { InsightResumeGetRs } from '@domain/rs/insight/InsightResumeGetRs';
import InsightResumeAutoSavedGetRs from '@domain/rs/insight/InsightResumeAutoSavedGetRs';

export default interface InsightRepo {
  // AccessToken 발급
  fetchInsightAccessToken(): Promise<AxiosResponse<AccessTokenRs>>;

  // 지원서 목록 조회
  fetchInsightResumeList(): Promise<AxiosResponse<InsightResumeListGetRs>>;

  // 지원서 상세 조회
  fetchInsightResume(sn: number): Promise<AxiosResponse<InsightResumeGetRs>>;

  // 지원서 저장
  saveInsightResume(rq: InsightResumeSaveRq): Promise<AxiosResponse<boolean>>;

  //미열람 지원서 존재 여부 확인
  fetchInsightUnreadResume(): Promise<AxiosResponse<boolean>>;

  //지원서 로그 저장
  fetchInsightResumeLog(rq: ResumeLogRq): Promise<AxiosResponse<boolean>>;

  //지원서 자동 저장 여부 확인
  fetchInsightAutoSaved(): Promise<AxiosResponse<InsightResumeAutoSavedGetRs>>;
}

export class RemoteInsightRepo implements InsightRepo {
  fetchInsightAccessToken() {
    return axios.get<AccessTokenRs>(`/insight/access-token`, { withCredentials: true });
  }

  fetchInsightResumeList() {
    return axios.get<InsightResumeListGetRs>(`/insight/resume/list`);
  }

  fetchInsightResume(sn: number) {
    return axios.get<InsightResumeGetRs>(`/insight/resume/${sn}`);
  }

  saveInsightResume(rq: InsightResumeSaveRq) {
    return axios.post<boolean>(`/insight/resume/${rq.resumeSn}/convert-profile`, rq.resumeData);
  }

  fetchInsightUnreadResume() {
    return axios.get<boolean>(`/insight/resume/unread`);
  }

  fetchInsightResumeLog(rq: ResumeLogRq) {
    return axios.post<boolean>(`/insight/resume/log`, rq);
  }

  fetchInsightAutoSaved() {
    return axios.get<InsightResumeAutoSavedGetRs>(`/insight/resume/auto-saved`);
  }
}
