import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Icon from '@common/assets';
import Checkbox from '@common/checkbox';
import CheckboxList from '@common/checkboxList';
import Chip from '@common/checkboxList/chip';
import { useJobGroupsWithTitles } from '@repository/codes/useCodes';
import Colors from '@domain/constant/colors';
import useClickAway from '@utils/hooks/useClickOutside';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export interface JobGroupJobTitle {
  jobGroupCode: number;
  jobGroupName: string;
  jobTitleCode: number;
  jobTitleName: string;
}

interface JobTitleCheckboxListProps {
  initValue?: JobGroupJobTitle[];
  onChange: (jobGroupJobTitle: JobGroupJobTitle, isChecked: boolean) => void;
}

const JobTitleCheckboxList = ({ initValue, onChange }: JobTitleCheckboxListProps) => {
  const searchMenuRef = useRef<HTMLDivElement>(null);
  const [selectedJobGroupCode, setSelectedJobGroupCode] = useState<number>(1);
  const [jobTitleKeyword, setJobTitleKeyword] = useState('');
  const [latestJobTitleCode, setLatestJobTitleCode] = useState<JobGroupJobTitle[]>([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const { data: jobGroupsWithTitles } = useJobGroupsWithTitles();

  useEffect(() => {
    if (initValue) setLatestJobTitleCode(initValue);
  }, [initValue]);

  //직무 검색 함수
  const onChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.trim()) {
      setIsVisibleModal(false);
      return;
    }
    if (!isVisibleModal) setIsVisibleModal(true);
    setJobTitleKeyword(e.target.value);
  };

  //체크 여부 판단 함수
  const getChecked = (jobTitleCode: number) => latestJobTitleCode.some((item) => item.jobTitleCode === jobTitleCode);

  useClickAway(() => setIsVisibleModal(false), searchMenuRef, 'mousedown');

  const onSelecteJobTitle = (jobGroupJobTitle: JobGroupJobTitle, isChecked: boolean) => {
    if (!isChecked) {
      const filteredList = latestJobTitleCode.filter((el) => el.jobTitleCode !== jobGroupJobTitle.jobTitleCode);
      setLatestJobTitleCode(filteredList);
    } else {
      const tempList = [...latestJobTitleCode, jobGroupJobTitle];
      //정렬 이유 : 직군·직무 버튼 표시시 제일 상단에 있는 직무 값 먼저 표시하기 위함
      tempList.sort((a, b) => a.jobTitleCode - b.jobTitleCode);
      setLatestJobTitleCode(tempList);
    }

    onChange(jobGroupJobTitle, isChecked);
  };

  const onDeleteJobTitle = (jobGroupJobTitle: JobGroupJobTitle) => {
    const filteredList = latestJobTitleCode.filter((el) => el.jobTitleCode !== jobGroupJobTitle.jobTitleCode);
    setLatestJobTitleCode(filteredList);

    onChange(jobGroupJobTitle, false);
  };

  const searchMenuEl = () => {
    const flattedTitles = jobGroupsWithTitles?.flatMap((jobTitles) => {
      const titles = jobTitles.jobTitles
        .filter((item) => {
          return item.name.includes(jobTitleKeyword);
        })
        .map((item) => {
          const labelText = `${jobTitles.name} > ${item.name}`;
          const isChecked = getChecked(item.code);

          return (
            <li className={cx('listItem')} key={item.code}>
              <Checkbox
                type={'square'}
                checked={isChecked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onSelecteJobTitle(
                    {
                      jobGroupCode: jobTitles.code,
                      jobGroupName: jobTitles.name,
                      jobTitleCode: item.code,
                      jobTitleName: item.name,
                    },
                    e.target.checked,
                  )
                }
              >
                <span className={cx('labelText')}>{labelText}</span>
              </Checkbox>
            </li>
          );
        });
      return titles;
    });

    if (!isVisibleModal) return null;
    if (!flattedTitles?.length) return null;

    return (
      <div className={cx('searchMenuArea')} ref={searchMenuRef}>
        <ul className={cx('searchMenuList')}>{flattedTitles}</ul>
      </div>
    );
  };

  const jobGroupList = jobGroupsWithTitles?.map((item) => {
    return {
      isOn: selectedJobGroupCode === item.code,
      name: item.name,
      onClick: () => setSelectedJobGroupCode(item.code),
    };
  });

  const jobTitleList = jobGroupsWithTitles
    ?.find((item) => item.code === selectedJobGroupCode)
    ?.jobTitles.map((item) => {
      const jobGroup = jobGroupsWithTitles.find((el) => el.code === selectedJobGroupCode);
      const isChecked = getChecked(item.code);
      const addItem = {
        jobGroupCode: jobGroup?.code || 0,
        jobGroupName: jobGroup?.name || '',
        jobTitleCode: item.code,
        jobTitleName: item.name,
      };
      return {
        isChecked,
        name: item.name,
        onChange: (checked: boolean) => onSelecteJobTitle(addItem, checked),
      };
    });

  const chipEls = latestJobTitleCode.map((jobGroupJobTitle) => {
    return (
      <Chip
        key={jobGroupJobTitle.jobTitleCode}
        name={jobGroupJobTitle.jobTitleName}
        onClick={() => onDeleteJobTitle(jobGroupJobTitle)}
      />
    );
  });

  return (
    <div className={cx('listArea')}>
      {/* 직무 검색 영역 */}
      <div className={cx('searchArea')}>
        <div className={cx('searchInner')}>
          <Icon name="searchLight" width={24} height={24} fill={Colors.C_COOL_GRAY_70} />
          <input type="text" className={cx('input')} placeholder={'직무를 입력해 주세요.'} onChange={onChangeKeyword} />
        </div>

        {/* [D] 직무 검색 리스트 영역 */}
        {searchMenuEl()}
      </div>

      <CheckboxList list={jobGroupList} checkboxList={jobTitleList} />
      <div className={cx('chipArea')}>{chipEls}</div>
    </div>
  );
};

export default JobTitleCheckboxList;
