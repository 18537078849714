import { useFormContext } from 'react-hook-form';
import LabelInput from '@components/labelInput';
import { useVerificationSmsCheck } from '@repository/verification/useMobile';
import styles from '@components/loginModal/find/find.module.scss';
import { MobileCertStateType } from '@domain/constant/MobileCertStateType';
import { onlyNumber, valueRequired } from '@utils/validators/validators';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface TokenProps {
  timerFormat: string;
  timerStop: () => void;
  mobileCertState: MobileCertStateType | null;
  setMobileCertState: (mobileCertState: MobileCertStateType | null) => void;
  setIsSuccessMobileAuth: (isSuccessMobileAuth: boolean) => void;
}

const Token = ({ timerFormat, timerStop, mobileCertState, setMobileCertState, setIsSuccessMobileAuth }: TokenProps) => {
  const {
    watch,
    register,
    setError,
    formState: { errors },
  } = useFormContext();

  const mobile = watch('mobile')?.replace(/[^0-9]/g, '');
  const token = watch('token');
  const countryCode = watch('countryCode');

  // SMS 인증 확인
  const { mutateAsync: verificationSmsCheck } = useVerificationSmsCheck();

  // SMS 인증
  const onClickCheckCert = async () => {
    const { success } = await verificationSmsCheck({ mobile, token, countryCode });
    if (success) {
      setMobileCertState('SUCCESS');
      setIsSuccessMobileAuth(true);
      timerStop();
      return;
    }
    if (!success) {
      setError('token', { message: '인증번호가 일치하지 않습니다.' });
    }
  };

  return (
    <div className={cx('infoWrap')}>
      <LabelInput
        {...register('token', {
          ...valueRequired,
        })}
        type="text"
        className={cx('input', { error: errors.token })}
        placeholder="인증번호"
        onInput={onlyNumber}
        maxLength={6}
        autoComplete="off"
        isvalue={token}
      />
      {errors.token && <div className={cx('infoText', 'type2', 'error')}>{errors.token.message as any}</div>}
      {mobileCertState === 'SUCCESS' && <div className={cx('infoText', 'type2', 'success')}>인증되었습니다.</div>}

      <div className={cx('timerWrap')}>
        <div className={cx('timer')}>{mobileCertState === 'ING' && <span>{timerFormat}</span>}</div>
        <button
          type="button"
          className={cx('tokenBtn')}
          disabled={token?.length !== 6 || mobileCertState === 'SUCCESS' || mobileCertState !== 'ING'}
          onClick={onClickCheckCert}
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default Token;
