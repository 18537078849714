import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      /** 403에러일 경우  api호출 재시도 안 함.*/
      retry: (failureCount, error: any) => {
        switch (error?.response?.status) {
          case 403:
            return failureCount < 0; //403에러일 경우 api호출 재시도 1번.

          default:
            return failureCount < 1;
        }
      },
    },
  },
});
