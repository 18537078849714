import React, { ChangeEvent, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useController, useFormContext } from 'react-hook-form';
import NoSsr from '@common/noSsr';
import { ReactSelectInputStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import useMajor from '@components/profile/profileOverlay/overlays/edu/query/useMajor';
import { LoadOptions } from '@domain/constant/ReactSelectOption';
import { ScoreType } from '@domain/constant/profile/EducationType';
import useDebounce from '@utils/hooks/useDebounce';
import { scoreDecimal, scoreNumber } from '@utils/validators/validators';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const Minor = ({ prefixName }: HookFormProps) => {
  const { mutateAsync: mutateAsyncMajor } = useMajor();
  const [scoreType, setScoreType] = useState<ScoreType>('SCORE');
  const [isChecked, setIsChecked] = useState(false);

  const { clearErrors } = useFormContext();

  const {
    field: { value: scoreTypeField, onChange: changeScoreType },
  } = useController({
    name: `${prefixName}.minor.scoreType`,
  });

  const { field: majorAliasField, fieldState: aliasState } = useController({
    name: `${prefixName}.minor.majorAlias`,
  });

  const { field: scoreField, fieldState: scoreState } = useController({
    name: `${prefixName}.minor.score`,
  });

  const { field: perfectScoreField, fieldState: perfectScoreState } = useController({
    name: `${prefixName}.minor.perfectScore`,
  });

  const {
    field: { value: minorUseYnField, onChange: changeUseYn },
  } = useController({
    name: `${prefixName}.minor.minorUseYn`,
  });

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    changeUseYn(checked);
  };

  const handleScoreType = (scoreType: ScoreType) => {
    perfectScoreField.onChange('');
    setScoreType(scoreType);
    changeScoreType(scoreType);
  };

  const handleChangeScore = (e: ChangeEvent<HTMLInputElement>) => {
    //score,  perfectScore 둘다 삭제된 경우 error 제거하기 위함
    clearErrors(`${prefixName}.minor.perfectScore`);
    scoreField.onChange(e.target.value);
  };

  const handleChangePerfectScore = (e: ChangeEvent<HTMLInputElement>) => {
    //score,  perfectScore 둘다 삭제된 경우 error 제거하기 위함
    clearErrors(`${prefixName}.minor.score`);
    perfectScoreField.onChange(e.target.value);
  };

  const loadOptions = useDebounce((keyword: string, callback: LoadOptions) => {
    mutateAsyncMajor(keyword).then((major) => {
      callback(major);
    });
  }, 100);

  useEffect(() => {
    setIsChecked(minorUseYnField);
  }, [minorUseYnField]);

  useEffect(() => {
    if (!!scoreTypeField) setScoreType(scoreTypeField);
    else setScoreType('SCORE');
  }, [scoreTypeField]);

  return (
    <div className={cx('majorInner')}>
      <div className={cx('majorTitleArea')}>
        <label className={cx('majorLabel')}>
          <input type="checkbox" className={cx('input', 'hidden')} onChange={handleCheck} checked={!!minorUseYnField} />
          <span className={cx('checkbox')} />
          부전공
        </label>
      </div>
      {isChecked && (
        <div className={cx('majorWrap')}>
          <div className={cx('inputArea')}>
            <NoSsr>
              <AsyncSelect
                {...majorAliasField}
                className={cx('select', { error: aliasState.invalid })}
                loadOptions={loadOptions}
                placeholder={'학과 · 전공을 검색해주세요.'}
                openMenuOnClick={false}
                styles={ReactSelectInputStyles}
                isClearable={true}
              />
            </NoSsr>
            {aliasState.invalid && <div className={cx('errorMessage')}>학과 · 전공을 검색해주세요.</div>}
          </div>

          <div className={cx('scoreArea')}>
            <div className={cx('scoreWrap')}>
              <a
                className={cx('btnScore', { on: scoreType === 'SCORE' })}
                role="button"
                onClick={() => handleScoreType('SCORE')}
              >
                학점
              </a>
              <a
                className={cx('btnScore', { on: scoreType === 'PERCENTAGE' })}
                role="button"
                onClick={() => handleScoreType('PERCENTAGE')}
              >
                백분위
              </a>
            </div>

            {scoreType === 'SCORE' ? (
              // 학점 input
              <div className={cx('scoreWrap')}>
                <div className={cx('scoreInputWrap')}>
                  <input
                    {...scoreField}
                    type="number"
                    step={0.01}
                    className={cx('scoreInput', { error: scoreState.invalid })}
                    placeholder="평점"
                    onInput={scoreDecimal}
                    onChange={handleChangeScore}
                  />
                  {scoreState.invalid && <div className={cx('errorMessage')}>{scoreState.error?.message}</div>}
                </div>
                <mark>/</mark>
                <div className={cx('scoreInputWrap')}>
                  <input
                    {...perfectScoreField}
                    type="number"
                    step={0.01}
                    className={cx('scoreInput', { error: perfectScoreState.invalid })}
                    placeholder="만점"
                    onInput={scoreDecimal}
                    onChange={handleChangePerfectScore}
                  />
                  {perfectScoreState.invalid && (
                    <div className={cx('errorMessage')}>{perfectScoreState.error?.message}</div>
                  )}
                </div>
              </div>
            ) : (
              // 백분위 input
              <div className={cx('scoreWrap')}>
                <div className={cx('scoreInputWrap')}>
                  <input
                    {...scoreField}
                    type="number"
                    className={cx('scoreInput', { error: scoreState.invalid })}
                    placeholder="백분위"
                    onInput={scoreNumber}
                  />
                  {scoreState.invalid && <div className={cx('errorMessage')}>{scoreState.error?.message}</div>}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Minor;
