import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import { useRouter } from 'next/router';
import Lottie from 'react-lottie';
import Modal from '@common/modal';
import Button from '@components/common/button';
import folder from '@graphic/folder.json';
import { useUserInfo } from '@repository/auth/useUserInfo';
import PreferInfo from './PreferInfo';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: folder,
};

interface MatchingOfferProps {
  visible: boolean;
  onClose: () => void;
  openPreferModal: () => void;
}

const MatchingOfferModal = ({ visible, onClose, openPreferModal }: MatchingOfferProps) => {
  const router = useRouter();
  const { data: userInfo } = useUserInfo();

  const fireConfetti = () => {
    const count = 200;
    const defaults = {
      origin: { y: 0.8 },
    };

    function fire(particleRatio: number, opts: confetti.Options) {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    }

    fire(0.25, {
      spread: 100,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 110,
    });
    fire(0.35, {
      spread: 120,
      startVelocity: 75,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 130,
      startVelocity: 55,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 140,
      startVelocity: 75,
    });
  };

  const removeQueryParameter = (url: string, paramName: string) => {
    const parsedUrl = new URL(url);
    parsedUrl.searchParams.delete(paramName);
    return parsedUrl.toString();
  };

  // 매칭 신청 감사 모달 한번만 보여주기 위해서, 다음에 보기 버튼 클릭 시 쿼리 파라미터 제거
  const handleClose = () => {
    const currentUrl = window.location.href;
    const updatedUrl = removeQueryParameter(currentUrl, 'matchConfigStatus');
    if (currentUrl !== updatedUrl) router.replace(updatedUrl);
    onClose();
  };

  useEffect(() => {
    visible && fireConfetti();
  }, [visible]);

  return (
    <Modal visible={visible} dimmed={true}>
      <section className={cx('modalArea')}>
        <header className={cx('titleArea')}>
          <div className={cx('lottieFolder')}>
            <Lottie options={lottieOptions} />
          </div>

          <h1 className={cx('title')}>
            잡다 매칭을
            <br />
            신청해주셔서 감사해요.
          </h1>
          <p className={cx('desc')}>
            이제부터 <em className={cx('emphasis')}>{userInfo?.name ?? '유저'}님이 선호하는 제안</em>을 보내드릴게요.
            <br />
            입력한 선호 정보를 확인해 보세요.
          </p>
        </header>

        <PreferInfo />

        <div className={cx('btnArea')}>
          <Button className={cx('btn')} onClick={openPreferModal} theme="secondary">
            선호 정보 수정
          </Button>
          <Button className={cx('btn')} onClick={handleClose}>
            확인
          </Button>
        </div>
      </section>
    </Modal>
  );
};

export default MatchingOfferModal;
