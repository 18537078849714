import React from 'react';
import Performance from './items/Performance';
import CompanyName from './items/CompanyName';
import EmploymentStatus from './items/EmploymentStatus';
import StartDate from './items/StartDate';
import EndDate from './items/EndDate';
import JobGroup from './items/JobGroup';
import JobTitle from './items/JobTitle';
import EmploymentType from './items/EmploymentType';
import Role from './items/Role';
import classnames from 'classnames/bind';
import styles from '../commonEdit.module.scss';

const cx = classnames.bind(styles);

const Career = ({ prefixName }: HookFormProps) => {
  return (
    <>
      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>직장명</span>
        <CompanyName prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>상태</span>
        {/* 상태 */}
        <EmploymentStatus prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>근무 기간</span>
        {/* 근무 기간 */}
        <div className={cx('periodArea')}>
          <StartDate prefixName={prefixName} />
          <mark>~</mark>
          <EndDate prefixName={prefixName} />
        </div>
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>직군</span>
        {/* 직군 */}
        <JobGroup prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>직무</span>
        {/* 직무 */}
        <JobTitle prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>계약 형태</span>
        {/* 계약 형태 */}
        <EmploymentType prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle')}>역할</span>
        {/* 역할 */}
        <Role prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle')}>주요 성과</span>
        {/* 주요 성과 */}
        <Performance prefixName={prefixName} />
      </div>
    </>
  );
};

export default Career;
