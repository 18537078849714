import { LocationCode, LocationShort, LocationType, Location } from '@domain/constant/locationType';

type ShowLevel =
  | '1T' // 조
  | '100M' //억
  | '10K' //만
  | 'ALL';

export default class NumberFormat {
  static formatPad(num: number): string {
    return num.toString().padStart(2, '0');
  }

  // 1000의 자리 마다 쉼표 찍는 함수
  static formatDecimal(target: number | string): string {
    const stringified = typeof target === 'string' ? target : target.toString();
    return stringified.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  static formatSalaryText(minSalary: number | null, maxSalary: number | null) {
    const minVal = minSalary?.toLocaleString() ?? '';
    const maxVal = maxSalary?.toLocaleString() ?? '';
    const noSalary = [0, null];
    if (noSalary.includes(minSalary) && noSalary.includes(maxSalary)) return '';
    else if (noSalary.includes(minSalary)) return `연봉 ${maxVal}만원`;
    else if (noSalary.includes(maxSalary)) return `연봉 ${minVal}만원`;

    return `연봉 ${minVal}만원 ~ ${maxVal}만원`;
  }

  static formatPositionSalaryText(minSalary: number | null, maxSalary: number | null) {
    const minVal = minSalary?.toLocaleString();
    const maxVal = maxSalary?.toLocaleString();
    const noSalary = [0, null];
    if (noSalary.includes(minSalary) && noSalary.includes(maxSalary)) return '';
    else if (noSalary.includes(minSalary)) return `#연봉 ${maxVal}만원`;
    else if (noSalary.includes(maxSalary)) return `#연봉 ${minVal}만원`;

    return `#연봉 ${minVal}만원-${maxVal}만원`;
  }

  static formatCompanyRevenueAndProfitText(revenue: number | null, unit = 10000) {
    if (revenue === null || typeof revenue !== 'number') return '-';
    const parserRevenue = Math.trunc(Math.abs(revenue) / unit) * unit; //unit 만큼 0으로 채움
    if (parserRevenue === 0) return '-';
    const formattedRevenue = NumberFormat.formatDecimal(NumberFormat.format(parserRevenue, 'ALL', ' ')) + '원';

    return revenue < 0 ? '-' + formattedRevenue : formattedRevenue;
  }

  static formatPositionCountInCompany(count: number) {
    if (!count) return '0';
    else if (count > 99) return '99+';
    return `${count}`;
  }

  static formatLocationName(locationCode: number) {
    const indexOfS = Object.values(LocationCode).indexOf(locationCode);
    const key = Object.keys(LocationCode)[indexOfS];
    return LocationShort[key as LocationType];
  }

  static formatLocationFullName(locationCode: number) {
    const indexOfS = Object.values(LocationCode).indexOf(locationCode);
    const key = Object.keys(LocationCode)[indexOfS];
    return Location[key as LocationType];
  }

  private static numberWords = ['', '만', '억', '조', '경'];
  private static fotmatSplittedNumberText(
    reversedList: (string | null)[],
    numberWords = NumberFormat.numberWords,
    formatNumber?: (value: number) => string,
  ): (string | null)[] {
    const formatterd = reversedList.map((numText, i) => {
      if (numText === null) return null;

      const modifier = numberWords[i] ?? '';
      const num = parseInt(numText);
      if (num === 0) return null;

      const formattedNum = formatNumber ? formatNumber(num) : num;
      return `${formattedNum}${modifier}`;
    });
    return formatterd.reverse();
  }

  private static numberSeparateRegex = /\d{1,4}(?=(\d{4})*$)/g;
  private static convertShowLevel = (level: ShowLevel) => {
    switch (level) {
      case 'ALL':
        return 0;
      case '10K':
        return 1;
      case '100M':
        return 2;
      case '1T':
        return 3;
      default:
        return 0;
    }
  };

  //TODO:함수 내부에 자리수별로 분리하는 로직과 포매팅하는 로직이 너무 결합되어 있음 개선 필요함
  static format(
    target: number,
    showLevel: ShowLevel = 'ALL',
    separator = '',
    customNumberWords?: string[],
    formatNumber?: (value: number) => string,
  ): string {
    if (typeof target !== 'number' || isNaN(target)) throw 'target이 올바른 형식이 아닙니다';

    if (target === 0) return '0';
    // if (target > Number.MAX_SAFE_INTEGER) throw '계산하는 수가 너무 큽니다';

    const level = NumberFormat.convertShowLevel(showLevel);
    const stringified = target.toString();
    const reversedList = stringified
      .match(NumberFormat.numberSeparateRegex)
      ?.reverse()
      .map((numText, i) => (i >= level ? numText : null));
    if (!reversedList) return '';

    const formatted = NumberFormat.fotmatSplittedNumberText(reversedList, customNumberWords, formatNumber);
    const joined = formatted.filter((numText) => numText !== null).join(separator);

    // 음수값이 들어온 경우 처리
    if (target < 0) {
      return '-' + joined;
    }
    return joined;
  }

  // 오전, 오후 시간 포맷
  static formatAmPmText(hours: number, minutes: string) {
    const isAm = hours <= 12;
    const hour = isAm ? hours : hours - 12;

    return `${isAm ? '오전' : '오후'} ${hour}:${minutes}`;
  }

  // 시간 초를 분:초 포맷으로 변환
  static formatSecondToHHMMSS(second: number) {
    const hour = Math.floor(second / 3600);
    let min = String(Math.floor((second - hour * 3600) / 60));
    let sec = String(second % 60);

    min = min.length <= 1 ? `0${min}` : min;
    sec = sec.length <= 1 ? `0${sec}` : sec;

    return hour ? `${NumberFormat.formatPad(hour)}:${min}:${sec}` : `${min}:${sec}`;
  }

  //숫자 >  y년 m개월 로 리턴
  static formatMonthToYYMMText(month: number) {
    if (month < 1) return '';
    else if (month < 12) return `${month}개월`;
    else if (month >= 12) {
      const yearText = `${Math.floor(month / 12)}년`;
      const monthText = month % 12 ? `${month % 12}개월` : '';

      return monthText ? `${yearText} ${monthText}` : yearText;
    }

    return '';
  }
}
