import { useFormContext } from 'react-hook-form';
import styles from '@components/loginModal/find/find.module.scss';
import LabelInput from '@components/labelInput';
import { birthDateValidator } from '@utils/validators/validators';

import TextFormat from '@utils/text/format';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

export const BirthDate = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={cx('infoWrap')}>
      <LabelInput
        {...register('birthDate', { validate: (value: string) => birthDateValidator(value.replace(/[^0-9]/g, '')) })}
        type="text"
        className={cx('input', { error: errors.birthDate })}
        placeholder="생년월일 8자리 (예시 : 20010508)"
        onInput={(e: Record<string, any>) => setValue('birthDate', TextFormat.birthDate(e.target.value, '.'))}
        maxLength={10}
        autoComplete="off"
      />
    </div>
  );
};
