declare global {
  interface Navigator {
    msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export default class FileUtil {
  static fileOpenToPdf(file: ArrayBuffer) {
    const blob = new Blob([file], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  static fileDownload(filename: string, file: ArrayBuffer) {
    const blob = new Blob([file], { type: 'octet/stream' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //IE11 and the legacy version Edge support
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // other browsers
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      a.hidden = true;
      document.body.appendChild(a);
      a.click();
    }
  }

  static fileUrlDownload = async (filename: string, fileUrl: string) => {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  static checkFileExtArr(file: File, allowableExtArr: string[], withDot = false) {
    const fileName = file.name;
    if (!withDot) {
      const ext = fileName.split('.').pop()?.toLowerCase();
      return allowableExtArr.some((v) => v == ext);
    }

    const ext = fileName.slice(fileName.lastIndexOf('.'));
    return allowableExtArr.includes(ext);
  }

  static checkFileSize(fileSize: number, limitSize: number, limitSizeUnit: 'KB' | 'MB') {
    let positionalNumber = 1;

    switch (limitSizeUnit) {
      case 'KB':
        positionalNumber = 1024;
        break;
      case 'MB':
        positionalNumber = 1024 ** 2;
        break;
    }

    return fileSize / positionalNumber <= limitSize;
  }

  static formatBytes(bytes: number, decimals = 1) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const dm = bytes < 1024 * 1024 ? 0 : decimals;
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
