import UserRs from '@domain/rs/UserRs';
import { genderType } from '@domain/constant/v1/userType';

export default class UserVO {
  id: string;
  name: string;
  authorities: string[];
  age: number;
  email: string;
  gender: genderType;
  profileImageUrl: string;
  isMatchingUser: boolean;
  matchingProposalCount: number;

  constructor(userRs: UserRs) {
    this.id = userRs.id;
    this.name = userRs.name;
    this.authorities = userRs.authorities;
    this.age = userRs.age;
    this.email = userRs.email;
    this.gender = userRs.gender;
    this.profileImageUrl = userRs.profileImageUrl;
    this.isMatchingUser = userRs.isMatchingUser;
    this.matchingProposalCount = userRs.matchingProposalCount;
  }
}
