import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Modal from '@common/modal';
import Text from '@common/text';
import Button from '@components/common/button';
import { LogTypeEnum } from '@/src/logging/LogType';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { AccNeedAdditionalModalExpiresKey } from '..';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import { MILLISECONDS_PER_DAY } from '@domain/constant/Time';
import useLocalStorageExpires from '@utils/hooks/useLocalStorageExpires';
import useModal from '@utils/hooks/useModal';
import { RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const AccNeedAdditionalModal = ({ visible }: { visible: boolean }) => {
  const router = useRouter();
  const { closeModal, isModalOpen, openModal } = useModal(visible);
  const { setExpires } = useLocalStorageExpires();
  const { data: userInfo } = useUserInfo();
  const userName = userInfo?.name ?? '회원';

  const handleExpires = () => {
    setExpires({ key: AccNeedAdditionalModalExpiresKey, expires: MILLISECONDS_PER_DAY * 7 });
    closeModal();
  };

  useEffect(() => {
    if (visible) openModal();
  }, [router]);

  return (
    <Modal visible={isModalOpen} dimmed={true}>
      <div className={cx('modalArea')}>
        <Text className={cx('mainText')} fontStyle={FontStyle.H4_B}>
          {userName}님, {userName.length >= 5 && <br />}
          추가 응시하고 역량검사 결과표 받아가세요!
        </Text>
        <Text className={cx('subText')} fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_80}>
          <>
            진행하던 역량검사에서 조금만 더 응시하면 역량 결과표를 받아 볼 수 있어요.
            <br />
            결과표를 통해 나의 강약점과 역량 유형을 확인하고 면접 준비에 활용해 보세요.
          </>
        </Text>

        <div className={cx('img')} />

        <div className={cx('btnArea')}>
          <Button className={cx('btn')} theme="secondary" onClick={closeModal}>
            취소
          </Button>

          <Button
            className={cx('btn')}
            href={RouteUrl.ACCA_TEST_LIST}
            target="_blank"
            onClick={closeModal}
            id={LogTypeEnum.log_acca_additional_test_popup}
          >
            추가 과제 응시하기
          </Button>
        </div>

        <div className={cx('expiresArea')}>
          <button className={cx('expiresBtn')} onClick={handleExpires}>
            일주일 동안 보지 않기
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AccNeedAdditionalModal;
