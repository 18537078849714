import React from 'react';
import Select from 'react-select';
import { useController, useFormContext } from 'react-hook-form';
import NoSsr from '@common/noSsr';
import { ReactSelectStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import { educationLevelOptions } from '@domain/constant/profile/EducationType';
import { ReactSelectOption } from '@domain/constant/ReactSelectOption';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const SchoolLevel = ({ prefixName }: HookFormProps) => {
  const { setValue } = useFormContext();
  const { field, fieldState } = useController({ name: `${prefixName}.schoolLevel` });

  const handleOnChange = (option: ReactSelectOption) => {
    field.onChange(option);
    setValue(`${prefixName}.schoolName`, null, { shouldValidate: true });
    setValue(`${prefixName}.educationType`, null, { shouldValidate: true });
    setValue(`${prefixName}.startDate`, '', { shouldValidate: true });
    setValue(`${prefixName}.endDate`, '', { shouldValidate: true });
  };

  return (
    <>
      <NoSsr>
        <Select
          {...field}
          onChange={(option: any) => handleOnChange(option)}
          className={cx('select', { error: fieldState.invalid })}
          placeholder={'학교 구분을 선택해 주세요.'}
          styles={ReactSelectStyles}
          isSearchable={false}
          options={educationLevelOptions}
        />
      </NoSsr>
      {fieldState.invalid && <div className={cx('errorMessage')}>학교 구분을 선택해 주세요.</div>}
    </>
  );
};

export default SchoolLevel;
