import {
  BaseProfileEducationDto,
  BaseProfileEducationsDto,
  ProfileMajorDto,
} from '@domain/rs/insight/InsightResumeGetRs';
import { ReactSelectOption } from '@domain/constant/ReactSelectOption';
import {
  EducationLevelNameMap,
  EducationLevelType,
  EducationNameMap,
  EducationStatusType,
  Major,
  MajorType,
  ScoreType,
} from '@domain/constant/profile/EducationType';
import DateFormat from '@utils/date/format';

export default class EducationFormVO {
  educations: BaseProfileEducationsVO[];

  constructor(dto: BaseProfileEducationsDto) {
    this.educations = dto.educations.map((item) => new BaseProfileEducationsVO(item));
  }
}

export class BaseProfileEducationsVO {
  schoolLevel: ReactSelectOption<EducationLevelType> | null;
  educationType: ReactSelectOption<EducationStatusType> | null;
  transferYn: boolean;
  startDate: string;
  endDate: string;
  schoolCode: number;
  schoolName: ReactSelectOption<string> | null;
  major?: ProfileMajorFormVO | null; // 주전공
  minor?: ProfileMajorFormVO | null; // 부전공
  double?: ProfileMajorFormVO | null; // 복수 전공

  constructor(dto: BaseProfileEducationDto) {
    this.schoolLevel = dto.schoolLevel
      ? { value: dto.schoolLevel, label: EducationLevelNameMap[dto.schoolLevel] }
      : null;
    this.educationType = dto.educationType
      ? { value: dto.educationType, label: EducationNameMap[dto.educationType] }
      : null;
    this.transferYn = dto.transferYn;
    this.startDate = DateFormat.getDateFormatString(dto.startDate, 'yyyy-MM') ?? '';
    this.endDate = DateFormat.getDateFormatString(dto.endDate, 'yyyy-MM') ?? '';
    this.schoolCode = dto.schoolCode;
    this.schoolName = { value: dto.schoolName, label: dto.schoolName };
    this.major = this.findMajotrByType(dto.profileMajors, Major.MAJOR);
    this.minor = this.findMajotrByType(dto.profileMajors, Major.MINOR);
    this.double = this.findMajotrByType(dto.profileMajors, Major.DOUBLE);
  }

  private findMajotrByType(dto: ProfileMajorDto[], type: MajorType): ProfileMajorFormVO | null {
    const item = dto?.find((major) => major.majorType === type) ?? null;
    if (item) {
      return new ProfileMajorFormVO(item);
    }

    return null;
  }

  static convertRqFormVO(vo: BaseProfileEducationsVO): BaseProfileEducationDto {
    return {
      schoolLevel: vo.schoolLevel?.value,
      educationType: vo.educationType?.value,
      transferYn: vo.transferYn,
      startDate: vo.startDate,
      endDate: vo.endDate,
      schoolCode: vo.schoolCode,
      schoolName: vo.schoolName?.value,
      profileMajors: [
        vo.major && vo.major.majorAlias?.value
          ? {
              majorType: Major.MAJOR,
              majorCode: vo.major.majorAlias?.value.majorCode,
              majorName: vo.major.majorAlias?.value.name,
              scoreType: vo.major.scoreType,
              score: vo.major.score,
              perfectScore: vo.major.perfectScore,
            }
          : null,
        vo.minor && vo.minor.majorAlias?.value
          ? {
              majorType: Major.MINOR,
              majorCode: vo.minor.majorAlias?.value.majorCode,
              majorName: vo.minor.majorAlias?.value.name,
              scoreType: vo.minor.scoreType,
              score: vo.minor.score,
              perfectScore: vo.minor.perfectScore,
            }
          : null,
        vo.double && vo.double.majorAlias?.value
          ? {
              majorType: Major.DOUBLE,
              majorCode: vo.double.majorAlias?.value.majorCode,
              majorName: vo.double.majorAlias?.value.name,
              scoreType: vo.double.scoreType,
              score: vo.double.score,
              perfectScore: vo.double.perfectScore,
            }
          : null,
      ].filter((item) => item !== null),
    } as BaseProfileEducationDto;
  }
}

export class ProfileMajorFormVO {
  majorAlias: ReactSelectOption<Record<string, number | string>> | null;
  scoreType: ScoreType | null;
  score: number | null;
  perfectScore: number | null;

  constructor(dto: ProfileMajorDto) {
    this.majorAlias = dto.majorName
      ? {
          value: {
            majorCode: dto.majorCode ?? '',
            name: dto.majorName,
          },
          label: dto.majorName,
        }
      : null;
    this.scoreType = dto.scoreType;
    this.score = dto.score;
    this.perfectScore = dto.perfectScore;
  }
}
