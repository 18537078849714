export const GUIDE_TEXT = {
  EMAIL: '* 입사제안, 전형안내 등 중요한 메일 수신에 사용되므로 정확히 입력해주세요.',
  ID: '5-20자, 영문 소문자, 숫자, 특수문자 (-), (_)만 사용',
  PASSWORD: '8-16자, 영문 대·소문자, 숫자, 특수문자 2종류 이상 사용',
};

export const ERROR_TEXT = {
  ID: '아이디를 입력해주세요.',
  PASSWORD: '비밀번호를 입력해주세요.',

  NAME_PATTERN: '잘못된 이름 형식이에요.',
  EMAIL_PATTERN: '잘못된 이메일 형식이에요.',
  BIRTHDATE_PATTERN: '정확한 생년월일을 8자리로 입력해주세요. (예:20010508)',
  BIRTHDATE_LENGTH: '생년월일 8자리로 입력해주세요. (예:20010508)',
  BIRTHDATE_15_PATTERN: '만 15세 미만은 가입할 수 없어요.',
  GENDER_PATTERN: '잘못된 성별 형식이에요.',
  MOBILE_DUPLICATE: '이미 사용중인 휴대폰 번호예요.',
  MOBILE_PATTERN: '잘못된 휴대폰 번호 형식이에요.',
  MOBILE_CERT: '인증번호가 일치하지 않아요.',
  MOBILE_CERT_REQUIRED: '휴대폰 번호 인증을 해주세요.',
  ID_LENGTH: '5자 이상 입력해주세요.',
  ID_DUPLICATE_REQUIRED: '아이디 중복확인을 해주세요.',
  ID_DUPLICATE: '이미 사용중인 아이디에요.',
  ID_PATTERN: '잘못된 아이디 형식이에요.',
  PASSWORD_LENGTH: '8자 이상 입력해주세요.',
  PASSWORD_CHAR: '공백, =, &, <, >, \', ", |, ?, /는 사용할 수 없습니다.',
  PASSWORD_CHAR_KIND: '영문 대·소문자, 숫자, 특수문자를 2종류 이상 사용하세요.',
  PASSWORD_INCLUDES_EMAIL: '이메일은 비밀번호로 사용할 수 없어요.',
  PASSWORD_INCLUDES_ID: '아이디는 비밀번호로 사용할 수 없어요.',
  PASSWORD_INCLUDES_PREV: '이전 비밀번호는 사용할 수 없어요.',
  PASSWORD_CONTINUOUS_KEYBOARD: '3자리 연속 키보드에 근접한 문자는 사용할 수 없어요.',
  PASSWORD_CONTINUOUS_NUMBER: '3자리 이상 연속된 숫자는 사용할 수 없어요.',
  PASSWORD_CONTINUOUS_CHAR: '3자리 이상 연속된 문자는 사용할 수 없어요.',
  PASSWORD_SAME_CHAR: '동일한 문자는 3자리 이상 사용할 수 없어요.',
  RE_PASSWORD_PATTERN: '비밀번호가 일치하지 않아요.',
  TERMS_AGREE: '필수 약관에 동의해주세요.',
};

export const SUCCESS_TEXT = {
  ID: '사용 가능한 아이디에요.',
  PASSWORD: '사용 가능한 비밀번호에요.',
  RE_PASSWORD: '비밀번호가 일치해요.',
  MOBILE_CERT_SEND: '인증번호가 발송되었어요.',
  MOBILE_CERT: '인증이 완료되었어요.',
};
