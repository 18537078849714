import CompanyViewVO from '@domain/vo/company/common/CompanyViewVO';
import CompanyEmployeeRs, {
  CompanyMonthlyEmploymentDto,
  CompanyYearlyEmploymentDto,
} from '@domain/rs/company/sn/CompanyEmployeeRs';
import NumberFormat from '@utils/number/format';
import DateFormat from '@utils/date/format';

export default class CompanyEmployeeVO extends CompanyViewVO {
  numberOfEmployees: number; // 전체 직원 수
  numberOfEmployeesText: string;
  lastYearIncrement: number; // 최근 1년 인원 증감
  lastYearIncrementText: string;
  isIncrement: boolean;
  isDecrement: boolean;
  maleRatio: number; // 남자 비율
  maleRatioText: string;
  femaleRatio: number; // 여자 비율
  femaleRatioText: string;
  averageWorkingYears: number; // 평균 근속 연수
  averageWorkingYearsText: string;
  yearlyEmployments: CompanyYearlyEmploymentVO[]; // 연도별 직원 수
  lastModifiedDateText: string; // 업데이트 날짜

  constructor(rs: CompanyEmployeeRs) {
    super({
      numberOfEmployees: rs.numberOfEmployees,
      lastYearIncrement: rs.lastYearIncrement,
      maleRatio: rs.maleRatio,
      femaleRatio: rs.femaleRatio,
      averageWorkingYears: rs.averageWorkingYears,
      yearlyEmployments: rs.yearlyEmployments,
    });

    this.numberOfEmployees = rs.numberOfEmployees;
    this.numberOfEmployeesText = rs.numberOfEmployees ? NumberFormat.formatDecimal(rs.numberOfEmployees) : '-';
    this.lastYearIncrement = this.getAbsLastYearIncrement(rs.lastYearIncrement);
    this.lastYearIncrementText =
      rs.lastYearIncrement !== null
        ? NumberFormat.formatDecimal(this.getAbsLastYearIncrement(rs.lastYearIncrement))
        : '-';
    this.isIncrement = (rs.lastYearIncrement ?? 0) > 0;
    this.isDecrement = (rs.lastYearIncrement ?? 0) < 0;

    this.maleRatio = rs.maleRatio;
    this.maleRatioText = rs.maleRatio || rs.maleRatio === 0 ? NumberFormat.formatDecimal(rs.maleRatio) : '-';
    this.femaleRatio = rs.femaleRatio;
    this.femaleRatioText = rs.femaleRatio || rs.femaleRatio === 0 ? NumberFormat.formatDecimal(rs.femaleRatio) : '-';
    this.averageWorkingYears = rs.averageWorkingYears;
    this.averageWorkingYearsText = rs.averageWorkingYears ? NumberFormat.formatDecimal(rs.averageWorkingYears) : '-';
    this.yearlyEmployments = rs.yearlyEmployments?.map(
      (dto: CompanyYearlyEmploymentDto) => new CompanyYearlyEmploymentVO(dto),
    );
    this.lastModifiedDateText = this.getDateFormat(rs.lastModifiedDate);
  }

  getDateFormat(data: string | null) {
    return data ? `업데이트 일자: ${DateFormat.format(new Date(data), 'yyyy.MM.dd')}` : '';
  }

  getAbsLastYearIncrement(data: number | null) {
    return data ? Math.abs(data) : 0;
  }
}

export class CompanyYearlyEmploymentVO {
  year: number; // 연도
  totalEmployeeOpenType: boolean; // 전체 직원 수 공개 여부
  newEmployeeOpenType: boolean; // 입사자 수 공개 여부
  resignEmployeeOpenType: boolean; // 퇴사자 수 공개 여부
  monthlyEmployments: CompanyMonthlyEmploymentVO[]; // 월별 직원 수

  constructor(dto: CompanyYearlyEmploymentDto) {
    this.year = dto.year;
    this.totalEmployeeOpenType = dto.totalEmployeeOpenType;
    this.newEmployeeOpenType = dto.newEmployeeOpenType;
    this.resignEmployeeOpenType = dto.resignEmployeeOpenType;
    this.monthlyEmployments = dto.monthlyEmployments?.map((dto) => new CompanyMonthlyEmploymentVO(dto));
  }
}

export class CompanyMonthlyEmploymentVO {
  month: number; // 월
  totalEmployeeNumber: number | null; // 전체 직원 수(해당 연도의 직원수 비공개면 null로 내려갑니다, 입퇴사자도 마찬가지)
  newEmployeeNumber: number | null; // 입사자 수
  resignEmployeeNumber: number | null; // 퇴사자 수

  constructor(rs: CompanyMonthlyEmploymentDto) {
    this.month = rs.month;
    this.totalEmployeeNumber = rs.totalEmployeeNumber;
    this.newEmployeeNumber = rs.newEmployeeNumber;
    this.resignEmployeeNumber = rs.resignEmployeeNumber;
  }
}
