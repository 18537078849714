import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@components/common/checkbox';
import Icon from '@components/common/assets';
import useCountries from '@components/profile/profileOverlay/overlays/experience/query/useCountries';
import styles from '../../../commonStyle.module.scss';
import { CheckListType, EditViewType } from '../..';
import InvalidMessage from '../invalidMessage';
import ExperienceFormVO from '@domain/vo/insight/resume/ExperienceFormVO';
import { Experience as ExperienceEnum } from '@domain/constant/profile/ExperienceType';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface Props {
  checkList?: number[];
  updateCheckList: (type: CheckListType, index: number) => void;
  openEditView: (type: EditViewType, formName: string) => void;
}

const Experience: FC<Props> = ({ checkList, updateCheckList, openEditView }) => {
  const { data: countryNameData } = useCountries();
  const { watch, getFieldState } = useFormContext();
  const data = watch('experience') as ExperienceFormVO;

  const getContryName = (code?: number) => {
    return countryNameData?.find((item) => item.code === code)?.name;
  };

  const listEls = data?.activitiesAndForeignExperiences?.map((item, index) => {
    const isChecked = checkList?.includes(index);
    const invalid = getFieldState(`experience.activitiesAndForeignExperiences.${index}`).invalid;
    const handleCheckBtn = () => {
      if (invalid) return;
      updateCheckList('experience', index);
    };
    const handleEditBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      openEditView(
        isForeignExperience ? 'foreign_experience' : 'experience',
        `experience.activitiesAndForeignExperiences.${index}`,
      );
    };
    const isForeignExperience = item.tag?.value === ExperienceEnum.OVERSEAS;
    const title = isForeignExperience ? getContryName(item.code?.code) : item?.title;
    const startDate = DateFormat.getDateFormatString(item.startDate, 'yyyy.MM');
    const endDate = DateFormat.getDateFormatString(item.endDate, 'yyyy.MM');

    return (
      <button key={index} className={cx('cardWrap', { invalid }, { isChecked })} onClick={handleCheckBtn}>
        <Checkbox
          className={cx('checkbox')}
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
          disabled={invalid}
          width={24}
          height={24}
          margin={0}
        />
        <div className={cx('infoArea')}>
          <div className={cx('textArea')}>
            <span className={cx('listText')}>{title}</span>
            <div className={cx('listDesc')}>
              {invalid ? (
                <InvalidMessage />
              ) : (
                <>
                  <span>{`${startDate} ~ ${endDate}`}</span>
                  <span className={cx('listLabel')}>{item.tag?.label}</span>
                </>
              )}
            </div>
          </div>
          <button className={cx('editBtn', { invalid })} onClick={handleEditBtn}>
            <Icon name="guideLight" width={32} height={32} />
          </button>
        </div>
      </button>
    );
  });

  if (!data?.activitiesAndForeignExperiences?.length) return null;

  return (
    <div className={cx('listWrap')}>
      <div className={cx('listTitleArea')}>
        <Icon name="readBookLight" width={32} height={32} />
        <span className={cx('listTitle')}>경험</span>
      </div>
      <div className={cx('cardList')}>{listEls}</div>
    </div>
  );
};

export default Experience;
