import React from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames/bind';
import styles from './toast.module.scss';
const cx = classnames.bind(styles);

type IconType = 'info' | 'check' | 'bookmark';
type ToastType = 'success' | 'error' | 'result' | null;
type size = 'xlarge' | 'content';

export interface ToastPopupProps {
  size?: size;
  type?: ToastType;
  iconType?: IconType;
  content?: React.ReactNode;
  onClose?: () => void;
  message?: string;
}

interface ToastContextProps {
  showToast: (props: ToastPopupProps) => void;
}

let timeout: ReturnType<typeof setTimeout> | null = null;

const resetTimeout = () => {
  timeout && clearTimeout(timeout);
  timeout = null;
};

const ToastContext = React.createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<Omit<ToastPopupProps, 'onClose'>>({
    size: 'content',
    type: null,
    iconType: 'info',
    content: '',
    message: '',
  });

  const showToast = ({ size, type, iconType, content, onClose, message }: ToastPopupProps) => {
    const handleClose = () => {
      onClose?.();
      resetTimeout();
      setState({});
      setOpen(false);
    };

    if (timeout) {
      handleClose();
    }

    /**
     * 이미 Toast가 있는 경우 기존 Toast를 제거하고 새로운 Toast를 생성해야 하고
     * setState가 한 Context 안에 여러개 있을 때 한 번에 실행되기 때문에(Batch) reqeustAnimationFrame으로 감싸줌
     */
    requestAnimationFrame(() => {
      setOpen(true);
      setState({ size, type, iconType, content, message });
    });

    timeout = setTimeout(() => {
      handleClose();
    }, 3000);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {open &&
        createPortal(
          <div className={cx('toastArea', state.type, state.iconType, state.size, state.message)}>{state.content}</div>,
          document.body,
        )}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error('ToastProvider를 찾을 수 없습니다.');
  }
  return context.showToast;
};
