import { ForwardedRef, forwardRef, InputHTMLAttributes, PropsWithChildren, useEffect, useState } from 'react';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

// TODO 폰트 상수화 필요
const DefaultPlaceholderFontStyle = {
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '14px',
  color: Colors.C_COOL_GRAY_60,
};

interface LabelInputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholderFontStyle?: {
    fontSize: string;
    fontWeight: number;
    lineHeight: string;
    color: string;
  };
  isvalue?: boolean;
}

const LabelInput = forwardRef((props: PropsWithChildren<LabelInputProps>, ref: ForwardedRef<HTMLInputElement>) => {
  const placeholderFontStyle = props.placeholderFontStyle || DefaultPlaceholderFontStyle;

  const [isEmpty, setIsEmpty] = useState<boolean>(true);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(e);
    setIsEmpty(e.target.value === '');
  };

  useEffect(() => {
    if (props.isvalue === undefined) return;
    setIsEmpty(!props.isvalue);
  }, [props.isvalue]);

  useEffect(() => {
    if (props.isvalue === undefined) return;
    setTimeout(() => {
      setIsEmpty(!props.isvalue);
    }, 0);
  }, []);

  const placeholderStyle = !isEmpty ? { ...placeholderFontStyle } : { color: placeholderFontStyle.color };
  const inputPaddingTop = !isEmpty ? placeholderFontStyle.lineHeight : undefined;

  return (
    <div className={`${cx('inputWrap', { empty: isEmpty })} ${props.className}`}>
      <label htmlFor={props.id} className={`${cx('label')}`}>
        <span className={cx('placeholder')} style={placeholderStyle}>
          {props.placeholder}
        </span>
        <input
          ref={ref}
          {...props}
          placeholder={undefined}
          onChange={handleOnChange}
          style={{ paddingTop: inputPaddingTop }}
          className={cx('input')}
        />
      </label>
    </div>
  );
});

LabelInput.displayName = 'LabelInput';
export default LabelInput;
