import usePersistFn, { noop } from './usePersistFn';

function debounce<T extends noop>(fn: T, wait: number, immediate = false) {
  let timer: ReturnType<typeof setTimeout> | null;
  let result: ReturnType<T> | undefined;
  let callNow = immediate;

  const debounced = (...params: Parameters<T>): ReturnType<T> => {
    if (timer) {
      clearTimeout(timer);
    }

    if (callNow) {
      result = fn(...params);
      callNow = false;
    }

    timer = setTimeout(() => {
      if (callNow) {
        result = fn(...params);
      }
      if (!immediate) {
        result = fn(...params);
      }
      callNow = immediate;
      timer = null;
    }, wait);

    return result as ReturnType<T>;
  };

  return debounced;
}

function useDebounce<T extends noop>(
  fn: T,
  wait: number,
  immediate = false,
): (...args: Parameters<T>) => ReturnType<T> {
  const debounced = debounce(fn, wait, immediate);
  const persistFn = usePersistFn(debounced);
  return persistFn;
}

export default useDebounce;
