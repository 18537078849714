import AccApplyRecentRs, { AccApplyItemDto } from '@domain/rs/acca/test/AccApplyRecentRs';
import DateFormat from '@utils/date/format';

export default class AccApplyRecentVO {
  applies: AccApplyItemVO[];

  constructor(rs: AccApplyRecentRs) {
    this.applies = rs.applies.map((apply) => new AccApplyItemVO(apply));
  }
}

export class AccApplyItemVO {
  sn: number;
  code: string;
  lastModifiedDateTime: string;
  title: string;
  isEnd: boolean;
  percentage: string;
  percentageText: string;
  thumbnailUrl: string;

  constructor(dto: AccApplyItemDto) {
    this.sn = dto.sn;
    this.code = dto.code;
    this.lastModifiedDateTime = DateFormat.format(new Date(dto.lastModifiedDateTime), 'yyyy.MM.dd HH:mm');
    this.title = dto.title;
    this.isEnd = dto.completed === dto.total;
    this.percentage = `${+(dto.completed / dto.total).toFixed(1) * 100}%`;
    this.percentageText = this.isEnd ? '응시완료' : `${this.percentage} 진행 (${dto.completed}/${dto.total})`;
    this.thumbnailUrl = dto.thumbnailUrl;
  }
}
