import { RouteUrl } from '@utils/routeUtil/index';

interface IUtmEventQuery {
  incomingUrls: string[];
  startDate?: Date; //이벤트 시작일
  endDate?: Date; //이벤트 종료일
  queryString: {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_content?: string;
  };
}

/**
 * new Date()로 시간을 생성할 때, 시간, 초까지 입력해서 만들어야함.
 */
export const UtmEventQuery: IUtmEventQuery[] = [
  //2023 하반기 디챔스 DCHAMPS_2023
  {
    incomingUrls: [
      RouteUrl.PHS_TEST_GATE,
      RouteUrl.PHS_PST_TEST_GATE,
      RouteUrl.PHS_PACC_TEST_GATE,
      RouteUrl.EVENT_PHS_DCHAMPS,
    ],
    startDate: new Date('2023-09-01'),
    endDate: new Date('2023-11-31'),
    queryString: {
      utm_source: 'jobda',
      utm_medium: 'cta',
      utm_campaign: '2023_dchamps',
    },
  },
  //인재풀 전환 마케팅 페이지
  {
    incomingUrls: [RouteUrl.EVENT_GET_JOB_CHALLENGE],
    startDate: new Date('2023-08-31'),
    endDate: new Date('2023-09-25 23:59:59'),
    queryString: {
      utm_source: 'jobda',
      utm_medium: 'cta',
      utm_campaign: '2023_get_job_challenge',
    },
  },
  //추석 인재풀 전환 마케팅 페이지
  {
    incomingUrls: [RouteUrl.EVENT_HOLIDAY],
    startDate: new Date('2023-09-26 00:00'),
    endDate: new Date('2023-10-11 00:00'),
    queryString: {
      utm_source: 'jobda',
      utm_medium: 'cta_join',
      utm_campaign: 'holiday2309',
    },
  },
];
