import ProfileViewVO from '@domain/vo/profile/common/ProfileViewVO';
import { DataLogRq, SourcePage } from '@domain/rq/DataLog';
import { ProfileSkillRs, SkillDto, LicenseDto, ExaminationDto } from '@domain/rs/profile/ProfileSkillRs';
import { SkillLevelNameMap, SkillLevelType } from '@domain/constant/profile/SkillType';

export default class ProfileSkillVO extends ProfileViewVO {
  licenses: LicenseVO[];
  examinations: ExaminationVO[];
  skills: SkillVO[];
  sourcePage?: SourcePage;

  constructor(rs: ProfileSkillRs & DataLogRq) {
    super(rs, false);

    this.licenses = rs.licenses.map((item) => new LicenseVO(item));
    this.examinations = rs.examinations.map((item) => new ExaminationVO(item));
    this.skills = rs.skills.map((item) => new SkillVO(item));
    this.sourcePage = rs.sourcePage;
  }
}

export class LicenseVO {
  code: number;
  name: string;

  constructor(rs: LicenseDto) {
    this.code = rs.code;
    this.name = rs.name;
  }
}

export class ExaminationVO {
  code: number;
  name: string;
  gradeCode: number | null;
  grade: string | null;
  score: number | null;
  gradeYn: boolean;
  scoreYn: boolean;
  labelText: string;

  constructor(rs: ExaminationDto) {
    this.code = rs.code;
    this.name = rs.name;
    this.gradeCode = rs.gradeCode;
    this.grade = rs.grade;
    this.score = rs.score;
    this.gradeYn = rs.gradeYn;
    this.scoreYn = rs.scoreYn;
    this.labelText = this.getLabelText(rs);
  }

  getLabelText(rs: ExaminationDto) {
    if (rs.gradeYn && rs.grade) {
      return `${rs.name} - ${rs.grade}`;
    } else if (rs.scoreYn && rs.score !== null) {
      return `${rs.name}-${rs.score}`;
    }

    return rs.name;
  }
}

export class SkillVO {
  code: number;
  keyword: string;
  level: SkillLevelType;
  labelText: string;

  constructor(rs: SkillDto) {
    this.code = rs.code;
    this.keyword = rs.keyword;
    this.level = rs.level;
    this.labelText = this.getLabelText(rs);
  }

  getLabelText(rs: SkillDto) {
    const levelText = rs.level ? SkillLevelNameMap[rs.level] : '';
    return levelText ? `${rs.keyword} - ${levelText}` : rs.keyword;
  }
}
