import MatchingConfigConditionRs from '@domain/rs/match/MatchingConfigConditionRs';
import { JobSearchStatusType } from '@domain/constant/match/jobSearchStatus';

export default class MatchingConfigConditionVO {
  matchingConfigYn: boolean;
  generalMatchingConfigAvailableYn: boolean;
  developerMatchingConfigAvailableYn: boolean;
  profileRequiredCompleteYn: boolean;
  accCompleteYn: boolean;
  paccCompleteYn: boolean;
  pstCompleteYn: boolean;
  isPosibleMatching: boolean;
  isNeedTodo: boolean;
  jobSearchStatus: JobSearchStatusType;
  lastProfileUpdateDateTime: null | string;

  constructor(rs: MatchingConfigConditionRs) {
    this.matchingConfigYn = rs.matchingConfigYn;
    this.generalMatchingConfigAvailableYn = rs.generalMatchingConfigAvailableYn;
    this.developerMatchingConfigAvailableYn = rs.developerMatchingConfigAvailableYn;
    this.profileRequiredCompleteYn = rs.matchingConfigCondition.profileRequiredCompleteYn;
    this.accCompleteYn = rs.matchingConfigCondition.accCompleteYn;
    this.paccCompleteYn = rs.matchingConfigCondition.paccCompleteYn;
    this.pstCompleteYn = rs.matchingConfigCondition.pstCompleteYn;
    this.isPosibleMatching = rs.generalMatchingConfigAvailableYn || rs.developerMatchingConfigAvailableYn;
    this.isNeedTodo = !rs.generalMatchingConfigAvailableYn && !rs.developerMatchingConfigAvailableYn;
    this.jobSearchStatus = rs.jobSearchStatus;
    this.lastProfileUpdateDateTime = rs.lastProfileUpdateDateTime;
  }
}
