import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import AccApplyCountRs from '@domain/rs/acca/test/AccApplyCountRs';
import AccApplyRecentRs from '@domain/rs/acca/test/AccApplyRecentRs';
import AccAToZListRs from '@domain/rs/acca/test/AccAToZListRs';
import AccPositingListRs from '@domain/rs/acca/test/AccJobPositingListRs';
import AccRecommendPositionListRs from '@domain/rs/acca/test/AccRecommendPositionListRs';
import AccScreeningPassReviewListRs from '@domain/rs/acca/test/AccScreeningPassReviewListRs';
import { AccaResultType } from '@domain/constant/acca/AccaResultType';

const accCenterPrefix = '/acc/center';

/**잡다 역량검사 센터 */
export default interface AccCenterRepo {
  //역량검사 응시자 수 조회
  fetchAccCenterApplyCount(): Promise<AxiosResponse<AccApplyCountRs>>;

  //최근 응시내역 목록
  fetchAccCenterApplyRecent(): Promise<AxiosResponse<AccApplyRecentRs>>;

  //최근 응시내역 제거
  fetchDeleteAccCenterApplyRecent(sn: number): Promise<AxiosResponse>;

  //역량검사 a to z 목록
  fetchAccCenterAtoz(page: number, size: number): Promise<AxiosResponse<AccAToZListRs>>;

  //역량검사 활용 공고 목록
  fetchAccCenterPostings(page: number, size: number): Promise<AxiosResponse<AccPositingListRs>>;

  //역량검사 결과 맞춤형 포지션 추천
  fetchAccCenterPositionsRecommend(type: AccaResultType): Promise<AxiosResponse<AccRecommendPositionListRs>>;

  //역량검사 합격 후기 목록
  fetchAccCenterScreeningPassReviews(page: number, size: number): Promise<AxiosResponse<AccScreeningPassReviewListRs>>;
}

export class RemoteAccCenterRepo implements AccCenterRepo {
  fetchAccCenterApplyCount() {
    return axios.get(`${accCenterPrefix}/apply-count`);
  }

  fetchAccCenterApplyRecent() {
    return axios.get(`/v2${accCenterPrefix}/apply/recent`);
  }

  fetchDeleteAccCenterApplyRecent(sn: number) {
    return axios.delete(`${accCenterPrefix}/apply/recent/${sn}`);
  }

  fetchAccCenterAtoz(page: number, size: number) {
    return axios.get(`${accCenterPrefix}/atoz`, { params: { page, size } });
  }

  fetchAccCenterPostings(page: number, size: number) {
    return axios.get(`/job-postings/ads/acc`, { params: { page, size } });
  }

  fetchAccCenterPositionsRecommend(type: AccaResultType) {
    return axios.get(`${accCenterPrefix}/positions/recommend`, { params: { type } });
  }

  fetchAccCenterScreeningPassReviews(page: number, size: number) {
    return axios.get(`${accCenterPrefix}/screening-pass-reviews`, { params: { page, size } });
  }
}
