import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@components/common/checkbox';
import Icon from '@components/common/assets';
import styles from '../../../commonStyle.module.scss';
import { CheckListType, EditViewType } from '../..';
import InvalidMessage from '../invalidMessage';
import PrizeFormVO from '@domain/vo/insight/resume/PrizeFormVO';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface Props {
  checkList?: number[];
  updateCheckList: (type: CheckListType, index: number) => void;
  openEditView: (type: EditViewType, formName: string) => void;
}

const Prize: FC<Props> = ({ checkList, updateCheckList, openEditView }) => {
  const { watch, getFieldState } = useFormContext();
  const data = watch('prize') as PrizeFormVO;

  const listEls = data?.prizes.map((prize, index) => {
    const isChecked = checkList?.includes(index);
    const invalid = getFieldState(`prize.prizes.${index}`).invalid;
    const handleCheckBtn = () => {
      if (invalid) return;
      updateCheckList('prize', index);
    };
    const handleEditBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      openEditView('prize', `prize.prizes.${index}`);
    };
    const date = DateFormat.getDateFormatString(prize.date, 'yyyy.MM');

    return (
      <button key={index} className={cx('cardWrap', { invalid }, { isChecked })} onClick={handleCheckBtn}>
        <Checkbox
          className={cx('checkbox')}
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
          disabled={invalid}
          width={24}
          height={24}
          margin={0}
        />

        <div className={cx('infoArea')}>
          <div className={cx('textArea')}>
            <span className={cx('listText')}>{prize.name}</span>
            <div className={cx('listDesc')}>
              {invalid ? (
                <InvalidMessage />
              ) : (
                <>
                  {date}
                  <span className={cx('listLabel')}>{prize.institution}</span>
                </>
              )}
            </div>
          </div>

          <button className={cx('editBtn', { invalid })} onClick={handleEditBtn}>
            <Icon name="guideLight" width={32} height={32} />
          </button>
        </div>
      </button>
    );
  });

  if (!data?.prizes?.length) return null;

  return (
    <div className={cx('listWrap')}>
      <div className={cx('listTitleArea')}>
        <Icon name="diamondLight" width={32} height={32} />
        <span className={cx('listTitle')}>수상 내역</span>
      </div>
      <div className={cx('cardList')}>{listEls}</div>
    </div>
  );
};

export default Prize;
