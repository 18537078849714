import { useInfiniteQuery, useMutation, useQuery, UseQueryOptions } from 'react-query';
import { JobOpeningTextListItemVO } from '@domain/vo/common/JobOpeningTextListItemVO';
import { RemoteAccCenterRepo } from '@repository/acc/center/AccCenterRepo';
import AccApplyCountVO from '@domain/vo/acca/test/AccApplyCountVO';
import AccApplyRecentVO from '@domain/vo/acca/test/AccApplyRecentVO';
import AccRecommendPositionListVO from '@domain/vo/acca/test/AccRecommendPositionListVO';
import { PostJobCardVO } from '@domain/vo/postJob/PostJobCardVO';
import { AccAdJobPositingItemDto } from '@domain/rs/acca/test/AccJobPositingListRs';
import { PostJobCardDto } from '@domain/rs/postJob/PostJobCardListRs';
import { AccaResultType } from '@domain/constant/acca/AccaResultType';

const remoteAccCenterRepo = new RemoteAccCenterRepo();
// const remoteAccCenterMockRepo = new AccCenterMock();

const keys = ['acca', 'center'];
const defaultPage = {
  page: 0,
  size: 4,
};

const fetchAccCenterApplyCount = async () => {
  const { data } = await remoteAccCenterRepo.fetchAccCenterApplyCount();
  return new AccApplyCountVO(data);
};

const fetchAccCenterApplyRecent = async () => {
  const { data } = await remoteAccCenterRepo.fetchAccCenterApplyRecent();
  return new AccApplyRecentVO(data);
};

const fetchDeleteAccCenterApplyRecent = async (sn: number) => {
  const { data } = await remoteAccCenterRepo.fetchDeleteAccCenterApplyRecent(sn);
  return data;
};

const fetchAccCenterAtoz = async (page = defaultPage.page, size = defaultPage.size + 2) => {
  const { data } = await remoteAccCenterRepo.fetchAccCenterAtoz(page, size);
  return {
    ...data,
    posts: data.posts.map((post: PostJobCardDto) => new PostJobCardVO(post)),
    isExistNextData: data.pages.totalPages > data.pages.page + 1,
  };
};

const fetchAccCenterPostings = async (page = defaultPage.page, size = defaultPage.size) => {
  const { data } = await remoteAccCenterRepo.fetchAccCenterPostings(page, size);
  return {
    ...data,
    jobPostings: data.jobPostings.map(
      (jobPosting: AccAdJobPositingItemDto) => new JobOpeningTextListItemVO(jobPosting),
    ),
    isExistNextData: data.pages.totalPages > data.pages.page + 1,
  };
};

const fetchAccCenterPositionsRecommend = async (type: AccaResultType) => {
  const { data } = await remoteAccCenterRepo.fetchAccCenterPositionsRecommend(type);
  return new AccRecommendPositionListVO(data);
};

const fetchAccCenterScreeningPassReviews = async (page = defaultPage.page, size = defaultPage.size + 2) => {
  const { data } = await remoteAccCenterRepo.fetchAccCenterScreeningPassReviews(page, size);
  return {
    ...data,
    posts: data.posts.map((post: PostJobCardDto) => new PostJobCardVO(post)),
    isExistNextData: data.pages.totalPages > data.pages.page + 1,
  };
};

function useAccCenterApplyCount() {
  return useQuery([...keys, 'apply-count'], fetchAccCenterApplyCount);
}

function useAccCenterApplyRecent(isLogin: boolean, options?: UseQueryOptions) {
  return useQuery([...keys, 'recent'], fetchAccCenterApplyRecent, {
    enabled: isLogin,
    ...(options as any),
    keepPreviousData: true,
    staleTime: 3000,
  });
}

function useDeleteAccCenterApplyRecent() {
  return useMutation((sn: number) => fetchDeleteAccCenterApplyRecent(sn));
}

function useAccCenterAtoz() {
  return useInfiniteQuery([...keys, 'atoz'], ({ pageParam }) => fetchAccCenterAtoz(pageParam), {
    getNextPageParam: ({ pages: { page, totalPages } }) => (page < totalPages ? page + 1 : undefined),
    refetchOnMount: false,
    refetchOnReconnect: false,
    keepPreviousData: true,
  });
}

function useAccCenterPostings() {
  return useInfiniteQuery([...keys, 'postings'], ({ pageParam }) => fetchAccCenterPostings(pageParam), {
    getNextPageParam: ({ pages: { page, totalPages } }) => (page < totalPages ? page + 1 : undefined),
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
}

function useAccCenterPositionsRecommend(type: AccaResultType, isLoginAndResults: boolean) {
  return useQuery([...keys, 'recommend', type], () => fetchAccCenterPositionsRecommend(type), {
    enabled: isLoginAndResults,
    keepPreviousData: true,
  });
}

function useAccCenterScreeningPassReviews() {
  return useInfiniteQuery(
    [...keys, 'screening-pass-reviews'],
    ({ pageParam }) => fetchAccCenterScreeningPassReviews(pageParam),
    {
      getNextPageParam: ({ pages: { page, totalPages } }) => (page < totalPages ? page + 1 : undefined),
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );
}

export {
  keys as accaCenterKeys,
  defaultPage as accaCenterDefaultPage,
  useAccCenterApplyCount,
  useAccCenterApplyRecent,
  useDeleteAccCenterApplyRecent,
  useAccCenterAtoz,
  useAccCenterPostings,
  useAccCenterPositionsRecommend,
  useAccCenterScreeningPassReviews,
};
