import { useRouter } from 'next/router';
import Icon from '@components/common/assets';
import { useToast } from '@components/common/toast';
import { ContentRouteUtil } from '@utils/routeUtil';
import SocialLoginUtil from '@utils/SocialLoginUtil';
import classnames from 'classnames/bind';
import styles from './socialLogin.module.scss';
const cx = classnames.bind(styles);
interface ClientConfig {
  clientId: string; // 앱 식별자, Apple Developer에서 확인 가능
  redirectURI: string; // 인증 후 리다이렉트할 URL, Apple Developer 계정에서 설정해야 함.
  scope?: string; // 사용자의 정보를 요청할 범위(email, name 등)
  state?: string; // 고유 문자열, 보안을 위해 사용 → Redirect URL로 전달됨
  nonce?: string; // 한 번 사용되는 숫자, 보안을 위해 사용 → Redirect URL로 전달됨
  usePopup?: boolean; // 팝업 창에서 실행될지 여부
}

interface User {
  email: string;
  name: string;
}

interface Authorization {
  code: string;
  id_token: string;
  state?: string; // init()에서 설정한 state 값
}

interface SigninResponse {
  user: User;
  authorization: Authorization;
}

declare global {
  interface Window {
    AppleID: {
      auth: {
        init: (config: ClientConfig) => void;
        signIn: (config?: ClientConfig) => Promise<SigninResponse>;
      };
    };
  }
}

const AppleLogin = () => {
  const router = useRouter();
  const Toast = useToast();

  const loginWithApple = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      SocialLoginUtil.appleLoginInit();
      const res = await window.AppleID.auth.signIn();
      const loginRs = await SocialLoginUtil.appleLogin(res.authorization.code);
      if (loginRs.registered === false) {
        router.push({
          pathname: ContentRouteUtil.getJoinUrl(),
          query: { ...loginRs.joinQuery },
        });
      } else {
        router.reload();
      }
    } catch (error) {
      console.log(error);
      if ((error as { error: string })?.error === 'popup_closed_by_user') return;
      Toast({ type: 'error', iconType: 'info', content: '로그인에 실패하였습니다.' });
    }
  };

  return (
    <button className={cx('socialLoginButton', 'appleLogin')} onClick={loginWithApple}>
      <Icon name="loginSocialApple" />
      <span className={cx('hidden')}>Apple로 로그인</span>
    </button>
  );
};

export default AppleLogin;
