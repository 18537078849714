export enum ChallengeStatus {
  READY = 'READY',
  PROGRESS = 'PROGRESS',
  FINISH = 'FINISH',
}

export const ChallengeStatusText: Record<ChallengeStatusType, string> = {
  [ChallengeStatus.READY]: '준비중',
  [ChallengeStatus.PROGRESS]: '진행중',
  [ChallengeStatus.FINISH]: '종료',
};

export type ChallengeStatusType = keyof typeof ChallengeStatus;
