import { ReactNode } from 'react';
import Button from '@components/common/button';
import PreferInfo from '@components/common/modals/MatchingOfferModal/PreferInfo';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface Props {
  visible: boolean;
  onNext: () => void;
  onCancle: () => void;
  closeBtnText?: string;
  confirmBtnText?: string;
  title?: ReactNode;
  subtitle?: string;
}

const PreferDemandModal = ({ onNext, visible, onCancle, closeBtnText, confirmBtnText, title, subtitle }: Props) => {
  if (!visible) return null;

  return (
    <div className={cx('layer')}>
      <h1 className={cx('title')}>
        {title ?? (
          <>
            혹시 <em className={cx('emphasis')}>선호하는 근무조건</em>이 바뀌셨나요?
          </>
        )}
      </h1>
      <p className={cx('desc')}>
        {subtitle ?? (
          <>
            정확히 작성해 주시면 <em className={cx('emphasis')}>딱 맞는 제안</em>을 드릴게요.
          </>
        )}
      </p>

      <PreferInfo />

      <footer className={cx('buttons')}>
        <Button className={cx('btn')} theme="secondary" onClick={onCancle}>
          {closeBtnText ?? '선호 정보 수정'}
        </Button>
        <Button className={cx('btn')} onClick={onNext}>
          {confirmBtnText ?? '잡다 매칭 취소'}
        </Button>
      </footer>
    </div>
  );
};

export default PreferDemandModal;
