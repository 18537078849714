import { useQuery } from 'react-query';
import { RemoteCodesRepo } from '@repository/codes/CodesRepo';
import WelfareCategoryWithWelfaresListVO from '@domain/vo/code/WelfareCategoryWithWelfaresListVO';

const remoteCodesRepo = new RemoteCodesRepo();

const keys = ['codes'];

export const fetchJobGroupList = async () => {
  const { data } = await remoteCodesRepo.fetchJobGroupList();
  return data;
};

export function useJobGroupList() {
  return useQuery([...keys, 'JobGroup'], fetchJobGroupList, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

const fetchJobGroupsWithTitles = async () => {
  const { data } = await remoteCodesRepo.fetchJobGroupsWithTitles();
  return data.jobGroupsWithTitles;
};

export function useJobGroupsWithTitles() {
  return useQuery([...keys, 'jobGrpoup', 'jobTitle'], fetchJobGroupsWithTitles, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

const fetchWelfareCategoriesWithWelfares = async () => {
  const { data } = await remoteCodesRepo.fetchWelfareCategoriesWithWelfares();
  return new WelfareCategoryWithWelfaresListVO(data);
};

export function useWelfareCategoriesWithWelfares() {
  return useQuery([...keys, 'welfare-categories', 'view'], fetchWelfareCategoriesWithWelfares);
}

const fetchMajorList = async (name: string) => {
  const { data } = await remoteCodesRepo.fetchMajorList(name);
  return data;
};

export function useMajorList(name: string) {
  return useQuery([...keys, 'major', name], () => fetchMajorList(name), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
