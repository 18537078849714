import WelfareCategoryWithWelfaresListRs, {
  WelfareCategoryDto,
  WelfareCategoryType,
  WelfareCategoryWithWelfareDto,
  WelfareDto,
} from '@domain/rs/code/WelfareCategoryWithWelfaresListRs';

export default class WelfareCategoryWithWelfaresListVO {
  welfareCategoryWithWelfares: WelfareCategoryWithWelfareVO[];

  constructor(rs: WelfareCategoryWithWelfaresListRs) {
    this.welfareCategoryWithWelfares = rs.welfareCategoryWithWelfares.map(
      (welfareCategoryWithWelfare) => new WelfareCategoryWithWelfareVO(welfareCategoryWithWelfare),
    );
  }
}

export class WelfareCategoryWithWelfareVO {
  code: number;
  name: WelfareCategoryType;
  welfares: WelfareVO[];
  children: WelfareCategoryWithWelfareVO[];

  constructor(dto: WelfareCategoryWithWelfareDto) {
    this.code = dto.code;
    this.name = dto.name;
    this.welfares = dto.welfares?.map((welfare) => new WelfareVO(welfare)) || [];
    this.children = dto.children?.map((child) => new WelfareCategoryWithWelfareVO(child)) || [];
  }
}

export class WelfareVO {
  code: number;
  name: string;
  category: WelfareCategoryVO;

  constructor(dto: WelfareDto) {
    this.code = dto.code;
    this.name = dto.name;
    this.category = new WelfareCategoryVO(dto.category);
  }
}

export class WelfareCategoryVO {
  code: number;
  name: string;
  parentCode: number;

  constructor(dto: WelfareCategoryDto) {
    this.code = dto.code;
    this.name = dto.name;
    this.parentCode = dto.parentCode;
  }
}
