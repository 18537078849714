import CompanyNewsListRs, { CompanyNewsItemDto } from '@domain/rs/company/CompanyNewsListRs';
import DateFormat from '@utils/date/format';

export default class CompanyNewsListVO {
  news: CompanyNewsListItemVO[];

  constructor(rs: CompanyNewsListRs) {
    this.news = rs.news.map((dto) => new CompanyNewsListItemVO(dto));
  }
}

export class CompanyNewsListItemVO {
  title: string; // 뉴스 제목
  url: string; // 뉴스 url
  date: string; // 뉴스 날짜

  constructor(rs: CompanyNewsItemDto) {
    this.title = rs.title;
    this.url = rs.url;
    this.date = this.getDateFormat(rs.date);
  }

  getDateFormat(data: string | null) {
    return data ? DateFormat.format(new Date(data), 'yyyy.MM.dd') : '';
  }
}
