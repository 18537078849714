import { JoinData } from '@domain/rq/register/JoinRq';
import { SocicalLoginRs } from '@domain/rs/LoginRs';
import { SOCIAL_ACCOUNT_TYPE } from '@domain/constant/v1/socicalType';
import { Gender } from '@domain/constant/genderType';

export class SocicalLoginVO {
  accessToken: string | null;
  birthday: string | null;
  email: string | null;
  gender: string | null;
  name: string | null;
  openId: string | null;
  openIdProvider: SOCIAL_ACCOUNT_TYPE | null;
  phoneNumber: string;
  registered: boolean;
  tokenType: string | null;

  constructor(rs: SocicalLoginRs) {
    this.accessToken = rs.accessToken;
    this.birthday = rs.birthday;
    this.email = rs.email;
    this.gender = rs.gender;
    this.name = rs.name;
    this.openId = rs.openId;
    this.openIdProvider = rs.openIdProvider;
    this.phoneNumber = rs.phoneNumber || '';
    this.registered = rs.registered;
    this.tokenType = rs.tokenType;
  }

  get joinQuery(): Pick<
    JoinData,
    'openId' | 'openIdProvider' | 'name' | 'birthDate' | 'mobile' | 'email' | 'genderFlag'
  > {
    return {
      openId: this.openId,
      openIdProvider: this.openIdProvider,
      name: this.name || '',
      birthDate: this.birthday?.toString().substr(2) || '',
      mobile: this.phoneNumber?.replace('+82 10', '010').replace(/-/gi, ''),
      email: this.email || '',
      genderFlag: this.genderFlag,
    };
  }

  private get genderFlag() {
    if (!this.birthday || !this.gender) return Gender['MALE'];
    const isMale = this.gender === 'male';

    // 2000년대 이전 출생
    if (this.birthday.startsWith('1')) {
      if (isMale) return Gender['MALE'];
      return Gender['FEMALE'];
    }

    if (isMale) return Gender['MALE'];
    return Gender['FEMALE'];
  }
}
