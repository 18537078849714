import * as yup from 'yup';
import { EmploymentStatusType } from '@domain/constant/profile/CareerType';

const careerFormSchema = yup.object({
  companyName: yup.lazy((ctx) => {
    if (!ctx) return yup.object().nullable().required('직장을 검색해주세요.');
    return yup
      .object()
      .shape({
        label: yup.string(),
      })
      .test('value-length', '30자 이내로 입력해주세요.', (obj) => {
        return obj.label ? obj.label.length <= 30 : true;
      });
  }),
  employmentStatus: yup.string(),
  startDate: yup.string().required(),
  endDate: yup.string().when('employmentStatus', {
    is: (val: EmploymentStatusType) => val === 'RESIGN',
    then: yup
      .string()
      .when('startDate', (startDate, schema) => {
        return schema.test({
          test: (endDate: Date) => {
            return endDate >= startDate;
          },
          message: '퇴사 일자가 입사 일자보다 빠릅니다.',
        });
      })
      .required('퇴사 일자를 입력해주세요.'),
    otherwise: yup.string().notRequired(),
  }),
  jobGroup: yup.object(),
  jobTitle: yup.object(),
  employmentType: yup.object(),
  role: yup.string().nullable().max(30, '30자 이내로 입력해주세요.'),
  performance: yup.string().nullable().max(10000, '최대 글자수를 초과했습니다.'),
});

export default careerFormSchema;
