import Cookies from 'js-cookie';
import { SHA256 } from 'crypto-js';
import { setAxiosHeader } from '@repository/RequestClient';
import { MILLISECONDS_PER_DAY, MILLISECONDS_PER_HOUR } from '@domain/constant/Time';
import { getTokenDomain } from '@utils/common';

export const AccessTokenExpireTime = 2 * MILLISECONDS_PER_HOUR; // 토큰 유지 시간 :  2시간
export const AutoLoginExpireTime = 30 * MILLISECONDS_PER_DAY; // 자동 로그인 유지 시간 : 30일

export const getAutoLogin = () => {
  return Cookies.get('autoLogin');
};

export const getAccessToken = () => {
  return Cookies.get('accessToken');
};

export const removeAutoLogin = () => {
  Cookies.remove('autoLogin');
};

export const removeAccessToken = () => {
  Cookies.remove('accessToken');
  Cookies.remove('accessToken', { domain: getTokenDomain() });
};

export const removeGAId = () => {
  Cookies.remove('gaId');
};

export const setAutoLogin = () => {
  Cookies.set('autoLogin', 'true', { expires: new Date(Date.now() + AutoLoginExpireTime) });
};

export const setAccessToken = (accessToken?: string) => {
  if (!accessToken) return;

  if (process.env.NEXT_PUBLIC_LOCAL) {
    Cookies.set('accessToken', accessToken, {
      expires: new Date(Date.now() + AccessTokenExpireTime),
    });
  } else {
    Cookies.set('accessToken', accessToken, {
      expires: new Date(Date.now() + AccessTokenExpireTime),
      domain: getTokenDomain(),
    });
  }
  setAxiosHeader('Authorization', `Bearer ${getAccessToken()}`);
};

export const setGAId = (id: string) => {
  const autoLogin = Cookies.get('autoLogin');
  const expiresTime = autoLogin ? AutoLoginExpireTime : AccessTokenExpireTime;
  Cookies.set('gaId', SHA256(id).toString(), { expires: new Date(Date.now() + expiresTime) });
};
