import React from 'react';
import { FontStyleValueType } from '@domain/constant/fontStyle';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface TextProps {
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span' | 'em' | 'strong' | 'p';
  children?: React.ReactNode;
  className?: string;
  fontStyle?: FontStyleValueType;
  color?: string;
  styles?: React.CSSProperties;
  ellipsis?: boolean;
}

const Text = ({ element = 'p', children, className, fontStyle, color, styles, ellipsis }: TextProps) => {
  const Element = element;
  const style = {
    ...fontStyle,
    color,
    ...styles,
  };

  return (
    <Element className={cx(className, { ellipsis })} style={style}>
      {children}
    </Element>
  );
};

export default Text;
