export enum ChallengeRecruitment {
  NEW,
  CAREER,
  INTERNSHIP,
  ANY,
}

export type ChallengeRecruitmentType = keyof typeof ChallengeRecruitment;
export const ChallengeRecruitmentMap: Record<ChallengeRecruitmentType, string> = {
  NEW: '신입',
  CAREER: '경력',
  INTERNSHIP: '인턴',
  ANY: '경력무관',
};
