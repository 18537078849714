import Icon from '@components/common/assets';
import Text from '@components/common/text';
import styles from '../../commonStyle.module.scss';
import Colors from '@domain/constant/colors';
import { FontStyle } from '@domain/constant/fontStyle';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

const InvalidMessage = () => {
  return (
    <div className={cx('invalidMessage')}>
      <Icon name="informationLight" width={16} height={16} fill={Colors.C_ERROR} />
      <Text fontStyle={FontStyle.B4_M} color={Colors.C_ERROR}>
        추가 작성 필요
      </Text>
    </div>
  );
};

export default InvalidMessage;
