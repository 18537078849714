export enum LocationCode {
  SEOUL = 10001,
  GYEONGGI = 10009,
  INCHEON = 10002,
  BUSAN = 10008,
  DAEGU = 10006,
  GWANGJU = 10005,
  DAEJEON = 10003,
  ULSAN = 10007,
  GANGWON = 10010,
  SEJONG = 10004,
  CHUNGBUK = 10011,
  CHUNGNAM = 10012,
  JEONBUK = 10013,
  JEONNAM = 10014,
  GYEONGBUK = 10015,
  GYEONGNAM = 10016,
  JEJU = 10017,
  전체 = 10000,
}

export enum LocationShortFilter {
  SEOUL = '서울',
  GYEONGGI = '경기',
  INCHEON = '인천',
  BUSAN = '부산',
  DAEGU = '대구',
  GWANGJU = '광주',
  DAEJEON = '대전',
  ULSAN = '울산',
  GANGWON = '강원',
  SEJONG = '세종',
  CHUNGBUK = '충북',
  CHUNGNAM = '충남',
  JEONBUK = '전북',
  JEONNAM = '전남',
  GYEONGBUK = '경북',
  GYEONGNAM = '경남',
  JEJU = '제주',
}

export enum LocationShort {
  SEOUL = '서울',
  GYEONGGI = '경기',
  INCHEON = '인천',
  BUSAN = '부산',
  DAEGU = '대구',
  GWANGJU = '광주',
  DAEJEON = '대전',
  ULSAN = '울산',
  GANGWON = '강원',
  SEJONG = '세종',
  CHUNGBUK = '충북',
  CHUNGNAM = '충남',
  JEONBUK = '전북',
  JEONNAM = '전남',
  GYEONGBUK = '경북',
  GYEONGNAM = '경남',
  JEJU = '제주',
  전체 = '전체 지역',
}

export enum Location {
  SEOUL = '서울특별시',
  GYEONGGI = '경기도',
  INCHEON = '인천광역시',
  BUSAN = '부산광역시',
  DAEGU = '대구광역시',
  GWANGJU = '광주광역시',
  DAEJEON = '대전광역시',
  ULSAN = '울산광역시',
  GANGWON = '강원도',
  SEJONG = '세종특별자치시',
  CHUNGBUK = '충청북도',
  CHUNGNAM = '충청남도',
  JEONBUK = '전라북도',
  JEONNAM = '전라남도',
  GYEONGBUK = '경상북도',
  GYEONGNAM = '경상남도',
  JEJU = '제주특별자치도',
}

export const LocationNameMap: Record<Location, string> = {
  [Location.SEOUL]: '서울특별시',
  [Location.GYEONGGI]: '경기도',
  [Location.INCHEON]: '인천광역시',
  [Location.BUSAN]: '부산광역시',
  [Location.DAEGU]: '대구광역시',
  [Location.GWANGJU]: '광주광역시',
  [Location.DAEJEON]: '대전광역시',
  [Location.ULSAN]: '울산광역시',
  [Location.GANGWON]: '강원도',
  [Location.SEJONG]: '세종특별자치시',
  [Location.CHUNGBUK]: '충청북도',
  [Location.CHUNGNAM]: '충청남도',
  [Location.JEONBUK]: '전라북도',
  [Location.JEONNAM]: '전라남도',
  [Location.GYEONGBUK]: '경상북도',
  [Location.GYEONGNAM]: '경상남도',
  [Location.JEJU]: '제주특별자치도',
};

export const OrderLocation = [
  'SEOUL',
  'GYEONGGI',
  'INCHEON',
  'BUSAN',
  'DAEGU',
  'GWANGJU',
  'DAEJEON',
  'ULSAN',
  'GANGWON',
  'SEJONG',
  'CHUNGBUK',
  'CHUNGNAM',
  'JEONBUK',
  'JEONNAM',
  'GYEONGBUK',
  'GYEONGNAM',
  'JEJU',
];

export type LocationValue =
  | Location.SEOUL
  | Location.GYEONGGI
  | Location.INCHEON
  | Location.BUSAN
  | Location.DAEGU
  | Location.GWANGJU
  | Location.DAEJEON
  | Location.ULSAN
  | Location.GANGWON
  | Location.SEJONG
  | Location.CHUNGBUK
  | Location.CHUNGNAM
  | Location.JEONBUK
  | Location.JEONNAM
  | Location.GYEONGBUK
  | Location.GYEONGNAM
  | Location.JEJU;

export const ALL_Locations: Array<LocationValue> = Object.values(Location);

export type LocationCodeType = keyof typeof LocationCode;
export type LocationType = keyof typeof Location;
export type LocationCodeNumberType = (typeof LocationCode)[LocationType];
