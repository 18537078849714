import Modal from '@components/common/modal';
import Button from '@components/common/button';
import Icon from '@components/common/assets';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface Props {
  visible: boolean;
  onClose: () => void;
  onNext: () => void;
}

const MatchingBenefitsModal = ({ visible, onClose, onNext }: Props) => {
  return (
    <Modal visible={visible}>
      <div className={cx('layer')}>
        <h1 className={cx('title')}>서비스 이용을 취소하시겠어요?</h1>
        <p className={cx('desc')}>잡다 매칭 취소 시 다음 혜택이 사라져요.</p>

        <div className={cx('benefits')}>
          <div className={cx('benefit')}>
            <Icon name="testAcca" width={32} height={32} />
            <span>
              <em className={cx('emphasis')}>역량검사 결과표 확인</em>하기
            </span>
          </div>
          <div className={cx('benefit')}>
            <Icon name="invitationUser" width={32} height={32} />
            <span>
              <em className={cx('emphasis')}>맞춤형 포지션 추천</em> 받기
            </span>
          </div>
          <div className={cx('benefit')}>
            <Icon name="resumeDoneGreen" width={32} height={32} />
            <span>
              인사 담당자의 <em className={cx('emphasis')}>입사지원 제안</em> 받기
            </span>
          </div>
        </div>

        <div className={cx('btnArea')}>
          <Button className={cx('btn')} theme="secondary" onClick={onClose}>
            취소
          </Button>
          <Button className={cx('btn')} onClick={onNext}>
            계속
          </Button>
        </div>

        <a className={cx('btnClose')} role="button" onClick={onClose}>
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default MatchingBenefitsModal;
