import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Slider, { Settings } from 'react-slick';
import Link from 'next/link';
import Portal from '@common/portal';
import CustomImage from '@components/common/image';
import usePopContents from '@repository/popup/usePopupContents';
import { PopupDto } from '@domain/rs/popup/PopupRs';
import { MILLISECONDS_PER_DAY } from '@domain/constant/Time';
import LocalStorageUtil from '@utils/storage/localStorage';
import { RouteUrl } from '@utils/routeUtil';

import DateUtil from '@utils/date/util';
import TextFormat from '@utils/text/format';
import classnames from 'classnames/bind';
import styles from './popup.module.scss';
const cx = classnames.bind(styles);

interface PopupCardProps {
  popups: PopupDto[];
}

const PopupCard = ({ popups }: PopupCardProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [popupData, setPopupData] = useState<PopupDto[]>([]);
  const [currentIndex, setCurrentIndex] = useState(1);

  const settings: Settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    afterChange: (index) => setCurrentIndex(index + 1),
  };

  const handleStop = () => {
    const popupItems = LocalStorageUtil.getItem('popup');
    const snsObject = popups.reduce<Record<string, number>>((acc, cur) => {
      acc[cur.sn] = cur.sn;
      return acc;
    }, {});
    const value = popupItems ? { ...JSON.parse(popupItems), ...snsObject } : snsObject;
    LocalStorageUtil.setItem('popup', JSON.stringify(value), { expires: new Date(Date.now() + MILLISECONDS_PER_DAY) });
    setIsVisible(false);
  };

  useEffect(() => {
    const popupItems = LocalStorageUtil.getItem('popup');

    const filteredPopups = popups.filter((item) => {
      const { sn, postStartDateTime, postEndDateTime, viewType } = item;
      if (viewType !== 'POPUP') return false;
      if (!popupItems || !JSON.parse(popupItems)[sn]) {
        // 그 안에서도 시간이 지났는지 안 지났는지 확인하기
        if (DateUtil.isPastDate(new Date(postStartDateTime))) return true;
        if (DateUtil.isPastDate(new Date(postStartDateTime)) && DateUtil.isPastDate(new Date(postEndDateTime)))
          return false;
      }
    });

    if (filteredPopups.length > 0) {
      setPopupData(filteredPopups);
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [popups]);

  if (!isVisible) return null;

  return (
    <div className={cx('popupArea')}>
      <Slider className={cx('slider')} {...settings}>
        {popupData.map((item) => {
          const { sn, linkType, link, pcAttachFileUrl } = item;
          const isNewWindow = linkType === 'NEW_WINDOW';
          const routerUrl = TextFormat.formatProtocol(link);

          return (
            <Link
              href={routerUrl}
              target={isNewWindow ? '_blank' : '_self'}
              className={cx('imgButton')}
              rel="noreferrer"
              key={sn}
            >
              <div className={cx('popupImg')}>
                <CustomImage src={pcAttachFileUrl} width={400} height={400} alt="popup image" pointer />
              </div>
            </Link>
          );
        })}
      </Slider>
      <div className={cx('numbering')}>
        <span className={cx('currentIndex')}>{currentIndex}</span>
        <span className={cx('totalIndex')}>{popupData.length}</span>
      </div>
      <div className={cx('buttonArea')}>
        <a onClick={handleStop} className={cx('button')} role="button">
          오늘은 그만 보기
        </a>
        <a onClick={() => setIsVisible(false)} className={cx('button')} role="button">
          닫기
        </a>
      </div>
    </div>
  );
};

const Popup = () => {
  const { asPath, isReady } = useRouter();
  //pathname 사용시 [sn] 이 포함된 URL에 대해서는 /[SN]/로 리턴되는 이슈로  asPath 사용
  const { data } = usePopContents(asPath, isReady);

  //로그인 페이지인 경우 로그인 모달보다 상위 위치하도록 함
  const isLoginPage = `${asPath}`.includes(RouteUrl.LOGIN);

  if (!data) return null;
  const popupData = data.popups.filter((item) => item.viewType === 'POPUP');

  return (
    <Portal selector="#popup">
      <div className={cx('popup', { fixed: isLoginPage })}>
        <PopupCard popups={popupData} />
      </div>
    </Portal>
  );
};

export default Popup;
