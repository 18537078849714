import AccApplyCountRs from '@domain/rs/acca/test/AccApplyCountRs';
import NumberFormat from '@utils/number/format';

export default class AccApplyCountVO {
  total: string;

  constructor(rs: AccApplyCountRs) {
    // 구 역량검사 누적 응시자수(40만)
    const oldTestApplyCountTotal = 400000;

    // 구 역량검사 누적 응시자수(40만) 추가 후 노출
    this.total = NumberFormat.formatDecimal(rs.total + oldTestApplyCountTotal);
  }
}
