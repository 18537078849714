export interface ICustomHeadVO {
  title: string;
  description: string;
  url: string;
  image: string;
  keywords?: string;
}

export default class CustomHeadVO {
  title: string;
  description: string;
  url: string;
  image: string;
  keywords?: string;

  constructor(vo: ICustomHeadVO) {
    this.title = vo.title;
    this.description = vo.description;
    this.url = vo.url;
    this.image = vo.image;
    this.keywords = vo.keywords;
  }
}
