export enum JobApplicationProcess {
  APPLY = 'APPLY',
  INTERVIEW_OFFERED = 'INTERVIEW_OFFERED',
  INTERVIEW_ACCEPTED = 'INTERVIEW_ACCEPTED',
  INTERVIEW_DENIED = 'INTERVIEW_DENIED',
  INTERVIEW_ADJUSTED = 'INTERVIEW_ADJUSTED',
  INTERVIEW_PASSED = 'INTERVIEW_PASSED',
  INTERVIEW_FAILED = 'INTERVIEW_FAILED',
  ACC_REQUEST = 'ACC_REQUEST',
  ACC_SUBMIT = 'ACC_SUBMIT',
  PHS_REQUEST = 'PHS_REQUEST',
  PHS_SUBMIT = 'PHS_SUBMIT',
  WRITING_RESUME = 'WRITING_RESUME ',
  IN_PROGRESS = 'IN_PROGRESS', //전형 진행중(INSIGHT)
  END = 'END', //전형 종료(INSIGHT)
}

//사용처 : 매칭 홈 > 지원 현황용
export const applicationStatusTextMap: Record<JobApplicationProcessType, string> = {
  APPLY: '입사 지원',
  INTERVIEW_OFFERED: '면접 제안 요청',
  INTERVIEW_ACCEPTED: '면접 수락',
  INTERVIEW_DENIED: '면접 거절',
  INTERVIEW_ADJUSTED: '면접 일정 조율 중',
  INTERVIEW_PASSED: '최종 합격',
  INTERVIEW_FAILED: '불합격',
  ACC_REQUEST: '역량검사 제출 필요',
  ACC_SUBMIT: '역량검사 제출 완료',
  PHS_REQUEST: '개발자 검사 제출 필요',
  PHS_SUBMIT: '개발자 검사 제출 완료',
  WRITING_RESUME: '작성 중',
  IN_PROGRESS: '진행 중',
  END: '종료',
};

export const applyStatusTextMap: Record<JobApplicationProcessType, string> = {
  APPLY: '지원 완료',
  INTERVIEW_OFFERED: '전형 진행 중',
  INTERVIEW_ACCEPTED: '전형 진행 중',
  INTERVIEW_DENIED: '전형 진행 중',
  INTERVIEW_ADJUSTED: '전형 진행 중',
  ACC_REQUEST: '전형 진행 중',
  ACC_SUBMIT: '전형 진행 중',
  PHS_REQUEST: '전형 진행 중',
  PHS_SUBMIT: '전형 진행 중',
  WRITING_RESUME: '전형 진행 중',
  IN_PROGRESS: '전형 진행 중',
  INTERVIEW_PASSED: '합격',
  INTERVIEW_FAILED: '불합격',
  END: '전형 종료',
};

export type JobApplicationProcessType = keyof typeof JobApplicationProcess;

export enum JobApplicationProcessTypeEnum {
  JOBDA = 'JOBDA',
  HOMEPAGE = 'HOMEPAGE', //jf3
}

export type JobApplicationPositionType = keyof typeof JobApplicationProcessTypeEnum;

export const jobApplicationPositionTextMap: Record<JobApplicationPositionType, string> = {
  JOBDA: '잡다 지원',
  HOMEPAGE: '홈페이지 지원',
};

export enum InsightVisibilityEnum {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type InsightVisibilityType = keyof typeof InsightVisibilityEnum;
