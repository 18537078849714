import { UploadFile, UploadFileStatus } from '@common/upload';
import FileRs, { ProfileFileUploadRs } from '@domain/rs/common/v1/FileRs';

export default class FileVO implements UploadFile {
  fileName: string;
  fileUid: string;
  status: UploadFileStatus;

  constructor(rs: FileRs, status: UploadFileStatus) {
    this.fileName = rs.fileName;
    this.fileUid = rs.fileUid;
    this.status = status;
  }
}

export class ProfileUploadFileVO implements UploadFile {
  fileUid: string;
  fileName: string;
  fileSize: number;
  fileUrl: string;
  status: UploadFileStatus;

  constructor(rs: ProfileFileUploadRs, status: UploadFileStatus) {
    this.fileUid = rs.fileUid;
    this.fileName = rs.fileName;
    this.fileSize = rs.fileSize;
    this.fileUrl = rs.fileUrl;
    this.status = status;
  }
}
