import AuthFindFormField from './AuthFindFormField';
import TextFormat from '@utils/text/format';

export default class UsersIdRq {
  birthDate: string;
  countryCode: string;
  mobile: string;
  token: string;

  constructor(findUserInfo: AuthFindFormField) {
    this.birthDate = TextFormat.birthDate(findUserInfo.birthDate);
    this.countryCode = findUserInfo.countryCode;
    this.mobile = findUserInfo.mobile;
    this.token = findUserInfo.token;
  }
}
