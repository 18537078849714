import { ProfileJobGroupDto } from './MatchingConditionSaveRq';
import { SourcePage } from '@domain/rq/DataLog';

export default class MatchingConditionJobGroupRq {
  jobGroups: ProfileJobGroupDto[];
  sourcePage?: SourcePage;

  constructor(rq: MatchingConditionJobGroupRq) {
    this.jobGroups = rq.jobGroups;
    this.sourcePage = rq.sourcePage;
  }
}
