import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from '@components/loginModal/find/find.module.scss';
import Token from '@components/loginModal/find/form/mobile/Token';
import CountryCode from '@components/loginModal/find/form/mobile/CountryCode';
import Mobile, { MobileCertificateType } from '@components/loginModal/find/form/mobile/Mobile';
import { MobileCertStateType } from '@domain/constant/MobileCertStateType';
import useTimer from '@utils/hooks/useTimer';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface MobileProps {
  type?: MobileCertificateType;
  setIsSuccessMobileAuth: (isSuccessMobileAuth: boolean) => void;
}

export const MobileWrap: FC<MobileProps> = ({ type = 'ID', setIsSuccessMobileAuth }) => {
  const [mobileCertState, setMobileCertState] = useState<MobileCertStateType | null>(null);
  const { watch, setValue } = useFormContext();
  const mobile = watch('mobile');

  const { getRemainingTimeFormat, onStart, onStop, remainingTime } = useTimer({
    time: 3 * 60,
    start: false,
    onCountUp: () => setMobileCertState('END'),
  });

  useEffect(() => {
    setMobileCertState(null);
    setValue('token', '');
  }, [mobile]);

  return (
    <>
      <div className={cx('infoWrap')}>
        <CountryCode cx={classnames.bind(styles)} />
        <Mobile
          type={type}
          remainingTime={remainingTime}
          resetTimer={onStart}
          mobileCertState={mobileCertState}
          setMobileCertState={setMobileCertState}
          setIsSuccessMobileAuth={setIsSuccessMobileAuth}
        />
      </div>
      <Token
        timerFormat={getRemainingTimeFormat('mm:ss')}
        timerStop={onStop}
        mobileCertState={mobileCertState}
        setMobileCertState={setMobileCertState}
        setIsSuccessMobileAuth={setIsSuccessMobileAuth}
      />
    </>
  );
};
