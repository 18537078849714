import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { RemoteIdentityRepo } from '@repository/identity/identityRepo';
import KcbIdentitySaveRq from '@domain/rq/verification/KcbIdentityRq';
import IdentificationPasswordRq from '@domain/rq/verification/IdentificationPasswordRq';
import KcbSmsVerificationRs from '@domain/rs/verification/KcbSmsVerificationRs';
import KcbIpinVerificationRs from '@domain/rs/verification/KcbIpinVerificationRs';

const remoteIdentityRepo = new RemoteIdentityRepo();

export const keys = ['identity', 'check'];

const fetchCheckKcbIdentity = async () => {
  const { data } = await remoteIdentityRepo.fetchCheckKcbIdentity();
  return data;
};

const saveKcbIdentity = async (rs: KcbSmsVerificationRs | KcbIpinVerificationRs) => {
  const rq = new KcbIdentitySaveRq(rs);
  const { data } = await remoteIdentityRepo.saveKcbIdentity(rq);
  return data;
};

const fetchIdentityIdFind = async (token: string) => {
  const { data } = await remoteIdentityRepo.fetchIdentityIdFind(token);
  return data;
};

//비밀번호 재설정
const fetchIdentityPasswordReset = async (rq: IdentificationPasswordRq) => {
  const { data } = await remoteIdentityRepo.fetchIdentityPasswordReset(rq);
  return data;
};

const fetchCheckIdentityMobile = async () => {
  const { data } = await remoteIdentityRepo.fetchCheckIdentityMobile();
  return data;
};

export function useSaveKcbIdentity(onError: (err: AxiosError) => void) {
  return useMutation((rs: KcbSmsVerificationRs | KcbIpinVerificationRs) => saveKcbIdentity(rs), {
    onError: onError,
  });
}

export function useCheckIdentity(enabled = true) {
  return useQuery(keys, fetchCheckKcbIdentity, {
    enabled,
  });
}

export function useIdentityIdFind() {
  return useMutation((token: string) => fetchIdentityIdFind(token));
}

export function useIdentityPasswordReset() {
  return useMutation((rq: IdentificationPasswordRq) => fetchIdentityPasswordReset(rq));
}

export function useCheckIdentityMobile() {
  return useMutation(() => fetchCheckIdentityMobile());
}
