import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToast } from '@common/toast';
import { useUsersId } from '@repository/users/useUsers';
import { FindIdStepType } from '@components/loginModal/find/id/index';
import styles from '@components/loginModal/find/find.module.scss';
import { BirthDate } from '@components/loginModal/find/form/BirthDate';
import { MobileWrap } from '@components/loginModal/find/form/mobile';
import AuthFindFormField from '@domain/rq/users/AuthFindFormField';
import IdentificationIdRs from '@domain/rs/verification/IdentificationIdRs';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface FindIdMobileProps {
  setFindIdStep: (state: FindIdStepType) => void;
  setFindIdResult: (result: IdentificationIdRs | null) => void;
}

const FindIdMobile: FC<FindIdMobileProps> = ({ setFindIdStep, setFindIdResult }) => {
  const Toast = useToast();
  const { mutateAsync: findUsersId } = useUsersId();
  const [isSuccessMobileAuth, setIsSuccessMobileAuth] = useState(false);

  const methods = useForm<AuthFindFormField>({ mode: 'onChange' });
  const {
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;
  const mobile = watch('mobile')?.replace(/[^0-9]/g, '');
  const birthDate = watch('birthDate')?.replace(/[^0-9]/g, '');
  const countryCode = watch('countryCode');
  const isDisabledConfirmIdBtn = !birthDate || !countryCode || !isSuccessMobileAuth || !!Object.values(errors).length;

  const onSubmit = async (authFindFormField: AuthFindFormField) => {
    try {
      const result = await findUsersId({ ...authFindFormField, mobile });
      if (result) {
        setFindIdResult(result);
        setFindIdStep('RESULT');
      }
    } catch (e: any) {
      Toast({ type: 'error', iconType: 'info', content: e.response.data.message });
    }
  };

  return (
    <FormProvider {...methods}>
      <div className={cx('userInfoArea')}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BirthDate />
          <MobileWrap setIsSuccessMobileAuth={setIsSuccessMobileAuth} />
          <button className={cx('btnFindInfo')} type="submit" disabled={isDisabledConfirmIdBtn}>
            아이디 확인
          </button>
        </form>
      </div>
    </FormProvider>
  );
};

export default FindIdMobile;
