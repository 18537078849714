import { useQuery } from 'react-query';
import { RemoteCodesRepo } from '@repository/codes/CodesRepo';

const remoteCodesRepo = new RemoteCodesRepo();

const fetchCountryList = async () => {
  const { data } = await remoteCodesRepo.fetchCountryList();
  return data.countries;
};

export default function useCountries() {
  return useQuery(['profile', 'overlays', 'experience', 'countries'], () => fetchCountryList(), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
