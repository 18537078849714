import ProfileViewVO from '@domain/vo/profile/common/ProfileViewVO';
import { ProfileProjectDto, ProfileProjectRs, SkillDto } from '@domain/rs/profile/ProfileProjectRs';
import { ProjectType, ProjectTypeNameMap } from '@domain/constant/profile/ProjectType';
import { SkillLevelNameMap, SkillLevelType } from '@domain/constant/profile/SkillType';
import { AuditItemsType } from '@domain/constant/profile/AuditItemsType';
import DateFormat from '@utils/date/format';

export default class ProfileProjectsVO extends ProfileViewVO {
  projects: ProfileProjectVO[];

  constructor(rs: ProfileProjectRs) {
    super(rs, false);

    this.projects = rs.projects.map((item) => new ProfileProjectVO(item));
  }
}

export class ProfileProjectVO {
  title: string;
  startDate: string;
  endDate: string;
  teamType: ProjectType;
  teamTypeText: string;
  contribution: number;
  performance: string;
  institution: string;
  skills: SkillsVO[];
  auditItems?: AuditItemsType[];

  constructor(rs: ProfileProjectDto) {
    this.title = rs.title;
    this.startDate = this.getDateFormat(rs.startDate);
    this.endDate = this.getDateFormat(rs.endDate);
    this.teamType = rs.teamType;
    this.teamTypeText = ProjectTypeNameMap[rs.teamType];
    this.contribution = rs.contribution;
    this.performance = rs.performance;
    this.institution = rs.institution;
    this.skills = rs.skills.map((item) => new SkillsVO(item));
    this.auditItems = rs.auditItems;
  }

  getDateFormat(data: string | null) {
    return DateFormat.getDateFormatString(data, 'yyyy.MM') ?? '';
  }
}

export class SkillsVO {
  code: number;
  keyword: string;
  skillLevelText: string;
  level: SkillLevelType;

  constructor(dto: SkillDto) {
    this.code = dto.code;
    this.keyword = dto.keyword;
    this.skillLevelText = SkillLevelNameMap[dto.level];
    this.level = dto.level;
  }
}
