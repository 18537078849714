import getConfig from 'next/config';
import { AxiosError } from 'axios';
import ApiErrorRs from '@domain/rs/common/ApiErrorRs';
import { REACT_APP_KAKAO_JS_KEY } from '@utils/KakaoUtils';

const { publicRuntimeConfig } = getConfig() || {
  publicRuntimeConfig: {},
};

export const baseURL: string = publicRuntimeConfig['BASE_URL'];
export const chatAppId: string = publicRuntimeConfig['CHAT_APP_ID'];
export const isServer = typeof window === 'undefined';
export const rootURL: string = publicRuntimeConfig['ROOT_URL'];
export const isPrServer = rootURL === 'jobda.im';
export const isQaServer = rootURL === 'st2-jobda-im.kr-st-jainwon.com';

declare global {
  interface Window {
    Kakao: any;
  }
}

export interface ShareLinkWithKakaoProps {
  title: string;
  description: string;
  btnText: string;
  imageUrl: string;
  mobileWebUrl?: string;
  webUrl?: string;
}

export function getFileImg(uid: string): string {
  return `${baseURL}/file/${uid}/view`.replace(/\/+file/gi, '/file');
}

export function getFile(uid: string): string {
  return `${baseURL}/file/${uid}/download`;
}

// 매개 변수가 비어있는지 확인
export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined) return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (Array.isArray(value) && value.length === 0) return true;
  if (typeof value === 'object' && value !== null && Object.keys(value).length === 0) return true;
  return false;
}

//callback : 복사 완료 후 동작 ,  copyUrl 복사할 string (기본 값: 현재 페이지)
export function copyString(callback?: () => void, copyString: string = window.document.location.href) {
  const textarea = document.createElement('textarea');
  document.body.appendChild(textarea);
  textarea.value = copyString;
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
  callback?.();
}

export function deepCopy<T>(obj: T): T {
  if (obj == null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item)) as T;
  }

  const copy = {} as T;
  for (const key in obj) {
    copy[key] = deepCopy(obj[key]);
  }
  return copy;
}

// from jobflex
// body 스크롤 overflow: hidden/auto 시키는 코드
export function getBodyScrollWidth() {
  if (isServer) return 0;

  const windowWidth = window.innerWidth;
  const bodyWidth = document.body.clientWidth;
  const scrollWidth = windowWidth - bodyWidth;
  return scrollWidth;
}

export function bodyScrollHidden(isHidden: boolean, hasScrollOffset = true) {
  const scrollWidth = getBodyScrollWidth();

  if (isHidden) {
    document.body.style.overflow = 'hidden';

    const shouldChangeOffset = scrollWidth !== 0 && hasScrollOffset;
    if (shouldChangeOffset) document.body.style.marginRight = `${scrollWidth}px`;
  } else {
    document.body.style.overflow = '';
    document.body.style.marginRight = '';
  }
}

export function shareLinkWithKakao({
  title,
  description,
  btnText,
  imageUrl,
  mobileWebUrl,
  webUrl,
}: ShareLinkWithKakaoProps) {
  const kakao = window.Kakao;

  if (window.Kakao) {
    if (!kakao.isInitialized()) kakao.init(REACT_APP_KAKAO_JS_KEY);
    kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: title,
        description: description,
        imageUrl: imageUrl,
        link: {
          mobileWebUrl: mobileWebUrl,
          webUrl: webUrl,
        },
      },
      buttons: [
        {
          title: btnText,
          link: {
            mobileWebUrl: mobileWebUrl,
            webUrl: webUrl,
          },
        },
      ],
    });
  }
}

export function shareLinkWithFacebook(_link?: string) {
  const link = _link ? _link : window.location.href;
  const url = 'https://www.facebook.com/sharer/sharer.php?u=' + link;
  window.open(url, '', 'width=400, height=400');
}

export const getIsAxiosError = <R = ApiErrorRs>(error: unknown): error is AxiosError<R> => {
  if (!(error instanceof Object)) return false;
  return error.hasOwnProperty('isAxiosError') && (error as Record<string, unknown>).isAxiosError === true;
};

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const getTokenDomain = () => {
  const parts = baseURL?.split('.');
  const rootDomain = `.${parts?.slice(-2).join('.')}`;

  return rootDomain;
};
