import React from 'react';
import Modal from '@common/modal';

import classnames from 'classnames/bind';
import styles from './noIdModal.module.scss';
const cx = classnames.bind(styles);

interface NoIdModalProps {
  visible: boolean;
  onConfirm: () => void;
}

const NoIdModal = ({ visible, onConfirm }: NoIdModalProps) => {
  return (
    <Modal visible={visible}>
      <div className={cx('layer')}>
        <em className={cx('title')}>아이디를 찾을 수 없습니다.</em>

        <p className={cx('desc')}>입력한 회원 정보를 다시 한 번 확인해 주세요.</p>

        <a className={cx('btnConfirm')} role="button" onClick={onConfirm}>
          확인
        </a>
      </div>
    </Modal>
  );
};

export default NoIdModal;
