import AuthRepo, { RemoteAuthRepo } from '@repository/auth/AuthRepo';
import { setAccessToken, setGAId } from '@repository/AccessToken';
import { isServer } from './common';
import { GoogleTokenRq, IGoogleTokenRq, IKaKaoTokenRq, KaKaoTokenRq } from '@domain/rq/LoginRq';
import { SocicalLoginVO } from '@domain/vo/user/SocicalLoginVO';
import { RouteUrl } from '@utils/routeUtil';

export default class SocialLoginUtil {
  private static authRepo: AuthRepo = new RemoteAuthRepo();
  private static kakaoAuthPath = 'oauth2/callback/kakao';
  private static googleAuthPath = 'oauth2/callback/google';
  private static appleAuthPath = 'oauth';

  private static get kakaoAuthRedirectUri() {
    return `${location.origin}/${this.kakaoAuthPath}`;
  }

  private static get googleAuthRedirectUri() {
    return `${location.origin}/${this.googleAuthPath}`;
  }

  private static get appleAuthRedirectUri() {
    return `${location.origin}/${this.appleAuthPath}`;
  }

  private static get kakaoClientId() {
    return 'e39f93b6bddaee7bf94d305dd02116f2';
  }

  private static get kakaoClientSecret() {
    return '72zF3ertsfmMY3VK4kUwyLcdp7Nsmw2H';
  }

  private static get appleClientId() {
    return 'io.ionic.jobdaIdentifier';
  }

  private static get currentPath() {
    return encodeURIComponent(location.href);
  }

  private static getNextPath(redirectUrl?: string) {
    if (location.pathname === RouteUrl.LOGIN) return RouteUrl.INDEX;
    if (!redirectUrl) return this.currentPath;
    return encodeURIComponent(`${location.origin}${redirectUrl}`);
  }

  private static getTokenParamKakao(code: string): IKaKaoTokenRq {
    return {
      grant_type: 'authorization_code',
      client_id: this.kakaoClientId,
      redirect_uri: this.kakaoAuthRedirectUri,
      code,
    };
  }

  static getKakaoLoginUrl(redirectUrl?: string) {
    const url =
      'https://kauth.kakao.com/oauth/authorize' +
      `?client_id=${this.kakaoClientId}` +
      `&redirect_uri=${this.kakaoAuthRedirectUri}` +
      `&response_type=code` +
      `&state=${this.getNextPath(redirectUrl)}`;
    return url;
  }

  private static get googleClientId() {
    return '1015367477918-9t1vu26mfbco0374va82vt5l23epctan.apps.googleusercontent.com';
  }

  private static get googleClientSecret() {
    return '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';
  }

  static getGoogleLoginUrl(redirectUrl?: string) {
    const url =
      'https://accounts.google.com/o/oauth2/v2/auth' +
      '?scope=email profile openid' +
      '&access_type=offline' +
      '&include_granted_scopes=true' +
      '&response_type=code' +
      `&state=${this.getNextPath(redirectUrl)}` +
      `&redirect_uri=${this.googleAuthRedirectUri}` +
      `&client_id=${this.googleClientId}`;
    return url;
  }

  private static getTokenParamGoogle(code: string): IGoogleTokenRq {
    return {
      grant_type: 'authorization_code',
      client_id: this.googleClientId,
      client_secret: this.googleClientSecret,
      redirect_uri: this.googleAuthRedirectUri,
      code,
    };
  }

  private static async getKakaoToken(code: string) {
    const param = SocialLoginUtil.getTokenParamKakao(code);
    const { data } = await this.authRepo.getKakaoToken(new KaKaoTokenRq(param).requestParam);

    return data;
  }

  static async kakaoLogin(code: string) {
    try {
      //인가 코드로 카카오 토큰 받음
      const { access_token } = await this.getKakaoToken(code);
      if (!access_token) throw 'access_token is empty';

      const { data: loginRs } = await this.authRepo.kakaoLogin(access_token);
      const { accessToken, openId } = loginRs;
      if (accessToken) setAccessToken(accessToken);
      if (openId) setGAId(openId);

      return new SocicalLoginVO(loginRs);
      // 같은 code로 getKakaoToken를 호출하면 에러 발생. 첫 호출시에만 success.
      // catch처리를 해줘서 outh/callback페이지에서 else문을 타게 만듬.
    } catch (e: any) {
      return e;
    }
  }

  private static async getGoogleToken(code: string) {
    const param = SocialLoginUtil.getTokenParamGoogle(code);
    const { data } = await this.authRepo.getGoogleToken(new GoogleTokenRq(param).requestParam);
    return data;
  }

  static async googleLogin(code: string) {
    try {
      //인가 코드로 구글 토큰 받음
      const { access_token } = await this.getGoogleToken(code);
      if (!access_token) throw 'access_token is empty';

      const { data: loginRs } = await this.authRepo.googleLogin(access_token);
      const { accessToken, openId } = loginRs;
      if (accessToken) setAccessToken(accessToken);
      if (openId) setGAId(openId);
      return new SocicalLoginVO(loginRs);
    } catch (e: any) {
      return e;
    }
  }

  private static getAppleLoginConfig() {
    return {
      clientId: this.appleClientId,
      scope: 'name email',
      redirectURI: this.appleAuthRedirectUri,
      usePopup: true,
    };
  }

  static appleLoginInit() {
    if (isServer) return;
    const config = SocialLoginUtil.getAppleLoginConfig();
    window.AppleID.auth.init(config);
  }

  static async appleLogin(code: string) {
    try {
      const { data: loginRs } = await this.authRepo.appleLogin(code);
      const { accessToken, openId } = loginRs;
      if (accessToken) setAccessToken(accessToken);
      if (openId) setGAId(openId);
      return new SocicalLoginVO(loginRs);
    } catch (e: any) {
      return e;
    }
  }
}
