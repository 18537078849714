import { MutableRefObject, RefObject } from 'react';

export type BasicTarget<T = HTMLElement> = (() => T | null) | T | null | MutableRefObject<T | null | undefined>;
type TargetElement = HTMLElement | Element | Document | Window;

export default class ElementUtil {
  static getTargetElement(
    target?: BasicTarget<TargetElement>,
    defaultElement?: TargetElement,
  ): TargetElement | undefined | null {
    if (!target) {
      return defaultElement;
    }

    let targetElement: TargetElement | undefined | null;

    if (typeof target === 'function') {
      targetElement = target();
    } else if ('current' in target) {
      targetElement = target.current;
    } else {
      targetElement = target;
    }

    return targetElement;
  }

  static fade = (opacity: string, domRefs: RefObject<HTMLElement>[]) => {
    domRefs.forEach((domRefs) => {
      if (domRefs.current) {
        domRefs.current.style.opacity = opacity;
      }
    });
  };

  static fadeIn = (...domRefs: RefObject<HTMLElement>[]) => {
    ElementUtil.fade('1', domRefs);
  };

  static fadeOut = (...domRefs: RefObject<HTMLElement>[]) => {
    ElementUtil.fade('0', domRefs);
  };

  //팝업 좌표 구하는 함수
  static getPopupPosition = (
    //팝업이 띄워질 수 있는 영역의 ref
    containerRef: React.RefObject<Element>,
    //클릭 대상 정보
    clickItemInfo: { clickItemId: string; height: number },
    //클릭 대상을 감싼 element
    clickItemWrapId: string,
    //띄울 팝업의 정보
    popupInfo: { width: number; height: number },
  ) => {
    if (!containerRef || !containerRef.current) return null;
    const { width: calendarWidth, height: calendarHeight } = containerRef.current.getBoundingClientRect();
    const clickItemWrapWidth = document.getElementById(clickItemWrapId)?.clientWidth ?? 0;
    const clickItemEl = document.getElementById(clickItemInfo.clickItemId);
    const clickItemElWidth = clickItemEl?.offsetLeft ?? 0;
    const clickItemElHeight = (clickItemEl?.offsetTop ?? 0) + clickItemInfo.height;
    const x =
      clickItemElWidth + popupInfo.width < calendarWidth
        ? clickItemElWidth
        : clickItemElWidth - (popupInfo.width - clickItemWrapWidth);
    const y =
      clickItemElHeight + popupInfo.height < calendarHeight
        ? clickItemElHeight
        : clickItemElHeight - clickItemInfo.height - popupInfo.height;
    return { x, y };
  };
}
