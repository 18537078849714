import {
  MatchingHomeHiddenPositionDto,
  MatchingHomeHiddenPositionRs,
} from '@domain/rs/match/MatchingHomeHiddenPositionRs';
import { JobApplicationPositionType, jobApplicationPositionTextMap } from '@domain/constant/jobApplicationProcessType';

export class MatchingHomeHiddenPositionVO {
  hiddenPositions: MatchingHomeHiddenPositionItemVO[];

  constructor(rs: MatchingHomeHiddenPositionRs) {
    this.hiddenPositions = rs.hiddenPositions.map(
      (hiddenPosition) => new MatchingHomeHiddenPositionItemVO(hiddenPosition),
    );
  }
}

export class MatchingHomeHiddenPositionItemVO {
  positionType: JobApplicationPositionType;
  positionTypeText: string;
  positionSn: number;
  positionName: string;
  companySn: number;
  companyName: string;

  constructor(dto: MatchingHomeHiddenPositionDto) {
    this.positionType = dto.positionType;
    this.positionTypeText = jobApplicationPositionTextMap[dto.positionType];
    this.positionSn = dto.positionSn;
    this.positionName = dto.positionName;
    this.companySn = dto.companySn;
    this.companyName = dto.companyName;
  }
}
