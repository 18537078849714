import {
  BaseProfileProjectDto,
  BaseProfileProjectsDto,
  BaseProfileSkillDto,
} from '@domain/rs/insight/InsightResumeGetRs';
import { ReactSelectOption } from '@domain/constant/ReactSelectOption';
import { ProjectType } from '@domain/constant/profile/ProjectType';
import DateFormat from '@utils/date/format';

export default class ProjectFormVO {
  projects: BaseProfileProjectsVO[];

  constructor(dto: BaseProfileProjectsDto) {
    this.projects = dto.projects.map((item) => new BaseProfileProjectsVO(item));
  }
}

export class BaseProfileProjectsVO {
  title: string;
  startDate?: string;
  endDate?: string;
  teamType: ProjectType | null;
  contribution: ReactSelectOption<number> | null;
  performance: string;
  institution: string;
  skills: BaseProfileSkillDto[];

  constructor(dto: BaseProfileProjectDto) {
    this.title = dto.title ?? '';
    this.startDate = DateFormat.getDateFormatString(dto.startDate, 'yyyy-MM') ?? '';
    this.endDate = DateFormat.getDateFormatString(dto.endDate, 'yyyy-MM') ?? '';
    this.teamType = dto.teamType;
    this.contribution = dto.contribution ? { value: dto.contribution, label: `${dto.contribution}%` } : null;
    this.performance = dto.performance ?? '';
    this.institution = dto.institution ?? '';
    this.skills = dto.skills;
  }

  static convertRqFormVO(vo: BaseProfileProjectsVO): BaseProfileProjectDto {
    return {
      title: vo.title,
      startDate: vo.startDate,
      endDate: vo.endDate,
      teamType: vo.teamType,
      contribution: vo.contribution?.value ?? null,
      performance: vo.performance,
      institution: vo.institution,
      skills: vo.skills,
    } as BaseProfileProjectDto;
  }
}
