import { DataLogRq, SourcePage } from '@domain/rq/DataLog';
import ProfileSkillVO, { LicenseVO, SkillVO, ExaminationVO } from '@domain/vo/profile/ProfileSkillVO';
import { SkillLevelType } from '@domain/constant/profile/SkillType';

export default class ProfileCareerSaveRq {
  licenses: LicensesRq[];
  examinations: ExaminationRq[];
  skills: SkillRq[];
  sourcePage?: SourcePage;

  constructor(vo: ProfileSkillVO & DataLogRq) {
    this.licenses = vo.licenses?.map((item) => new LicensesRq(item)) ?? [];
    this.examinations = vo.examinations?.map((item) => new ExaminationRq(item)) ?? [];
    this.skills = vo.skills?.map((item) => new SkillRq(item)) ?? [];
    this.sourcePage = vo.sourcePage;
  }
}

class LicensesRq {
  code: number;

  constructor(vo: LicenseVO) {
    this.code = vo.code;
  }
}

class ExaminationRq {
  examinationCode: number;
  score: number | null;
  examinationGradeCode: number | null;

  constructor(vo: ExaminationVO) {
    this.examinationCode = vo.code;
    this.score = vo.score ?? null;
    this.examinationGradeCode = vo.gradeCode ?? null;
  }
}

class SkillRq {
  code: number;
  level: SkillLevelType;

  constructor(vo: SkillVO) {
    this.code = vo.code;
    this.level = vo.level;
  }
}
