import TextFormat from '@utils/text/format';

export default class VerificationSmsSendIdRq {
  countryCode: string;
  mobile: string | number;
  birthDate: string;

  constructor(rq: VerificationSmsSendIdRq) {
    this.countryCode = rq.countryCode;
    this.mobile = rq.mobile;
    this.birthDate = TextFormat.birthDate(rq.birthDate);
  }
}
