import { useEffect, useRef, useState } from 'react';

export type TransitionStatus = 'entered' | 'exited' | 'entering' | 'exiting' | 'pre-exiting' | 'pre-entering';

const useTransitionStatus = (visible: boolean, duration = 0) => {
  const [status, setStatus] = useState<TransitionStatus>(visible ? 'entered' : 'exited');
  const timeoutRef = useRef<number>(-1);

  useEffect(() => {
    setStatus(visible ? 'pre-entering' : 'pre-exiting');

    const preTimeout = window.setTimeout(() => {
      setStatus(visible ? 'entering' : 'exiting');
    }, 10);

    timeoutRef.current = window.setTimeout(() => {
      window.clearTimeout(preTimeout);
      setStatus(visible ? 'entered' : 'exited');
    }, duration);

    return () => clearTimeout(timeoutRef.current);
  }, [visible, duration]);

  return status;
};

export default useTransitionStatus;
