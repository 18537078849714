import { AxiosError } from 'axios';
import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import { useToast } from '@common/toast';
import { RemoteUsersRepo } from '@repository/users/UsersRepo';
import { MarketingInfoModifyRq } from '@domain/rq/users/MarketingInfoModifyRq';
import UsersIdRq from '@domain/rq/users/UsersIdRq';
import AuthFindFormField from '@domain/rq/users/AuthFindFormField';
import UsersPasswordVerificationRq from '@domain/rq/users/UsersPasswordVerificationRq';
import UserPasswordResetRq from '@domain/rq/users/UsersPasswordResetRq';
import { UserBirthDateUpdateRq } from '@domain/rq/users/UserBirthDateUpdateRq';
import { marketingInfoItem } from '@domain/constant/userType';

const remoteUsersRepo = new RemoteUsersRepo();

export const keys = ['user'];
export const marketingInfoKeys = [...keys, 'marketingInfo'];

const fetchBasicInfo = async () => {
  const { data } = await remoteUsersRepo.fetchBasicInfo();
  return data;
};

const usersIdExist = async (id: string) => {
  const { data } = await remoteUsersRepo.usersIdExist(id);
  return data;
};

const fetchUsersId = async (form: AuthFindFormField) => {
  const rq = new UsersIdRq(form);
  const { data } = await remoteUsersRepo.usersId(rq);
  return data;
};

const fetchUsersPasswordVerification = async (form: AuthFindFormField) => {
  const rq = new UsersPasswordVerificationRq(form);
  const { data } = await remoteUsersRepo.usersPasswordVerification(rq);
  return data;
};

const fetchUsersPasswordReset = async (form: AuthFindFormField) => {
  const rq = new UserPasswordResetRq(form);
  const { data } = await remoteUsersRepo.usersPasswordReset(rq);
  return data;
};

const fetchMarketingInfo = async () => {
  const { data } = await remoteUsersRepo.fetchMarketingInfo();
  return data;
};

const fetchUpdateMarketingInfo = async (rq: MarketingInfoModifyRq) => {
  const { data } = await remoteUsersRepo.fetchModifyMarketingInfo(rq);
  return data;
};

const fetchUsersBirthDate = async (rq: UserBirthDateUpdateRq) => {
  const { data } = await remoteUsersRepo.fetchUsersBirthDate(rq);
  return data;
};

//sms, email 마케팅 수신 동의 처리
const fetchUpdateMarketingInfoEmailSnsAgree = async () => {
  await remoteUsersRepo.fetchModifyMarketingInfo({
    type: marketingInfoItem.SMS,
    useYn: true,
  });

  await remoteUsersRepo.fetchModifyMarketingInfo({
    type: marketingInfoItem.EMAIL,
    useYn: true,
  });

  return;
};

const fetchNotificationProfileRemind = async () => {
  const { data } = await remoteUsersRepo.fetchNotificationProfileRemind();
  return data;
};

const fetchUsersWorkLogCounts = async () => {
  const { data } = await remoteUsersRepo.fetchUsersWorkLogCounts();
  return data;
};

export function useBasicInfo() {
  return useQuery([...keys, 'basic'], fetchBasicInfo);
}

export function useUsersIdExist() {
  return useMutation(usersIdExist);
}

export function useUsersId() {
  return useMutation(fetchUsersId);
}

export function useUsersPasswordVerification() {
  return useMutation(fetchUsersPasswordVerification);
}

export function useUsersPasswordReset() {
  const Toast = useToast();
  return useMutation(fetchUsersPasswordReset, {
    onError: (err: AxiosError) => {
      const message = err?.response?.data.message ?? '저장 실패하였습니다.';
      Toast({ type: 'error', iconType: 'info', content: message });
    },
  });
}

export function useUpdateMarketingInfo(onSuccess?: () => void, onError?: (err: AxiosError) => void) {
  return useMutation((rq: MarketingInfoModifyRq) => fetchUpdateMarketingInfo(rq), {
    onError,
    onSuccess,
  });
}

export function useUpdateMarketingInfoEmailSns(options?: UseQueryOptions) {
  return useMutation(() => fetchUpdateMarketingInfoEmailSnsAgree(), options as any);
}

export function useMarketingInfo(enabled = true, onError?: (err: AxiosError) => void) {
  return useQuery(marketingInfoKeys, fetchMarketingInfo, {
    enabled,
    onError,
    refetchOnWindowFocus: true,
  });
}

export function useNotificationProfileRemind() {
  return useMutation(fetchNotificationProfileRemind);
}

export function useUsersWorkLogCounts() {
  return useQuery([...keys, 'workLogCounts'], fetchUsersWorkLogCounts);
}

export const useUsersBirthDate = () => {
  return useMutation(fetchUsersBirthDate);
};

const fetchSavePassword = async (passwords: { currentPassword: string; newPassword: string }) => {
  const { data } = await remoteUsersRepo.fetchSavePassword(passwords.currentPassword, passwords.newPassword);
  return data;
};

export const useSavePassword = () => {
  return useMutation(fetchSavePassword);
};

export function useRefreshPasswordExpiration() {
  return useMutation(async () => {
    await remoteUsersRepo.refreshPasswordExpiration();
  });
}

const deleteAccount = async () => {
  const { data } = await remoteUsersRepo.deleteAccount();
  return data;
};

export const useDeleteAccount = () => {
  return useMutation(deleteAccount);
};
