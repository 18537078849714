import {
  MatchingChallengePositionDto,
  MatchingChallengePositionListRs,
} from '@domain/rs/challenge/MatchingChallengePositionListRs';
import { ChallengeRecruitmentMap } from '@domain/constant/challenge/challengeRecruitmentType';
import { JobApplicationProcessType } from '@domain/constant/jobApplicationProcessType';
import NumberFormat from '@utils/number/format';

export class MatchingChallengePositionVO {
  positionSn: number;
  positionName: string;
  recruitmentType: string;
  minSalary: number | null;
  closingDateTime: string;
  companyName: string;
  location: string;
  jobTitleName: string;
  jobApplicationProcessType: JobApplicationProcessType | null;

  constructor(dto: MatchingChallengePositionDto) {
    this.positionSn = dto.positionSn;
    this.positionName = dto.positionName;
    this.recruitmentType = ChallengeRecruitmentMap[dto.recruitmentType];
    this.minSalary = dto.minSalary;
    this.closingDateTime = dto.closingDateTime;
    this.companyName = dto.companyName;
    this.location = NumberFormat.formatLocationName(dto.locationCode);
    this.jobTitleName =
      dto.jobTitleNames.length > 1 ? `${dto.jobTitleNames[0]} +${dto.jobTitleNames.length - 1}` : dto.jobTitleNames[0];
    this.jobApplicationProcessType = dto.jobApplicationProcessType;
  }
}

export default class MatchingChallengePositionListVO {
  challengePositions: MatchingChallengePositionVO[];

  constructor(rs: MatchingChallengePositionListRs) {
    this.challengePositions = rs.challengePositions.map(
      (challengePosition) => new MatchingChallengePositionVO(challengePosition),
    );
  }
}
