import TextFormat from '@utils/text/format';

export default class VerificationSmsSendRq {
  name: string;
  countryCode: string;
  mobile: string;
  birthDate: string;

  constructor(rq: VerificationSmsSendRq) {
    this.name = rq.name;
    this.countryCode = rq.countryCode;
    this.mobile = rq.mobile;
    this.birthDate = TextFormat.birthDate(rq.birthDate);
  }
}
