import React, { FC } from 'react';
import Icon from '@common/assets';
import { PreferTabType } from '@components/profile/profileOverlay/overlays/prefer/index';
import Colors from '@domain/constant/colors';

import classnames from 'classnames/bind';
import styles from '../prefer.module.scss';

const cx = classnames.bind(styles);

const getPreferTitle = (selectedTabType: PreferTabType) => {
  switch (selectedTabType) {
    case null:
      return (
        <>
          <strong className={cx('overlayTitle')}>선호하는 근무 조건을 알려주세요.</strong>
          <p className={cx('desc')}>
            <Icon name="informationLight" width={20} height={20} fill={Colors.C_COOL_GRAY_70} />
            구체적으로 작성해주시면 딱 맞는 포지션을 제안해드릴게요.
          </p>
        </>
      );
    case 'jobGroup':
      return (
        <>
          <strong className={cx('overlayTitle')}>희망하는 직군 · 직무를 모두 선택해 주세요.</strong>
          <p className={cx('desc')}>
            <Icon name="informationLight" width={20} height={20} fill={Colors.C_COOL_GRAY_70} />
            직군·직무 선택에 따라 추천 포지션이 달라져요.
          </p>
        </>
      );
    case 'location':
      return (
        <>
          <strong className={cx('overlayTitle')}>희망하는 근무 지역을 모두 선택해 주세요.</strong>
          <p className={cx('desc')}>
            <Icon name="informationLight" width={20} height={20} fill={Colors.C_COOL_GRAY_70} />
            다양한 지역을 선택할수록 더 많은 추천을 받을 수 있어요.
          </p>
        </>
      );
    case 'salary':
      return (
        <>
          <strong className={cx('overlayTitle')}>선호하는 연봉 수준을 알려주세요.</strong>
          <p className={cx('desc')}>
            <Icon name="informationLight" width={20} height={20} fill={Colors.C_COOL_GRAY_70} />
            인사 담당자가 근무 조건 조율 시 가장 중요하게 보는 항목이에요.
          </p>
        </>
      );
    case 'welfare':
      return (
        <>
          <strong className={cx('overlayTitle')}>선호하는 복지 키워드를 선택해 주세요.</strong>
          <p className={cx('desc')}>
            <Icon name="informationLight" width={20} height={20} fill={Colors.C_COOL_GRAY_70} />
            선택한 키워드로 딱 맞는 복지를 가진 회사를 추천해드릴게요.
          </p>
        </>
      );
    case 'working_preference':
      return (
        <>
          <strong className={cx('overlayTitle')}>희망하는 근무 조건을 자유롭게 입력해 주세요.</strong>
          <p className={cx('desc')}>
            <Icon name="informationLight" width={20} height={20} fill={Colors.C_COOL_GRAY_70} />
            조건을 상세히 입력할수록 더 정확한 공고와 포지션을 추천해 드릴게요.
          </p>
        </>
      );
    default:
      return null;
  }
};

interface IPreferTabTitle {
  selectedTabType: PreferTabType;
}

const PreferTabTitle: FC<IPreferTabTitle> = ({ selectedTabType }) => {
  return <>{getPreferTitle(selectedTabType)}</>;
};

export default PreferTabTitle;
