import { useMutation, useQuery } from 'react-query';
import { RemoteProfilesRepo } from '@repository/profiles/ProfilesRepo';
import ProfileExperienceSaveRq from '@domain/rq/profile/ProfileExperienceSaveRq';
import ProfileExperienceVO from '@domain/vo/profile/ProfileExperienceVO';
import ProfileExperienceFormVO from '@domain/vo/profile/ProfileExperienceFormVO';
// import MockProfileRepo from "@repository/mock/MockProfileRepo";

export const keys = ['profile', 'experience'];
export const viewKeys = [...keys, 'view'];

const remoteProfilesRepo = new RemoteProfilesRepo();
// const profileRepo = new MockProfileRepo();

const fetchProfileExperience = async () => {
  const { data } = await remoteProfilesRepo.fetchProfileExperience();
  return new ProfileExperienceVO(data);
};

const fetchSaveProfileExperience = async (vo: ProfileExperienceFormVO) => {
  const rq = new ProfileExperienceSaveRq(vo);
  const { data } = await remoteProfilesRepo.fetchSaveProfileExperience(rq);
  return data;
};

export default function useProfileExperience(isEnabled: boolean) {
  return useQuery(viewKeys, fetchProfileExperience, {
    enabled: isEnabled,
  });
}

export const useSaveProfileExperience = () => {
  return useMutation((vo: ProfileExperienceFormVO) => fetchSaveProfileExperience(vo));
};
