import React, { createContext } from 'react';
import useLogging from './useLogging';

interface LoggingContextProps {
  handleLoggingNewTab: (event: MouseEvent, newLink?: string) => void;
}

const LoggingContext = createContext<LoggingContextProps>({
  handleLoggingNewTab: () => {},
});

export const useLoggingContext = () => {
  const context = React.useContext(LoggingContext);
  if (!context) {
    throw new Error('LoggingProvider 내부에서 사용되어야 합니다.');
  }
  return context;
};

export const LoggingProvider = ({ children }: { children: React.ReactNode }) => {
  const { handleClick: handleLoggingNewTab } = useLogging(); //로깅

  return <LoggingContext.Provider value={{ handleLoggingNewTab }}>{children}</LoggingContext.Provider>;
};
