import { Descendant } from 'slate';
import CompanyInfoRs, { CompanyDetailDto, CompanyImagesDto } from '@domain/rs/company/CompanyInfoRs';
import { businessSizeTextMap, BusinessSizeType } from '@domain/constant/businessSize';
import NumberFormat from '@utils/number/format';
import DateFormat from '@utils/date/format';
import TextFormat from '@utils/text/format';

export default class CompanyInfoVO {
  companySn: number;
  companyName: string;
  homepageUrl: string;
  vision: string; // 한 줄 소개
  companyDetail: CompanyDetailVO;
  companyImages: CompanyImagesVO[]; // 기업 소개 사진 목록
  introduce: Descendant[]; // 기업 소개
  lastModifiedDate: Date; // 업데이트 일자
  lastModifiedDateText: string; // 업데이트 일자
  logoImageUrl: string; // 로고 이미지 url
  pcBannerImageUrl: string; // pc 배너 이미지 url
  moBannerImageUrl: string; // mo 배너 이미지 url}

  constructor(rs: CompanyInfoRs) {
    this.companySn = rs.companySn;
    this.companyName = rs.companyName;
    this.homepageUrl = rs.homepageUrl ? TextFormat.formatProtocol(rs.homepageUrl) : '';
    this.vision = rs.vision;
    this.companyDetail = new CompanyDetailVO(rs.companyDetailDto);
    this.companyImages = rs.images.map((item) => new CompanyImagesVO(item));
    this.introduce = this.setJobDescription(rs.introduce);
    this.lastModifiedDate = rs.lastModifiedDate ? new Date(rs.lastModifiedDate) : new Date();
    this.lastModifiedDateText = this.getDateFormat(rs.lastModifiedDate);
    this.logoImageUrl = rs.logoImageUrl;
    this.pcBannerImageUrl = rs.pcBannerImageUrl;
    this.moBannerImageUrl = rs.moBannerImageUrl;
  }

  setJobDescription(data: string | null): Descendant[] {
    try {
      if (!data) return [];
      return JSON.parse(data);
    } catch (e) {
      return [];
    }
  }

  getDateFormat(data: string | null) {
    return data ? `업데이트 일자: ${DateFormat.format(new Date(data), 'yyyy.MM.dd')}` : '';
  }
}

export class CompanyDetailVO {
  ceoName: string; // 대표자명
  industryAverageSalary: number; // 동종 업계 평균 연봉
  industryAverageSalaryText: string;
  averageSalary: number; // 전체 종업원 평균 연봉
  averageSalaryText: string;
  numberOfEmployees: number; // 전체 직원 수
  numberOfEmployeesText: string;
  numberOfNewEmployees: number; // 작년 입사자 수
  numberOfNewEmployeesText: string;
  numberOfTerminateEmployees: number; //작년 퇴사자 수
  numberOfTerminateEmployeesText: string;
  averageWorkingYears: number; // 평균 근속연수
  averageWorkingYearsText: string; // 평균 근속연수
  businessSize: BusinessSizeType; // 기업 규모
  businessSizeText: string;
  revenue: number; // 매출액
  revenueText: string;
  profit: number; // 영업 이익
  profitText: string;
  establishDate: string; // 설립일
  location: string | null; // 주소
  industryName: string;

  constructor(rs: CompanyDetailDto) {
    this.ceoName = rs.ceoName ? rs.ceoName : '-';
    this.industryAverageSalary = rs.industryAverageSalary;
    this.industryAverageSalaryText = rs.industryAverageSalary
      ? `${this.getSalaryText(rs.industryAverageSalary, 10000)}원`
      : this.getSalaryText(rs.industryAverageSalary, 10000);
    this.averageSalary = rs.averageSalary;
    this.averageSalaryText = rs.averageSalary
      ? `${this.getSalaryText(rs.averageSalary, 10000)}원`
      : this.getSalaryText(rs.averageSalary, 10000);
    this.numberOfEmployees = rs.numberOfEmployees;
    this.numberOfEmployeesText = rs.numberOfEmployees ? `${NumberFormat.formatDecimal(rs.numberOfEmployees)}명` : '-';
    this.numberOfNewEmployees = rs.numberOfNewEmployees;
    this.numberOfNewEmployeesText = rs.numberOfNewEmployees
      ? `${NumberFormat.formatDecimal(rs.numberOfNewEmployees)}명`
      : '-';
    this.numberOfTerminateEmployees = rs.numberOfTerminateEmployees;
    this.numberOfTerminateEmployeesText = rs.numberOfTerminateEmployees
      ? `${NumberFormat.formatDecimal(rs.numberOfTerminateEmployees)}명`
      : '-';
    this.averageWorkingYears = rs.averageWorkingYears;
    this.averageWorkingYearsText = rs.averageWorkingYears
      ? `${NumberFormat.formatDecimal(rs.averageWorkingYears)}년`
      : '-';
    this.businessSize = rs.businessSize;
    this.businessSizeText = rs.businessSize ? businessSizeTextMap[rs.businessSize] : '-';
    this.revenue = rs.revenue;
    this.revenueText = NumberFormat.formatCompanyRevenueAndProfitText(rs.revenue);
    this.profit = rs.profit;
    this.profitText = NumberFormat.formatCompanyRevenueAndProfitText(rs.profit);
    this.establishDate = rs.establishDate ? rs.establishDate : '-';
    this.location = rs.location ? rs.location : null;
    this.industryName = rs.industryName ? rs.industryName : '-';
  }

  getSalaryText(salary: number | null, unit = 1) {
    if (salary === null || typeof salary !== 'number') return '-';
    const parserSalary = Math.trunc(Math.abs(salary) / unit) * unit; //unit 만큼 0으로 채움
    const formattedSalary = NumberFormat.formatDecimal(NumberFormat.format(parserSalary, 'ALL', ' '));

    return salary < 0 ? '-' + formattedSalary : formattedSalary;
  }
}

export class CompanyImagesVO {
  caption: string; // 제목
  description: string; // 설명
  fileUrl: string; // 파일 url

  constructor(rs: CompanyImagesDto) {
    this.caption = rs.caption;
    this.description = rs.description;
    this.fileUrl = rs.fileUrl;
  }
}
