import React from 'react';
import { useController } from 'react-hook-form';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const maxLength = 10000;

const Performance = ({ prefixName }: HookFormProps) => {
  const { field, fieldState } = useController({ name: `${prefixName}.performance` });

  const length = field.value?.length ?? 0;

  return (
    <div className={cx('textareaWrap')}>
      <textarea
        {...field}
        placeholder="프로젝트 진행 시 담당했던 업무와 성과를 입력해 주세요."
        maxLength={maxLength}
        rows={4}
      />
      <div className={cx('infoText')}>
        최대 글자수 : <span className={cx('value')}>{length}</span> / {maxLength}
        {fieldState.invalid && <div className={cx('errorMessage')}>{fieldState.error?.message}</div>}
      </div>
    </div>
  );
};

export default Performance;
