import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Text from '@components/common/text';
import { useToast } from '@components/common/toast';
import ExitModal from '@components/profile/profileOverlay/exitModal';
import Education from './education';
import Career from './career';
import Project from './project';
import ForeignExperience from './experience/ForeignExperience';
import Experience from './experience';
import Prize from './prize';
import { CheckListType, EditViewType } from '..';
import { InsightResumeGetFormVO } from '@domain/vo/insight/resume/InsightResumeGetFormVO';
import { FontStyle } from '@domain/constant/fontStyle';
import classnames from 'classnames/bind';
import styles from './commonEdit.module.scss';
const cx = classnames.bind(styles);

interface EditViewProps {
  addCheckList: (type: CheckListType, index: number) => void;
  viewData: Map<EditViewType, string>;
  latestResumeData: InsightResumeGetFormVO | null;
  setLatestResumeData: (data: InsightResumeGetFormVO) => void;
  onPrev: () => void;
}

const EditView: FC<EditViewProps> = ({ addCheckList, viewData, latestResumeData, setLatestResumeData, onPrev }) => {
  const Toast = useToast();
  const [visibleExitModal, setVisibleExitModal] = useState(false);
  const { getFieldState, getValues, reset, trigger, setFocus } = useFormContext();
  const type = viewData.keys().next().value;
  const prefixName = viewData.get(type) ?? '';
  const fieldIndex = prefixName.split('.').pop() ?? '';

  const getItems = () => {
    switch (type) {
      case 'education':
        return {
          title: '학력',
          subTitle: '학력 정보는 기업에 따라 선택적으로 활용돼요.',
          contents: <Education prefixName={prefixName} />,
        };
      case 'career':
        return {
          title: '경력',
          subTitle: '업무 성과를 나타낼 수 있는 구체적인 수치, 사실을 포함해 주세요.',
          contents: <Career prefixName={prefixName} />,
        };
      case 'project':
        return {
          title: '프로젝트',
          subTitle: '자신이 했던 역할과 문제 해결 방식을 상세히 작성해 주세요.',
          contents: <Project prefixName={prefixName} />,
        };
      case 'experience':
        return {
          title: '경험',
          subTitle: '경험을 통해 얻은 결과와 배운 점을 구체적으로 작성해주세요.',
          contents: <Experience prefixName={prefixName} />,
        };
      case 'foreign_experience':
        return {
          title: '경험',
          subTitle: '경험을 통해 얻은 결과와 배운 점을 구체적으로 작성해주세요.',
          contents: <ForeignExperience prefixName={prefixName} />,
        };
      case 'prize':
        return {
          title: '수상 내역',
          subTitle: '나의 성과를 결과 중심으로 간결하게 작성해 보세요.',
          contents: <Prize prefixName={prefixName} />,
        };
      default:
        return { title: '', subTitle: '', contents: <></> };
    }
  };

  const { title, subTitle, contents } = getItems();

  const handlePrevBtn = async () => {
    await trigger();

    if (getFieldState(prefixName)?.isDirty) {
      setVisibleExitModal(true);
      return;
    }

    onPrev();
  };

  //error 필드 이름 찾는 함수
  function findFirstKeyOrNested(data: any): string | null {
    function findKey(input: any, parentPath: string[] = []): string | null {
      if (typeof input !== 'object' || input === null || Array.isArray(input)) {
        return parentPath.join('.');
      }

      const keys = Object.keys(input);
      const relevantKeys = keys.filter((key) => !['message', 'type', 'ref'].includes(key));

      if (relevantKeys.length === 0) {
        return parentPath.join('.');
      }

      const firstKey = relevantKeys[0];
      const newPath = [...parentPath, firstKey];

      const value = input[firstKey];

      if (typeof value === 'object' && value !== null && !Array.isArray(value) && Object.keys(value).length > 0) {
        return findKey(value, newPath);
      } else {
        return newPath.join('.');
      }
    }

    const result = findKey(data);
    return result ? result.replace(/\.$/, '') : null;
  }

  const handleConfirmBtn = () => {
    if (getFieldState(prefixName)?.invalid) {
      const errorFieldName = findFirstKeyOrNested(getFieldState(prefixName)?.error);
      setFocus(`${prefixName}.${errorFieldName}`);
      return Toast({ type: 'error', iconType: 'info', content: '필수 입력 항목을 확인해주세요.' });
    }

    if (['education', 'career', 'project', 'foreign_experience', 'experience', 'prize'].includes(type)) {
      if (type === 'foreign_experience') {
        addCheckList('experience', Number(fieldIndex));
      } else addCheckList(type, Number(fieldIndex));
      Toast({ type: 'success', iconType: 'check', content: '성공적으로 저장되었습니다.' });
    }

    const newData = JSON.parse(JSON.stringify(getValues()));
    setLatestResumeData(newData);
    onPrev();
  };

  const handleExitBtn = async () => {
    const newData = JSON.parse(JSON.stringify(latestResumeData));
    reset(newData as Partial<InsightResumeGetFormVO>);
    await trigger();
    onPrev();
  };

  return (
    <>
      <div className={cx('stepWrap')}>
        <div>
          <div>
            <Text className={cx('editViewTitle')} fontStyle={FontStyle.H4_B}>
              {title}
            </Text>
            <Text className={cx('subTitle')} fontStyle={FontStyle.H5_B}>
              {subTitle}
            </Text>
          </div>

          <div className={cx('scrollArea')}>
            <div className={cx('scrollInner')}>
              <div className={cx('contents')}>{contents}</div>
            </div>
          </div>
        </div>

        <div className={cx('btnArea')}>
          <button className={cx('prev')} type="button" onClick={handlePrevBtn}>
            이전
          </button>
          <button className={cx('btn', 'confirm')} type="button" onClick={handleConfirmBtn}>
            저장
          </button>
        </div>
      </div>
      <ExitModal visible={visibleExitModal} onClose={() => setVisibleExitModal(false)} onExit={handleExitBtn} />
    </>
  );
};

export default EditView;
