import { AxiosError } from 'axios';
import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import { useToast } from '@components/common/toast';
import { RemoteMatchApplicationRepo } from './MatchApplicationRepo';
import AccountLinkRq, { IAccountLinkRq } from '@domain/rq/accountLink/AccountLinkRq';
import { SubmitApplyRq } from '@domain/rq/position/jd/SubmitApplyRq';
import MatchingApplicationApplyRq from '@domain/rq/match/MatchingApplicationApplyRq';
import { MatchApplicationInterviewRq } from '@domain/rq/match/application/MatchApplicationRq';
import MatchingRejectRq from '@domain/rq/match/MatchingRejectRq';
import MatchingAdjustRq from '@domain/rq/match/application/MatchingAdjustRq';
import MatchApplicationCertificationRq from '@domain/rq/match/MatchApplicationCertificationRq';
import MatchingRequirementVO from '@domain/vo/match/MatchingRequirementVO';
import ResumeAdditionInfoVO from '@domain/vo/match/ResumeAdditionInfoVO';
import FileUtil from '@utils/fileUtil';

const keys = ['match', 'application'];

const remoteMatchApplicationRepo = new RemoteMatchApplicationRepo();

const fetchCheckWritingResume = async (formData: IAccountLinkRq) => {
  const rq = new AccountLinkRq(formData);
  const { data } = await remoteMatchApplicationRepo.fetchCheckWritingResume(rq);
  return data;
};

const fetchRemoveResume = async (positionSn: number) => {
  return await remoteMatchApplicationRepo.fetchRemoveResume(positionSn);
};

const fetchCheckConsent = async (positionSn: number) => {
  const { data } = await remoteMatchApplicationRepo.fetchCheckConsent(positionSn);
  return data;
};

const fetchCancelMatchPosition = async (positionSn: number) => {
  return await remoteMatchApplicationRepo.fetchCancelMatchPosition(positionSn);
};

const fetchProcessingConsent = async (positionSn: number) => {
  return await remoteMatchApplicationRepo.fetchProcessingConsent(positionSn);
};

const fetchAgreeUrl = async (positionSn: number) => {
  const { data } = await remoteMatchApplicationRepo.fetchAgreeUrl(positionSn);
  return data;
};

const fetchResumeNumber = async (positionSn: number | null) => {
  if (!positionSn) return;

  const { data } = await remoteMatchApplicationRepo.fetchResumeNumber(positionSn);
  return data;
};

const fetchApplyVerification = async (positionSn: number) => {
  const { data } = await remoteMatchApplicationRepo.fetchApplyVerification(positionSn);
  return data;
};

const fetchSubmitApply = async (sns: SubmitApplyRq) => {
  const { data } = await remoteMatchApplicationRepo.fetchSubmitApply(sns.positionSn, sns.optionSn, sns.applySn);
  return data;
};

export const fetchRequiredItems = async (positionSn: number) => {
  const { data } = await remoteMatchApplicationRepo.fetchRequiredItems(positionSn);
  return new MatchingRequirementVO(data);
};

const fetchApplyPosition = async (rq: MatchingApplicationApplyRq) => {
  const { data } = await remoteMatchApplicationRepo.applyPosition(rq);
  return data;
};

const fetchAdditionalItems = async (positionSn: number) => {
  const { data } = await remoteMatchApplicationRepo.fetchAdditionalItems(positionSn);
  return new ResumeAdditionInfoVO(data);
};

const acceptInterview = async (rq: MatchApplicationInterviewRq) => {
  await remoteMatchApplicationRepo.acceptInterview(rq);
};

const rejectInterview = async (rq: MatchingRejectRq) => {
  await remoteMatchApplicationRepo.rejectInterview(rq);
};

const uploadApplicationFiles = async ({
  positionSn,
  optionSn,
  files,
}: {
  positionSn: number;
  optionSn: number;
  files: File[];
}) => {
  await remoteMatchApplicationRepo.uploadApplicationFiles(positionSn, optionSn, files);
};

const downloadApplicationFiles = async ({
  fileName,
  positionSn,
  fileUid,
}: {
  fileName: string;
  positionSn: number;
  fileUid: string;
}) => {
  const { data } = await remoteMatchApplicationRepo.downloadApplicationFiles(positionSn, fileUid);
  FileUtil.fileDownload(fileName, data);
};

const fetchApplicationInterviewAdjusted = async (rq: MatchingAdjustRq) => {
  await remoteMatchApplicationRepo.fetchApplicationInterviewAdjusted(rq);
};

// 입사 지원 취소
export const useCancelApplication = (onSuccess: () => void, onError: (e: AxiosError) => void) => {
  return useMutation((positionSn: number) => remoteMatchApplicationRepo.fetchCancelApplication(positionSn), {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// 작성중 지원서 있는지 체크
function useCheckWritingResume(rq: IAccountLinkRq) {
  return useQuery([...keys, 'check', rq], () => fetchCheckWritingResume(rq), {
    enabled: !!rq.publicKey && !!rq.positionId,
  });
}

// 작성 중인 지원서 삭제
function useDeleteResume(onSuccess: () => void, onError: (e: AxiosError) => void) {
  return useMutation((positionSn: number) => fetchRemoveResume(positionSn), {
    onSuccess: onSuccess,
    onError: (e: AxiosError) => onError(e),
  });
}

// 지원서 취소 전 동의 여부
function useGetCheckConsent(onSuccess?: (result: boolean) => void) {
  const Toast = useToast();
  return useMutation((positionSn: number) => fetchCheckConsent(positionSn), {
    onSuccess: (result: boolean) => onSuccess?.(result),
    onError: (err: AxiosError) => {
      switch (err?.response?.data?.errorCode) {
        case 'B901':
          Toast({ type: 'error', iconType: 'info', content: '채용 전형이 종료되어 삭제된 지원서입니다.' });
          return;
        default:
          Toast({ type: 'error', iconType: 'info', content: err.response?.data.message });
          return;
      }
    },
  });
}

// 매칭 포지션 입사취소
function useCancelMatchPosition(onSuccess: () => void, onError: (e: AxiosError) => void) {
  return useMutation((positionSn: number) => fetchCancelMatchPosition(positionSn), {
    onSuccess,
    onError,
  });
}

// 지원서 저장 동의
function usePostProcessingConsent(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((positionSn: number) => fetchProcessingConsent(positionSn), {
    onSuccess,
    onError,
  });
}

// 동의문 자세히 보기
function useGetAgreeUrl(positionSn: number, isEnabled: boolean) {
  return useQuery([...keys, positionSn, 'agreeUrl'], () => fetchAgreeUrl(positionSn), {
    enabled: isEnabled,
  });
}

// 포지션에 제출 한 지원서 번호 조회
function useGetResumeNumber() {
  return useMutation((positionSn: number) => fetchResumeNumber(positionSn));
}

// 입사 지원 조건 검증
function useApplyVerification(onSuccess: () => void, onError: (err: AxiosError) => void) {
  return useMutation((positionSn: number) => fetchApplyVerification(positionSn), {
    onSuccess,
    onError,
  });
}

// 응시 결과 제출
function useSubmitApply(onSuccess?: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((sns: SubmitApplyRq) => fetchSubmitApply(sns), {
    onSuccess: onSuccess,
    onError: onError,
  });
}

// 포지션 필수값 검증
function useRequiredItems(positionSn: number, { enabled = true } = {}) {
  return useQuery([...keys, positionSn, 'requiredItems'], () => fetchRequiredItems(positionSn), {
    enabled,
    refetchOnWindowFocus: true,
  });
}

// 입사 지원 (프로필)
function useApplyPosition({ onSuccess, onError }: { onSuccess?: () => void; onError?: (e: AxiosError) => void } = {}) {
  return useMutation((rq: MatchingApplicationApplyRq) => fetchApplyPosition(rq), {
    onSuccess,
    onError,
  });
}

// 포지션 추가 정보 항목 조회
function useAdditionalItems(
  positionSn: number,
  {
    enabled,
    onError,
    onSuccess,
  }: { enabled?: boolean; onSuccess?: () => void; onError?: (e: AxiosError) => void } = {},
) {
  return useQuery([...keys, positionSn, 'additionalItems'], () => fetchAdditionalItems(positionSn), {
    enabled: enabled ?? !!positionSn,
    onSuccess,
    onError,
  });
}

// 매칭 포지션 면접 제안 수락
function useAcceptInterview(onSuccess?: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq: MatchApplicationInterviewRq) => acceptInterview(rq), {
    onSuccess,
    onError,
  });
}

// 매칭 포지션 면접 제안 거절
function useRejectInterview(onSuccess?: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq: MatchingRejectRq) => rejectInterview(rq), {
    onSuccess,
    onError,
  });
}

// 추가 서류 제출 파일 업로드
function useUploadApplicationFiles(options?: UseQueryOptions) {
  return useMutation(uploadApplicationFiles, options as any);
}

// 추가 서류 제출 파일 다운로드
function useDownloadApplicationFiles(options?: UseQueryOptions) {
  return useMutation(downloadApplicationFiles, options as any);
}

// 면접 일정 조정
function useInterviewAdjusted(options?: UseQueryOptions) {
  return useMutation(fetchApplicationInterviewAdjusted, options as any);
}

const fetchApplicationCertification = async (rq: MatchApplicationCertificationRq) => {
  const { data } = await remoteMatchApplicationRepo.fetchApplicationCertification(rq);
  return data;
};

function useApplicationCertification() {
  return useMutation((positionSns: number[]) =>
    fetchApplicationCertification({
      positionSns,
    }),
  );
}

export {
  keys as applicationKeys,
  useCheckWritingResume,
  useDeleteResume,
  useGetCheckConsent,
  useCancelMatchPosition,
  usePostProcessingConsent,
  useGetAgreeUrl,
  useGetResumeNumber,
  useApplyVerification,
  useSubmitApply,
  useRequiredItems,
  useApplyPosition,
  useAdditionalItems,
  useAcceptInterview,
  useRejectInterview,
  useUploadApplicationFiles,
  useDownloadApplicationFiles,
  useInterviewAdjusted,
  useApplicationCertification,
};
