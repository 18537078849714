import { Descendant } from 'slate';
import { getChallengeStatus } from './MatchingChallengeListVO';
import { MatchingChallengeRs, MatchingChallengeTabDto } from '@domain/rs/challenge/MatchingChallengeRs';
import { ChallengeParticipantType } from '@domain/constant/challenge/challengeParticipantType';
import { ChallengeStatusText, ChallengeStatusType } from '@domain/constant/challenge/challengeStatusType';
import DateUtil from '@utils/date/util';
import DateFormat from '@utils/date/format';

export class MatchingChallengeTabVO {
  name: string;
  contents: Descendant[];

  constructor(challengeTabs: MatchingChallengeTabDto) {
    this.name = challengeTabs.name;
    this.contents = this.setChallengeContents(challengeTabs.contents);
  }

  setChallengeContents(data: string): Descendant[] {
    try {
      return JSON.parse(data);
    } catch (e) {
      return [];
    }
  }
}

export default class MatchingChallengeVO {
  sn: number;
  title: string;
  bannerBackgroundColor: string;
  bannerTextColor: string;
  description: string;
  participants: string;
  type: ChallengeParticipantType;
  postStartDateTime: Date;
  postEndDateTime: Date;
  bannerImageUrl: string;
  challengeTabs: MatchingChallengeTabVO[];
  leftDays: string;
  postDateRangeTimeForm: string;
  applicableSize: number | null;
  challengeStatus: ChallengeStatusType;
  challengeStatusText: string;
  openYn: boolean;

  constructor(rs: MatchingChallengeRs) {
    this.sn = rs.sn;
    this.title = rs.title;
    this.bannerBackgroundColor = rs.bannerBackgroundColor;
    this.bannerTextColor = rs.bannerTextColor;
    this.description = this.convertDescription(rs.description);
    this.participants = rs.participants;
    this.type = rs.type;
    this.postStartDateTime = new Date(rs.postStartDateTime);
    this.postEndDateTime = new Date(rs.postEndDateTime);
    this.bannerImageUrl = rs.bannerImageUrl;
    this.challengeTabs = rs.challengeTabs.map((challengeTab) => new MatchingChallengeTabVO(challengeTab));
    this.leftDays = convertLeftDays(new Date(), new Date(this.postEndDateTime));
    this.postDateRangeTimeForm = getDateFormatForm(this.postStartDateTime, this.postEndDateTime);
    this.applicableSize = rs.applicableSize;
    this.challengeStatus = getChallengeStatus(rs.postStartDateTime, rs.postEndDateTime, rs.endYn);
    this.challengeStatusText = ChallengeStatusText[this.challengeStatus];
    this.openYn = rs.openYn;
  }

  convertDescription(description: string) {
    const tagArray = description.split(',');
    return tagArray.map((tag) => `#${tag}`).join('  ');
  }
}

const convertLeftDays = (date: Date, endDate: Date) => {
  const leftDays = DateUtil.getLeftDays(date, endDate);

  if (leftDays === 0) return 'D-Day';
  else return `D-${leftDays}`;
};

const getDateFormatForm = (startDate: Date, endDate: Date) =>
  `${DateFormat.format(new Date(startDate), 'yyyy.MM.dd')}
    (${DateFormat.format(new Date(startDate), 'week')}) 
    ${DateFormat.format(new Date(startDate), 'HH:mm')}
    ~ 
    ${DateFormat.format(new Date(endDate), 'yyyy.MM.dd')}
    (${DateFormat.format(new Date(endDate), 'week')})
    ${DateFormat.format(new Date(endDate), 'HH:mm')}`;
