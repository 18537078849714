import { MatchingCompaniesRs, MatchingCompanyDto } from '@domain/rs/match/MatchingCompaniesRs';
import NumberFormat from '@utils/number/format';

export default class MatchingCompaniesVO {
  matchingCompanies: MatchingCompanyVO[];

  constructor(rs: MatchingCompaniesRs) {
    this.matchingCompanies = rs.matchingCompanies.map((item) => new MatchingCompanyVO(item));
  }
}

export class MatchingCompanyVO {
  companySn: number; // 기업 sn
  companyName: string; // 기업명
  logoUrl: string; // 기업 로고
  locationText: string; // 기업 지역
  bookmarkYn: boolean; // 기업 북마크 여부

  constructor(dto: MatchingCompanyDto) {
    this.companySn = dto.companySn;
    this.companyName = dto.companyName;
    this.logoUrl = dto.companyLogoUrl;
    this.locationText = NumberFormat.formatLocationName(dto.companyLocationCode) || '국내';
    this.bookmarkYn = dto.companyBookmarkYn;
  }
}
