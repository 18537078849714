import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { PageState } from '@domain/rs/common/PageableRs';
import { RemoteCompaniesRepo } from '@repository/companies/CompaniesRepo';
import CompaniesRq from '@domain/rq/profile/CompaniesRq';
import CompanyPositionFilterRq from '@domain/rq/company/sn/CompanyPositionFilterRq';
import { CompanyVO } from '@domain/vo/profile/ProfileCareersVO';
import CompanyInfoVO from '@domain/vo/company/CompanyInfoVO';
import CompanyNewsListVO from '@domain/vo/company/CompanyNewsListVO';
import { CompanyListVO } from '@domain/vo/company/sn/CompanyListVO';
import CompanyCultureVO from '@domain/vo/company/sn/CompanyCultureVO';
import CompanyEmployeeStoriesVO from '@domain/vo/company/sn/CompanyEmployeeStoryVO';
import CompanyPositionFilterVO from '@domain/vo/company/sn/CompanyPositionFilterVO';
import CompanyPositionVO from '@domain/vo/company/sn/CompanyPositionVO';
import CompanyEmployeeVO from '@domain/vo/company/sn/CompanyEmployeeVO';
import CompanyFinanceVO from '@domain/vo/company/sn/CompanyFinanceVO';

const keys = ['companies'];
export const getCompaniesInformationKeys = (companySn: number) => [...keys, 'information', companySn];

const remoteCompaniesRepo = new RemoteCompaniesRepo();

const fetchCompanies = async (rq: CompaniesRq) => {
  const { data } = await remoteCompaniesRepo.fetchCompanies(rq);
  return data.companies.map((rs) => new CompanyVO(rs));
};

const fetchCompanyEmployee = async (companySn: number) => {
  const { data } = await remoteCompaniesRepo.fetchCompanyEmployee(companySn);
  return new CompanyEmployeeVO(data);
};

const fetchCompanyFinance = async (companySn: number) => {
  const { data } = await remoteCompaniesRepo.fetchCompanyFinance(companySn);
  return new CompanyFinanceVO(data);
};

export const fetchCompaniesInformation = async (companySn: number) => {
  const { data } = await remoteCompaniesRepo.fetchCompaniesInformation(companySn);
  return new CompanyInfoVO(data);
};

const fetchCompanyNewsList = async (companySn: number) => {
  const { data } = await remoteCompaniesRepo.fetchCompanyNewsList(companySn);
  return new CompanyNewsListVO(data);
};

const addCompanyLike = async (companySn: number) => {
  await remoteCompaniesRepo.addCompanyLike(companySn);
};

const deleteCompanyLike = async (companySn: number) => {
  await remoteCompaniesRepo.deleteCompanyLike(companySn);
};

export function useCompanies() {
  return useMutation((rq: CompaniesRq) => fetchCompanies(rq));
}

export function useCompanyEmployee(companySn: number) {
  return useQuery([...keys, companySn, 'employment'], () => fetchCompanyEmployee(companySn), {
    enabled: !!companySn,
  });
}

export function useCompanyFinance(companySn: number) {
  return useQuery([...keys, companySn, 'finance'], () => fetchCompanyFinance(companySn), {
    enabled: !!companySn,
  });
}

export function useCompaniesInformation(companySn: number, onError?: (e: AxiosError) => void) {
  return useQuery(getCompaniesInformationKeys(companySn), () => fetchCompaniesInformation(companySn), {
    enabled: !!companySn,
    onError,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
}

export function useCompanyNewsList(companySn: number) {
  return useQuery([...keys, companySn, 'news'], () => fetchCompanyNewsList(companySn), {
    enabled: !!companySn,
  });
}

export function useAddCompanyLike(onSuccess?: () => void, onError?: () => void) {
  return useMutation((companySn: number) => addCompanyLike(companySn), {
    onSuccess,
    onError,
  });
}

export function useDeleteCompanyLike(onSuccess?: () => void, onError?: () => void) {
  return useMutation((companySn: number) => deleteCompanyLike(companySn), {
    onSuccess,
    onError,
  });
}

//신규 기업정보관
const fetchCompaniesSearch = async (keyword: string) => {
  const { data } = await remoteCompaniesRepo.fetchCompaniesSearch(keyword);
  return new CompanyListVO(data);
};

export function useCompaniesSearch(keyword: string) {
  return useQuery([...keys, 'search', keyword], () => fetchCompaniesSearch(keyword), {
    enabled: !!keyword,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

const fetchCompanyAdvantage = async (companySn: number) => {
  const { data } = await remoteCompaniesRepo.fetchCompaniesAdvantage(companySn);
  return new CompanyCultureVO(data);
};

export function useCompanyAdvantage(companySn: number) {
  return useQuery([...keys, 'advantage', companySn], () => fetchCompanyAdvantage(companySn), {
    enabled: !!companySn,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 1,
  });
}

const fetchCompaniesThemes = async (companySn: number) => {
  const { data } = await remoteCompaniesRepo.fetchCompaniesThemes(companySn);
  return data;
};

export function useCompaniesThemes(companySn: number) {
  return useQuery([...keys, 'themes', companySn], () => fetchCompaniesThemes(companySn), {
    enabled: !!companySn,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

const fetchCompaniesSpecialty = async (companySn: number) => {
  const { data } = await remoteCompaniesRepo.fetchCompaniesSpecialty(companySn);
  return data;
};

export function useCompaniesSpecialty(companySn: number) {
  return useQuery([...keys, 'specialty', companySn], () => fetchCompaniesSpecialty(companySn), {
    enabled: !!companySn,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

const fetchCompaniesHeader = async (companySn: number) => {
  const { data } = await remoteCompaniesRepo.fetchCompaniesHeader(companySn);
  return data;
};

export function useCompaniesHeader(companySn: number, onSuccess?: () => void, onError?: (e: AxiosError) => void) {
  return useQuery([...keys, 'header', companySn], () => fetchCompaniesHeader(companySn), {
    enabled: !!companySn,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 0,
    onSuccess,
    onError,
  });
}

const fetchCompaniesWelfare = async (companySn: number) => {
  const { data } = await remoteCompaniesRepo.fetchCompaniesWelfare(companySn);
  return data;
};

export function useCompaniesWelfare(companySn: number) {
  return useQuery([...keys, 'welfare', companySn], () => fetchCompaniesWelfare(companySn), {
    enabled: !!companySn,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

const fetchCompaniesEmployeeStories = async (companySn: number) => {
  const { data } = await remoteCompaniesRepo.fetchCompaniesEmployeeStories(companySn);
  return new CompanyEmployeeStoriesVO(data);
};

export function useCompaniesEmployeeStories(companySn: number) {
  return useQuery([...keys, 'employeeStories', companySn], () => fetchCompaniesEmployeeStories(companySn), {
    enabled: !!companySn,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

const defaultPage: PageState = {
  page: 0,
  size: 10,
  totalPages: 0,
  totalElements: 0,
};

const fetchCompaniesPositions = async (companySn: number, vo?: CompanyPositionFilterVO, page = 0) => {
  const rq = new CompanyPositionFilterRq(page, defaultPage.size, vo);
  const { data } = await remoteCompaniesRepo.fetchCompaniesPositions(companySn, rq);
  return {
    ...data,
    positions: data.positions.map((item) => new CompanyPositionVO(item)),
    isExistNextData: data.pages.totalPages > data.pages.page + 1, //다음 불러올 데이터 존재 유무
  };
};

export function useCompaniesPositions(companySn: number, vo?: CompanyPositionFilterVO) {
  return useInfiniteQuery(
    [...keys, companySn, vo],
    ({ pageParam }) => fetchCompaniesPositions(companySn, vo, pageParam),
    {
      getNextPageParam: ({ pages: { page, totalPages } }) => (page < totalPages ? page + 1 : undefined),
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      cacheTime: 0, //필터 초기화시 0 페이지만 조회 하기 위함
    },
  );
}

export { keys as companiesKeys };
