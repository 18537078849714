import ProfileViewVO from '@domain/vo/profile/common/ProfileViewVO';
import { ProfileExperienceRs, EtcExperiencesDto, ExperienceDto } from '@domain/rs/profile/ProfileExperienceRs';
import { ExperienceNameMap, ExperienceType } from '@domain/constant/profile/ExperienceType';
import { AuditItemsType } from '@domain/constant/profile/AuditItemsType';
import DateFormat from '@utils/date/format';

export default class ProfileExperienceVO extends ProfileViewVO {
  activitiesAndForeignExperiences: ExperienceVO[];
  experiences: EtcExperiencesVO[];

  constructor(rs: ProfileExperienceRs) {
    super(rs, false);
    this.activitiesAndForeignExperiences = rs.activitiesAndForeignExperiences.map((item) => new ExperienceVO(item));
    this.experiences = rs.etcExperiences;
  }
}

export class ExperienceVO {
  tag: ExperienceType;
  tagText?: string;
  code: number; //국가 코드
  title: string;
  startDate: string;
  endDate: string;
  description: string;
  auditItems?: AuditItemsType[];

  constructor(rs: ExperienceDto) {
    this.tag = rs.tag;
    this.tagText = rs.tag ? ExperienceNameMap[rs.tag] : '';
    this.startDate = this.getDateFormat(rs.startDate);
    this.endDate = this.getDateFormat(rs.endDate);
    this.code = rs.code;
    this.title = rs.title;
    this.description = rs.description ?? '';
    this.auditItems = rs.auditItems;
  }

  getDateFormat(data: string | null) {
    return DateFormat.getDateFormatString(data, 'yyyy.MM') ?? '';
  }
}

export class EtcExperiencesVO {
  description: string;

  constructor(rs: EtcExperiencesDto) {
    this.description = rs.description;
  }
}
