import React from 'react';
import Performance from './items/Performance';
import ProjectName from './items/ProjectName';
import StartDate from './items/StartDate';
import EndDate from './items/EndDate';
import ProjectType from './items/ProjectType';
import ProjectPlace from './items/ProjectPlace';
import SkillKeywordForm from './items/skillKeywordForm';
import classnames from 'classnames/bind';
import styles from '../commonEdit.module.scss';
const cx = classnames.bind(styles);

const Project = ({ prefixName }: HookFormProps) => {
  return (
    <>
      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>프로젝트명</span>
        {/* 프로젝트명 */}
        <ProjectName prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>프로젝트 기간</span>
        {/* 프로젝트 기간 */}
        <div className={cx('periodArea')}>
          <StartDate prefixName={prefixName} />
          <mark>~</mark>
          <EndDate prefixName={prefixName} />
        </div>
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>팀/개인</span>
        {/* 프로젝트 타입 */}
        <ProjectType prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle')}>주요 업무 및 성과</span>
        {/* 주요 업무 및 성과 */}
        <Performance prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle')}>학교/회사/기관</span>
        {/* 프로젝트 진행 장소 */}
        <ProjectPlace prefixName={prefixName} />
      </div>

      <div className={cx('writeWrap')}>
        {/* 활용 기술 */}
        <SkillKeywordForm prefixName={prefixName} />
      </div>
    </>
  );
};

export default Project;
