import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { SkillKeywordsRs } from '@domain/rs/profile/skill/SkillKeywordRs';
import { RecommendSkillKeywordRs } from '@domain/rs/profile/skill/RecommendSkillKeywordRs';

const skillsPrefix = '/skills';

export default interface SkillsRepo {
  //기술키워드 목록 조회
  fetchSkillKeywords(keyword: string): Promise<AxiosResponse<SkillKeywordsRs>>;

  //추천키워드 목록 조회
  fetchRecommendSkillKeywords(skillCode: number): Promise<AxiosResponse<RecommendSkillKeywordRs>>;
}

export class RemoteSkillsRepo implements SkillsRepo {
  fetchSkillKeywords(keyword: string) {
    return axios.get<SkillKeywordsRs>(`${skillsPrefix}`, { params: { keyword } });
  }

  fetchRecommendSkillKeywords(skillCode: number) {
    return axios.get<RecommendSkillKeywordRs>(`${skillsPrefix}/${skillCode}/recommend`);
  }
}
