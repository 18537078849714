import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Text from '@components/common/text';
import Modal from '@components/common/modal';
import CloseButton from '@components/common/closeButton';
import ProgressBar from '@components/progressBar';
import Fennel from '@components/_atoms/Fennel';
import { useUserInfo } from '@repository/auth/useUserInfo';
import useProfileConditions from '@repository/profiles/useProfileConditions';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import { FontStyle } from '@domain/constant/fontStyle';
import { V2RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface ResumeProfileModalProps {
  startStep?: number;
  visible: boolean;
  onClose: () => void;
}

const ResumeProfileModal = ({ startStep, visible, onClose }: ResumeProfileModalProps) => {
  const { push, pathname } = useRouter();
  const [selected, setSelected] = useState<number | null>(null); // 선택된 지원서 sn
  const [totalResumeCount, setTotalResumeCount] = useState(0);
  //refetch돼서 모달 개수가 달라지는 것을 방지하기 위함.
  const [isFillConditions, setIsFillConditions] = useState<boolean | null>(null);
  const { data: userInfo } = useUserInfo();
  const { data: conditionsData } = useProfileConditions(!!userInfo?.id);

  const handleClose = () => {
    setSelected(null);
    setIsFillConditions(null);
    onClose();
  };

  const step5Next = () => {
    if (V2RouteUrl.PROFILE !== pathname) push(V2RouteUrl.PROFILE);
    handleClose();
  };

  const getProgress = (step: number) => {
    if (!isFillConditions) {
      if (step === 3) return { startProgress: 3 * 20, progress: (step + 1) * 20 };

      return { startProgress: 0, progress: (step + 1) * 20 };
    } else {
      if (step === 3) return { startProgress: 3 * 25, progress: (step + 1) * 25 };
      return { startProgress: 0, progress: (step + 1) * 25 };
    }
  };

  useEffect(() => {
    if (visible && conditionsData && isFillConditions === null) {
      setIsFillConditions(
        !!conditionsData.jobGroups.length && !!conditionsData.locations.length && !!conditionsData.salary,
      );
    }
  }, [conditionsData, visible]);

  return (
    <Modal visible={visible} dimmed={true}>
      <div className={cx('modalArea')}>
        <Fennel
          startStep={startStep}
          layout={(content, step) => (
            <>
              {step !== 2 && (
                <>
                  <Text className={cx('modalTitle')} fontStyle={FontStyle.H4_B}>
                    지원서 불러오기
                  </Text>
                  <ProgressBar
                    className={cx('progressBar')}
                    startProgress={getProgress(step).startProgress}
                    progress={getProgress(step).progress}
                  />
                </>
              )}
              {![2, 3].includes(step) && <CloseButton className={cx('btnClose')} onClick={handleClose} />}
              {content}
            </>
          )}
        >
          {({ nextStep, prevStep }) => {
            return (
              <>
                <Fennel.Item>
                  <Step1 nextStep={nextStep} />
                </Fennel.Item>
                <Fennel.Item>
                  <Step2 onClose={handleClose} nextStep={nextStep} selected={selected} setSelected={setSelected} />
                </Fennel.Item>
                <Fennel.Item>
                  <Step3
                    onClose={handleClose}
                    nextStep={(totalResumeCount: number) => {
                      setTotalResumeCount(totalResumeCount);
                      nextStep();
                    }}
                    prevStep={prevStep}
                    selected={selected}
                    isFillConditions={isFillConditions ?? false}
                  />
                </Fennel.Item>
                {!isFillConditions && (
                  <Fennel.Item>
                    <Step4 nextStep={nextStep} />
                  </Fennel.Item>
                )}
                <Fennel.Item>
                  <Step5 nextStep={step5Next} totalResumeCount={totalResumeCount} />
                </Fennel.Item>
              </>
            );
          }}
        </Fennel>
      </div>
    </Modal>
  );
};

export default ResumeProfileModal;
