import React from 'react';
import { useRouter } from 'next/router';

import { useLoggingContext } from '@/src/logging';
import { FontStyleValueType } from '@domain/constant/fontStyle';
import classnames from 'classnames/bind';
import styles from './button.module.scss';
const cx = classnames.bind(styles);

interface IButtonProps {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  className?: string;
  fontStyle?: FontStyleValueType;
  color?: string;
  href?: string;
  target?: string;
  theme?: 'primary' | 'secondary';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  styles?: React.CSSProperties;
  id?: string;
}

const Button = ({
  onClick,
  children,
  className,
  fontStyle,
  color,
  href,
  target,
  theme = 'primary',
  disabled,
  type,
  isLoading,
  styles,
  id,
}: IButtonProps) => {
  const router = useRouter();
  const { handleLoggingNewTab } = useLoggingContext();
  const style = {
    ...fontStyle,
    color,
    ...styles,
  };

  const Element = href ? 'a' : 'button';
  const handleClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {
    if (href) {
      if (target === '_blank') {
        handleLoggingNewTab(e as any, href);
        window.open(href, '_blank', 'noopener noreferrer');
      } else {
        router.push(href);
      }
    }

    onClick?.(e);
  };

  return (
    <Element
      onClick={isLoading ? undefined : handleClick}
      className={cx('button', className, {
        primary: theme === 'primary',
        secondary: theme === 'secondary',
        disabled,
        isLoading,
      })}
      disabled={disabled || isLoading}
      type={type}
      style={style}
      id={id}
    >
      {isLoading ? <span className={cx('spinner', theme)} /> : children}
    </Element>
  );
};

export default Button;
