import { useMutation, useQuery } from 'react-query';
import { RemoteProfilesRepo } from './ProfilesRepo';
import ProfileSkillSaveRq from '@domain/rq/profile/ProfileSkillSaveRq';
import ProfileSkillVO from '@domain/vo/profile/ProfileSkillVO';
// import MockProfileRepo from "@repository/mock/MockProfileRepo";

export const keys = ['profile', 'skill'];
export const viewKeys = [...keys, 'view'];

const remoteProfilesRepo = new RemoteProfilesRepo();

const fetchProfileSkill = async () => {
  const { data } = await remoteProfilesRepo.fetchProfileSkill();
  return new ProfileSkillVO(data);
};

const fetchSaveProfileSkill = async (vo: ProfileSkillVO) => {
  const rq = new ProfileSkillSaveRq(vo);
  const { data } = await remoteProfilesRepo.fetchSaveProfileSkill(rq);
  return data;
};

export default function useProfileSkill(isEnabled: boolean) {
  return useQuery(viewKeys, fetchProfileSkill, {
    enabled: isEnabled,
  });
}

export const useSaveProfileSkill = () => {
  return useMutation((vo: ProfileSkillVO) => fetchSaveProfileSkill(vo));
};
