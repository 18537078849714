import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Element } from 'react-scroll';
import { useFieldArray } from 'react-hook-form';
import NoSsr from '@common/noSsr';
import Icon from '@common/assets';
import {
  ReactSelectInputStyles,
  ReactSelectStyles,
} from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import useSkillKeyword from '@components/profile/profileOverlay/overlays/skill/query/useSkillKeyword';

import SkillKeywordChipList from './SkillKeywordChipList';
import RecommendSkillContents from './RecommendSkillContents';
import { SkillsVO } from '@domain/vo/profile/ProfileProjectVO';
import SkillKeywordDto from '@domain/rs/profile/skill/SkillKeywordRs';
import { SkillLevelOptions, SkillLevelType } from '@domain/constant/profile/SkillType';
import { LoadOptions } from '@domain/constant/ReactSelectOption';
import useDebounce from '@utils/hooks/useDebounce';
import { V2Route } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);

const Tooltip = () => {
  return (
    <div className={cx('tooltip')}>
      <div className={cx('tooltipBox')}>
        <div className={cx('tooltipInfoWrap')}>
          <div className={cx('tooltipTitle')}>활용 기술이란?</div>
          <p className={cx('tooltipDesc')}>프로젝트에서 활용한 전문 스킬 또는 툴을 의미합니다.</p>
        </div>
        <div className={cx('tooltipInfoWrap')}>
          <div className={cx('tooltipTitle')}>활용 기술의 숙련도는?</div>
          <p className={cx('tooltipDesc')}>• 기초 : 대학교에서 수업을 통해 지식을 학습한 수준</p>
          <p className={cx('tooltipDesc')}>• 초급 : 아르바이트/인턴을 통해 업무를 하였거나 활용한 수준</p>
          <p className={cx('tooltipDesc')}>• 중급 : 1년 미만 업무에 적용한 경험이 있거나, 실무에 활용 가능한 수준</p>
          <p className={cx('tooltipDesc')}>
            • 고급 : 1~3년 동안 업무에 적용한 경험이 있거나, 큰 도움 없이 활용 가능한 수준
          </p>
          <p className={cx('tooltipDesc')}>
            • 전문가 : 3년 이상 업무에 적용한 경험이 있거나, 자유자재로 다룰 수 있는 수준
          </p>
        </div>
      </div>
    </div>
  );
};

const TooltipButton = () => {
  return (
    <div className={cx('btnInfoArea')}>
      <a className={cx('btnInfo')} role="button">
        <Icon name="informationLight" width={20} height={20} />
        <Tooltip />
      </a>
    </div>
  );
};

const skillKeywordName = 'SkillKeyword-form';

const SkillKeywordForm = ({ prefixName }: HookFormProps) => {
  const { fields, append, remove } = useFieldArray({
    name: `${prefixName}.skills`,
  });

  const lastFieldValue = fields.length ? (fields[fields.length - 1] as unknown as SkillsVO) : null;

  const { mutateAsync: skillKeywordMutateAsync } = useSkillKeyword();

  const [selectedSkill, setSelectedSkill] = useState<SkillKeywordDto | null>(null);

  const loadOptions = useDebounce((keyword: string, callback: LoadOptions) => {
    skillKeywordMutateAsync(keyword).then((skillKeywords: SkillKeywordDto[]) => {
      //선택된 항목 option에서 제거
      const selectedList = fields.map((selected: any) => selected.code);
      callback(skillKeywords.filter((item) => !selectedList.includes(item.code)));
    });
  }, 100);

  const onSelectSkill = (selected: any) => {
    setSelectedSkill(selected as SkillKeywordDto);
  };

  const onSelectSkillLevel = (selected: any) => {
    if (!selectedSkill) return;

    append(
      new SkillsVO({
        ...selectedSkill,
        level: selected.value as SkillLevelType,
      }),
    );

    setSelectedSkill(null);
  };

  const onSelectRecommendSkill = (selected: SkillKeywordDto) => {
    setSelectedSkill(selected);
  };

  return (
    <div className={cx('keywordArea')}>
      <Element name={skillKeywordName} className={cx('sectionTitleArea')}>
        <em className={cx('sectionTitle')}>
          활용 기술
          <TooltipButton />
        </em>
        <a
          href={V2Route.getRequestSkillKeywordPage()}
          className={cx('requestKeyword')}
          target="_blank"
          rel="noreferrer"
        >
          기술 키워드 요청하기
        </a>
      </Element>

      <div className={cx('selectArea')}>
        <div className={cx('selectInner')}>
          <NoSsr>
            <AsyncSelect
              className={cx('select')}
              placeholder={'본인이 활용한 기술을 입력해 주세요.'}
              loadOptions={loadOptions}
              getOptionValue={(option: any) => option.code}
              getOptionLabel={(option: any) => option.keyword}
              openMenuOnClick={false}
              styles={ReactSelectInputStyles}
              isClearable={true}
              onChange={onSelectSkill}
              value={selectedSkill}
            />
          </NoSsr>
        </div>

        <div className={cx('selectInner', 'type2')}>
          <Select
            className={cx('select')}
            placeholder={'숙련도'}
            styles={ReactSelectStyles}
            isSearchable={false}
            options={SkillLevelOptions}
            onChange={onSelectSkillLevel}
            value={null}
            isDisabled={!selectedSkill}
          />
        </div>
      </div>

      {/* 선택한 아이템 영역 */}
      <SkillKeywordChipList
        prefixName={prefixName}
        skillKeywordChips={fields as unknown as SkillsVO[]}
        onDelete={(idx: number) => remove(idx)}
      />

      {/* 추천 키워드 */}
      <RecommendSkillContents
        skillKeyword={lastFieldValue}
        selectedSkillKeywords={fields as unknown as SkillsVO[]}
        onSelectSkill={onSelectRecommendSkill}
      />
    </div>
  );
};

export default SkillKeywordForm;
