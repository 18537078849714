import React, { FC } from 'react';
import { FindStateType, ModalStateType } from '@components/loginModal';
import styles from '@components/loginModal/find/find.module.scss';
import IdentificationIdRs from '@domain/rs/verification/IdentificationIdRs';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface FindIdResultProps {
  findIdData: IdentificationIdRs | null;
  setModalState: (state: ModalStateType) => void;
  setFindState: (state: FindStateType) => void;
}

const FindIdResult: FC<FindIdResultProps> = ({ findIdData, setModalState, setFindState }) => {
  const login = () => {
    setModalState('LOGIN');
  };

  const findPassword = () => {
    setFindState('PASSWORD');
  };

  return (
    <div className={cx('findIdResultArea')}>
      <em className={cx('text')}>찾은 아이디는 아래와 같습니다.</em>
      <div className={cx('resultArea')}>
        <div className={cx('resultWrap')}>
          <div className={cx('id')}>{findIdData?.id}</div>
          <div className={cx('date')}>{findIdData?.createdDateTime} 가입</div>
        </div>
      </div>
      <div className={cx('btnArea')}>
        <button className={cx('btnLogin')} type="button" onClick={login}>
          로그인
        </button>
        <button className={cx('btnResetPw')} type="button" onClick={findPassword}>
          비밀번호 재설정
        </button>
      </div>
    </div>
  );
};

export default FindIdResult;
