export enum Progress {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CONNECT_OVER = 'CONNECT_OVER',
}

export type ProgressType = keyof typeof Progress;

export const ProgressStatusList = ['NOT_STARTED', 'IN_PROGRESS'];
