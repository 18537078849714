import { useMutation, useQuery } from 'react-query';
import { RemoteProfileCareerRepo } from './ProfileCareerRepo';
import NhisProfileCareerRq from '@domain/rq/profile/NhisProfileCareerRq';
import { ProfileCareerSaveV2Rq } from '@domain/rq/profile/ProfileCareerSaveV2Rq';
import ProfileCareersVO from '@domain/vo/profile/ProfileCareersVO';

export const keys = ['profile', 'career'];

const remoteProfileCareerRepo = new RemoteProfileCareerRepo();
// const profileRepo = new MockProfileRepo();

export default function useProfileCareer(isEnabled: boolean) {
  return useQuery(
    [...keys, 'view'],
    async () => {
      const { data } = await remoteProfileCareerRepo.fetchProfileCareer();
      return new ProfileCareersVO(data);
    },
    {
      enabled: isEnabled,
    },
  );
}

export const useProfileCareerNHIS = () => {
  return useMutation((rq: NhisProfileCareerRq) => remoteProfileCareerRepo.fetchProfileCareerNHIS(rq));
};

export const usePostProfileCareer = () => {
  return useMutation((rq: ProfileCareerSaveV2Rq) => remoteProfileCareerRepo.fetchPostProfilesCareer(rq));
};

export const usePutProfileCareer = () => {
  return useMutation(({ sn, rq }: { sn: number; rq: ProfileCareerSaveV2Rq }) =>
    remoteProfileCareerRepo.fetchPutProfilesCareer({ sn, rq }),
  );
};

export const useDeleteProfileCareer = () => {
  return useMutation((sn: number) => remoteProfileCareerRepo.fetchDeleteProfilesCareer(sn));
};
