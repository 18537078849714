import { DataLogRq, SourcePage } from '@domain/rq/DataLog';
import { EtcExperiencesVO } from '@domain/vo/profile/ProfileExperienceVO';
import ProfileExperienceFormVO, { ExperienceFromVO } from '@domain/vo/profile/ProfileExperienceFormVO';
import { ExperienceType } from '@domain/constant/profile/ExperienceType';
import DateFormat from '@utils/date/format';

export default class ProfileExperienceSaveRq {
  activities: ActivitiesRq[];
  foreignExperiences: ForeignExperienceRq[];
  experiences: ExperienceRq[];
  sourcePage?: SourcePage;

  constructor(vo: ProfileExperienceFormVO & DataLogRq) {
    this.activities = vo.activities
      .filter((item) => {
        const length = Object.values(item).filter((v) => !!v).length;
        return length > 0;
      })
      .map((item) => new ActivitiesRq(item));
    this.foreignExperiences = vo.foreignExperiences
      .filter((item) => {
        const length = Object.values(item).filter((v) => !!v).length;
        return length > 0;
      })
      .map((item) => new ForeignExperienceRq(item));
    this.experiences = vo.experiences.map((item) => new ExperienceRq(item));
    this.sourcePage = vo.sourcePage;
  }
}

class ActivitiesRq {
  type: ExperienceType;
  place: string;
  startDate: string;
  endDate: string;
  description: string;

  constructor(vo: ExperienceFromVO) {
    this.type = vo.tag?.value as ExperienceType;
    this.place = vo.title;
    this.startDate = this.getDateFormat(vo.startDate);
    this.endDate = this.getDateFormat(vo.endDate);
    this.description = vo.description;
  }

  getDateFormat(data: string | null) {
    return DateFormat.getDateFormatString(data, 'yyyy-MM') ?? '';
  }
}

class ForeignExperienceRq {
  countryCode: number;
  startDate: string;
  endDate: string;
  description: string;

  constructor(vo: ExperienceFromVO) {
    this.countryCode = vo.code?.code as number;
    this.startDate = this.getDateFormat(vo.startDate);
    this.endDate = this.getDateFormat(vo.endDate);
    this.description = vo.description;
  }

  getDateFormat(data: string | null) {
    return DateFormat.getDateFormatString(data, 'yyyy-MM') ?? '';
  }
}

class ExperienceRq {
  description: string;

  constructor(vo: EtcExperiencesVO) {
    this.description = vo.description;
  }
}
