import { useMutation, useQuery } from 'react-query';
import { RemoteProfilesRepo } from '@repository/profiles/ProfilesRepo';
import MatchingConditionJobGroupRq from '@domain/rq/profile/matchingConditions/MatchingConditionJobGroupRq';
import MatchingConditionLocationRq from '@domain/rq/profile/matchingConditions/MatchingConditionLocationRq';
import MatchingConditionSalaryRq from '@domain/rq/profile/matchingConditions/MatchingConditionSalaryRq';
import MatchingConditionWelfareRq from '@domain/rq/profile/matchingConditions/MatchingConditionWelfareRq';
import MatchingConditionWorkingPreferenceRq from '@domain/rq/profile/matchingConditions/MatchingConditionWorkingPreferenceRq';
import ProfileConditionsVO from '@domain/vo/profile/ProfileConditionsVO';
// import MockProfileRepo from "@repository/mock/MockProfileRepo";

export const keys = ['profile', 'conditions'];

const remoteProfilesRepo = new RemoteProfilesRepo();
// const profileRepo = new MockProfileRepo();

const fetchProfileConditions = async () => {
  const { data } = await remoteProfilesRepo.fetchProfileConditions();
  return new ProfileConditionsVO(data);
};

export default function useProfileConditions(isEnabled: boolean) {
  return useQuery([...keys, 'view'], fetchProfileConditions, {
    enabled: isEnabled,
    staleTime: Infinity,
  });
}

const fetchSaveJobGroups = async (rq: MatchingConditionJobGroupRq) => {
  const { data } = await remoteProfilesRepo.fetchSaveJobGroups(rq);
  return data;
};

export function useSaveJobGroups() {
  return useMutation(fetchSaveJobGroups);
}

const fetchSaveLocations = async (rq: MatchingConditionLocationRq) => {
  const { data } = await remoteProfilesRepo.fetchSaveLocations(rq);
  return data;
};

export function useSaveLocations() {
  return useMutation(fetchSaveLocations);
}

const fetchSaveSalary = async (rq: MatchingConditionSalaryRq) => {
  const { data } = await remoteProfilesRepo.fetchSaveSalary(rq);
  return data;
};

export function useSaveSalary() {
  return useMutation(fetchSaveSalary);
}

const fetchSaveWelfare = async (rq: MatchingConditionWelfareRq) => {
  const { data } = await remoteProfilesRepo.fetchSaveWelfare(rq);
  return data;
};

export function useSaveWelfare() {
  return useMutation(fetchSaveWelfare);
}

const fetchSaveWorkingPreference = async (rq: MatchingConditionWorkingPreferenceRq) => {
  const { data } = await remoteProfilesRepo.fetchSaveWorkingPreference(rq);
  return data;
};

export function useSaveWorkingPreference() {
  return useMutation(fetchSaveWorkingPreference);
}
