import qs from 'qs';
import { LoginFormField } from '@components/loginModal/loginForm';

export default class LoginRq {
  id: string;
  password: string;

  constructor(loginInfo?: LoginFormField) {
    this.id = loginInfo?.id ?? '';
    this.password = loginInfo?.password ?? '';
  }
}

export interface IKaKaoTokenRq {
  grant_type: string;
  client_id: string;
  redirect_uri: string;
  code: string;
}

export class KaKaoTokenRq {
  grant_type: string;
  client_id: string;
  redirect_uri: string;
  code: string;

  constructor(rq: IKaKaoTokenRq) {
    this.grant_type = rq.grant_type;
    this.client_id = rq.client_id;
    this.redirect_uri = rq.redirect_uri;
    this.code = rq.code;
  }

  get requestParam() {
    return qs.stringify(this);
  }
}

export interface IGoogleTokenRq {
  grant_type: string;
  client_id: string;
  client_secret: string;
  redirect_uri: string;
  code: string;
}

export class GoogleTokenRq {
  grant_type: string;
  client_id: string;
  client_secret: string;
  redirect_uri: string;
  code: string;

  constructor(rq: IGoogleTokenRq) {
    this.grant_type = rq.grant_type;
    this.client_id = rq.client_id;
    this.client_secret = rq.client_secret;
    this.redirect_uri = rq.redirect_uri;
    this.code = rq.code;
  }

  get requestParam() {
    return qs.stringify(this);
  }
}
