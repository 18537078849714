import React, { FC } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useController } from 'react-hook-form';
import NoSsr from '@common/noSsr';
import { ReactSelectInputStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import useSchool from '@components/profile/profileOverlay/overlays/edu/query/useSchool';
import { LoadOptions } from '@domain/constant/ReactSelectOption';
import useDebounce from '@utils/hooks/useDebounce';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const SchoolName: FC<HookFormProps> = ({ prefixName }) => {
  const { mutateAsync: mutateAsyncSchool } = useSchool();
  const { field: schoolNameField, fieldState: schoolNameState } = useController({ name: `${prefixName}.schoolName` });
  const { field: schoolLevelField } = useController({ name: `${prefixName}.schoolLevel` });

  const isDisabled = !schoolLevelField.value;
  const loadOptions = useDebounce((keyword: string, callback: LoadOptions) => {
    mutateAsyncSchool({ educationLevel: schoolLevelField?.value.value, name: keyword }).then((schools) => {
      callback(schools);
    });
  }, 100);

  const setMaxLength = (inputValue: string) => (inputValue.length <= 30 ? inputValue : inputValue.substring(0, 30));

  return (
    <>
      <NoSsr>
        <AsyncCreatableSelect
          {...schoolNameField}
          className={cx('select', { error: schoolNameState.invalid })}
          loadOptions={loadOptions}
          placeholder={'학교를 검색해주세요.'}
          openMenuOnClick={false}
          formatCreateLabel={() => `직접 입력`}
          styles={ReactSelectInputStyles}
          isDisabled={isDisabled}
          isClearable={true}
          onInputChange={setMaxLength}
        />
      </NoSsr>
      {schoolNameState?.error?.message && <div className={cx('errorMessage')}>{schoolNameState.error.message}</div>}
    </>
  );
};

export default SchoolName;
