import { AxiosResponse } from 'axios';
import successRs from '@domain/rs/common/SuccessRs';
import { VerificationUserSmsCheckRq } from '@domain/rq/common/VerificationMobileRq';
import { axios } from '@repository/RequestClient';
import VerificationSmsSendRq from '@domain/rq/sms/VerificationSmsSendRq';
import VerificationSmsCheckRq from '@domain/rq/sms/VerificationSmsCheckRq';
import VerificationSmsSendIdRq from '@domain/rq/sms/VerificationSmsSendIdRq';
import VerificationSmsSendPasswordRq from '@domain/rq/sms/VerificationSmsSendPasswordRq';

export default interface VerificationRepo {
  //아이디 찾기 - 인증번호 발송 : 비 로그인용
  fetchVerificationSmsSendId(verificationSmsSendRq: VerificationSmsSendIdRq): Promise<AxiosResponse>;

  // 비밀번호 찾기 - 인증번호 발송 : 비 로그인용
  fetchVerificationSmsSendPassword(
    VerificationSmsSendPasswordRq: VerificationSmsSendPasswordRq,
  ): Promise<AxiosResponse>;

  //회원 가입, 아이디 비밀번호 찾기 - 인증번호 발송 : 비 로그인용
  fetchVerificationSmsSend(verificationSmsSendRq: VerificationSmsSendRq): Promise<AxiosResponse>;

  //회원 가입, 아이디 비밀번호 찾기 - 인증 번호 체크 : 비 로그인용
  fetchVerificationSmsCheck(verificationSmsCheckRq: VerificationSmsCheckRq): Promise<AxiosResponse<successRs>>;

  //인증번호 발송 : 로그인용
  fetchVerificationUserSmsSend({
    mobile,
    countryCode,
  }: {
    mobile: string;
    countryCode: string;
  }): Promise<AxiosResponse<successRs>>;

  //인증 번호 체크 :로그인용
  fetchVerificationUserSmsCheck(rq: VerificationUserSmsCheckRq): Promise<AxiosResponse<successRs>>;

  //장기 미이용자 본인 인증 확인
  updateVerificationLongTermInActiveCheck(rq: VerificationUserSmsCheckRq): Promise<AxiosResponse<boolean>>;
}

export class RemoteVerificationRepo implements VerificationRepo {
  fetchVerificationSmsSendId(verificationSmsSendRq: VerificationSmsSendIdRq) {
    return axios.post(`/verification/sms/send/id`, verificationSmsSendRq);
  }

  fetchVerificationSmsSendPassword(VerificationSmsSendPasswordRq: VerificationSmsSendPasswordRq) {
    return axios.post(`/verification/sms/send/password`, VerificationSmsSendPasswordRq);
  }

  fetchVerificationSmsSend(verificationSmsSendRq: VerificationSmsSendRq) {
    return axios.post(`/v2/verification/sms/send`, verificationSmsSendRq);
  }

  fetchVerificationSmsCheck(verificationSmsCheckRq: VerificationSmsCheckRq) {
    return axios.post(`/verification/sms/check`, verificationSmsCheckRq);
  }

  fetchVerificationUserSmsSend({ mobile, countryCode }: { mobile: string; countryCode: string }) {
    return axios.post(`/verification/user/sms/send`, { mobile, countryCode });
  }

  fetchVerificationUserSmsCheck(rq: VerificationUserSmsCheckRq) {
    return axios.post(`/verification/user/sms/check`, rq);
  }

  updateVerificationLongTermInActiveCheck(rq?: VerificationUserSmsCheckRq) {
    return axios.post(`/verification/user/long-term-inactive/check`, rq ?? {});
  }
}
