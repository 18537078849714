import * as yup from 'yup';
import { Experience } from '@domain/constant/profile/ExperienceType';

const experienceFormSchema = yup.object().shape({
  tag: yup.lazy((tag) =>
    tag.value !== Experience.OVERSEAS
      ? yup.object().nullable().required('내용을 입력해주세요.')
      : yup.object().nullable(),
  ),
  title: yup.lazy((ctx) =>
    ctx?.parent?.tag?.value !== Experience.OVERSEAS
      ? yup.string().nullable().required('기관/장소를 입력해주세요.').max(25, '25자 이내로 입력해주세요.')
      : yup.string(),
  ),
  startDate: yup.string().required('시작 일자를 입력해주세요.'),
  endDate: yup.lazy((endDate) => {
    return endDate
      ? yup.date().when('startDate', (startDate, schema) => {
          return startDate ? schema.min(startDate, '종료 일자가 시작 일자보다 빠릅니다.') : schema;
        })
      : yup.string().required('일자를 입력해주세요.');
  }),
  code: yup.lazy((ctx) =>
    ctx?.parent?.tag?.value === Experience.OVERSEAS
      ? yup.object().nullable().required('국가를 입력해주세요.')
      : yup.object().nullable(),
  ),
  description: yup.string().nullable().max(1000, '최대 글자수를 초과했습니다.'),
});

export default experienceFormSchema;
