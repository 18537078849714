import { useMutation, useQuery } from 'react-query';
import { RemoteMatchConditionsRepo } from './MatchConditionsRepo';

const keys = ['match', 'conditions', 'last-modified'];
const repo = new RemoteMatchConditionsRepo();

// 선호 정보 마지막 수정일
const fetchConditionsLastModified = async () => {
  const { data } = await repo.fetchConditionsLastModified();

  return data;
};

export const useConditionsLastModified = (enabled?: boolean) => {
  return useQuery(keys, fetchConditionsLastModified, { enabled });
};

// 선호 정보 이대로 제안 받기
const fetchConditionsLastModifiedSkip = async () => {
  const { data } = await repo.fetchConditionsLastModifiedSkip();

  return data;
};

export const useConditionsLastModifiedSkip = () => {
  return useMutation(fetchConditionsLastModifiedSkip);
};
