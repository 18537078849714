import { RecruitCategoryType, RecruitCategoryTypeNameMap } from '@domain/constant/recruitCategoryType';
import NumberFormat from '@utils/number/format';
import DateFormat from '@utils/date/format';

export interface IPositionBookmarkListItemVO {
  sn: number; //포지션 sn
  title: string; //포지션명
  companyName: string; //기업명
  locationCode: number; //지역코드
  teamImageUrl: string; //팀 이미지 url
  recruitmentType: RecruitCategoryType;
  bookmarkYn?: boolean; //북마크 여부
  closingDateTime: string | null; // 마감기간
  matchingYn?: boolean; // 매칭 받음 여부
}

export class PositionBookmarkListItemVO {
  sn: number;
  title: string;
  companyName: string;
  locationName: string;
  teamImageUrl: string;
  recruitment: string;
  bookmarkYn: boolean;
  closingDateText: string;
  matchingYn: boolean;

  constructor(dto: IPositionBookmarkListItemVO) {
    this.sn = dto.sn;
    this.title = dto.title;
    this.companyName = dto.companyName;
    this.locationName = NumberFormat.formatLocationName(dto.locationCode);
    this.teamImageUrl = dto.teamImageUrl;
    this.recruitment = RecruitCategoryTypeNameMap[dto.recruitmentType];
    this.bookmarkYn = dto.bookmarkYn ?? false;
    this.closingDateText = DateFormat.formatPositionClosingDateText(dto.closingDateTime);
    this.matchingYn = dto.matchingYn ?? false;
  }
}
