import { useMutation } from 'react-query';
import { RemoteLogRepo } from './LogRepo';
import LogRq from '@domain/rq/log/LogRq';

const keys = ['log'];

const repo = new RemoteLogRepo();

const fetchLog = async (rq: LogRq) => {
  const { data } = await repo.fetchLog(rq);
  return data;
};

export function useLog() {
  return useMutation(fetchLog);
}
