import { FC } from 'react';
import Lottie from 'react-lottie';
import Text from '@components/common/text';
import fireCracker from '@graphic/fireCracker.json';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface Step4Props {
  totalResumeCount: number;
  nextStep: () => void;
}

const options = {
  loop: true,
  autoplay: true,
  animationData: fireCracker,
};

const Step5: FC<Step4Props> = ({ totalResumeCount, nextStep }) => {
  return (
    <div className={cx('stepWrap')}>
      <div className={cx('contents')}>
        <Text fontStyle={FontStyle.B2_M} color={Colors.C_GREEN_200}>
          축하해요! 총 {totalResumeCount}개의 프로필 정보가 추가되었어요!
        </Text>

        <div className={cx('titleArea')}>
          <>
            불러온 프로필 정보를 상세히 작성하고
            <br />
            인사 담당자에게 많은 제안을 받아보세요!
          </>
        </div>

        <div className={cx('lottieArea')}>
          <Lottie options={options} />
        </div>
      </div>

      <div className={cx('btnArea', 'end')}>
        <button className={cx('confirm')} onClick={nextStep}>
          내 프로필에서 확인하기
        </button>
      </div>
    </div>
  );
};

export default Step5;
