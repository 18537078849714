import MatchingStatusListRs, {
  MatchResponseType,
  MatchStatusType,
  MatchingStatusDto,
} from '@domain/rs/match/MatchingStatusListRs';
import DateFormat from '@utils/date/format';
import DateUtil from '@utils/date/util';

export default class MatchingStatusListVO {
  matchingList: MatchingStatusVO[];
  firstRecommendOrProfileOpenMatchingSn?: number;

  constructor(rs: MatchingStatusListRs) {
    this.matchingList = (rs.matchingList ?? []).map((dto) => {
      return new MatchingStatusVO(dto);
    });
    this.firstRecommendOrProfileOpenMatchingSn = this.matchingList.find(
      (item) => (item.statusType === 'RECOMMEND' || item.statusType === 'PROFILE_OPEN') && !item.likeYn,
    )?.matchingSn;
  }
}

export class MatchingStatusVO {
  matchingSn: number;
  companySn: number;
  companyName: string;
  companyLogoUrl: string;
  positionSn: number | null;
  positionName: string | null;
  positionClosingDate: Date | null;
  matchingStatusLastModifiedDate: Date;
  statusType: MatchStatusType;
  responseType: MatchResponseType;
  readYn: boolean;
  likeYn: boolean;
  isNeedBadge: boolean;
  matchingStatusLastModifiedDateText: string;
  decisionDueDate: Date | null;
  dueDateDDay: number | null;
  decisionDueDateDDayText: string | null;
  isOfferClosed: boolean;

  constructor(dto: MatchingStatusDto) {
    const currentDate = DateUtil.getNow();

    this.matchingSn = dto.matchingSn;
    this.companySn = dto.companySn;
    this.companyName = dto.companyName;
    this.companyLogoUrl = dto.companyLogoUrl;
    this.positionSn = dto.positionSn;
    this.positionName = dto.positionTitle;
    this.positionClosingDate = dto.positionClosingDateTime ? new Date(dto.positionClosingDateTime) : null;
    this.matchingStatusLastModifiedDate = new Date(dto.matchingStatusLastModifiedDateTime ?? '');
    this.statusType = dto.statusType;
    this.responseType = dto.responseType;
    this.readYn = dto.readYn;
    this.likeYn = dto.likeYn;
    this.matchingStatusLastModifiedDateText = DateFormat.format(this.matchingStatusLastModifiedDate, 'yyyy.MM.dd');

    this.decisionDueDate = dto.decisionDueDateTime ? new Date(dto.decisionDueDateTime) : null;
    this.dueDateDDay = this.decisionDueDate !== null ? DateUtil.getLeftDays(currentDate, this.decisionDueDate) : null;
    this.decisionDueDateDDayText = (() => {
      if (this.dueDateDDay === null) return null;
      if (this.dueDateDDay === 0) return 'D-Day';
      if (this.dueDateDDay > 0) return `D-${this.dueDateDDay}`;
      return null;
    })();
    this.isNeedBadge =
      this.statusType === 'OFFER' && this.responseType === null && this.dueDateDDay !== null && this.dueDateDDay >= 0;
    this.isOfferClosed =
      dto.statusType === 'OFFER_CLOSED' ||
      (this.responseType === null &&
        !!this.positionClosingDate &&
        this.positionClosingDate < DateUtil.getNow() &&
        (this.dueDateDDay === null || this.dueDateDDay < 0));
  }
}
