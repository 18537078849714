import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useController } from 'react-hook-form';
import NoSsr from '@common/noSsr';
import { ReactSelectStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import { useJobGroupsWithTitles } from '@repository/codes/useCodes';
import { JobGroupVO } from '@domain/vo/profile/ProfileCareersVO';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const JobGroup = ({ prefixName }: HookFormProps) => {
  const [jobGroup, setJobGroup] = useState<JobGroupVO[]>([]);
  const { field, fieldState } = useController({ name: `${prefixName}.jobGroup` });
  const { data: jobGroupsData } = useJobGroupsWithTitles();

  useEffect(() => {
    setJobGroup(jobGroupsData?.map((e) => new JobGroupVO(e)) ?? []);
  }, [jobGroupsData]);

  return (
    <>
      <NoSsr>
        <Select
          {...field}
          className={cx('select', { error: fieldState.invalid })}
          placeholder={'직군을 선택해 주세요.'}
          styles={ReactSelectStyles}
          isSearchable={false}
          options={jobGroup}
        />
      </NoSsr>
      {fieldState.invalid && <div className={cx('errorMessage')}>직군을 선택해 주세요.</div>}
    </>
  );
};

export default JobGroup;
