import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import MatchConditionLastModifiedRs from '@domain/rs/match/conditions/MatchConditionLastModifiedRs';

export default interface MatchConditionsRepo {
  // 선호 정보 마지막 수정일
  fetchConditionsLastModified(): Promise<AxiosResponse<MatchConditionLastModifiedRs>>;

  // 선호 정보 이대로 제안 받기
  fetchConditionsLastModifiedSkip(): Promise<AxiosResponse<boolean>>;
}

export class RemoteMatchConditionsRepo implements MatchConditionsRepo {
  fetchConditionsLastModified(): Promise<AxiosResponse<MatchConditionLastModifiedRs>> {
    return axios.get('/match/conditions/last-modified');
  }

  fetchConditionsLastModifiedSkip(): Promise<AxiosResponse<boolean>> {
    return axios.post('/match/conditions/last-modified/skip');
  }
}
