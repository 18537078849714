import { getOptions } from '@domain/constant/ReactSelectOption';

export enum EducationLevel {
  HIGHSCHOOL = 'HIGHSCHOOL', //'고졸'
  ASSOCIATE = 'ASSOCIATE', //'초대졸'
  BACHELOR = 'BACHELOR', //'대졸'
  MASTER = 'MASTER', //'석사'
  DOCTOR = 'DOCTOR', //'박사'
}

export type EducationLevelType = keyof typeof EducationLevel;

export const EducationLevelShortNameMap: Record<EducationLevelType, string> = {
  [EducationLevel.HIGHSCHOOL]: '고졸',
  [EducationLevel.ASSOCIATE]: '초대졸',
  [EducationLevel.BACHELOR]: '대졸',
  [EducationLevel.MASTER]: '석사',
  [EducationLevel.DOCTOR]: '박사',
};

export const EducationLevelNameMap: Record<EducationLevelType, string> = {
  [EducationLevel.HIGHSCHOOL]: '고등학교',
  [EducationLevel.ASSOCIATE]: '전문대',
  [EducationLevel.BACHELOR]: '대학교',
  [EducationLevel.MASTER]: '대학원(석사)',
  [EducationLevel.DOCTOR]: '대학원(박사)',
};

export const educationLevelOptions = getOptions(EducationLevelNameMap);

// 학업 상태
export enum EducationStatus {
  GRADUATION = 'GRADUATION', // 졸업
  ATTENDING = 'ATTENDING', // 재학중
  GRADUATION_CANDIDATE = 'GRADUATION_CANDIDATE', // 졸업 예정
  COMPLETE = 'COMPLETE', // 수료
  LEAVE = 'LEAVE', // 휴학중
  DROP = 'DROP', // 중퇴
}

export type EducationStatusType = keyof typeof EducationStatus;

export const EducationNameMap: Record<EducationStatusType, string> = {
  [EducationStatus.GRADUATION]: '졸업',
  [EducationStatus.ATTENDING]: '재학 중',
  [EducationStatus.GRADUATION_CANDIDATE]: '졸업예정',
  [EducationStatus.COMPLETE]: '수료',
  [EducationStatus.LEAVE]: '휴학 중',
  [EducationStatus.DROP]: '중퇴',
};

//전공 타입
export enum Major {
  DOUBLE = 'DOUBLE',
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
}

export type MajorType = keyof typeof Major;

export const MajorNameMap: Record<MajorType, string> = {
  [Major.DOUBLE]: '복수전공',
  [Major.MAJOR]: '주전공',
  [Major.MINOR]: '부전공',
};

//학점 타입
export enum Score {
  PERCENTAGE = 'PERCENTAGE',
  SCORE = 'SCORE',
}

export type ScoreType = keyof typeof Score;

export const ScoreNameMap: Record<ScoreType, string> = {
  [Score.PERCENTAGE]: '백분위',
  [Score.SCORE]: '학점',
};

export const educationTypeOptions = getOptions(EducationNameMap);

export const getEducationDateText = ({
  educationType,
  startDate,
  endDate,
  educationTypeText,
}: {
  educationType: EducationStatusType;
  startDate: string | null;
  endDate?: string | null;
  educationTypeText?: string;
}) => {
  switch (educationType) {
    case 'GRADUATION':
      return `${startDate} ~ ${endDate}`;
    case 'GRADUATION_CANDIDATE':
      return `${startDate} ~ ${endDate} (졸업예정)`;
    case 'ATTENDING':
    case 'COMPLETE':
    case 'LEAVE':
      return `${startDate} ~ ${educationTypeText}`;
    case 'DROP':
      return `${startDate} ~ ${endDate} (중퇴)`;
    default:
      return '';
  }
};
