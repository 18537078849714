import { useQuery } from 'react-query';
import { RemoteProfilesRepo } from '@repository/profiles/ProfilesRepo';

export const keys = ['profile', 'profilesType'];

const remoteProfilesRepo = new RemoteProfilesRepo();

const fetchProfilesType = async () => {
  const { data } = await remoteProfilesRepo.fetchProfilesType();
  return data;
};

export function useProfilesType(isEnabled: boolean) {
  return useQuery(keys, fetchProfilesType, {
    enabled: isEnabled,
  });
}
