import { CheckListType } from '@components/common/modals/resumeProfileModal/step3';
import CareerFormVO, { BaseProfileCareerVO } from './CareerFormVO';
import EducationFormVO, { BaseProfileEducationsVO } from './EductaionFormVO';
import ExperienceFormVO, { BaseProfileExperienceVO } from './ExperienceFormVO';
import PrizeFormVO, { BaseProfileCareerPrizeVO } from './PrizeFormVO';
import ProjectFormVO, { BaseProfileProjectsVO } from './ProjectFormVO';
import { BaseProfileKnowledgeAndSkillsDto, InsightResumeGetRs } from '@domain/rs/insight/InsightResumeGetRs';

export class InsightResumeGetFormVO {
  education: EducationFormVO;
  career: CareerFormVO;
  project: ProjectFormVO;
  knowledgeAndSkill: BaseProfileKnowledgeAndSkillsDto;
  experience: ExperienceFormVO;
  prize: PrizeFormVO;

  constructor(rs: InsightResumeGetRs) {
    this.education = new EducationFormVO(rs.education);
    this.career = new CareerFormVO(rs.career);
    this.project = new ProjectFormVO(rs.project);
    this.knowledgeAndSkill = rs.knowledgeAndSkill;
    this.experience = new ExperienceFormVO(rs.experience);
    this.prize = new PrizeFormVO(rs.prize);
  }

  public static convertRqFormVO(
    vo: InsightResumeGetFormVO,
    checkList: Map<CheckListType, number[]>,
  ): InsightResumeGetRs {
    return {
      education: {
        educations: vo.education.educations
          .map((item, idx) => {
            if (checkList.get('education')?.includes(idx)) return BaseProfileEducationsVO.convertRqFormVO(item);
            else return null;
          })
          .filter((item) => !!item),
      },

      career: {
        careers: vo.career.careers
          .map((item, idx) => {
            if (checkList.get('career')?.includes(idx)) return BaseProfileCareerVO.convertRqFormVO(item);
            else return null;
          })
          .filter((item) => !!item),
      },

      project: {
        projects: vo.project.projects
          .map((item, idx) => {
            if (checkList.get('project')?.includes(idx)) return BaseProfileProjectsVO.convertRqFormVO(item);
            else return null;
          })
          .filter((item) => !!item),
      },

      knowledgeAndSkill: vo.knowledgeAndSkill,

      experience: {
        activitiesAndForeignExperiences: vo.experience.activitiesAndForeignExperiences
          .map((item, idx) => {
            if (checkList.get('experience')?.includes(idx)) return BaseProfileExperienceVO.convertRqFormVO(item);
            else return null;
          })
          .filter((item) => !!item),
      },

      prize: {
        prizes: vo.prize.prizes
          .map((item, idx) => {
            if (checkList.get('prize')?.includes(idx)) return BaseProfileCareerPrizeVO.convertRqFormVO(item);
            else return null;
          })
          .filter((item) => !!item),
      },
    } as InsightResumeGetRs;
  }
}
