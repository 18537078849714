import { AxiosResponse } from 'axios';
import { ResultRs } from '@domain/rs/common/SuccessRs';
import { DuplicateRs } from '@domain/rs/common/DuplicateRs';
import { axios } from '@repository/RequestClient';
import JoinRq from '@domain/rq/register/JoinRq';

export default interface RegisterRepo {
  //회원 가입 저장
  fetchSaveJoinContent(param: JoinRq): Promise<AxiosResponse<ResultRs>>;

  //이메일 중복 체크
  fetchCheckDuplicationEmail(email: string): Promise<AxiosResponse<DuplicateRs>>;

  //핸드폰 중복 체크  : 비로그인용 : ex) 회원가입
  fetchDuplicationMobileCheck({
    mobile,
    countryCode,
  }: {
    mobile: string;
    countryCode: string;
  }): Promise<AxiosResponse<boolean>>;

  //아이디 중복 체크
  fetchCheckDuplicationID(id: string): Promise<AxiosResponse<DuplicateRs>>;
}

export class RemoteRegisterRepo implements RegisterRepo {
  fetchSaveJoinContent(param: JoinRq) {
    return axios.post(`/v5/register`, param);
  }

  fetchCheckDuplicationEmail(email: string) {
    return axios.get(`/register/duplication/check/email`, { params: { email } });
  }

  fetchDuplicationMobileCheck({ mobile, countryCode }: { mobile: string; countryCode: string }) {
    return axios.get(`/v2/register/duplication/check/mobile`, { params: { mobile, countryCode } });
  }

  fetchCheckDuplicationID(id: string) {
    return axios.get(`/register/duplication/check/id`, { params: { id } });
  }
}
