import { FC } from 'react';

// SVG icons
// A
import IconAccaComponent from './icon_acca.svg';
import IconAddLightComponent from './icon_add_light.svg';
import IconAlertLightComponent from './icon_alert_light.svg';
import IconArrowBottomLightComponent from './icon_arrow_bottom_light.svg';
import IconArrowDownwardLightComponent from './icon_arrow_downward_light.svg';
import IconArrowLeftLightComponent from './icon_arrow_left_light.svg';
import IconArrowRightLightComponent from './icon_arrow_right_light.svg';
import IconArrowTopLightComponent from './icon_arrow_top_light.svg';
import IconArrowUpwardLightComponent from './icon_arrow_upward_light.svg';

// B
import IconBellLightComponent from './icon_bell_light.svg';
import IconBellOffLightComponent from './icon_bell_off_light.svg';
import IconBetaComponent from './icon_beta.svg';
import IconBookGreenComponent from './icon_book_green.svg';
import IconBookmarkLightComponent from './icon_bookmark_light.svg';
import IconBookmarkLightFilledComponent from './icon_bookmark_light_filled.svg';
import IconBriefcaseYellow from './icon_briefcase_yellow.svg';
import IconBusinessFilledLightComponent from './icon_business_filled_light.svg';
import IconBusinessLightComponent from './icon_business_light.svg';

// C
import IconCalendarRed from './icon_calendar.svg';
import IconCalendarBlue from './icon_calendar_blue.svg';
import IconCalenderLightComponent from './icon_calender_light.svg';
import IconCameraLightComponent from './icon_camera_light.svg';
import IconChartLightComponent from './icon_chart_light.svg';
import IconChatBlueComponent from './icon_chat_blue.svg';
import IconChatYellowComponent from './icon_chat_yellow.svg';
import IconCheckCircleLightComponent from './icon_check_circle_light.svg';
import IconCheckCircleLightFilledComponent from './icon_check_circle_light_filled.svg';
import IconCheckLightComponent from './icon_check_light.svg';
import IconCloseLightComponent from './icon_close_light.svg';
import IconCodingComponent from './icon_coding.svg';
import IconContentsLightComponent from './icon_contents_light.svg';

// D
import IconDeleteLightComponent from './icon_delete_light.svg';
import IconDesignLightComponent from './icon_design_light.svg';
import IconDiamondLightComponent from './icon_diamond_light.svg';
import IconDiversity2Component from './icon_diversity_2.svg';
import IconDocComponent from './icon_doc_separation.svg';
import IconDoneGreenFillComponent from './icon_done_green_fill.svg';
import IconDoubleQuotationComponent from './icon_double_quotation.svg';
import IconDownSLightFilledComponent from './icon_down_s_light_filled.svg';
import IconDownloadComponent from './icon_download_light.svg';

// E
import IconEnterLightComponent from './icon_enter_light.svg';
import IconErrorLightComponent from './icon_error_light.svg';
import IconErrorLightFilledComponent from './icon_error_light_filled.svg';

// F
import IconFileAddLightComponent from './icon_file_add_light.svg';
import IconFilterLightComponent from './icon_filter_light.svg';
import IconFlagRedComponent from './icon_flag_red.svg';
import IconFolderLightComponent from './icon_folder_light.svg';

// G
import IconGiftComponent from './icon_gift_red.svg';
import IconGuideAccaGreenComponent from './icon_guide_acca_green.svg';
import IconGuideLightComponent from './icon_guide_light.svg';

// H
import IconHandshakeComponent from './icon_handshake.svg';
import IconHeartLightComponent from './icon_heart_light.svg';
import IconHeartLightFilledComponent from './icon_heart_light_filled.svg';
import IconHomeLightComponent from './icon_home_light.svg';

// I
import IconInfoUserComponent from './icon_info_user.svg';
import IconInformationLightComponent from './icon_information_light.svg';
import IconInvitationUserComponent from './icon_invitation_user.svg';

// J
import IconJoyEmptyComponent from './icon_joy_empty.svg';
import IconJoyEmpty2Component from './icon_joy_empty2.svg';

// K

// L
import IconLaptopAccaBlueComponent from './icon_laptop_acca_blue.svg';
import IconLargeArrowBottomLightComponent from './icon_large_arrow_bottom_light.svg';
import IconLargeArrowLeftLightComponent from './icon_large_arrow_left_light.svg';
import IconLargeArrowRightLightComponent from './icon_large_arrow_right_light.svg';
import IconLargeArrowTopLightComponent from './icon_large_arrow_top_light.svg';
import IconLinkArrowComponent from './icon_link_arrow.svg';
import IconLinkLineLightComponent from './icon_link_line_light.svg';
import IconListCheckComponent from './icon_list_check.svg';
import IconListViewLightComponent from './icon_list_view_light.svg';
import IconLockGoldComponent from './icon_lock_gold.svg';
import IconLockGreenComponent from './icon_lock_green.svg';
import IconLoginSocialApple from './icon_login_social_apple.svg';
import IconLoginSocialGoogle from './icon_login_social_google.svg';
import IconLoginSocialKakao from './icon_login_social_kakao.svg';
import IconLogoComponent from './icon_logo.svg';

// M
import IconMatchingGreenComponent from './icon_matching_green.svg';
import IconMatchingPersonGreenComponent from './icon_matching_person_green.svg';
import IconMatchingYellowComponent from './icon_matching_yellow.svg';
import IconMessageLightComponent from './icon_message_light.svg';
import IcomMicLightComponent from './icon_mic_light.svg';
import IconMilitaryLightComponent from './icon_military_light.svg';
import IconMoreHorizonLightComponent from './icon_more_horizon_light.svg';
import IconMoveLightComponent from './icon_move_light.svg';

// N
import IconNewComponent from './icon_new.svg';
import IconNewLongComponent from './icon_new_long.svg';

// O
import IconOpenInNewLightComponent from './icon_open_in_new_light.svg';
import IconOutputLightComponent from './icon_output_light.svg';

// P
import IconPageChecklistBlue from './icon_page_checklist_blue.svg';
import IconPeopleLightComponent from './icon_people_light.svg';
import IconPinLightComponent from './icon_pin_light.svg';
import IconProfileDefaultComponent from './icon_profile_default.svg';
import IconProjectLightComponent from './icon_project_light.svg';

// Q

// R
import IconReadBookLightComponent from './icon_read_book_light.svg';
import IconRemoveLightComponent from './icon_remove_light.svg';
import IconRemoveLightFilledComponent from './icon_remove_light_filled.svg';
import IconResetLightComponent from './icon_reset_light.svg';
import IconResumeCopyComponent from './icon_resume_copy.svg';
import IconResumeDoneGreenComponent from './icon_resume_done_green.svg';
import IconRocket from './icon_rocket.svg';

// S
import IconSearchAccaBlueComponent from './icon_search_acca_blue.svg';
import IconSearchLightComponent from './icon_search_light.svg';
import IconShareFacebookComponent from './icon_share_facebook.svg';
import IconShareKakaoComponent from './icon_share_kakako.svg';
import IconShareUrlComponent from './icon_share_URL.svg';
import IconSpeakerLightComponent from './icon_speaker_light.svg';
import IconStarComponent from './icon_star.svg';
import IconStdioBlueComponent from './icon_stdio_blue.svg';
import IconSummaryBuildingComponent from './icon_summary_building.svg';
import IconSummaryFlagComponent from './icon_summary_flag.svg';
import IconSummaryGraphBarComponent from './icon_summary_graph_bar.svg';
import IconSummaryPeopleComponent from './icon_summary_people.svg';
import IconSummaryPresentationComponent from './icon_summary_presentation.svg';
import IconSummarySalaryComponent from './icon_summary_salary.svg';
import IconSummaryStarComponent from './icon_summary_star.svg';

// T
import IconTestAccaComponent from './icon_test_acca.svg';
import IconTestCodingBlueComponent from './icon_test_coding_blue.svg';
import IconThumbsUpLightComponent from './icon_thumbs_up_light.svg';
import IconTimeLightComponent from './icon_time_light.svg';
import IconTooltipBottomTailComponent from './icon_tooltip_bottom_tail.svg';
import IconTooltipTopTailComponent from './icon_tooltip_top_tail.svg';

// U
import IconUniversityLightComponent from './icon_university_light.svg';
import IconUpSLightLightFilledComponent from './icon_up_s_light_filled.svg';
import IconUploadLightComponent from './icon_upload_light.svg';
import IconUserLightComponent from './icon_user_light.svg';

// V
import IconVerifiedLightComponent from './icon_verified_light.svg';
import IconVerifiedLightFilledComponent from './icon_verified_light_filled.svg';
import IconVisibilityLightComponent from './icon_visibility_light.svg';
import IconVisibilityOffLightComponent from './icon_visibility_off_light.svg';
import IconVolumeMuteLightComponent from './icon_volume_mute_light.svg';

// W
import IconWifiOffComponent from './icon_wifi_off.svg';
import IconWorkLightComponent from './icon_work_light.svg';

import Colors from '@domain/constant/colors';

import classnames from 'classnames/bind';
import styles from './assets.module.scss';
const cx = classnames.bind(styles);

/**
 * 컴포넌트 작성법
 * @param className(선택) 한가지 색으로 이루어진 svg파일만 asset클래스를 추가해줍니다.
 * @param fill(선택) className과 마찬가지로 한가지 색으로 이루어져있다면 fill($잡다 블랙)을 추가해줍니다.
 * @param viewBox(필수) svg파일에 적혀있는 viewBox를 그대로 사용해줍니다.
 * @param props(필수)
 */
export enum IconName {
  acca,
  addLight,
  alertLight,
  arrowBottomLight,
  arrowLeftLight,
  arrowRightLight,
  arrowTopLight,
  arrowDownwardLight,
  arrowUpwardLight,
  bellLight,
  bellOffLight,
  bookGreen,
  bookmarkLightFilled,
  bookmarkLight,
  businessLight,
  businessFilledLight,
  beta,
  briefcaseYellow,
  calendarBlue,
  calenderLight,
  calendarRed,
  cameraLight,
  chartLight,
  chatYellow,
  chatBlue,
  checkCircleLight,
  checkCircleLightFilled,
  checkLight,
  closeLight,
  coding,
  contentsLight,
  deleteLight,
  designLight,
  diamondLight,
  diversity2,
  doc,
  doneGreenFill,
  downSLightFilled,
  download,
  doubleQuotation,
  enterLight,
  errorLight,
  errorLightFilled,
  fileAddLight,
  folderLight,
  filterLight,
  flagRed,
  guideAccaGreen,
  guideLight,
  micLight,
  gift,
  heartLight,
  heartLightFilled,
  homeLight,
  handShake,
  infoUser,
  informationLight,
  invitationUser,
  laptopAccaBlue,
  largeArrowTopLight,
  largeArrowRightLight,
  largeArrowBottomLight,
  largeArrowLeftLight,
  linkLineLight,
  linkArrow,
  listViewLight,
  listCheck,
  loginSocialApple,
  loginSocialGoogle,
  loginSocialKakao,
  logo,
  lockGold,
  lockGreen,
  messageLight,
  militaryLight,
  moreHorizonLight,
  moveLight,
  matchingPersonGreen,
  matchingYellow,
  matchingGreen,
  new,
  newLong,
  openInNewLight,
  outputLight,
  pageChecklistBlue,
  peopleLight,
  pinLight,
  profileDefault,
  projectLight,
  readBookLight,
  removeLightFilled,
  removeLight,
  resetLight,
  resumeDoneGreen,
  resumeCopy,
  rocket,
  searchAccaBlue,
  searchLight,
  shareFacebook,
  shareKakao,
  shareUrl,
  star,
  stdioBlue,
  summaryBuilding,
  summaryFlag,
  summaryGraphBar,
  summaryPeople,
  summaryPresentation,
  summarySalary,
  summaryStar,
  speakerLight,
  testAcca,
  testCodingBlue,
  thumbsUpLight,
  tooltipBottomTail,
  tooltipTopTail,
  timeLight,
  universityLight,
  upSLightLightFilled,
  uploadLight,
  userLight,
  verifiedLight,
  verifiedLightFilled,
  visibilityOffLight,
  visibilityLight,
  volumeMuteLight,
  workLight,
  wifiOff,
  joyEmpty,
  joyEmpty2,
}

export type IconNameType = keyof typeof IconName;

interface IconProps {
  name: IconNameType;
  width?: number;
  height?: number;
  fill?: string;
}

const Icon: FC<IconProps> = (props) => {
  switch (props.name) {
    //A
    case 'acca':
      return <IconAccaComponent viewBox={'0 0 56 56'} {...props} />;
    case 'addLight':
      return (
        <IconAddLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'alertLight':
      return (
        <IconAlertLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'arrowBottomLight':
      return (
        <IconArrowBottomLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'arrowLeftLight':
      return (
        <IconArrowLeftLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'arrowRightLight':
      return (
        <IconArrowRightLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'arrowTopLight':
      return (
        <IconArrowTopLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'arrowDownwardLight':
      return (
        <IconArrowDownwardLightComponent
          className={cx('asset')}
          viewBox={'0 0 16 16'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'arrowUpwardLight':
      return (
        <IconArrowUpwardLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    //B
    case 'bellLight':
      return (
        <IconBellLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'bellOffLight':
      return (
        <IconBellOffLightComponent
          className={cx('asset')}
          viewBox={'0 0 24 24'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'bookGreen':
      return <IconBookGreenComponent viewBox={'0 0 56 56'} {...props} />;
    case 'bookmarkLightFilled':
      return (
        <IconBookmarkLightFilledComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'bookmarkLight':
      return (
        <IconBookmarkLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'businessLight':
      return (
        <IconBusinessLightComponent
          className={cx('asset')}
          viewBox={'0 0 16 16'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'businessFilledLight':
      return (
        <IconBusinessFilledLightComponent
          className={cx('asset')}
          viewBox={'0 0 16 16'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'beta':
      return <IconBetaComponent className={cx('asset')} viewBox={'0 0 21 10'} fill={Colors.C_WHITE} {...props} />;
    case 'briefcaseYellow':
      return <IconBriefcaseYellow viewBox={'0 0 24 20'} {...props} />;
    //C
    case 'calendarBlue':
      return <IconCalendarBlue viewBox={'0 0 20 20'} {...props} />;
    case 'calendarRed':
      return <IconCalendarRed viewBox={'0 0 24 24'} {...props} />;
    case 'calenderLight':
      return (
        <IconCalenderLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'cameraLight':
      return (
        <IconCameraLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'chartLight':
      return (
        <IconChartLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'chatYellow':
      return <IconChatYellowComponent viewBox={'0 0 56 56'} {...props} />;
    case 'chatBlue':
      return <IconChatBlueComponent viewBox={'0 0 28 28'} {...props} />;
    case 'checkCircleLight':
      return (
        <IconCheckCircleLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'checkCircleLightFilled':
      return (
        <IconCheckCircleLightFilledComponent
          className={cx('asset')}
          viewBox={'0 0 32 32'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'checkLight':
      return (
        <IconCheckLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'closeLight':
      return (
        <IconCloseLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'coding':
      return <IconCodingComponent viewBox={'0 0 56 56'} {...props} />;
    case 'contentsLight':
      return (
        <IconContentsLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    //D
    case 'deleteLight':
      return (
        <IconDeleteLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'designLight':
      return (
        <IconDesignLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'diamondLight':
      return (
        <IconDiamondLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'diversity2':
      return <IconDiversity2Component viewBox={'0 0 56 56'} {...props} />;
    case 'doc':
      return <IconDocComponent viewBox={'0 0 24 24'} {...props} />;
    case 'doneGreenFill':
      return <IconDoneGreenFillComponent viewBox={'0 0 56 56'} {...props} />;
    case 'downSLightFilled':
      return (
        <IconDownSLightFilledComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'download':
      return (
        <IconDownloadComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'doubleQuotation':
      return (
        <IconDoubleQuotationComponent
          className={cx('asset')}
          viewBox={'0 0 20 12'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    //E
    case 'enterLight':
      return (
        <IconEnterLightComponent className={cx('asset')} viewBox={'0 0 24 24'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'errorLight':
      return (
        <IconErrorLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'errorLightFilled':
      return (
        <IconErrorLightFilledComponent
          className={cx('asset')}
          viewBox={'0 0 32 32'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    //F
    case 'fileAddLight':
      return (
        <IconFileAddLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'folderLight':
      return (
        <IconFolderLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'filterLight':
      return (
        <IconFilterLightComponent
          className={cx('asset')}
          viewBox={'0 0 24 24'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'flagRed':
      return <IconFlagRedComponent viewBox={'0 0 28 28'} {...props} />;
    //G
    case 'guideAccaGreen':
      return <IconGuideAccaGreenComponent viewBox={'0 0 56 56'} {...props} />;
    case 'guideLight':
      return (
        <IconGuideLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'gift':
      return <IconGiftComponent viewBox={'0 0 24 24'} {...props} />;
    //H
    case 'heartLight':
      return (
        <IconHeartLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'heartLightFilled':
      return <IconHeartLightFilledComponent viewBox={'0 0 24 24'} {...props} />;
    case 'homeLight':
      return (
        <IconHomeLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'handShake':
      return <IconHandshakeComponent viewBox={'0 0 24 24'} {...props} />;
    //I
    case 'infoUser':
      return <IconInfoUserComponent viewBox={'0 0 56 56'} {...props} />;
    case 'informationLight':
      return (
        <IconInformationLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'invitationUser':
      return <IconInvitationUserComponent viewBox={'0 0 48 48'} {...props} />;
    //J
    case 'joyEmpty':
      return <IconJoyEmptyComponent viewBox={'0 0 96 96'} {...props} />;
    case 'joyEmpty2':
      return <IconJoyEmpty2Component viewBox={'0 0 102 87'} {...props} />;

    //K
    //L
    case 'laptopAccaBlue':
      return <IconLaptopAccaBlueComponent viewBox={'0 0 56 56'} {...props} />;
    case 'largeArrowTopLight':
      return (
        <IconLargeArrowTopLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'largeArrowRightLight':
      return (
        <IconLargeArrowRightLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'largeArrowBottomLight':
      return (
        <IconLargeArrowBottomLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'largeArrowLeftLight':
      return (
        <IconLargeArrowLeftLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'linkLineLight':
      return (
        <IconLinkLineLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'listViewLight':
      return (
        <IconListViewLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'listCheck':
      return <IconListCheckComponent viewBox={'0 0 64 64'} {...props} />;
    case 'loginSocialApple':
      return <IconLoginSocialApple viewBox={'0 0 20 20'} {...props} />;
    case 'loginSocialGoogle':
      return <IconLoginSocialGoogle viewBox={'0 0 40 40'} {...props} />;
    case 'loginSocialKakao':
      return <IconLoginSocialKakao viewBox={'0 0 21 20'} {...props} />;
    case 'logo':
      return <IconLogoComponent className={cx('asset')} viewBox={'0 0 80 18'} fill={Colors.C_JOBDA_BLACK} {...props} />;
    case 'lockGold':
      return <IconLockGoldComponent viewBox={'0 0 220 220'} {...props} />;
    case 'lockGreen':
      return <IconLockGreenComponent viewBox={'0 0 40 40'} {...props} />;
    case 'linkArrow':
      return <IconLinkArrowComponent className={cx('asset')} viewBox={'0 0 32 32'} {...props} />;
    //M
    case 'messageLight':
      return (
        <IconMessageLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'militaryLight':
      return (
        <IconMilitaryLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'moreHorizonLight':
      return (
        <IconMoreHorizonLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'moveLight':
      return (
        <IconMoveLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'matchingPersonGreen':
      return <IconMatchingPersonGreenComponent viewBox={'0 0 64 64'} {...props} />;
    case 'matchingYellow':
      return <IconMatchingYellowComponent viewBox={'0 0 56 56'} {...props} />;
    case 'matchingGreen':
      return <IconMatchingGreenComponent viewBox="0 0 100 100" {...props} />;
    case 'micLight':
      return <IcomMicLightComponent className={cx('asset')} {...props} />;
    //N
    case 'new':
      return <IconNewComponent viewBox={'0 0 10 11'} {...props} />;
    case 'newLong':
      return (
        <IconNewLongComponent className={cx('asset')} viewBox={'0 0 19 7'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    //O
    case 'openInNewLight':
      return (
        <IconOpenInNewLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'outputLight':
      return (
        <IconOutputLightComponent
          className={cx('asset')}
          viewBox={'0 0 32 32'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    //P
    case 'pageChecklistBlue':
      return <IconPageChecklistBlue viewBox={'0 0 22 22'} {...props} />;
    case 'peopleLight':
      return (
        <IconPeopleLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'pinLight':
      return (
        <IconPinLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'profileDefault':
      return <IconProfileDefaultComponent viewBox={'0 0 47 47'} {...props} />;
    case 'projectLight':
      return (
        <IconProjectLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    //Q
    //R
    case 'readBookLight':
      return (
        <IconReadBookLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'removeLightFilled':
      return (
        <IconRemoveLightFilledComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'removeLight':
      return (
        <IconRemoveLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'resetLight':
      return (
        <IconResetLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'resumeDoneGreen':
      return <IconResumeDoneGreenComponent viewBox={'0 0 56 56'} {...props} />;
    case 'resumeCopy':
      return <IconResumeCopyComponent viewBox={'0 0 64 64'} {...props} />;
    case 'rocket':
      return <IconRocket viewBox={'0 0 20 20'} {...props} />;
    //S
    case 'searchAccaBlue':
      return <IconSearchAccaBlueComponent viewBox={'0 0 56 56'} {...props} />;
    case 'searchLight':
      return (
        <IconSearchLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'shareFacebook':
      return (
        <IconShareFacebookComponent
          className={cx('asset')}
          viewBox={'0 0 28 28'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'shareKakao':
      return (
        <IconShareKakaoComponent className={cx('asset')} viewBox={'0 0 28 28'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'shareUrl':
      return (
        <IconShareUrlComponent className={cx('asset')} viewBox={'0 0 28 28'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'star':
      return <IconStarComponent viewBox={'0 0 10 10'} {...props} />;
    case 'stdioBlue':
      return <IconStdioBlueComponent viewBox={'0 0 56 56'} {...props} />;
    case 'summaryBuilding':
      return <IconSummaryBuildingComponent viewBox={'0 0 40 40'} {...props} />;
    case 'summaryFlag':
      return <IconSummaryFlagComponent viewBox={'0 0 40 40'} {...props} />;
    case 'summaryGraphBar':
      return <IconSummaryGraphBarComponent viewBox={'0 0 40 40'} {...props} />;
    case 'summaryPeople':
      return <IconSummaryPeopleComponent viewBox={'0 0 40 40'} {...props} />;
    case 'summaryPresentation':
      return <IconSummaryPresentationComponent viewBox={'0 0 40 40'} {...props} />;
    case 'summarySalary':
      return <IconSummarySalaryComponent viewBox={'0 0 40 40'} {...props} />;
    case 'summaryStar':
      return <IconSummaryStarComponent viewBox={'0 0 40 40'} {...props} />;
    case 'speakerLight':
      return (
        <IconSpeakerLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    //T
    case 'testAcca':
      return <IconTestAccaComponent viewBox={'0 0 56 56'} {...props} />;
    case 'testCodingBlue':
      return <IconTestCodingBlueComponent viewBox={'0 0 56 56'} {...props} />;
    case 'thumbsUpLight':
      return (
        <IconThumbsUpLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'tooltipBottomTail':
      return (
        <IconTooltipBottomTailComponent
          className={cx('asset')}
          viewBox={'0 0 8 8'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'tooltipTopTail':
      return (
        <IconTooltipTopTailComponent
          className={cx('asset')}
          viewBox={'0 0 8 8'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'timeLight':
      return (
        <IconTimeLightComponent className={cx('asset')} viewBox={'0 0 24 24'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    //U
    case 'universityLight':
      return (
        <IconUniversityLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'upSLightLightFilled':
      return (
        <IconUpSLightLightFilledComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'uploadLight':
      return (
        <IconUploadLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'userLight':
      return (
        <IconUserLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    //V
    case 'verifiedLight':
      return (
        <IconVerifiedLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'verifiedLightFilled':
      return (
        <IconVerifiedLightFilledComponent
          className={cx('asset')}
          viewBox={'0 0 32 32'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'visibilityOffLight':
      return (
        <IconVisibilityOffLightComponent
          className={cx('asset')}
          viewBox={'0 0 16 16'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'visibilityLight':
      return (
        <IconVisibilityLightComponent
          className={cx('asset')}
          viewBox={'0 0 24 24'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    case 'volumeMuteLight':
      return (
        <IconVolumeMuteLightComponent
          className={cx('asset')}
          viewBox={'0 0 48 48'}
          fill={Colors.C_JOBDA_BLACK}
          {...props}
        />
      );
    //W
    case 'workLight':
      return (
        <IconWorkLightComponent className={cx('asset')} viewBox={'0 0 48 48'} fill={Colors.C_JOBDA_BLACK} {...props} />
      );
    case 'wifiOff':
      return <IconWifiOffComponent viewBox={'0 0 148 148'} {...props} />;
  }
};

export default Icon;
