import React from 'react';
import Colors from '@domain/constant/colors';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fill?: React.CSSProperties['fill'];
  stroke?: React.CSSProperties['stroke'];
}

const IconTip = ({
  width = 8,
  height = 8,
  fill = Colors.C_JOBDA_BLACK,
  stroke = Colors.C_COOL_GRAY_40,
  ...props
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_5034_21590)">
        <path d="M-0.330127 8.5L4 1L8.33013 8.5L-0.330127 8.5Z" fill={fill} stroke={stroke} />
      </g>
      <defs>
        <clipPath id="clip0_5034_21590">
          <rect width="8" height="8" fill="white" transform="translate(8) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconTip;
