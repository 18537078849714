import { BaseProfileCareerDto, BaseProfileCareersDto } from '@domain/rs/insight/InsightResumeGetRs';
import { BasicCompanyInfoDto } from '@domain/rs/profile/career/CompaniesRs';
import { ReactSelectOption } from '@domain/constant/ReactSelectOption';
import { EmploymentStatusType, EmploymentType, EmploymentNameMap } from '@domain/constant/profile/CareerType';
import DateFormat from '@utils/date/format';

export default class CareerFormVO {
  careers: BaseProfileCareerVO[];

  constructor(dto: BaseProfileCareersDto) {
    this.careers = dto.careers.map((item) => new BaseProfileCareerVO(item));
  }
}

export class BaseProfileCareerVO {
  sn: number | null;
  companySn: number | null;
  companyName: ReactSelectOption<BasicCompanyInfoDto> | null;
  employmentStatus: EmploymentStatusType | null; // 재직 상태
  startDate: string | null;
  endDate: string | null;
  jobGroup: ReactSelectOption | null; // 직군 코드
  jobTitle: ReactSelectOption | null; // 직무 코드;
  employmentType: ReactSelectOption<EmploymentType> | null; // 계약 형태
  performance: string;
  role: string;
  verified: boolean;

  constructor(dto: BaseProfileCareerDto) {
    this.sn = dto.sn;
    this.companySn = dto.companySn;
    this.companyName =
      dto.companyName && dto.companySn && dto.companyName
        ? { value: { name: dto.companyName, sn: dto.companySn }, label: dto.companyName ?? '' }
        : null;
    this.employmentStatus = dto.employmentStatus;
    this.startDate = DateFormat.getDateFormatString(dto.startDate, 'yyyy-MM') ?? '';
    this.endDate = DateFormat.getDateFormatString(dto.endDate, 'yyyy-MM') ?? '';
    this.jobGroup = dto.jobGroupCode && dto.jobGroupName ? { value: dto.jobGroupCode, label: dto.jobGroupName } : null;
    this.jobTitle = dto.jobTitleCode && dto.jobTitleName ? { value: dto.jobTitleCode, label: dto.jobTitleName } : null;
    this.employmentType =
      dto.employmentType && EmploymentNameMap[dto.employmentType]
        ? { value: dto.employmentType, label: EmploymentNameMap[dto.employmentType] }
        : null;
    this.performance = dto.performance ?? '';
    this.role = dto.role ?? '';
    this.verified = dto.verified;
  }

  public static convertRqFormVO(vo: BaseProfileCareerVO): BaseProfileCareerDto {
    return {
      sn: vo.sn,
      companySn: vo.companySn,
      companyName: vo.companyName?.label ?? null,
      employmentStatus: vo.employmentStatus,
      startDate: vo.startDate,
      endDate: vo.endDate,
      jobGroupCode: vo.jobGroup?.value ?? null,
      jobGroupName: vo.jobGroup?.label ?? null,
      jobTitleCode: vo.jobTitle?.value ?? null,
      jobTitleName: vo.jobTitle?.label ?? null,
      employmentType: vo.employmentType?.value ?? null,
      performance: vo.performance,
      role: vo.role,
      verified: vo.verified,
    } as BaseProfileCareerDto;
  }
}
