import InsTitution from './items/Institution';
import Name from './items/Name';
import PrizeDate from './items/PrizeDate';
import Description from './items/Description';
import classnames from 'classnames/bind';
import styles from '../commonEdit.module.scss';
const cx = classnames.bind(styles);

const Prize = ({ prefixName }: HookFormProps) => {
  return (
    <>
      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>수여 기관</span>
        <InsTitution prefixName={prefixName} />
      </div>
      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>수상명</span>
        <Name prefixName={prefixName} />
      </div>
      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>수상 일자</span>
        <PrizeDate prefixName={prefixName} />
      </div>
      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle')}>내용</span>
        <Description prefixName={prefixName} />
      </div>
    </>
  );
};

export default Prize;
