import React from 'react';
import { useController } from 'react-hook-form';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const ProjectPlace = ({ prefixName }: HookFormProps) => {
  const { field, fieldState } = useController({ name: `${prefixName}.institution` });
  return (
    <div className={cx('inputArea')}>
      <input
        {...field}
        className={cx('input', { error: fieldState.invalid })}
        type="text"
        placeholder={'프로젝트를 진행한 학교/회사/기관을 입력해 주세요.'}
        maxLength={30}
      />
      {fieldState.invalid && <div className={cx('errorMessage')}>{fieldState.error?.message}</div>}
    </div>
  );
};

export default ProjectPlace;
