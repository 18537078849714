import { SourcePage } from '@domain/rq/DataLog';

export default class MatchingConditionWelfareRq {
  welfareKeywords: number[];
  sourcePage?: SourcePage;

  constructor(rq: MatchingConditionWelfareRq) {
    this.welfareKeywords = rq.welfareKeywords;
    this.sourcePage = rq.sourcePage;
  }
}
