// 대분류 - 프로필 항목 (필수값 체크 시 보이는 컴포넌트 항목)
export enum ProfileItem {
  BASIC = 'BASIC', // 기본정보
  EDUCATION = 'EDUCATION', // 학력
  CAREER = 'CAREER', // 경력
  PROJECT = 'PROJECT', // 프로젝트
  KNOWLEDGE_AND_SKILL = 'KNOWLEDGE_AND_SKILL', // 지식 · 기술
  PREFERENTIAL = 'PREFERENTIAL', // 병역 · 취업 우대
  FILE = 'FILE', // 첨부자료
}
export type ProfileItemType = keyof typeof ProfileItem;
export const ProfileItemNameMap: Record<ProfileItemType, string> = {
  [ProfileItem.BASIC]: '기본정보',
  [ProfileItem.EDUCATION]: '학력',
  [ProfileItem.CAREER]: '경력',
  [ProfileItem.PROJECT]: '프로젝트',
  [ProfileItem.KNOWLEDGE_AND_SKILL]: '지식 · 기술',
  [ProfileItem.PREFERENTIAL]: '병역 · 취업 우대',
  [ProfileItem.FILE]: '첨부자료',
};

// 소분류 - ats 설정 필수 값(각 컴포넌트 내 세부항목)
// 기본정보
export enum BasicItem {
  NAME = 'NAME',
  BIRTH_DATE = 'BIRTH_DATE',
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
}
export type BasicItemType = keyof typeof BasicItem;
export const BasicItemNameMap: Record<BasicItemType, string> = {
  [BasicItem.NAME]: '이름',
  [BasicItem.BIRTH_DATE]: '생년월일',
  [BasicItem.EMAIL]: '이메일',
  [BasicItem.MOBILE]: '휴대폰',
  [BasicItem.PROFILE_IMAGE]: '사진',
};

// 학력
export enum EducationItem {
  EDUCATION = 'EDUCATION',
  EDUCATION_SCORE = 'EDUCATION_SCORE',
  EDUCATION_RESEARCH = 'EDUCATION_RESEARCH',
}
export type EducationItemType = keyof typeof EducationItem;
export const EducationItemNameMap: Record<EducationItemType, string> = {
  [EducationItem.EDUCATION]: '학력',
  [EducationItem.EDUCATION_SCORE]: '학점',
  [EducationItem.EDUCATION_RESEARCH]: '연구분야 · 논문',
};

// 경력
export enum CareerItem {
  CAREER = 'CAREER',
}
export type CareerItemType = keyof typeof CareerItem;

// 프로젝트
export enum ProjectItem {
  PROJECT = 'PROJECT',
}
export type ProjectItemType = keyof typeof ProjectItem;

// 지식 · 기술
export enum KnowledgeAndSkillItem {
  LICENSE = 'LICENSE',
  LANGUAGE = 'LANGUAGE',
}
export type KnowledgeAndSkillItemType = keyof typeof KnowledgeAndSkillItem;
export const KnowledgeAndSkillItemNameMap: Record<KnowledgeAndSkillItemType, string> = {
  [KnowledgeAndSkillItem.LICENSE]: '자격증',
  [KnowledgeAndSkillItem.LANGUAGE]: '공인 어학시험',
};

// 병역 · 취업 우대
export enum PreferentialItem {
  MILITARY = 'MILITARY',
  DISABILITY = 'DISABILITY',
  VETERAN = 'VETERAN',
}
export type PreferentialItemType = keyof typeof PreferentialItem;
export const PreferentialItemNameMap: Record<PreferentialItemType, string> = {
  [PreferentialItem.MILITARY]: '병역',
  [PreferentialItem.DISABILITY]: '장애',
  [PreferentialItem.VETERAN]: '보훈',
};

// 첨부자료
export enum FileItem {
  PORTFOLIO = 'PORTFOLIO',
  CAREER_ATTACH_FILE = 'CAREER_ATTACH_FILE',
  URL = 'URL',
}
export type FileItemType = keyof typeof FileItem;
export const FileItemNameMap: Record<FileItemType, string> = {
  [FileItem.PORTFOLIO]: '포트폴리오',
  [FileItem.CAREER_ATTACH_FILE]: '경력기술서',
  [FileItem.URL]: 'URL',
};

// 추가정보
export enum AdditionalItem {
  TEXT = 'TEXT', // 텍스트 입력형
  SINGLE_CHOICE = 'SINGLE_CHOICE', // 단일 선택형
  MULTI_CHOICE = 'MULTI_CHOICE', // 다중 선택형
  DROPDOWN = 'DROPDOWN', // 드롭다운 선택형
  ADDITION_ATTACH_FILE = 'ADDITION_ATTACH_FILE', // 파일 첨부형
}
export type AdditionalItemType = keyof typeof AdditionalItem;
export const AdditionalItemNameMap: Record<AdditionalItemType, string> = {
  [AdditionalItem.TEXT]: '텍스트 입력형',
  [AdditionalItem.SINGLE_CHOICE]: '단일 선택형',
  [AdditionalItem.MULTI_CHOICE]: '다중 선택형',
  [AdditionalItem.DROPDOWN]: '드롭다운 선택형',
  [AdditionalItem.ADDITION_ATTACH_FILE]: '파일 첨부형',
};
