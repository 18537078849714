import React from 'react';
import StartDate from './items/StartDate';
import EndDate from './items/EndDate';
import Description from './items/Description';
import CountryName from './items/CountryName';
import classnames from 'classnames/bind';
import styles from '../commonEdit.module.scss';

const cx = classnames.bind(styles);

const ForeignExperience = ({ prefixName }: HookFormProps) => {
  return (
    <>
      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>국가명</span>
        <CountryName prefixName={prefixName} />
      </div>
      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle', 'required')}>기간</span>
        {/* 재학 기간 */}
        <div className={cx('periodArea')}>
          <StartDate prefixName={prefixName} />
          <mark>~</mark>
          <EndDate prefixName={prefixName} />
        </div>
      </div>
      <div className={cx('writeWrap')}>
        <span className={cx('writeTitle')}>내용</span>
        <Description prefixName={prefixName} foreign />
      </div>
    </>
  );
};

export default ForeignExperience;
