import { PositionBookmarkListItemVO } from '@domain/vo/common/PositionBookmarkListItemVO';
import AccRecommendPositionListRs from '@domain/rs/acca/test/AccRecommendPositionListRs';
import { AccaResultType } from '@domain/constant/acca/AccaResultType';
import { competenceProfileMap, CompetenceType } from '@domain/constant/competenceType';

export default class AccRecommendPositionListVO {
  positions: PositionBookmarkListItemVO[];

  constructor(rs: AccRecommendPositionListRs) {
    this.positions = rs.positions.map((position) => new PositionBookmarkListItemVO(position));
  }

  static getSectionDesc(profileResultType?: CompetenceType, type?: AccaResultType) {
    if (profileResultType && type === 'ACC_RESULT_TYPE') {
      const { name } = competenceProfileMap[profileResultType];
      return `${name} 유형이 많이 본 포지션이에요`;
    }
    return '추천 직군과 딱 맞는 포지션이에요';
  }
}
