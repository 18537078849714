import { AxiosError } from 'axios';
import { UseMutationOptions, UseQueryOptions, useMutation } from 'react-query';
import { VerificationSmsCheckRq, VerificationUserSmsCheckRq } from '@domain/rq/common/VerificationMobileRq';
import { RemoteVerificationRepo } from '@repository/verification/VerificationRepo';
import { RemoteRegisterRepo } from '@repository/register/RegisterRepo';
import { RemoteUsersRepo } from '@repository/users/UsersRepo';
import VerificationSmsSendIdRq from '@domain/rq/sms/VerificationSmsSendIdRq';
import VerificationSmsSendPasswordRq from '@domain/rq/sms/VerificationSmsSendPasswordRq';
import VerificationSmsSendRq from '@domain/rq/sms/VerificationSmsSendRq';

const remoteVerificationRepo = new RemoteVerificationRepo();
const remoteRegisterRepo = new RemoteRegisterRepo();
const remoteUsersRepo = new RemoteUsersRepo();

const fetchVerificationSmsSend = async (rq: VerificationSmsSendRq) => {
  const { data } = await remoteVerificationRepo.fetchVerificationSmsSend(new VerificationSmsSendRq(rq));
  return data;
};

export function useVerificationSmsSend(queryOptions?: UseQueryOptions) {
  return useMutation((rq: VerificationSmsSendRq) => fetchVerificationSmsSend(rq), queryOptions as any);
}

const fetchVerificationSmsCheck = async (rq: VerificationSmsCheckRq) => {
  const { data } = await remoteVerificationRepo.fetchVerificationSmsCheck(rq);
  return data;
};

export function useVerificationSmsCheck(onSuccess?: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq: VerificationSmsCheckRq) => fetchVerificationSmsCheck(rq), {
    onSuccess,
    onError,
  });
}

const fetchVerificationUserSmsSend = async ({ mobile, countryCode }: { mobile: string; countryCode: string }) => {
  const { data } = await remoteVerificationRepo.fetchVerificationUserSmsSend({ mobile, countryCode });
  return data;
};

export function useVerificationUserSmsSend(queryOptions?: UseMutationOptions) {
  return useMutation(
    ({ mobile, countryCode }: { mobile: string; countryCode: string }) =>
      fetchVerificationUserSmsSend({ mobile, countryCode }),
    queryOptions as any,
  );
}

const fetchVerificationUserSmsCheck = async (param: VerificationUserSmsCheckRq) => {
  const { data } = await remoteVerificationRepo.fetchVerificationUserSmsCheck(param);
  return data;
};

export function useVerificationUserSmsCheck(onSuccess?: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((param: VerificationUserSmsCheckRq) => fetchVerificationUserSmsCheck(param), {
    onSuccess,
    onError,
  });
}

const fetchDuplicationMobileCheck = async ({ mobile, countryCode }: { mobile: string; countryCode: string }) => {
  const { data } = await remoteRegisterRepo.fetchDuplicationMobileCheck({ mobile, countryCode });
  return data;
};

export function useDuplicationMobileCheck() {
  return useMutation(({ mobile, countryCode }: { mobile: string; countryCode: string }) =>
    fetchDuplicationMobileCheck({ mobile, countryCode }),
  );
}

const fetchDuplicationMobileCheckWhenLogin = async ({
  mobile,
  countryCode,
}: {
  mobile: string;
  countryCode: string;
}) => {
  const { data } = await remoteUsersRepo.fetchDuplicationMobileCheckWhenLogin({ mobile, countryCode });
  return data;
};

export function useDuplicationMobileCheckWhenLogin(onSuccess?: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(
    ({ mobile, countryCode }: { mobile: string; countryCode: string }) =>
      fetchDuplicationMobileCheckWhenLogin({ mobile, countryCode }),
    {
      onSuccess,
      onError,
    },
  );
}

const updateVerificationLongTermInActiveCheck = async (rq?: VerificationUserSmsCheckRq) => {
  await remoteVerificationRepo.updateVerificationLongTermInActiveCheck(rq);
};

export const useVerificationLongTermInActiveCheck = (options?: UseQueryOptions) => {
  return useMutation(updateVerificationLongTermInActiveCheck, options as any);
};

const fetchVerificationSmsSendId = async (rq: VerificationSmsSendIdRq) => {
  const { data } = await remoteVerificationRepo.fetchVerificationSmsSendId(new VerificationSmsSendIdRq(rq));
  return data;
};

export function useVerificationSmsSendId(queryOptions?: UseQueryOptions) {
  return useMutation((rq: VerificationSmsSendIdRq) => fetchVerificationSmsSendId(rq), queryOptions as any);
}

const fetchVerificationSmsSendPassword = async (rq: VerificationSmsSendPasswordRq) => {
  const { data } = await remoteVerificationRepo.fetchVerificationSmsSendPassword(rq);
  return data;
};

export function useVerificationSmsSendPassword(queryOptions?: UseQueryOptions) {
  return useMutation((rq: VerificationSmsSendPasswordRq) => fetchVerificationSmsSendPassword(rq), queryOptions as any);
}
