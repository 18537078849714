const isServer = typeof window === 'undefined';

export interface setItemOptions {
  expires?: Date;
}

export default class LocalStorageUtil {
  static isExpired(expires: string | undefined) {
    if (!expires) return false;
    return new Date().getTime() > Number(expires);
  }

  static setItem(key: string, value: string, options?: setItemOptions) {
    if (isServer) return;
    const newValue = options?.expires ? { value: value, expires: options.expires.getTime() } : { value };

    try {
      localStorage.setItem(encodeURIComponent(key), JSON.stringify(newValue));
    } catch (e) {
      console.error(e);
    }
  }

  static getItem(key: string): string | null {
    if (isServer) return null;

    const item = localStorage.getItem(encodeURIComponent(key));
    if (!item) return null;

    try {
      const { value, expires } = JSON.parse(item);

      if (LocalStorageUtil.isExpired(expires)) {
        LocalStorageUtil.removeItem(key);
        return null;
      }

      return value;
    } catch (e) {
      return null;
    }
  }

  static removeItem(key: string) {
    if (isServer) return;
    return localStorage.removeItem(encodeURIComponent(key));
  }

  /**
   * setItem과 차이점: 값 parse 처리를 내부적으로 처리
   * @param key localStorage key
   * @param value localStorage에 저장할 value
   */
  static setValue<T>(key: string, value: T) {
    if (isServer) return;
    localStorage.setItem(encodeURIComponent(key), JSON.stringify(value));
  }

  /**
   * getItem과 차이점: 값 parse 처리를 내부적으로 처리
   * @returns setValue에서 저장한 value와 다를 수 있음
   */
  static getValue<T>(key: string) {
    if (isServer) return;
    const value = localStorage.getItem(encodeURIComponent(key));
    if (value === null) return null;
    return JSON.parse(value) as T;
  }
}
