import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import LoginRq from '@domain/rq/LoginRq';
import LoginRs, { GoogleTokenRs, KaKaoTokenRs, SocicalLoginRs } from '@domain/rs/LoginRs';
import RenewAccessTokenRs from '@domain/rs/RenewAccessTokenRs';
import UserRs from '@domain/rs/UserRs';

export default interface AuthRepo {
  //접속 유저 정보
  getUser(): Promise<AxiosResponse<UserRs>>;

  //로그인
  login(loginRq: LoginRq): Promise<AxiosResponse<LoginRs>>;

  //로그아웃
  logout(): Promise<AxiosResponse<boolean>>;

  //AccessToken 재발급
  renewAccessToken(): Promise<AxiosResponse<RenewAccessTokenRs>>;

  getKakaoToken(data: string): Promise<AxiosResponse<KaKaoTokenRs>>;

  getGoogleToken(data: string): Promise<AxiosResponse<GoogleTokenRs>>;

  kakaoLogin(token: string): Promise<AxiosResponse<SocicalLoginRs>>;

  googleLogin(token: string): Promise<AxiosResponse<SocicalLoginRs>>;

  appleLogin(code: string): Promise<AxiosResponse<SocicalLoginRs>>;

  //Access token, Refresh token 재발급
  renewAccessTokenRefreshToken(): Promise<AxiosResponse<LoginRs>>;
}

export class RemoteAuthRepo implements AuthRepo {
  getUser() {
    return axios.get<UserRs>('/login/user/info', { withCredentials: true });
  }

  login(loginRq: LoginRq) {
    return axios.post<LoginRs>('/login', loginRq, { withCredentials: true });
  }

  logout() {
    return axios.post<boolean>('/logout', null, { withCredentials: true });
  }

  renewAccessToken() {
    return axios.get<RenewAccessTokenRs>('/access-token', { withCredentials: true });
  }

  getKakaoToken(data: string) {
    return axios.post<KaKaoTokenRs>('https://kauth.kakao.com/oauth/token', data);
  }

  getGoogleToken(data: string) {
    return axios.post<GoogleTokenRs>('https://www.googleapis.com/oauth2/v4/token', data);
  }

  kakaoLogin(token: string) {
    return axios.post<SocicalLoginRs>('/v2/oauth2/kakao/login', { accessToken: token }, { withCredentials: true });
  }

  googleLogin(token: string) {
    return axios.post<SocicalLoginRs>('/v2/oauth2/google/login', { accessToken: token }, { withCredentials: true });
  }

  appleLogin(code: string) {
    return axios.post<SocicalLoginRs>('/oauth2/apple/login', { code }, { withCredentials: true });
  }

  renewAccessTokenRefreshToken() {
    return axios.get<LoginRs>('/reissue-token', { withCredentials: true });
  }
}
