import React, { KeyboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useToast } from '@components/common/toast';
import Button from '@components/common/button';
import { FindStateType, ModalStateType } from '@components/loginModal';
import { useLogin } from '@repository/auth/useUserInfo';
import LabelInput from '@components/labelInput';
import { ContentRouteUtil, V2RouteUrl } from '@utils/routeUtil';

import { onInputPassword } from '@utils/validators/validators';
import { UtmEventQuery } from '@utils/routeUtil/UtmEventQuery';
import DateUtil from '@utils/date/util';
import classnames from 'classnames/bind';
import styles from './loginForm.module.scss';

const cx = classnames.bind(styles);

export interface LoginFormField {
  id: string;
  password: string;
  autoLogin: boolean;
}

interface ILoginFormProps {
  redirectUrl?: string;
  afterLogin?: () => void;
  setModalState: (state: ModalStateType) => void;
  setFindState: (state: FindStateType) => void;
  isAutoLogin?: boolean;
  onClose?: () => void;
  visibleJoinButton?: boolean;
}

const LoginForm = ({
  redirectUrl,
  afterLogin,
  setModalState,
  setFindState,
  isAutoLogin = true,
  onClose,
  visibleJoinButton = true,
}: ILoginFormProps) => {
  const Toast = useToast();
  const isReload = !redirectUrl && !afterLogin;
  const { mutateAsync: fetchLogin } = useLogin(isReload, () => onClose?.());
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [onCapsLock, setOnCapsLock] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormField>();

  const { pathname } = useRouter();

  //이벤트 페이지 유입일 경우 utm 데이터 포함해서 회원 가입페이지로 이동
  const signUpQuery =
    UtmEventQuery.find(({ incomingUrls, startDate, endDate }) => {
      //이벤트 기간에 포함되어 있는지 여부 확인
      if (!DateUtil.getWhetherIncludedInThePeriod(startDate, endDate)) return false;
      //현재 페이지가 이벤트 유입 페이지인지 확인
      return incomingUrls.some((url) => url.includes(pathname));
    })?.queryString ?? {};

  const handlePasswordVisible = () => setPasswordVisible(!passwordVisible);

  const findIdPassword = () => {
    setFindState('ID');
    setModalState('FIND');
  };

  const checkCapsLock = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.getModifierState('CapsLock')) setOnCapsLock(true);
    else setOnCapsLock(false);
  };

  const onSubmit = async (values: LoginFormField) => {
    try {
      await fetchLogin(values);
      redirectUrl && ContentRouteUtil.goRedirectPage(redirectUrl);
      afterLogin?.();
    } catch (e) {
      handleLoginError(e as unknown as AxiosError);
    }
  };

  const handleLoginError = (e: AxiosError) => {
    setIsLoginError(true);
    if (e?.response?.data.errorCode === 'U003') {
      setFindState('PASSWORD');
      setModalState('FIND');
      Toast({
        type: 'error',
        iconType: 'info',
        content: (
          <>
            5회 이상 로그인에 실패하였습니다. <br />
            비밀번호 재설정 후 로그인 해주세요.
          </>
        ),
      });
    }
  };

  return (
    <div className={cx('formWrap')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={cx('infoWrap')}>
          <LabelInput
            {...register('id', { required: true })}
            type="text"
            className={cx('input', errors.id && 'error')}
            placeholder="아이디"
            onChange={(e) => {
              setValue('id', e.target.value);
              setIsLoginError(false);
              setOnCapsLock(false);
            }}
            isvalue={true}
          />
          {errors.id && <div className={cx('infoText', 'error')}>아이디를 입력해 주세요.</div>}
        </div>
        <div className={cx('infoWrap')}>
          <LabelInput
            {...register('password', { required: true })}
            type={passwordVisible ? 'text' : 'password'}
            className={cx('input', 'withButton', errors.password && 'error')}
            placeholder="비밀번호"
            onChange={(e) => {
              setValue('password', e.target.value);
              setIsLoginError(false);
            }}
            minLength={6}
            maxLength={16}
            onInput={onInputPassword}
            onKeyUp={checkCapsLock}
            isvalue={true}
          />
          {errors.password && <div className={cx('infoText', 'error')}>비밀번호를 입력해 주세요.</div>}
          <span className={cx('btnArea')}>
            <button
              type="button"
              tabIndex={-1}
              onClick={handlePasswordVisible}
              className={cx('btnConfirmPassword', passwordVisible && 'on')}
            />
          </span>
        </div>
        {isLoginError && (
          <div className={cx('infoWrap')}>
            <div className={cx('infoText', 'error')}>가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.</div>
          </div>
        )}
        {onCapsLock && (
          <div className={cx('infoWrap')}>
            <div className={cx('infoText')}>Caps Lock이 켜져있습니다.</div>
          </div>
        )}
        <div className={cx('optionArea')}>
          <label className={cx('label', { visible: isAutoLogin })}>
            <input {...register('autoLogin')} type="checkbox" className={cx('input', 'hidden')} />
            <span className={cx('checkbox')} />
            자동 로그인
          </label>
          <button className={cx('btnFindIdPassword')} type="button" onClick={findIdPassword}>
            아이디·비밀번호 찾기
          </button>
        </div>
        <button className={cx('btnLogin')} type="submit">
          로그인
        </button>
      </form>
      {visibleJoinButton && (
        <Link
          href={{
            pathname: V2RouteUrl.JOIN,
            query: signUpQuery,
          }}
        >
          <Button theme="secondary" className={cx('signUp')}>
            회원가입
          </Button>
        </Link>
      )}
    </div>
  );
};

export default LoginForm;
