import { PATTERN } from '@utils/validators/pattern';

export default class TextFormat {
  // ()포함 시 제외하고 첫 글자 표시
  static formatRemoveParenthesesCompanyName(companyName: string) {
    const parenthesisWordRegx = /\(.*?\)/g;

    const firstChar = companyName.replace(parenthesisWordRegx, '').trim().substring(0, 1);
    return firstChar;
  }

  //예) textList 2개이상일떄 return "직무명+N"
  static formatSummaryListWithCount(list: string[]) {
    if (list.length === 1) return list[0];
    else if (list.length > 1) return `${list[0]}+${list.length - 1}`;
    return '';
  }

  static formatProtocol(url: string) {
    if (url.match(PATTERN.PROTOCOL)) return url;
    return `//${url}`;
  }

  //문자열 말줄임 처리
  static formatEllipsis(str: string, length: number) {
    if (str.length > length) {
      return str.substr(0, length).trim() + '...';
    }
    return str;
  }

  static formatMobileWithHypen(mobile: string, separator: string = '-') {
    const removeHypenMobile = mobile.replace(/[^0-9]/g, '');
    let convertMobile = '';

    if (removeHypenMobile.length < 4) {
      return removeHypenMobile;
    } else if (removeHypenMobile.length < 7) {
      convertMobile += removeHypenMobile.substring(0, 3);
      convertMobile += separator;
      convertMobile += removeHypenMobile.substring(3);
    } else if (removeHypenMobile.length < 11) {
      convertMobile += removeHypenMobile.substring(0, 3);
      convertMobile += separator;
      convertMobile += removeHypenMobile.substring(3, 6);
      convertMobile += separator;
      convertMobile += removeHypenMobile.substring(6);
    } else {
      convertMobile += removeHypenMobile.substring(0, 3);
      convertMobile += separator;
      convertMobile += removeHypenMobile.substring(3, 7);
      convertMobile += separator;
      convertMobile += removeHypenMobile.substring(7);
    }

    return convertMobile;
  }

  static birthDate(birthDate: string, separator: string = '-') {
    const removeSeparatorDate = birthDate.replace(/[^0-9]/g, '');
    let convertDate = '';

    if (removeSeparatorDate.length < 5) {
      convertDate += removeSeparatorDate.substring(0, 4);
    } else if (removeSeparatorDate.length < 7) {
      convertDate += removeSeparatorDate.substring(0, 4);
      convertDate += separator;
      convertDate += removeSeparatorDate.substring(4);
    } else {
      convertDate += removeSeparatorDate.substring(0, 4);
      convertDate += separator;
      convertDate += removeSeparatorDate.substring(4, 6);
      convertDate += separator;
      convertDate += removeSeparatorDate.substring(6);
    }

    return convertDate;
  }

  static hypenMobile(mobile: string) {
    const removeHypenMobileNum = mobile.replace(/[^0-9]/g, '');
    let convertMobile = '';

    if (removeHypenMobileNum.length < 4) {
      return removeHypenMobileNum;
    } else if (removeHypenMobileNum.length < 7) {
      convertMobile += removeHypenMobileNum.substring(0, 3);
      convertMobile += '-';
      convertMobile += removeHypenMobileNum.substring(3);
    } else if (removeHypenMobileNum.length < 11) {
      convertMobile += removeHypenMobileNum.substring(0, 3);
      convertMobile += '-';
      convertMobile += removeHypenMobileNum.substring(3, 6);
      convertMobile += '-';
      convertMobile += removeHypenMobileNum.substring(6);
    } else {
      convertMobile += removeHypenMobileNum.substring(0, 3);
      convertMobile += '-';
      convertMobile += removeHypenMobileNum.substring(3, 7);
      convertMobile += '-';
      convertMobile += removeHypenMobileNum.substring(7);
    }

    return convertMobile;
  }
}
