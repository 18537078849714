import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import Modal from '@common/modal';
import NoSsr from '@common/noSsr';
import { ReactSelectStyles } from '@components/common/matchSwitch/matchProposalModals/applyCancelModal/ReactSelectStyles';
import Button from '@components/common/button';
import { ReactSelectOption } from '@domain/constant/ReactSelectOption';
import classnames from 'classnames/bind';
import styles from './applyCancelModal.module.scss';
const cx = classnames.bind(styles);

export const MATCHING_CANCEL_REASON_LIST = {
  EMPLOYED: '취업을 해서 잡다매칭이 필요없어요',
  NO_INTENTION: '취업의사가 없는 상태에요',
  MATCHING: '추천받은 공고가 마음에 들지 않아요',
  KAKAO: '카카오 알림톡을 받고 싶지 않아요',
};

const reasonList: ReactSelectOption[] = [
  {
    label: MATCHING_CANCEL_REASON_LIST.EMPLOYED,
    value: MATCHING_CANCEL_REASON_LIST.EMPLOYED,
  },
  {
    label: MATCHING_CANCEL_REASON_LIST.NO_INTENTION,
    value: MATCHING_CANCEL_REASON_LIST.NO_INTENTION,
  },
  {
    label: MATCHING_CANCEL_REASON_LIST.MATCHING,
    value: MATCHING_CANCEL_REASON_LIST.MATCHING,
  },
  {
    label: MATCHING_CANCEL_REASON_LIST.KAKAO,
    value: MATCHING_CANCEL_REASON_LIST.KAKAO,
  },
];

interface IApplyCancelModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
  onNext: () => void;
}

const ApplyCancelModal: FC<IApplyCancelModalProps> = ({ visible, onClose, onConfirm, onNext }) => {
  const [selected, setSelected] = useState<any>(null);
  const isDisabledBtn = selected === null;

  const handleChangeReason = (option: any) => {
    setSelected(option);
  };

  const handleConfirm = () => {
    if (!selected) return;

    if (selected.value === MATCHING_CANCEL_REASON_LIST.MATCHING) {
      onNext();
    } else {
      onConfirm(selected.value);
      onClose();
    }
  };

  useEffect(() => {
    if (visible) setSelected(null);
  }, [visible]);

  return (
    <Modal visible={visible} dimmed={true}>
      <div className={cx('layer')}>
        <h1 className={cx('title')}>서비스 이용을 취소하시겠어요?</h1>
        <p className={cx('desc')}>
          개선을 위해 <em className={cx('emphasis')}>취소 사유</em>를 알려주세요.
        </p>
        <div className={cx('selectArea')}>
          <NoSsr>
            <Select
              className={cx('select')}
              placeholder={'취소 사유를 선택해 주세요.'}
              styles={ReactSelectStyles}
              isSearchable={false}
              options={reasonList}
              onChange={handleChangeReason}
              value={selected}
            />
          </NoSsr>
        </div>

        <div className={cx('btnArea')}>
          <Button className={cx('btn')} theme="secondary" onClick={onClose}>
            취소
          </Button>
          <Button className={cx('btn')} disabled={isDisabledBtn} onClick={handleConfirm}>
            확인
          </Button>
        </div>

        <a className={cx('btnClose')} role="button" onClick={onClose}>
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default ApplyCancelModal;
