import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import MatchingRejectRq from '@domain/rq/match/MatchingRejectRq';
import MatchingConfigUpdateRq from '@domain/rq/match/MatchingConfigUpdateRq';
import MatchingStatusListRq, { MatchingStatusListTabType } from '@domain/rq/match/MatchingStatusListRq';
import MatchingAcceptRq from '@domain/rq/match/MatchingAccept';
import MatchingConfigJobSearchRq from '@domain/rq/match/MatchingConfigJobSearchRq';
import MatchingStatusListRs from '@domain/rs/match/MatchingStatusListRs';
import MatchingProfileRs from '@domain/rs/match/MatchingProfileRs';
import MatchingResponseStatusRs from '@domain/rs/match/MatchingResponseStatusRs';
import MatchingConfigConditionRs from '@domain/rs/match/MatchingConfigConditionRs';
import MatchingStatusRs from '@domain/rs/match/MatchingStatusRs';
import ProfileDetailRs from '@domain/rs/position/jd/ProfileDetailRs';
import { MatchingCompaniesRs } from '@domain/rs/match/MatchingCompaniesRs';

export default interface MatchRepo {
  // 매칭 현황 리스트 조회
  getMatchingStatusList(params?: MatchingStatusListRq): Promise<AxiosResponse<MatchingStatusListRs>>;

  // 매칭 프로필 조회
  getMatchingProfile(): Promise<AxiosResponse<MatchingProfileRs>>;

  // 제안 거절
  fetchRejectMatching(rq: MatchingRejectRq): Promise<AxiosResponse>;

  // 매칭 포지션 응답률 조회
  fetchMatchingResponseStatus(): Promise<AxiosResponse<MatchingResponseStatusRs>>;

  // 매칭 설정 상태 조회
  fetchMatchConfigStatus(): Promise<AxiosResponse<MatchingConfigConditionRs>>;

  // 매칭 설정 상태 변경
  updateMatchConfigStatus(rq: MatchingConfigUpdateRq): Promise<AxiosResponse>;

  // 매칭 포지션 전부 읽음 처리
  fetchReadMatchingPositionAll(tabType: MatchingStatusListTabType): Promise<AxiosResponse>;

  // 매칭 포지션 읽음 처리
  fetchReadMatchingPosition(matchingSn: number): Promise<AxiosResponse>;

  // 매칭 현황 조회
  getMatchingStatus(): Promise<AxiosResponse<MatchingStatusRs>>;

  // 구직 상태 저장
  saveJobSearchStatus(rq: MatchingConfigJobSearchRq): Promise<AxiosResponse>;

  // 제안 수락
  fetchAcceptMatching(rq: MatchingAcceptRq): Promise<AxiosResponse>;

  // 제안 수락 CBO
  fetchAcceptCBOMatching(rq: MatchingAcceptRq): Promise<AxiosResponse>;

  // 제안 받은 프로필 상세 조회
  fetchMatchingPositionDetail(matchingSn: number): Promise<AxiosResponse<ProfileDetailRs>>;

  // 관심 포지션 추가
  likeFavoritePosition(positionSn: number): Promise<AxiosResponse<void>>;

  // 관림 포지션 해제
  unlikeFavoritePosition(positionSn: number): Promise<AxiosResponse<void>>;

  // 잡다매칭 참여중인 기업 목록 조회
  getMatchingCompanyList(): Promise<AxiosResponse<MatchingCompaniesRs>>;
}

export class RemoteMatchRepo implements MatchRepo {
  getMatchingStatusList(params?: MatchingStatusListRq) {
    return axios.get<MatchingStatusListRs>('/v2/match/list', { params });
  }

  getMatchingProfile() {
    return axios.get<MatchingProfileRs>('/match/profile');
  }

  fetchRejectMatching(rq: MatchingRejectRq) {
    return axios.post('/v2/match/reject', rq);
  }

  fetchMatchingResponseStatus() {
    return axios.get<MatchingResponseStatusRs>('/match/response-status');
  }

  fetchMatchConfigStatus() {
    return axios.get<MatchingConfigConditionRs>('/match/config/status');
  }

  updateMatchConfigStatus(rq: MatchingConfigUpdateRq) {
    return axios.put('/match/config/status', rq);
  }

  fetchReadMatchingPositionAll(tabType: MatchingStatusListTabType) {
    return axios.post('/v2/match/read/all', null, { params: { tabType } });
  }

  fetchReadMatchingPosition(matchingSn: number) {
    return axios.post('/v2/match/read', { matchingSn });
  }

  getMatchingStatus() {
    return axios.get<MatchingStatusRs>('/v3/match/status');
  }

  saveJobSearchStatus(rq: MatchingConfigJobSearchRq) {
    return axios.put('/match/config/job-search/status', rq);
  }

  fetchAcceptMatching(rq: MatchingAcceptRq) {
    return axios.post('/match/accept', rq);
  }

  fetchAcceptCBOMatching(rq: MatchingAcceptRq) {
    return axios.post('/v2/match/accept', rq);
  }

  fetchMatchingPositionDetail(positionSn: number) {
    return axios.get<ProfileDetailRs>(`/match/${positionSn}/profile`);
  }

  likeFavoritePosition(positionSn: number) {
    return axios.post<void>(`/match/${positionSn}/like`);
  }

  unlikeFavoritePosition(positionSn: number) {
    return axios.post<void>(`/match/${positionSn}/unlike`);
  }

  getMatchingCompanyList() {
    return axios.get<MatchingCompaniesRs>('/match/company');
  }
}
