export enum LogTypeEnum {
  // GNB 메뉴
  log_gnb_menu = 'log_gnb_menu',
  log_gnb_search = 'log_gnb_search',
  log_gnb_chat = 'log_gnb_chat',
  log_gnb_notification = 'log_gnb_notification',
  log_gnb_hover_menu = 'log_gnb_hover_menu',

  // 메인페이지
  log_main_ad_banner = 'log_main_ad_banner',
  log_main_dashboard = 'log_main_dashboard',
  log_main_ad_rolling = 'log_main_ad_rolling',
  log_profile_recommend_position = 'log_profile_recommend_position', // 개인화 포지션
  log_recommend_position = 'log_recommend_position', // 직군별 추천 공고

  // 매칭 페이지
  log_match_snb = 'log_match_snb', // 매칭페이지 사이드 bar
  log_match_empty_position = 'log_match_empty_position', // 제안 받은 포지션 없는 경우 보여주는 버튼
  log_match_position_card = 'log_match_position_card', // 제안 포지션 클릭
  log_match_setting_preference = 'log_match_setting_preference', // 매칭 선호 정보 설정
  log_match_setting_recruit_status = 'log_match_setting_recruit_status', // 매칭 구직 상태 설정
  log_match_setting_profile_open = 'log_match_setting_profile_open', // 매칭 프로필 열람 설정

  // 역량검사 센터
  log_acca_additional_test_popup = 'log_acca_additional_test_popup', // 역량검사 센터 추가 응시 팝업
  log_acca_tutorial = 'log_acca_tutorial', // 역량검사 센터 튜토리얼 연습하기
  log_acca_test = 'log_acca_test', // 역량검사 센터 응시하기
  log_acca_result = 'log_acca_result', // 역량검사 센터 결과표 확인하기
  log_acca_recent_test_continue = 'log_acca_test_continue', // 역량검사 센터 최근 응시한 시험 계속하기

  // 프로필
  log_profile_edit = 'log_profile_edit', // 프로필 수정

  // 지원현황
  log_apply_position_card = 'log_apply_position_card', // 지원현황 카드 클릭
  log_empty_apply_position = 'log_empty_apply_position', // 지원현황 카드 없을 때 보여주는 버튼

  // 기업 정보
  log_company_position = 'log_company_position', // 기업 정보 - 공고

  // 공통
  log_job_posting_modal_position = 'log_job_posting_modal_position',
  log_bookmark_position = 'log_bookmark_position',
  log_info_card = 'log_info_card',
  log_notification_message = 'log_notification_message', // 알림 메세지 클릭
  log_match_switch = 'log_match_switch', // 메인 & 매칭 매칭 스위치 버튼

  // 메세지
  log_message_company_info = 'log_message_company_info', // 회사 정보 클릭
  log_message_position_info = 'log_message_position_info', // 포지션 정보 확인
  log_message_user_submit_profile = 'log_message_user_submit_profile', // 유저 메세지 - 제출한 지원서 확인
  log_message_company_interview_request_schedule = 'log_message_company_interview_request_schedule', // 회사 메세지 - 면접 일정 조율
  log_message_company_interview_accept = 'log_message_company_interview_accept', // 회사 메세지 - 면접 제안 수락
  log_message_company_interview_reject = 'log_message_company_interview_reject', // 회사 메세지 - 면접 제안 거절
  log_message_cbo_match_report = 'log_message_cbo_match_report', // CBO 메세지 - 매칭 리포트
  log_message_cbo_match_accept = 'log_message_cbo_match_accept', // CBO 메세지 - 매칭 수락
  log_message_cbo_match_reject = 'log_message_cbo_match_reject', // CBO 메세지 - 매칭 거절
  log_message_company_match_accept = 'log_message_company_match_accept', // 기업 메세지 - 매칭 수락
  log_message_company_match_reject = 'log_message_company_match_reject', // 기업 메세지 - 매칭 거절
}
