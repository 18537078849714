import { MILLISECONDS_PER_DAY } from '@domain/constant/Time';

const minuteInMilliSecond = 60 * 1000;

export default class DateUtil {
  //현재 날짜 반환
  static getNow() {
    return new Date(Date.now());
  }

  /*
  두 날짜를 비교
  a < b : -1
  a = b : 0
  a > b : 1
  */
  static compare(dateA: Date, dateB: Date): number {
    const a = dateA.valueOf();
    const b = dateB.valueOf();
    return Number(a > b) - Number(a < b);
  }

  static isToday(date: Date): boolean {
    return DateUtil.getLeftDays(date, DateUtil.getNow()) === 0;
  }

  /*
   date 가 compareDate 보다 전날이거나, 같은날이면 return true
   date 가 compareDate 보다 다음날이면 return false
   compareDate 가 없으면 현재 시간과 비교
  */
  static isPastDate(date: Date, compareDate = DateUtil.getNow()): boolean {
    return DateUtil.compare(compareDate, date) >= 0;
  }

  static isAfterDate(date: Date, compareDate = DateUtil.getNow()): boolean {
    return DateUtil.compare(compareDate, date) < 0;
  }

  static addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  //월 차이 구하기(yyyy.mm)
  static getLeftMonth(startDate: Date = new Date(), endDate: Date = new Date()) {
    return (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
  }

  //현재 시간이 startDate ~ endDate 에 포함되어 있는지 여부
  static getWhetherIncludedInThePeriod(
    startDate: Date = new Date(),
    endDate: Date = new Date(),
    currentDate = new Date(),
  ) {
    return DateUtil.compare(startDate, currentDate) < 1 && DateUtil.compare(currentDate, endDate) < 1;
  }

  //현재 시간 기준 남은 초 return
  static getLeftSeconds(endTime: string | Date) {
    const endDate = new Date(endTime);
    //시간 문자열인지 체크
    if (isNaN(endDate.getTime())) return 0;
    //남은 시간 계산
    const millisecond = endDate.getTime() - new Date().getTime();
    return Math.floor(millisecond / 1000);
  }

  // 두 날짜의 연월일이 같은 지 판단
  static areDatesEqual(dateA: Date, dateB: Date) {
    // 연도, 월, 일 부분만 추출합니다.
    const yearA = dateA.getFullYear();
    const monthA = dateA.getMonth();
    const dayA = dateA.getDate();

    const yearB = dateB.getFullYear();
    const monthB = dateB.getMonth();
    const dayB = dateB.getDate();

    // 연월일을 비교합니다.
    return yearA === yearB && monthA === monthB && dayA === dayB;
  }

  static getLeftDays(startDate: Date, endDate = DateUtil.getNow()): number {
    const offset = -1 * startDate.getTimezoneOffset() * minuteInMilliSecond; // timezone offset으로 값 보정
    const clearedStartDate = Math.floor((startDate.valueOf() + offset) / MILLISECONDS_PER_DAY);
    const clearedEndDate = Math.floor((endDate.valueOf() + offset) / MILLISECONDS_PER_DAY);

    return clearedEndDate - clearedStartDate;
  }
}
