import { useQuery } from 'react-query';
import { RemoteMatchChallengeRepo } from '@repository/match/challenge/MatchChallengeRepo';
import MatchingChallengeAdListVO from '@domain/vo/challenge/MatchingChallengeAdListVO';
import MatchingChallengeCompanyListVO from '@domain/vo/challenge/MatchingChallengeCompanyListVO';
import MatchingChallengeListVO from '@domain/vo/challenge/MatchingChallengeListVO';
import MatchingChallengePositionListVO from '@domain/vo/challenge/MatchingChallengePositionListVO';
import MatchingChallengeVO from '@domain/vo/challenge/MatchingChallengeVO';

const keys = ['match', 'challenge'];

const remoteMatchChallengeRepo = new RemoteMatchChallengeRepo();

// 매칭 챌린지 목록 조회
const fetchChallengeList = async () => {
  const { data } = await remoteMatchChallengeRepo.fetchChallengeList();
  return new MatchingChallengeListVO(data);
};

function useMatchChallengeList() {
  return useQuery([...keys, 'list'], fetchChallengeList);
}

// 매칭 챌린지 상세 조회
const fetchChallenge = async (challengeSn: number) => {
  const { data } = await remoteMatchChallengeRepo.fetchChallenge(challengeSn);
  return new MatchingChallengeVO(data);
};

function useMatchChallenge(challengeSn: number) {
  return useQuery([...keys, challengeSn], () => fetchChallenge(challengeSn), {
    enabled: !!challengeSn,
  });
}

// 매칭 챌린지 광고 목록 조회
const fetchChallengeAdList = async () => {
  const { data } = await remoteMatchChallengeRepo.fetchChallengeAdList();
  return new MatchingChallengeAdListVO(data);
};

function useChallengeAdList() {
  return useQuery([...keys, 'ad', 'list'], fetchChallengeAdList);
}

// 매칭 챌린지 포지션 목록 조회
const fetchChallengePositionList = async (challengeSn: number, selectedCompanySns?: number[]) => {
  const { data } = await remoteMatchChallengeRepo.fetchChallengePositionList(challengeSn, selectedCompanySns);
  return new MatchingChallengePositionListVO(data);
};

function useChallengePositionList(challengeSn: number, selectedCompanySns?: number[]) {
  return useQuery(
    [...keys, 'positions', challengeSn, selectedCompanySns],
    () => fetchChallengePositionList(challengeSn, selectedCompanySns),
    {
      staleTime: 1000 * 60 * 5,
      keepPreviousData: true,
      enabled: !!challengeSn,
    },
  );
}

//매칭 챌린지 참여 기업 목록 조회
const fetchChallengeCompanyList = async (challengeSn: number) => {
  const { data } = await remoteMatchChallengeRepo.fetchChallengeCompanyList(challengeSn);
  return new MatchingChallengeCompanyListVO(data);
};

function useChallengeCompanyList(challengeSn: number) {
  return useQuery([...keys, 'companies', challengeSn], () => fetchChallengeCompanyList(challengeSn), {
    enabled: !!challengeSn,
  });
}

export {
  keys as matchChallengeKeys,
  useMatchChallengeList,
  useMatchChallenge,
  useChallengeAdList,
  useChallengePositionList,
  useChallengeCompanyList,
};
