import { BaseProfileCareerPrizeDto, BaseProfilePrizeDto } from '@domain/rs/insight/InsightResumeGetRs';
import DateFormat from '@utils/date/format';

export default class PrizeFormVO {
  prizes: BaseProfileCareerPrizeVO[];

  constructor(dto: BaseProfilePrizeDto) {
    this.prizes = dto.prizes.map((item) => new BaseProfileCareerPrizeVO(item));
  }
}

export class BaseProfileCareerPrizeVO {
  institution: string | null;
  name: string | null;
  date: string | null;
  description: string | null;

  constructor(dto: BaseProfileCareerPrizeDto) {
    this.institution = dto.institution;
    this.name = dto.name;
    this.date = DateFormat.getDateFormatString(dto.date, 'yyyy-MM') ?? '';
    this.description = dto.description;
  }

  public static convertRqFormVO(vo: BaseProfileCareerPrizeVO): BaseProfileCareerPrizeDto {
    return {
      institution: vo.institution,
      name: vo.name,
      date: vo.date,
      description: vo.description,
    } as BaseProfileCareerPrizeDto;
  }
}
