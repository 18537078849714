import UserVO from '@domain/vo/user/UserVO';
import { CouponTypeEnum } from '@domain/constant/coupons/couponType';

const CouponTypeArray = Object.values(CouponTypeEnum) as string[];

const isEventUser = (userData?: UserVO) => {
  return userData?.authorities?.some((v) => CouponTypeArray.includes(v)) ?? false;
};

const isAuthorityUser = (userData?: UserVO) => {
  return userData?.authorities?.includes('PARTNERSHIP') ?? false;
};

const isMatchingUser = (userData?: UserVO) => {
  return !!userData?.isMatchingUser;
};

const isReceivingMatchingUser = (userData?: UserVO) => {
  return !!userData?.matchingProposalCount;
};

const useCheckAuthority = (userData?: UserVO) => {
  return {
    isEventUser: isEventUser(userData),
    isAuthorityUser: isAuthorityUser(userData),
    isMatchingUser: isMatchingUser(userData),
    isReceivingMatchingUser: isReceivingMatchingUser(userData),
  };
};

export default useCheckAuthority;
