import { ReactSelectOption } from '@domain/constant/ReactSelectOption';

export type genderType = 'FEMALE' | 'MALE' | 'NONE';

export enum marketingInfoItem {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  KAKAOTALK = 'KAKAOTALK',
  MATCHING = 'MATCHING',
}

export type marketingInfoItemType = keyof typeof marketingInfoItem;

export enum notificationSettingItem {
  MATCHING_POSITION = 'MATCHING_POSITION',
}

export type notificationSettingItemType = keyof typeof notificationSettingItem;

export type yearType =
  | 'NEW'
  | 'FIRST'
  | 'SECOND'
  | 'THIRD'
  | 'FOURTH'
  | 'FIFTH'
  | 'SIXTH'
  | 'SEVENTH'
  | 'EIGHTH'
  | 'NINTH'
  | 'OVER_TENTH';

export enum CAREER_TYPE {
  NEWCOMER = '신입으로 입사 준비중',
  CAREER = '경력으로 입사 준비중',
}

export enum USER_YEAR_TYPE {
  NEW = '신입',
  FIRST = '1년 차',
  SECOND = '2년 차',
  THIRD = '3년 차',
  FOURTH = '4년 차',
  FIFTH = '5년 차',
  SIXTH = '6년 차',
  SEVENTH = '7년 차',
  EIGHTH = '8년 차',
  NINTH = '9년 차',
  OVER_TENTH = '10년 이상',
}

export const yearTypeOptions: ReactSelectOption[] = [
  { value: 'FIRST', label: USER_YEAR_TYPE.FIRST },
  { value: 'SECOND', label: USER_YEAR_TYPE.SECOND },
  { value: 'THIRD', label: USER_YEAR_TYPE.THIRD },
  { value: 'FOURTH', label: USER_YEAR_TYPE.FOURTH },
  { value: 'FIFTH', label: USER_YEAR_TYPE.FIFTH },
  { value: 'SIXTH', label: USER_YEAR_TYPE.SIXTH },
  { value: 'SEVENTH', label: USER_YEAR_TYPE.SEVENTH },
  { value: 'EIGHTH', label: USER_YEAR_TYPE.EIGHTH },
  { value: 'NINTH', label: USER_YEAR_TYPE.NINTH },
  { value: 'OVER_TENTH', label: USER_YEAR_TYPE.OVER_TENTH },
];
