export type UnauthorizedExceptionType = 'authRequired';

export class UnauthorizedException extends Error {
  type: UnauthorizedExceptionType;

  constructor(type: UnauthorizedExceptionType) {
    super(type);
    this.type = type;
  }
}
