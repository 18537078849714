import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useController, useFormContext } from 'react-hook-form';
import NoSsr from '@common/noSsr';
import { ReactSelectStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import { useJobGroupsWithTitles } from '@repository/codes/useCodes';
import { JobTitleVO } from '@domain/vo/profile/ProfileCareersVO';
import { ProfileCareersFormVO } from '@domain/vo/profile/ProfileCareersFormVO';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const JobTitle = ({ prefixName }: HookFormProps) => {
  const { data: jobGroupsData } = useJobGroupsWithTitles();
  const [jobTitle, setJobTitle] = useState<JobTitleVO[]>([]);
  const { formState, setValue } = useFormContext<ProfileCareersFormVO>();
  const { field, fieldState } = useController({ name: `${prefixName}.jobTitle` });
  const { field: jobGroupField } = useController({ name: `${prefixName}.jobGroup` });
  const isDisabled = !jobGroupField.value;

  useEffect(() => {
    if (jobGroupField.value?.value) {
      const sn = Number(jobGroupField.value?.value);
      const jobTitles = jobGroupsData?.find((e) => e.code === sn)?.jobTitles ?? [];
      const convertedJobTitles = jobTitles.map((e) => new JobTitleVO(e));
      setJobTitle(convertedJobTitles);
    }
  }, [jobGroupField.value]);

  useEffect(() => {
    if (!formState.isDirty) return;
    if (!field.value) return;
    setValue('jobTitle', null);
  }, [jobGroupField.value]);

  return (
    <>
      <NoSsr>
        <Select
          {...field}
          className={cx('select', { error: fieldState.invalid })}
          placeholder={'직무를 선택해 주세요.'}
          styles={ReactSelectStyles}
          isSearchable={false}
          options={jobTitle}
          isDisabled={isDisabled}
        />
      </NoSsr>
      {fieldState.invalid && <div className={cx('errorMessage')}>직무를 선택해 주세요.</div>}
    </>
  );
};

export default JobTitle;
