import CompanyCultureRs, {
  CompanyCultureAnswerDto,
  CompanyCultureIncumbentDto,
} from '@domain/rs/company/sn/CompanyCultureRs';
import CultureIncumbentsQuestionType, {
  CultureIncumbentsQuestionTypeProps,
  IncumbentsQuestionIconTypeEnum,
} from '@domain/constant/company/sn/companyCultureIncumbentsQuestionType';
import { ReviewType } from '@domain/constant/company/sn/reviewType';

export default class CompanyCultureVO {
  cultureList: CompanyCultureIncumbentsVO[];
  reviewTypeList: ReviewType[];

  constructor(dto: CompanyCultureRs) {
    this.cultureList = dto.companyCulture.companyCultureIncumbents
      .map((item) => new CompanyCultureIncumbentsVO(item))
      .reverse();
    this.reviewTypeList = dto.companyCulture.companyCultureIncumbents.map((item) => item.type).reverse();
  }
}

class CompanyCultureIncumbentsVO {
  type: ReviewType;
  answerData: AnswerDataVO[];

  constructor(dto: CompanyCultureIncumbentDto) {
    this.type = dto.type;
    this.answerData = dto.companyCultureAnswers.map((item) => this.getAnswerData(dto.type, item));
  }

  private getAnswerData = (type: ReviewType, dto: CompanyCultureAnswerDto) => {
    return new AnswerDataVO(CultureIncumbentsQuestionType[type][dto.questionNumber], dto.averageScore);
  };
}

class AnswerDataVO {
  answer: string;
  iconUrl: string;
  avergageScore: number | null;

  constructor(dto: CultureIncumbentsQuestionTypeProps, averageScore: number | null) {
    this.answer = dto.answer;
    this.iconUrl = dto.iconType ? IncumbentsQuestionIconTypeEnum[dto.iconType] : '';
    this.avergageScore = averageScore;
  }
}
