import { getOptions } from '@domain/constant/ReactSelectOption';

// 병역 상태
export enum MilitaryStatus {
  NONE = 'NONE', // 비대상자
  DISCHARGE = 'DISCHARGE', // 제대(군필)
  NON_DISCHARGE = 'NON_DISCHARGE', // 미제대(미필)
  EXEMPTION = 'EXEMPTION', // 면제
  IN_SERVICE = 'IN_SERVICE', // 복무중
}

export type MilitaryStatusType = keyof typeof MilitaryStatus;

export const MilitaryStatusNameMap: Record<MilitaryStatusType, string> = {
  [MilitaryStatus.NONE]: '비대상',
  [MilitaryStatus.DISCHARGE]: '군필',
  [MilitaryStatus.NON_DISCHARGE]: '미필',
  [MilitaryStatus.EXEMPTION]: '면제',
  [MilitaryStatus.IN_SERVICE]: '복무중',
};

export const militaryStatusOptions = getOptions(MilitaryStatusNameMap);

// 군 종류
export enum Military {
  ARMY = 'ARMY', // 육군
  NAVY = 'NAVY', // 해군
  AIR_FORCE = 'AIR_FORCE', // 공군
  MARINE = 'MARINE', // 해병
  COMBAT_POLICE = 'COMBAT_POLICE', // 작전전경(전경)
  AUXILIARY_POLICE = 'AUXILIARY_POLICE', // 의무경찰(의경)
  PUBLIC_SERVICE_WORKER = 'PUBLIC_SERVICE_WORKER', // 공익근무요원(공익)
  ETC = 'ETC', // 기타
}

export type MilitaryType = keyof typeof Military;

export const MilitaryNameMap: Record<MilitaryType, string> = {
  [Military.ARMY]: '육군',
  [Military.NAVY]: '해군',
  [Military.AIR_FORCE]: '공군',
  [Military.MARINE]: '해병',
  [Military.COMBAT_POLICE]: '전경',
  [Military.AUXILIARY_POLICE]: '의경',
  [Military.PUBLIC_SERVICE_WORKER]: '공익',
  [Military.ETC]: '기타',
};

export const militaryOptions = getOptions(MilitaryNameMap);

// 군 계급
export enum MilitaryClass {
  PRIVATE = 'PRIVATE', // 이등병
  PRIVATE_FIRST_CLASS = 'PRIVATE_FIRST_CLASS', // 일병
  CORPORAL = 'CORPORAL', // 상병
  SERGEANT = 'SERGEANT', // 병장
  STAFF_SERGEANT = 'STAFF_SERGEANT', // 하사
  SERGEANT_FIRST_CLASS = 'SERGEANT_FIRST_CLASS', // 중사
  MASTER_SERGEANT = 'MASTER_SERGEANT', // 상사
  SERGEANT_MAJOR = 'SERGEANT_MAJOR', // 원사
  WARRANT_OFFICER = 'WARRANT_OFFICER', // 준위
  SECOND_LIEUTENANT = 'SECOND_LIEUTENANT', // 소위
  FIRST_LIEUTENANT = 'FIRST_LIEUTENANT', // 중위
  CAPTAIN = 'CAPTAIN', // 대위
  MAJOR = 'MAJOR', // 소령
  LIEUTENANT_COLONEL = 'LIEUTENANT_COLONEL', // 중령
  COLONEL = 'COLONEL', // 대령
  BRIGADIER_GENERAL = 'BRIGADIER_GENERAL', // 준장
  MAJOR_GENERAL = 'MAJOR_GENERAL', // 소장
  LIEUTENANT_GENERAL = 'LIEUTENANT_GENERAL', // 중장
  GENERAL = 'GENERAL', // 대장
  ETC = 'ETC', // 기타
}

export type MilitaryClassType = keyof typeof MilitaryClass;

export const MilitaryClassNameMap: Record<MilitaryClassType, string> = {
  [MilitaryClass.PRIVATE]: '이병',
  [MilitaryClass.PRIVATE_FIRST_CLASS]: '일병',
  [MilitaryClass.CORPORAL]: '상병',
  [MilitaryClass.SERGEANT]: '병장',
  [MilitaryClass.STAFF_SERGEANT]: '하사',
  [MilitaryClass.SERGEANT_FIRST_CLASS]: '중사',
  [MilitaryClass.MASTER_SERGEANT]: '상사',
  [MilitaryClass.SERGEANT_MAJOR]: '원사',
  [MilitaryClass.WARRANT_OFFICER]: '준위',
  [MilitaryClass.SECOND_LIEUTENANT]: '소위',
  [MilitaryClass.FIRST_LIEUTENANT]: '중위',
  [MilitaryClass.CAPTAIN]: '대위',
  [MilitaryClass.MAJOR]: '소령',
  [MilitaryClass.LIEUTENANT_COLONEL]: '중령',
  [MilitaryClass.COLONEL]: '대령',
  [MilitaryClass.BRIGADIER_GENERAL]: '준장',
  [MilitaryClass.MAJOR_GENERAL]: '소장',
  [MilitaryClass.LIEUTENANT_GENERAL]: '중장',
  [MilitaryClass.GENERAL]: '대장',
  [MilitaryClass.ETC]: '기타',
};

export const militaryClassOptions = getOptions(MilitaryClassNameMap);

// 제대 구분
export enum MilitaryDischarge {
  COMPLETE = 'COMPLETE', // 만기 제대
  RELEASE_OF_CALL = 'RELEASE_OF_CALL', // 소집 해제
  FAMILY = 'FAMILY', // 의가사 제대(가정)
  DISEASE = 'DISEASE', // 의병 제대(질병)
  DISHONOR = 'DISHONOR', // 불명예 제대
  VETERAN = 'VETERAN', // 상이 제대 (질병&보훈)
  ETC = 'ETC', // 기타
}

export type MilitaryDischargeType = keyof typeof MilitaryDischarge;

export const MilitaryDischargeNameMap: Record<MilitaryDischargeType, string> = {
  [MilitaryDischarge.COMPLETE]: '만기제대',
  [MilitaryDischarge.RELEASE_OF_CALL]: '소집해제',
  [MilitaryDischarge.FAMILY]: '의가사제대',
  [MilitaryDischarge.DISEASE]: '의병제대',
  [MilitaryDischarge.DISHONOR]: '불명예제대',
  [MilitaryDischarge.VETERAN]: '상이제대',
  [MilitaryDischarge.ETC]: '기타',
};

export const militaryDischargeOptions = getOptions(MilitaryDischargeNameMap);

//장애 등급
export enum DisabilityGrade {
  NONE = 'NONE', // 비대상자
  MILD = 'MILD', // 경증(기존 1~3등급)
  SEVERE = 'SEVERE', // 중증(기존 4~6등급)
}

export type DisabilityGradeType = keyof typeof DisabilityGrade;

export const DisabilityGradeShortNameMap: Record<DisabilityGradeType, string> = {
  [DisabilityGrade.NONE]: '비대상',
  [DisabilityGrade.MILD]: '경증',
  [DisabilityGrade.SEVERE]: '중증',
};

export const DisabilityGradeNameMap: Record<DisabilityGradeType, string> = {
  [DisabilityGrade.NONE]: '비대상',
  [DisabilityGrade.SEVERE]: '중증(기존 1~3등급)',
  [DisabilityGrade.MILD]: '경증(기존 4~6등급)',
};

export const disabilityGradeOptions = getOptions(DisabilityGradeNameMap);

//장애 종류
export enum Disability {
  LIVER = 'LIVER', //간
  BRAIN_LESION = 'BRAIN_LESION', //뇌병변
  EPILEPSY = 'EPILEPSY', //뇌전증
  VISUAL = 'VISUAL', //시각
  KIDNEY = 'KIDNEY', //신장
  CARDIAC = 'CARDIAC', //심장
  FACIAL = 'FACIAL', //안면
  SPEECH = 'SPEECH', //언어
  AUTISM = 'AUTISM', //자폐
  INTESTINAL_URINARY = 'INTESTINAL_URINARY', //장루, 요루
  MENTAL = 'MENTAL', //정신
  INTELLECTUAL = 'INTELLECTUAL', //지적
  PHYSICAL = 'PHYSICAL', //지체
  HEARING = 'HEARING', //청각
  RESPIRATORY = 'RESPIRATORY', //호흡기
}

export type DisabilityType = keyof typeof Disability;

export const DisabilityNameMap: Record<DisabilityType, string> = {
  [Disability.LIVER]: '간장애',
  [Disability.BRAIN_LESION]: '뇌병변장애',
  [Disability.EPILEPSY]: '뇌전증장애',
  [Disability.VISUAL]: '시각장애',
  [Disability.KIDNEY]: '신장장애',
  [Disability.CARDIAC]: '심장장애',
  [Disability.FACIAL]: '안면장애',
  [Disability.SPEECH]: '언어장애',
  [Disability.AUTISM]: '자폐성장애',
  [Disability.INTESTINAL_URINARY]: '장루·요루장애',
  [Disability.MENTAL]: '정신장애',
  [Disability.INTELLECTUAL]: '지적장애',
  [Disability.PHYSICAL]: '지체장애',
  [Disability.HEARING]: '청각장애',
  [Disability.RESPIRATORY]: '호흡기장애',
};

export const disabilityOptions = getOptions(DisabilityNameMap);

// 보훈 여부
export enum VeteranStatus {
  NONE = 'NONE', // 비대상자
  VETERAN = 'VETERAN', // 보훈대상자
}

export type VeteranStatusType = keyof typeof VeteranStatus;

export const VeteranStatusNameMap: Record<VeteranStatusType, string> = {
  [VeteranStatus.NONE]: '비대상',
  [VeteranStatus.VETERAN]: '대상',
};
export const VeteranSTatusUINameMap: Record<VeteranStatusType, string> = {
  [VeteranStatus.NONE]: '비대상',
  [VeteranStatus.VETERAN]: '대상자',
};

export const veteranStatusOptions = getOptions(VeteranStatusNameMap);
