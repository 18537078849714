import { useEffect, useState } from 'react';
import Router from 'next/router';
import { getAccessToken } from '@repository/AccessToken';
import { useUserInfo } from '@repository/auth/useUserInfo';
import useProfileConditions from '@repository/profiles/useProfileConditions';
import { useTermsCheckNew } from '@components/acca/test/query/useTerms';
import { useApplyTutorial } from '@components/acca/test/query/useApply';
import { RouteUrl } from '.';
import { TERMS } from '@domain/constant/termsType';
import { baseURL, isEmpty } from '@utils/common';
import UrlUtil from '@utils/urlUtil';

export class V2Route {
  //지원서 저장하기 서비스에 대한 개인정보 3자 제공 동의 URL 생성
  static getResumeThirdpartyAgreementPage(url: string) {
    return `//${url}/thirdparty-agreement`;
  }

  //지원서 작성 페이지
  static getWriteResumePage(url: string, publicKey: string, positionId: number) {
    return (
      `${url}/writeResume` +
      `?publicKey=${publicKey}` +
      `&positionId=${positionId}` +
      `&jobdaToken=Bearer ${getAccessToken()}`
    );
  }

  //지원서 미리보기 페이지
  static getViewResumePage(url: string, resumeSn: number) {
    return `${url}/jobda/viewResume` + `?resumeSn=${resumeSn}` + `&jobdaToken=Bearer ${getAccessToken()}`;
  }

  static goMainPage() {
    Router.push(V2RouteUrl.INDEX);
  }

  static getCompanyChannelPage(sn: number) {
    return `/company/${sn}`;
  }

  static getPositionListMatchStatusPage() {
    return '/position?matchingYn=' + true;
  }

  static getGnbMenuStatus(pathname: string, subPaths: string[]) {
    return subPaths.includes(pathname);
  }

  static getPositionJDPage(positionSn: number) {
    return `/position/${positionSn}/jd`;
  }

  static getSearchPage(keyword: string) {
    return `/search/result?keyword=${keyword}`;
  }

  static goSearchPage(keyword: string) {
    location.href = `/search/result?keyword=${encodeURIComponent(keyword)}`;
  }

  static getAccaResultSnPage(sn: number) {
    return `/acca/results?sn=${sn}`;
  }

  static getPhsPstApplyResultPage(sn: number) {
    return `/phs/pst/results?sn=${sn}`;
  }

  static getPhsPaccApplyResultPage(sn: number) {
    return `/phs/pacc/results?sn=${sn}`;
  }

  static getChallengePage(challengeSn: number) {
    return `/challenge/${challengeSn}`;
  }

  static getAccaTutorialPage(url: string) {
    return `${url}/${V2RouteUrl.TUTORIAL}`;
  }

  static getAccaV3ResultPage(url: string, token: string, tab: string) {
    return `${url}/acca/examinee-result-for-examinee?Authorization=Bearer ${token}&requestPage=${tab}`;
  }

  // KCB SMS 본인인증 페이지
  static openKcbSmsPage(onCloseEvent?: () => void) {
    const popup = window?.open(`${baseURL}/kcb/verification/sms`, 'auth_popup', 'width=804,height=1245,scrollbar=yes');

    //자식 창 닫혔을 경우 이벤트
    if (onCloseEvent && popup) {
      const timer = setInterval(function () {
        if (popup.closed) {
          clearInterval(timer);
          onCloseEvent();
        }
      }, 1000);
    }
  }

  // KCB IPIN 본인인증 페이지
  static openKcbIpinPage(onCloseEvent?: () => void) {
    const popup = window?.open(
      `${baseURL}/kcb/verification/ipin`,
      'kcbPop',
      'left=200, top=100, status=0, width=450, height=550',
    );

    //자식 창 닫혔을 경우 이벤트
    if (onCloseEvent && popup) {
      const timer = setInterval(function () {
        if (popup.closed) {
          clearInterval(timer);
          onCloseEvent();
        }
      }, 1000);
    }
  }

  static getPHSApplyApiPage(baseUrl = '') {
    if (!baseUrl) return '';

    return `${baseUrl}/api/v1/login/exam-code/redirect`;
  }

  //기술 키워드 요청하기 페이지
  static getRequestSkillKeywordPage() {
    return 'https://docs.google.com/forms/d/1TXJVG4fDhWXFr_1D70oFBf2XBuV3Q9ygDJBDfPo7h9s/viewform?edit_requested=true';
  }

  //기업 채널 > 기업 회원 로그인하고 수정 하기 페이지
  static getAtsCompanyUpdatePage() {
    return 'https://jain-membership.hri.link/login?serviceId=ATS';
  }

  //기업 채널 > 잡다 고객센터 요청 페이지
  static getRequestJobDaServiceCenterPage() {
    return 'https://recruit.jobda.im/inquiry';
  }

  //취업 컨텐츠 상세
  static getInfoDetailPage(infoSn?: number | string) {
    return `/info/${infoSn}`;
  }

  //공고 모달 URL
  static getJobPostingSnModalUrl(jobPostingSn?: number | string) {
    return `/jobs/${jobPostingSn}`;
  }

  static getInfoListPage(categorySn?: number | string) {
    const query = categorySn ? `?category=${categorySn}` : '';
    return `/info/list${query}`;
  }

  static getInfo395Page() {
    return `https://www.jobda.im/info/395`;
  }

  static getInfoPreTestPreparationGuidePage() {
    return `https://www.jobda.im/info/328`;
  }

  //채용 정보 역량검사 꿀팀 카테고리 페이지
  static getJobInfoAcccResultGuidePage() {
    return 'https://www.jobda.im/info/list?category=20';
  }

  //역량검사 응시 전 꼭 알아야 할 준비 사항
  static getGuidePageBeforeApply() {
    return 'https://www.jobda.im/info/328';
  }

  // 역량검사 활용법
  static getAccaUsagePage() {
    return 'https://www.jobda.im/info/334';
  }

  // 역량검사 콘텐츠 가이드
  static getAccaGuidePage() {
    return 'https://www.jobda.im/info/344';
  }

  //개발자검사 응시 확인 페이지
  static getPhsPreparePage() {
    return 'https://www.jobda.im/info/361';
  }
}

export enum V2RouteUrl {
  INDEX = '/',
  JOBS = '/jobs',
  PREPARATION = '/preparation', // 취업준비
  JOIN = '/join',
  WELCOME = '/join/welcome',
  PREFERRED = '/preferred',

  PROFILE = '/profile',
  PROFILE_ACCA = '/acca/results',
  PROFILE_PHS_PST = '/phs/pst/results',
  PROFILE_PHS_PACCA = '/phs/pacc/results',

  TUTORIAL = 'tutorial',
  POSITION = '/position',
  CHALLENGE = '/challenge',
  JOBDA_WEBTOON = '/jobda/webtoon',

  //이니셔티브 클래스 (TODO  기획 협의 필요)
  INITIATIVE_CLASS = '/initiativeclass',

  //개발자 역량검사
  PHS_TEST_GATE = '/phs/test',
  PHS_PST_TEST_GATE = '/phs/pst/test',
  PHS_PACC_TEST_GATE = '/phs/pacc/test',
  PHS_PACC_RESULTS = '/phs/pacc/results',
  PHS_DCHAMPS_RESULTS = '/phs/dchamps/results',
  PHS_PST_RESULTS = '/phs/pst/results',
  PHS_RESULT_GATE = '/phs/result',

  //잡다매칭 서비스
  MATCH_APPLY = '/match/apply',

  //약관 페이지
  PRIVACY = '/privacy', //개인정보처리방침
  TERMS_OF_SERVICE = '/termsofservice', //서비스 이용약관
  PRIVACY_COLLECTION = '/privacycollection', //개인정보 수집 및 이용 동의
  MARKETING_POLICY = '/marketingPolicy', // 마케팅 정보 수신 동의
  TERMS_NHIS = '/terms/nhis', //국민건강보험공단 약관

  //이벤트
  EVENT_GET_JOB_CHALLENGE = '/event/challenge', //인재풀 전환 마케팅 페이지
  EVENT_PHS_DCHAMPS = '/event/dchamps2309', // 디쳄스 이벤트 페이지
  EVENT_HOLIDAY = '/event/holiday2309',
}

// 잡다매칭 페이지 접근
export const useGetMatchPage = () => {
  const { data: userInfo, isLoading: isUserInfoLoading } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: profileConditionsData, isLoading: isProfileConditionLoading } = useProfileConditions(isLogin);
  const [route, setRoute] = useState('');

  useEffect(() => {
    if (isUserInfoLoading || isProfileConditionLoading) return;

    if (!isLogin || isEmpty(profileConditionsData?.jobGroups)) {
      setRoute(RouteUrl.MATCH_INTRODUCE);
    } else {
      setRoute(RouteUrl.MATCH_APPLY);
    }
  }, [isUserInfoLoading, isProfileConditionLoading, isLogin, profileConditionsData]);

  return route;
};

export const useGetACCAPage = () => {
  const { data: userInfo, isLoading: isUserInfoLoading } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: accTerms, isLoading: isTermsCheckNewLoading } = useTermsCheckNew(
    TERMS.ACC_TRIAL_PRIVACY_POLICY,
    !!isLogin,
  );
  const [route, setRoute] = useState('');

  useEffect(() => {
    if (isUserInfoLoading || isTermsCheckNewLoading) return;

    if (!isLogin || accTerms?.existsNewTermsAgree) {
      setRoute(RouteUrl.ACCA_INTRODUCE);
    } else {
      setRoute(RouteUrl.ACCA_TEST_GATE);
    }
  }, [isUserInfoLoading, isTermsCheckNewLoading, isLogin, accTerms]);

  return route;
};

export const useGetACCATutorialPage = () => {
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: accTerms } = useTermsCheckNew(TERMS.ACC_TRIAL_PRIVACY_POLICY, isLogin);
  const { data: tutorialApplyData } = useApplyTutorial(isLogin && accTerms?.existsNewTermsAgree === false);
  if (!isLogin) return RouteUrl.ACCA_INTRODUCE;
  if (accTerms?.existsNewTermsAgree) return RouteUrl.ACCA_TEST_GATE;

  const openACCATutorialPage = async () => {
    const { url, token } = tutorialApplyData;
    const accTutorialUrl = V2Route.getAccaTutorialPage(url);
    UrlUtil.openWindowWithPost(accTutorialUrl, { Authorization: `Bearer ${token}` });
  };

  return openACCATutorialPage;
};
