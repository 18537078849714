import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import KcbIdentitySaveRq from '@domain/rq/verification/KcbIdentityRq';
import IdentificationPasswordRq from '@domain/rq/verification/IdentificationPasswordRq';
import IdentificationIdRs from '@domain/rs/verification/IdentificationIdRs';

export default interface IdentityRepo {
  //본인 인증 여부 체크
  fetchCheckKcbIdentity(): Promise<AxiosResponse<boolean>>;

  // kcb 인증결과 잡다 저장
  saveKcbIdentity(rq: KcbIdentitySaveRq): Promise<AxiosResponse<boolean>>;

  //아이디 찾기
  fetchIdentityIdFind(token: string): Promise<AxiosResponse<IdentificationIdRs>>;

  //비밀번호 재설정
  fetchIdentityPasswordReset(rq: IdentificationPasswordRq): Promise<AxiosResponse>;

  //휴대폰 본인인증 여부 조회
  fetchCheckIdentityMobile(): Promise<AxiosResponse<boolean>>;
}

export class RemoteIdentityRepo implements IdentityRepo {
  fetchCheckKcbIdentity() {
    return axios.get<boolean>(`/identity`);
  }

  saveKcbIdentity(rq: KcbIdentitySaveRq) {
    return axios.put<boolean>(`/v2/identity`, rq);
  }

  fetchIdentityIdFind(token: string) {
    return axios.post<IdentificationIdRs>(`/identity/id`, { token });
  }

  fetchIdentityPasswordReset(rq: IdentificationPasswordRq) {
    return axios.post(`/identity/password/reset`, rq);
  }

  fetchCheckIdentityMobile() {
    return axios.get<boolean>(`/identity/mobile`);
  }
}
