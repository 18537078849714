import { ContentRouteUrl } from '@utils/routeUtil/ContentRoute';
import { MatchRouteUrl } from '@utils/routeUtil/MatchRoute';
import { AccaRouteUrl } from '@utils/routeUtil/AccaRoute';
import { V2RouteUrl } from '@utils/routeUtil/V2Route';

export * from './ContentRoute';
export * from './MatchRoute';
export * from './AccaRoute';
export * from './V2Route';

export const RouteUrl = {
  ...ContentRouteUrl,
  ...MatchRouteUrl,
  ...AccaRouteUrl,
  ...V2RouteUrl,
};
