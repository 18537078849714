import * as yup from 'yup';
import { ProjectType } from '@domain/constant/profile/ProjectType';

const projectFormSchema = yup.object({
  title: yup.string().required('프로젝트명을 입력해주세요.').max(30, '30자 이내로 입력해주세요.'),
  startDate: yup.string().required('시작 일자를 입력해주세요.'),
  endDate: yup
    .string()
    .when('startDate', (startDate, schema) => {
      return schema.test({
        test: (endDate: Date) => {
          return endDate >= startDate;
        },
        message: '종료 일자가 시작 일자보다 빠릅니다.',
      });
    })
    .required('종료 일자를 입력해주세요.'),
  teamType: yup.string().nullable().required('프로젝트 타입을 선택해주세요.'),
  contribution: yup
    .object()
    .nullable()
    .when('teamType', {
      is: (val: ProjectType) => val === 'TEAM',
      then: yup.object().nullable().required('기여도를 입력해주세요.'),
      otherwise: yup.object().nullable().notRequired(),
    }),
  performance: yup.string().nullable().max(10000, '최대 글자수를 초과했습니다.'),
  institution: yup.string().nullable().max(30, '30자 이내로 입력해주세요.'),
});

export default projectFormSchema;
