import React, { FC } from 'react';
import Portal from '@common/portal';

import classnames from 'classnames/bind';
import styles from './exitModal.module.scss';
const cx = classnames.bind(styles);

interface IExitModal {
  visible: boolean;
  onClose: () => void;
  onExit: () => void;
}

const ExitModal: FC<IExitModal> = ({ visible, onClose, onExit }) => {
  const handleExit = () => {
    onClose();
    onExit();
  };

  return (
    <>
      {visible && (
        // overlay를 덮어야 되는 특수한 상황이라 따로 제작
        <Portal selector="#modal">
          <div className={cx('modal')}>
            <div className={cx('layer')}>
              <strong className={cx('title')}>
                저장되지 않은 내용이 있어요.
                <br /> 페이지에서 나가시겠어요?
              </strong>
              <p className={cx('desc')}>저장되지 않은 내용은 사라져요.</p>
              <div className={cx('btnArea')}>
                <a className={cx('btn', 'close')} role="button" onClick={onClose}>
                  취소
                </a>
                <a className={cx('btn', 'exit')} role="button" onClick={handleExit}>
                  나가기
                </a>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default ExitModal;
