import Modal from '@components/common/modal';
import Button from '@components/common/button';
import MatchTodoContent from '@components/common/matchSwitch/matchProposalModals/needTodoModal/matchTodoContent';
import MatchingConfigConditionVO from '@domain/vo/match/MatchingConfigConditionVO';

import classnames from 'classnames/bind';
import styles from './needTodoModal.module.scss';
const cx = classnames.bind(styles);

interface INeedTodoModalProps {
  visible: boolean;
  onClose: () => void;
  matchConfigStatus: MatchingConfigConditionVO;
  generalYn: boolean;
  developerYn: boolean;
}

const NeedTodoModal = ({ visible, onClose, matchConfigStatus, generalYn, developerYn }: INeedTodoModalProps) => {
  const { accCompleteYn, paccCompleteYn, pstCompleteYn, profileRequiredCompleteYn } = matchConfigStatus;

  return (
    <Modal visible={visible} dimmed={true}>
      <div className={cx('layer')}>
        <div className={cx('title')}>
          잡다매칭에 참여하기 위해
          <br />할 일을 확인해보세요.
        </div>

        <MatchTodoContent
          generalYn={generalYn}
          developerYn={developerYn}
          profileRequiredCompleteYn={profileRequiredCompleteYn}
          accCompleteYn={accCompleteYn}
          paccCompleteYn={paccCompleteYn}
          pstCompleteYn={pstCompleteYn}
        />

        <Button className={cx('btn')} onClick={onClose}>
          확인
        </Button>
      </div>
    </Modal>
  );
};

export default NeedTodoModal;
