import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import LogRq from '@domain/rq/log/LogRq';

export default interface LogRepo {
  //로그 저장
  fetchLog(rq: LogRq): Promise<AxiosResponse<boolean>>;
}

export class RemoteLogRepo implements LogRepo {
  fetchLog(rq: LogRq) {
    return axios.post<boolean>(`/v1/log`, rq);
  }
}
