export interface IAccountLinkRq {
  publicKey: string;
  positionId: number;
}

export default class AccountLinkRq {
  publicKey: string;
  positionId: number;

  constructor(vo: IAccountLinkRq) {
    this.publicKey = vo.publicKey;
    this.positionId = vo.positionId;
  }
}
