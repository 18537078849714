import ProfileConditionsVO, { JobGroupVO, JobTitleVO } from './ProfileConditionsVO';
import { DataLogRq, SourcePage } from '@domain/rq/DataLog';
import { WelfareDto } from '@domain/rs/profile/ProfileConditionsRs';
import NumberFormat from '@utils/number/format';

export default class ProfileConditionsFormVO {
  jobGroups: SelectedJobGroup[];
  locations: string[];
  salary: { value: number; label: string } | null;
  welfareKeywords: number[];
  workingPreference: string;
  sourcePage?: SourcePage;

  constructor(vo: ProfileConditionsVO & DataLogRq) {
    this.jobGroups = vo.jobGroups.map((jobGroup) => new SelectedJobGroup(jobGroup));
    this.salary = this.convertSalary(vo.salary);
    this.locations = vo.locationTexts || [];
    this.welfareKeywords = getWelfareKeywords(vo.welfare);
    this.workingPreference = vo.workingPreference;
    this.sourcePage = vo.sourcePage;
  }

  convertSalary(salary: number | null) {
    if (!salary) return null;
    return {
      value: salary,
      label: `${NumberFormat.formatDecimal(salary)} 만원 이상`,
    };
  }
}

export class SelectedJobGroup {
  jobGroupCode: number;
  jobTitleCodes: number[];

  constructor(vo: JobGroupVO) {
    this.jobGroupCode = vo.jobGroupCode;
    this.jobTitleCodes = getJobTitleCodes(vo.jobTitles);
  }
}

export class SelectedJob {
  jobTitleCodes: number;

  constructor(vo: JobTitleVO) {
    this.jobTitleCodes = vo.jobTitleCodes;
  }
}

const getWelfareKeywords = (dto: WelfareDto[]) => {
  return dto.map(({ code }) => code);
};

const getJobTitleCodes = (dto: JobTitleVO[]) => {
  return dto.map(({ jobTitleCodes }) => jobTitleCodes) || [];
};
