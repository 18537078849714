import { DataLogRq, SourcePage } from '@domain/rq/DataLog';
import ProfileConditionsFormVO, { SelectedJobGroup } from '@domain/vo/profile/ProfileConditionsFormVO';
import { Location, LocationCode, LocationCodeType } from '@domain/constant/locationType';

export default class MatchingConditionSaveRq {
  jobGroups: ProfileJobGroupDto[];
  locations: number[];
  salary: number | null;
  welfareKeywords: number[];
  workingPreference: string;
  sourcePage?: SourcePage;

  constructor(vo: ProfileConditionsFormVO & DataLogRq) {
    this.jobGroups = vo.jobGroups.map((jobGroup) => new ProfileJobGroupDto(jobGroup));
    this.locations = vo.locations.map((location) => this.getLocationCode(location));
    this.salary = vo.salary?.value || null;
    this.welfareKeywords = vo.welfareKeywords;
    this.workingPreference = vo.workingPreference;
    this.sourcePage = vo.sourcePage;
  }

  private getLocationCode(value: string) {
    const indexOfS = Object.values(Location).indexOf(value as Location);
    const key = Object.keys(Location)[indexOfS];
    return LocationCode[key as LocationCodeType];
  }
}

export class ProfileJobGroupDto {
  jobGroupCode: number;
  jobTitleCodes: number[];

  constructor(dto: SelectedJobGroup) {
    this.jobGroupCode = dto.jobGroupCode;
    this.jobTitleCodes = dto.jobTitleCodes;
  }
}
