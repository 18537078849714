import { SourcePage } from '@domain/rq/DataLog';

export default class MatchingConditionWorkingPreferenceRq {
  workingPreference: string;
  sourcePage?: SourcePage;

  constructor(rq: MatchingConditionWorkingPreferenceRq) {
    this.workingPreference = rq.workingPreference;
    this.sourcePage = rq.sourcePage;
  }
}
