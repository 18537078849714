import { CompanySearchDto, CompanyListRs } from '@domain/rs/company/sn/CompanyListRs';

export class CompanyListVO {
  companies: CompanySearchVO[];

  constructor(rs: CompanyListRs) {
    this.companies = rs.companies.map((company: CompanySearchDto) => new CompanySearchVO(company));
  }
}

export class CompanySearchVO {
  sn: number;
  name: string;
  logoImageUrl: string;
  locationCode: number;
  vision: string;
  numberOfPositions: number;

  constructor(rs: CompanySearchDto) {
    this.sn = rs.sn;
    this.name = rs.name;
    this.logoImageUrl = rs.logoImageUrl;
    this.locationCode = rs.locationCode;
    this.vision = rs.vision;
    this.numberOfPositions = rs.numberOfPositions;
  }
}
