import { MatchingChallengeAdDto, MatchingChallengeAdListRs } from '@domain/rs/challenge/MatchingChallengeAdListRs';

export class MatchingChallengeAdVO {
  sn: number;
  bannerLink: string;
  pcBannerImage: string;

  constructor(dto: MatchingChallengeAdDto) {
    this.sn = dto.sn;
    this.bannerLink = dto.bannerLink;
    this.pcBannerImage =
      dto.bannerImageFiles.find((bannerImageFile) => bannerImageFile.fileType === 'PC_BANNER')?.fileUrl || '';
  }
}

export default class MatchingChallengeAdListVO {
  challengeAds: MatchingChallengeAdVO[];

  constructor(rs: MatchingChallengeAdListRs) {
    this.challengeAds = rs.challengeAds.map((challengeAd) => new MatchingChallengeAdVO(challengeAd));
  }
}
