export default class UsersPasswordVerificationRq {
  id: string;
  countryCode: string;
  mobile: string;
  token: string;

  constructor(findUserInfo: UsersPasswordVerificationRq) {
    this.id = findUserInfo.id;
    this.countryCode = findUserInfo.countryCode;
    this.mobile = findUserInfo.mobile;
    this.token = findUserInfo.token;
  }
}
