import BlindCompanyListRs, { BlindCompanyDto } from '@domain/rs/profile/blind/BlindCompanyListRs';

export default class ProfileBlindCompanyVO {
  blindCompanies: BlindCompanyDto[];
  blindCompaniesText: string;

  constructor(rs: BlindCompanyListRs) {
    this.blindCompanies = rs.blindCompanies;
    this.blindCompaniesText = rs.blindCompanies.map((blindCompany) => blindCompany.name).join(', ');
  }
}
