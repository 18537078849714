export enum RecruitCategory {
  NEW = 'NEW',
  CAREER = 'CAREER',
  INTERNSHIP = 'INTERNSHIP',
  ANY = 'ANY',
  NEWORCAREER = 'NEWORCAREER',
}
export enum RecruitCategoryFilter {
  NEW = 'NEW',
  CAREER = 'CAREER',
  INTERNSHIP = 'INTERNSHIP',
  ANY = 'ANY',
}

export const ORDER_RECRUIT_CATEGORY = ['NEW', 'CAREER', 'INTERNSHIP', 'ANY'];

export type RecruitCategoryType = keyof typeof RecruitCategory;
export type RecruitCategoryFilterType = keyof typeof RecruitCategoryFilter;

export const RecruitCategoryTypeNameMap: Record<RecruitCategoryType, string> = {
  NEW: '신입',
  CAREER: '경력',
  ANY: '경력무관',
  INTERNSHIP: '인턴',
  NEWORCAREER: '신입/경력',
};

export const RecruitCategoryFilterNameMap: Record<RecruitCategoryFilterType, string> = {
  NEW: '신입',
  CAREER: '경력',
  ANY: '경력무관',
  INTERNSHIP: '인턴',
};
