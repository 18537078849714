// Created by kdw0601 on 2021-07-26
import React, { HTMLAttributes } from 'react';
import classNames from 'classnames/bind';
import styles from './checkbox.module.scss';

const cx = classNames.bind(styles);

interface ICheckboxProps extends Omit<HTMLAttributes<HTMLInputElement>, 'type'> {
  small?: boolean;
  checked?: boolean;
  disabled?: boolean;
  type?: 'square' | '';
  value?: string;
  name?: string;
  width?: number;
  height?: number;
  margin?: string | number;
}

const Checkbox = (props: ICheckboxProps) => {
  const { className, children, small, type, width, height, margin, ...rest } = props;

  const cls = cx('label', className);

  return (
    <label className={cls}>
      <input type="checkbox" className={cx('input', 'hidden')} {...rest} />
      <span
        className={cx('checkbox', small ? 'small' : null, { square: type === 'square' })}
        style={{ width, height, margin }}
      />
      {children}
    </label>
  );
};

export default Checkbox;
