import React, { useState } from 'react';
import Link from 'next/link';
import Modal from '@common/modal';
import Login from '@components/loginModal/login';
import Find from '@components/loginModal/find';
import LoginJoin from '@components/loginModal/loginJoin';
import SocialLogin from '@components/loginModal/socialLogin';
import LoginForm from '@components/loginModal/loginForm';
import { RouteUrl } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './loginModal.module.scss';
const cx = classnames.bind(styles);

const Logo = () => {
  return (
    <div className={cx('logoArea')}>
      <Link href={RouteUrl.INDEX} legacyBehavior>
        <a className={cx('logo')} role="button">
          <strong className={cx('hidden')}>JOBDA</strong>
        </a>
      </Link>
    </div>
  );
};

type ModalStateType = 'LOGIN' | 'FIND' | 'SECURE';
type FindStateType = 'ID' | 'PASSWORD';

interface LoginModalProps {
  subTitle?: string;
  title?: string;
  visible?: boolean;
  onClose?: () => void;
  onClickBg?: React.MouseEventHandler;
  dimmed?: boolean;
  afterLogin?: () => void; // TODO 일반, 소셜 로그인 기능 통일로 삭제될 API 입니다.
  redirectUrl?: string;
  isUseScrollHidden?: boolean;
}

const LoginModal = (props: LoginModalProps) => {
  const [modalState, setModalState] = useState<ModalStateType>('LOGIN');
  const [findState, setFindState] = useState<FindStateType>('ID');
  const { visible, onClickBg, dimmed = true, isUseScrollHidden } = props;

  return (
    <Modal visible={visible} onClickBg={onClickBg} dimmed={dimmed} isUseScrollHidden={isUseScrollHidden}>
      <div className={cx('layerLoginModal')}>
        <Logo />
        {modalState === 'LOGIN' && <Login {...props} setFindState={setFindState} setModalState={setModalState} />}
        {modalState === 'FIND' && (
          <Find {...props} findState={findState} setFindState={setFindState} setModalState={setModalState} />
        )}
        {/* 비밀번호 변경 90일 경과 시 노출 모달*/}
      </div>
    </Modal>
  );
};

export { Login, Find, LoginJoin, SocialLogin, LoginForm };
export type { ModalStateType, FindStateType };
export default LoginModal;
