import { useState } from 'react';
import Lottie from 'react-lottie';
import { useRouter } from 'next/router';
import Modal from '@components/common/modal';
import Text from '@components/common/text';
import CloseButton from '@components/common/closeButton';
import getProfileLottie from '@graphic/getProfileLottie.json';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useInsightResumeLog } from '@repository/insight/useInsight';
import ResumeProfileModal from '../../common/modals/resumeProfileModal';
import { ResumeProfileNoticeModalExpiresKey } from '..';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import { MILLISECONDS_PER_DAY } from '@domain/constant/Time';
import useLocalStorageExpires from '@utils/hooks/useLocalStorageExpires';
import useModal from '@utils/hooks/useModal';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const options2 = {
  loop: true,
  autoplay: true,
  animationData: getProfileLottie,
};

interface Props {
  isVisible: boolean;
  type: 'type1' | 'type2';
}

const ComponentLogId = 'btn_nudge_enter_insight_resume';

const ResumeProfileNoticeModal = ({ isVisible, type }: Props) => {
  const { pathname } = useRouter();
  const { data: userInfo } = useUserInfo();
  const { mutateAsync: fetchLog } = useInsightResumeLog();
  const { setExpires } = useLocalStorageExpires();
  const [visibleResumeProfileModal, setVisibleResumeProfileModal] = useState(false);
  const { closeModal, isModalOpen } = useModal(isVisible);

  const handleResumeBtn = async () => {
    setVisibleResumeProfileModal(true);
    await fetchLog({
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: ComponentLogId,
    });
    closeModal();
  };

  const handleExpires = () => {
    setExpires({ key: ResumeProfileNoticeModalExpiresKey, expires: MILLISECONDS_PER_DAY * 7 });
    closeModal();
  };

  const title =
    type === 'type1' ? (
      <>
        귀찮았던 프로필 작성
        <br />
        클릭 한번으로 업데이트 끝!
      </>
    ) : (
      <>
        {userInfo?.name}님! <br />
        저장해 놓은 지원서가 있네요?
      </>
    );

  const subtitle =
    type === 'type1' ? '저장한 지원서로 내 프로필 간편히 채워보세요.' : '클릭 한 번으로 내 프로필 간편히 채워보세요.';

  return (
    <>
      <Modal visible={isModalOpen} dimmed={true}>
        <div className={cx('modalArea')}>
          <CloseButton className={cx('btnClose')} onClick={closeModal} />
          <Text fontStyle={FontStyle.H4_B}>{title}</Text>
          <Text className={cx('subtitle')} fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            {subtitle}
          </Text>
          <div className={cx('lottieArea')}>
            <Lottie options={options2} />
          </div>

          <button id={ComponentLogId} className={cx('resumeBtn')} onClick={handleResumeBtn}>
            지원서 불러오기
          </button>

          <div className={cx('expiresArea')}>
            <button className={cx('expiresBtn')} onClick={handleExpires}>
              일주일 동안 보지 않기
            </button>
          </div>
        </div>
      </Modal>

      <ResumeProfileModal
        startStep={1}
        visible={visibleResumeProfileModal}
        onClose={() => setVisibleResumeProfileModal(false)}
      />
    </>
  );
};

export default ResumeProfileNoticeModal;
