import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import { RemoteInsightRepo } from '@repository/insight/InsightRepo';
import InsightResumeSaveRq from '@domain/rq/insight/InsightResumeSaveRq';
import ResumeLogRq from '@domain/rq/insight/ResumeLogRq';

const keys = ['insight'];

const repo = new RemoteInsightRepo();

export const fetchInsightAccessToken = async () => {
  const { data } = await repo.fetchInsightAccessToken();
  return data;
};

export function useInsightAceessToken() {
  return useMutation(fetchInsightAccessToken);
}

const fetchInsightResumeList = async () => {
  const { data } = await repo.fetchInsightResumeList();
  return data;
};

export function useInsightResumeList() {
  return useQuery([...keys, 'resume'], fetchInsightResumeList);
}

const fetchInsightResume = async (sn: number) => {
  const { data } = await repo.fetchInsightResume(sn);
  return data;
};

export function useInsightResume(sn: number) {
  return useQuery([...keys, 'resume', sn], () => fetchInsightResume(sn), {
    enabled: !!sn,
  });
}

const saveInsightResume = async (rq: InsightResumeSaveRq) => {
  const { data } = await repo.saveInsightResume(rq);
  return data;
};

export function useSaveInsightResume() {
  return useMutation((rq: InsightResumeSaveRq) => saveInsightResume(rq));
}

const fetchInsightUnreadResume = async () => {
  const { data } = await repo.fetchInsightUnreadResume();
  return data;
};

export function useInsightUnreadResume(enabled?: boolean) {
  return useQuery([...keys, 'resume', 'unread'], fetchInsightUnreadResume, {
    enabled,
  });
}

const fetchInsightResumeLog = async (rq: ResumeLogRq) => {
  const { data } = await repo.fetchInsightResumeLog(rq);
  return data;
};

export function useInsightResumeLog() {
  return useMutation(fetchInsightResumeLog);
}

const fetchInsightAutoSaved = async () => {
  const { data } = await repo.fetchInsightAutoSaved();
  return data;
};

export function useInsightAutoSaved(options?: UseQueryOptions) {
  return useQuery([...keys, 'resume', 'auto-saved'], fetchInsightAutoSaved, {
    staleTime: 3000,
    ...(options as any),
  });
}
