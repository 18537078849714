import { useMutation, useQuery } from 'react-query';
import { RemoteProfilesRepo } from './ProfilesRepo';
import ProfileProjectListSaveRq from '@domain/rq/profile/ProfileProjectListSaveRq';
import ProfileProjectsVO from '@domain/vo/profile/ProfileProjectVO';

export const keys = ['profile', 'project'];

const remoteProfilesRepo = new RemoteProfilesRepo();
// const profileRepo = new MockProfileRepo();

const fetchProfileProjects = async () => {
  const { data } = await remoteProfilesRepo.fetchProfileProjects();
  return new ProfileProjectsVO(data);
};

export default function useProfileProjects(isEnabled: boolean) {
  return useQuery([...keys, 'view'], fetchProfileProjects, {
    enabled: isEnabled,
  });
}

const fetchSaveProfileProjects = async (rq: ProfileProjectListSaveRq) => {
  const { data } = await remoteProfilesRepo.fetchSaveProfileProjects(rq);
  return data;
};

export function useSaveProfileProjects(onSuccess?: () => void) {
  return useMutation((rq: ProfileProjectListSaveRq) => fetchSaveProfileProjects(rq), { onSuccess });
}
