import React, { useEffect, useRef } from 'react';
import Portal from '@common/portal';
import { bodyScrollHidden } from '@utils/common';
import classnames from 'classnames/bind';
import styles from './modal.module.scss';

const cx = classnames.bind(styles);

export interface ModalProps {
  visible?: boolean;
  dimmed?: boolean;
  children?: React.ReactNode | string;
  onClickBg?: React.MouseEventHandler;
  isUseScrollHidden?: boolean; //scroll hidden 기능 사용할지 여부
}

const isModalOpened = () => {
  const modalContainer = document.body.querySelector('#modal');
  if (!modalContainer) return false;

  return modalContainer.childElementCount > 0;
};

const Modal = ({ visible, dimmed = true, children, onClickBg, isUseScrollHidden = true }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const ignoreClick = useRef(false);

  useEffect(() => {
    const isBodyScrollHidden = !!visible;

    if (isBodyScrollHidden && isUseScrollHidden) {
      bodyScrollHidden(true);
    }

    return () => {
      if (isUseScrollHidden && !isModalOpened()) {
        bodyScrollHidden(false);
      }
    };
  }, [visible, isUseScrollHidden]);

  useEffect(() => {
    const handleClickAway = (e: MouseEvent) => {
      if (ignoreClick.current) {
        ignoreClick.current = false;
        return;
      }
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClickBg?.(e as any);
      }
    };

    if (visible) {
      document.addEventListener('click', handleClickAway);
    }

    return () => {
      if (visible) {
        document.removeEventListener('click', handleClickAway);
      }
    };
  }, [visible, onClickBg]);

  const handleChildClick = (e: React.MouseEvent) => {
    ignoreClick.current = true; // 다음 외부 클릭 이벤트 무시
  };

  return (
    <>
      {visible && (
        <Portal selector="#modal">
          <div className={cx('modal', dimmed && 'dimmed')}>
            <div className={cx('modalLayer')} ref={modalRef} onClick={handleChildClick}>
              {children}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default Modal;
