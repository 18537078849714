import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import PopupRs from '@domain/rs/popup/PopupRs';

const popupPrefix = '/popup';

export default interface PopupRepo {
  fetchPopup(url: string): Promise<AxiosResponse<PopupRs>>;
}

export class RemotePopupRepo implements PopupRepo {
  fetchPopup(url: string): Promise<AxiosResponse<PopupRs>> {
    return axios.get<PopupRs>(`${popupPrefix}`, { params: { url } });
  }
}
