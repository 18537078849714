import React from 'react';
import { useController } from 'react-hook-form';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const maxLength = 1000;

interface Props extends HookFormProps {
  foreign?: boolean;
}
const Description = ({ prefixName, foreign }: Props) => {
  const placeholder = foreign
    ? '해외에서 어떤 경험을 했는지 간단히 입력해 주세요.\n(예시. 교환학생, 어학연수, 워킹홀리데이, 해외근무)'
    : '내용을 간략히 입력해 주세요.';

  const { field, fieldState } = useController({ name: `${prefixName}.description` });

  const length = field.value?.length ?? 0;

  return (
    <div className={cx('textareaWrap', { error: fieldState.invalid })}>
      <textarea {...field} placeholder={placeholder} maxLength={maxLength} rows={4} />
      <div className={cx('infoText')}>
        최대 글자수 : <span className={cx('value')}>{length}</span> / {maxLength}
        {fieldState.invalid && <div className={cx('errorMessage')}>{fieldState.error?.message}</div>}
      </div>
    </div>
  );
};

export default Description;
