import React, { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import Select from 'react-select';
import NoSsr from '@common/noSsr';
import { ReactSelectStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import classnames from 'classnames/bind';
import styles from '../prefer.module.scss';
const cx = classnames.bind(styles);

const selectOption = [
  { value: 1500, label: '1,500 만원 이상' },
  { value: 2000, label: '2,000 만원 이상' },
  { value: 2500, label: '2,500 만원 이상' },
  { value: 3000, label: '3,000 만원 이상' },
  { value: 3500, label: '3,500 만원 이상' },
  { value: 4000, label: '4,000 만원 이상' },
  { value: 4500, label: '4,500 만원 이상' },
  { value: 5000, label: '5,000 만원 이상' },
  { value: 5500, label: '5,500 만원 이상' },
  { value: 6000, label: '6,000 만원 이상' },
  { value: 6500, label: '6,500 만원 이상' },
  { value: 7000, label: '7,000 만원 이상' },
  { value: 7500, label: '7,500 만원 이상' },
  { value: 8000, label: '8,000 만원 이상' },
  { value: 8500, label: '8,500 만원 이상' },
  { value: 9000, label: '9,000 만원 이상' },
  { value: 9500, label: '9,500 만원 이상' },
  { value: 10000, label: '1억원 이상' },
];

interface SalaryProps extends UseControllerProps {
  visible: boolean;
}
const Salary: FC<SalaryProps> = (props) => {
  const { visible } = props;
  const { field, fieldState } = useController(props);

  if (!visible) return null;

  return (
    <div className={cx('salaryArea')}>
      <NoSsr>
        <Select
          {...field}
          className={cx('select', { error: fieldState.invalid })}
          placeholder={'희망하는 연봉을 알려주세요.'}
          styles={{
            ...ReactSelectStyles,
            menuList: () => ({
              overflowY: 'auto',
              padding: '4px 0',
              maxHeight: 262,
            }),
          }}
          isSearchable={false}
          options={selectOption}
        />
      </NoSsr>
      {fieldState.invalid && <div className={cx('errorMessage')}>희망 연봉수준을 선택해 주세요.</div>}
    </div>
  );
};

export default Salary;
