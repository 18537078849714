import { useMutation, useQuery } from 'react-query';
import { RemoteProfilesRepo } from '@repository/profiles/ProfilesRepo';
import ProfilePrizeSaveRq from '@domain/rq/profile/ProfilePrizeSaveRq';
import ProfilePrizeVO from '@domain/vo/profile/ProfilePrizeVO';
import ProfilePrizeFormVO from '@domain/vo/profile/ProfilePrizeFormVO';
// import MockProfileRepo from "@repository/mock/MockProfileRepo";

export const keys = ['profile', 'prize'];
export const viewKeys = [...keys, 'view'];

const remoteProfilesRepo = new RemoteProfilesRepo();
// const profileRepo = new MockProfileRepo();

const fetchProfilePrize = async () => {
  const { data } = await remoteProfilesRepo.fetchProfilePrize();
  return new ProfilePrizeVO(data);
};

const fetchSaveProfilePrize = async (vo: ProfilePrizeFormVO) => {
  const rq = new ProfilePrizeSaveRq(vo);
  const { data } = await remoteProfilesRepo.fetchSaveProfilePrize(rq);
  return data;
};

export default function useProfilePrize(isEnabled: boolean) {
  return useQuery([...keys, 'view'], fetchProfilePrize, {
    enabled: isEnabled,
  });
}

export const useSaveProfilePrize = () => {
  return useMutation((vo: ProfilePrizeFormVO) => fetchSaveProfilePrize(vo));
};
