import React from 'react';
import Icon from '@common/assets';
import { FindStateType, ModalStateType } from '@components/loginModal';
import FindId from './id';
import FindPassword from './password';

import classnames from 'classnames/bind';
import styles from './find.module.scss';
const cx = classnames.bind(styles);

interface FindTabProps {
  findState: FindStateType;
  setFindState: (state: FindStateType) => void;
}

const FindTab = ({ findState, setFindState }: FindTabProps) => {
  const findId = () => {
    setFindState('ID');
  };

  const findPassword = () => {
    setFindState('PASSWORD');
  };

  return (
    <div className={cx('findTabArea')}>
      <a className={cx('tab', findState === 'ID' && 'active')} role="button" onClick={findId}>
        아이디 찾기
      </a>
      <a className={cx('tab', findState === 'PASSWORD' && 'active')} role="button" onClick={findPassword}>
        비밀번호 찾기
      </a>
    </div>
  );
};

interface FindProps {
  setModalState: (state: ModalStateType) => void;
  findState: FindStateType;
  setFindState: (state: FindStateType) => void;
}

const Find = ({ setModalState, findState, setFindState }: FindProps) => {
  const onClose = () => {
    setModalState('LOGIN');
  };

  return (
    <div className={cx('findArea')}>
      <FindTab findState={findState} setFindState={setFindState} />
      {findState === 'ID' && <FindId setModalState={setModalState} setFindState={setFindState} />}
      {findState === 'PASSWORD' && <FindPassword setModalState={setModalState} />}
      <a className={cx('btnClose')} role="button" onClick={onClose}>
        <Icon name="closeLight" width={24} height={24} />
      </a>
    </div>
  );
};

export default Find;
