import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import CompaniesRq from '@domain/rq/profile/CompaniesRq';
import PositionListFilterRq from '@domain/rq/search/PositionListFilterRq';
import CompaniesRs from '@domain/rs/profile/career/CompaniesRs';
import CompanyInfoRs from '@domain/rs/company/CompanyInfoRs';
import CompanySalaryRs from '@domain/rs/company/CompanySalaryRs';
import CompanyNewsListRs from '@domain/rs/company/CompanyNewsListRs';
import { CompanyListRs } from '@domain/rs/company/sn/CompanyListRs';
import CompanyCultureRs from '@domain/rs/company/sn/CompanyCultureRs';
import PooledCompanyQuestionListRs from '@domain/rs/company/sn/PooledCompanyQuestionListRs';
import CompanyWelfareKeywordRs from '@domain/rs/company/sn/CompanyWelfareKeywordRs';
import CompanyThemesRs from '@domain/rs/company/sn/CompanyThemesRs';
import CompanySpecialtyRs from '@domain/rs/company/sn/CompanySpecialtyRs';
import CompanyHeaderGetRs from '@domain/rs/company/sn/CompanyHeaderGetRs';
import CompanyEmployeeStoryRs from '@domain/rs/company/sn/CompanyEmployeeStoryRs';
import PositionListRs from '@domain/rs/position/list/PositionListRs';
import CompanyEmployeeRs from '@domain/rs/company/sn/CompanyEmployeeRs';
import CompanyFinanceRs from '@domain/rs/company/sn/CompanyFinanceRs';
import CompanyNameAutoCompleteRs from '@domain/rs/company/v1/CompanyNameAutoCompleteRs';

export default interface CompaniesRepo {
  // 메인페이지 > 기업을 잡다 기업명 조회
  fetchCompanyNameAutoComplete(keyword: string): Promise<AxiosResponse<CompanyNameAutoCompleteRs>>;

  // 회사 조회
  fetchCompanies(rq: CompaniesRq): Promise<AxiosResponse<CompaniesRs>>;

  // 기업 가고 싶어요
  setCompanyLike(companySn: number): Promise<AxiosResponse<{ result: boolean }>>;

  // 기업 가고 싶어요 취소
  setCompanyUnlike(companySn: number): Promise<AxiosResponse<{ result: boolean }>>;

  // 관심 기업 추가
  addCompanyLike(companySn: number): Promise<AxiosResponse>;

  // 관심 기업 취소
  deleteCompanyLike(companySn: number): Promise<AxiosResponse>;

  //기업 검색 > 기업명 조회
  fetchCompaniesSearch(keyword: string): Promise<AxiosResponse<CompanyListRs>>;

  //기업 채널 > 기업정보 > 입사 선배가 알려주는 장점
  fetchCompaniesAdvantage(companySn: number): Promise<AxiosResponse<CompanyCultureRs>>;

  //기업 채널 > 기업정보 > 기업 정보 조회
  fetchCompaniesInformation(companySn: number): Promise<AxiosResponse<CompanyInfoRs>>;

  //기업 채널 > 기업정보 > 기출 면접 항목
  fetchCompaniesInterviewPractice(companySn: number): Promise<AxiosResponse<PooledCompanyQuestionListRs>>;

  //기업 채널 > 기업정보 > 뉴스 정보 조회
  fetchCompanyNewsList(companySn: number): Promise<AxiosResponse<CompanyNewsListRs>>;

  //기업 채널 > 기업정보 > 잡다가 분석한 특별한 강점
  fetchCompaniesSpecialty(companySn: number): Promise<AxiosResponse<CompanySpecialtyRs>>;

  //기업 채널 > 기업정보 > 복지
  fetchCompaniesWelfare(companySn: number): Promise<AxiosResponse<CompanyWelfareKeywordRs>>;

  //기업 채널 > 기업정보 > 기업 테마
  fetchCompaniesThemes(companySn: number): Promise<AxiosResponse<CompanyThemesRs>>;

  //기업 채널 > 기업정보 > 헤더
  fetchCompaniesHeader(companySn: number): Promise<AxiosResponse<CompanyHeaderGetRs>>;

  //기업 채널 > 기업정보 > 현직자 이야기
  fetchCompaniesEmployeeStories(companySn: number): Promise<AxiosResponse<CompanyEmployeeStoryRs>>;

  // 기업 채널 > 기업정보 > 직업 정보 조회
  fetchCompanyEmployee(companySn: number): Promise<AxiosResponse<CompanyEmployeeRs>>;

  // 기업 채널 > 기업정보 > 재무 정보 조회
  fetchCompanyFinance(companySn: number): Promise<AxiosResponse<CompanyFinanceRs>>;

  //기업 채널 > 공고 > 공고 리스트
  fetchCompaniesPositions(companySn: number, rq: PositionListFilterRq): Promise<AxiosResponse<PositionListRs>>;
}

export class RemoteCompaniesRepo implements CompaniesRepo {
  fetchCompanyNameAutoComplete(keyword: string) {
    return axios.get<CompanyNameAutoCompleteRs>(`/companies/names`, { params: { keyword } });
  }

  fetchCompanies(rq: CompaniesRq) {
    return axios.get<CompaniesRs>(`/companies`, { params: rq });
  }

  // 기업 연봉 정보 조회
  fetchCompanyProfileSalary(companySn: number) {
    return axios.get<CompanySalaryRs>(`/companies/${companySn}/profile/salary`);
  }

  // 기업 가고 싶어요
  setCompanyLike(companySn: number) {
    return axios.put(`/companies/${companySn}/like`);
  }

  setCompanyUnlike(companySn: number) {
    return axios.put(`/companies/${companySn}/unlike`);
  }

  // 관심 기업 추가
  addCompanyLike(companySn: number) {
    return axios.put(`/v2/companies/${companySn}/like`, null, { params: { companySn } });
  }

  // 관심 기업 취소
  deleteCompanyLike(companySn: number) {
    return axios.delete(`/v2/companies/${companySn}/unlike`, { params: { companySn } });
  }

  fetchCompaniesSearch(keyword: string) {
    return axios.get<CompanyListRs>(`/companies/search`, { params: { keyword } });
  }

  fetchCompaniesAdvantage(companySn: number) {
    return axios.get<CompanyCultureRs>(`/companies/${companySn}/advantage`);
  }

  fetchCompaniesInformation(companySn: number) {
    return axios.get<CompanyInfoRs>(`/companies/${companySn}/information`);
  }

  fetchCompaniesInterviewPractice(companySn: number) {
    return axios.get<PooledCompanyQuestionListRs>(`/companies/${companySn}/interview-practice/pooled-questions`);
  }

  fetchCompanyNewsList(companySn: number) {
    return axios.get<CompanyNewsListRs>(`/companies/${companySn}/news`);
  }

  fetchCompaniesSpecialty(companySn: number) {
    return axios.get<CompanySpecialtyRs>(`/companies/${companySn}/specialty`);
  }

  fetchCompaniesWelfare(companySn: number) {
    return axios.get<CompanyWelfareKeywordRs>(`/companies/${companySn}/welfare`);
  }

  fetchCompaniesThemes(companySn: number) {
    return axios.get<CompanyThemesRs>(`/v2/companies/${companySn}/themes`);
  }

  fetchCompaniesHeader(companySn: number) {
    return axios.get<CompanyHeaderGetRs>(`/v2/companies/${companySn}/header`);
  }

  fetchCompaniesEmployeeStories(companySn: number) {
    return axios.get<CompanyEmployeeStoryRs>(`/companies/${companySn}/employee-stories`);
  }

  fetchCompanyEmployee(companySn: number) {
    return axios.get<CompanyEmployeeRs>(`/companies/${companySn}/employment`);
  }

  fetchCompanyFinance(companySn: number) {
    return axios.get<CompanyFinanceRs>(`/companies/${companySn}/finance`);
  }

  fetchCompaniesPositions(companySn: number, rq: PositionListFilterRq) {
    return axios.get<PositionListRs>(`/companies/${companySn}/positions`, { params: rq });
  }
}
