export default class IdentificationPasswordRq {
  token: string;
  id: string;
  password: string;

  constructor(dto: IdentificationPasswordDto) {
    this.token = dto.token;
    this.id = dto.id;
    this.password = dto.password;
  }
}

interface IdentificationPasswordDto {
  token: string;
  id: string;
  password: string;
}
