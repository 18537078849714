import React, { useEffect, useRef, useState } from 'react';
import Transition from '@components/common/transition';
import IconTip from '../assets/components/IconTip';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './tooltip.module.scss';
const cx = classnames.bind(styles);

type PlacementType = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

type TooltipProps = {
  label: React.ReactNode; // 호버 영역
  top?: number | string; // 커스텀 위치 top
  left?: number | string; // 커스텀 위치 left
  iconTop?: number | string; // 아이콘 위치 top
  iconLeft?: number | string; // 아이콘 위치 left
  placement?: PlacementType; // 툴팁 위치
  className?: string;
  tooltipClassName?: string;
  withArrow?: boolean; // 화살표 여부
  withBounce?: boolean; // 툴팁 bounce 애니메이션 여부
  duration?: number; // 툴팁 transition 애니메이션 시간
  width?: number | string;
  durationForFirstRender?: number; // 첫 렌더링 시 툴팁 보여주는 시간 | undefined 시 동작 안함
  theme?: 'default' | 'gray'; // 툴팁 테마
  condition?: boolean; // true일 경우 툴팁 노출
} & (
  | {
      children: React.ReactNode;
      title?: never;
      content?: never;
    }
  | {
      children?: never;
      title?: React.ReactNode;
      content: React.ReactNode;
    }
);

const Tooltip = ({
  label,
  top,
  left,
  iconTop,
  iconLeft,
  placement = 'bottom-left',
  className,
  tooltipClassName,
  withArrow = true,
  withBounce,
  duration = 250,
  width,
  durationForFirstRender,
  theme = 'default',
  children,
  title,
  content,
  condition = true,
}: TooltipProps) => {
  const [visible, setVisible] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const targetRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const positionStyle = getTooltipPositionStyle({ placement, tooltipRef, targetRef, top, left });
  const iconTipPositionStyle = getIconTipPositionStyle({
    placement,
    tooltipRef,
    targetRef,
    top: iconTop,
    left: iconLeft,
  });

  const isDefault = theme === 'default';
  const isGray = theme === 'gray';
  const isTop = placement.split('-')[0] === 'top';

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  useEffect(() => {
    // durationForFirstRender 값이 있을 경우 tooltip을 바로 보여줌. (tooltipRef가 연결 돼있어야 하므로 requestAnimationFrame 사용)
    requestAnimationFrame(() => {
      if (firstRender && durationForFirstRender) {
        setVisible(true);
        setTimeout(() => {
          setVisible(false);
        }, durationForFirstRender);
        setFirstRender(false);
      } else {
        setFirstRender(false);
      }
    });
  }, [firstRender, durationForFirstRender]);

  return (
    <div className={cx('tooltipArea', className)}>
      <div
        className={cx('label', { condition })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={targetRef}
      >
        {label}
      </div>

      <Transition
        style={{ ...positionStyle, width }}
        className={cx(
          'tooltip',
          {
            default: isDefault,
            gray: isGray,
            bounceUp: withBounce && !isTop,
            bounceDown: withBounce && isTop,
          },
          tooltipClassName,
        )}
        visible={condition && (visible || isHover)}
        duration={duration}
        ref={tooltipRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {withArrow && (
          <IconTip
            style={iconTipPositionStyle}
            className={cx('arrow')}
            fill={isGray ? Colors.C_COOL_GRAY_70 : Colors.C_WHITE}
            stroke={isGray ? Colors.C_COOL_GRAY_70 : Colors.C_COOL_GRAY_40}
          />
        )}
        {children && children}
        {!children && title ? (
          <>
            <div className={cx('title')}>{title}</div>
            <div className={cx('content')}>{content}</div>
          </>
        ) : (
          <div className={cx('content')}>{content}</div>
        )}
      </Transition>
    </div>
  );
};

export default Tooltip;

interface GetPositionStyleProps {
  placement: PlacementType;
  tooltipRef: React.RefObject<HTMLDivElement>;
  targetRef: React.RefObject<HTMLSpanElement>;
  top?: number | string;
  left?: number | string;
}

const getTooltipPositionStyle = ({
  placement,
  tooltipRef,
  targetRef,
  top: topProp,
  left: leftProp,
}: GetPositionStyleProps) => {
  if (!tooltipRef.current || !targetRef.current) return { top: 0, left: 0 };

  const tooltipRect = tooltipRef.current.getBoundingClientRect();
  const targetRect = targetRef.current.getBoundingClientRect();
  const place = placement.split('-');

  let top: string | number = 0;
  let left: string | number = 0;

  if (place[0] === 'top') top = topProp ?? -tooltipRect.height - 12;
  else if (place[0] === 'bottom') top = topProp ?? targetRect.height + 12;

  if (place[1] === 'left') left = leftProp ?? 0;
  else if (place[1] === 'center') left = leftProp ?? (-tooltipRect.width + targetRect.width) / 2;
  else if (place[1] === 'right') left = leftProp ?? -tooltipRect.width + targetRect.width;

  return { top, left };
};

const getIconTipPositionStyle = ({
  placement,
  tooltipRef,
  targetRef,
  top: topProp,
  left: leftProp,
}: GetPositionStyleProps) => {
  if (!tooltipRef.current || !targetRef.current) return { top: 0, left: 0 };

  let top: string | number = 0;
  let left: string | number = 0;
  let transform = '';
  const tooltipRect = tooltipRef.current.getBoundingClientRect();
  const targetRect = targetRef.current.getBoundingClientRect();
  const place = placement.split('-');

  if (place[0] === 'top') {
    top = topProp ?? tooltipRect.height - 2;
    transform = 'rotateX(180deg)';
  } else if (place[0] === 'bottom') top = topProp ?? -8;

  if (place[1] === 'left') left = leftProp ?? targetRect.width / 2 - 4;
  else if (place[1] === 'center') left = leftProp ?? (tooltipRect.width - 10) / 2;
  else if (place[1] === 'right') left = leftProp ?? tooltipRect.width - targetRect.width / 2 - 4;

  return { top, left, transform };
};
