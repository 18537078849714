import {
  BaseProfileCareersDto,
  BaseProfileEducationsDto,
  BaseProfileKnowledgeAndSkillsDto,
} from '@domain/rs/position/jd/ProfileDetailRs';

export default interface LoggingRq {
  platform: keyof typeof LoggingPlatformTypeEnum;
  currentUrl: string;
  nextUrl?: string;
  actions: LoggingAction[];
  userType?: (keyof typeof LoggingUserTypeEnum)[];
  // profile?: ProfileDetail;
}

export interface LoggingAction {
  actionType: string;
  scroll: number;
}

export interface UrlInfo {
  currentUrl: string;
  nextUrl?: string;
}

interface ProfileDetail {
  education: BaseProfileEducationsDto | null;
  career: BaseProfileCareersDto | null;
  knowledgeAndSkill: BaseProfileKnowledgeAndSkillsDto | null;
}

export enum LoggingPlatformTypeEnum {
  PC = 'PC',
  MO = 'MO',
}

export enum LoggingUserTypeEnum {
  CC = 'CC',
  MATCHING_ON = 'MATCHING_ON',
  RECEIVING_MATCHING = 'RECEIVING_MATCHING',
  USER = 'USER', //매칭이 아닌 유저
}
