export default class CompanyViewVO {
  dataCheckYn: boolean;

  constructor(vo: Object) {
    this.dataCheckYn = this.getDataCheckYn(vo);
  }

  // 하나라도 저장되어 있는지 여부
  getDataCheckYn(obj: Object): boolean {
    return Object.values(obj).some((value) => {
      return Array.isArray(value) ? value.length > 0 : value !== null;
    });
  }
}
