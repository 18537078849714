import { DataLogRq, SourcePage } from '@domain/rq/DataLog';
import ProfilePrizeFormVO, { PrizeFormVO } from '@domain/vo/profile/ProfilePrizeFormVO';
import DateFormat from '@utils/date/format';

export default class ProfilePrizeSaveRq {
  prizes: PrizeSaveRq[];
  sourcePage?: SourcePage;

  constructor(vo: ProfilePrizeFormVO & DataLogRq) {
    this.prizes = vo.prizes
      .filter((item) => {
        const length = Object.values(item).filter((v) => !!v).length;
        return length > 0;
      })
      .map((item) => new PrizeSaveRq(item));
    this.sourcePage = vo.sourcePage;
  }
}

export class PrizeSaveRq {
  institution: string;
  name: string;
  date: string;
  description: string;

  constructor(vo: PrizeFormVO) {
    this.institution = vo.institution;
    this.name = vo.name;
    this.date = this.getDateFormat(vo.date);
    this.description = vo.description;
  }

  getDateFormat(data: string | null) {
    return DateFormat.getDateFormatString(data, 'yyyy-MM') ?? '';
  }
}
