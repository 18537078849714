import {
  MatchingChallengeCompanyDto,
  MatchingChallengeCompanyListRs,
} from '@domain/rs/challenge/MatchingChallengeCompanyListRs';

export class MatchingChallengeCompanyVO {
  companySn: number;
  companyName: string;

  constructor(dto: MatchingChallengeCompanyDto) {
    this.companySn = dto.companySn;
    this.companyName = dto.companyName;
  }
}

export default class MatchingChallengeCompanyListVO {
  challengeCompanies: MatchingChallengeCompanyVO[];

  constructor(rs: MatchingChallengeCompanyListRs) {
    this.challengeCompanies = rs.challengeCompanies.map(
      (challengeCompany) => new MatchingChallengeCompanyVO(challengeCompany),
    );
  }
}
