import { FC } from 'react';
import { PreferTabType } from '..';
import classnames from 'classnames/bind';
import styles from '../prefer.module.scss';
const cx = classnames.bind(styles);

interface Props {
  isExit?: boolean;
  selectedTabType: PreferTabType;
  onClose?: () => void;
  onPrev?: () => void;
  onConfirm?: () => void;
}

const ButtonArea: FC<Props> = ({ isExit = true, selectedTabType, onPrev, onClose, onConfirm }) => {
  return (
    <div className={cx('btnArea')}>
      {selectedTabType ? (
        <>
          <a className={cx('btn', 'cancel')} role="button" onClick={onPrev}>
            이전
          </a>
          <button className={cx('btn', 'confirm')} type="submit" onClick={onConfirm}>
            저장
          </button>
        </>
      ) : (
        <a className={cx('btn', 'cancel', { hidden: !isExit })} role="button" onClick={onClose}>
          나가기
        </a>
      )}
    </div>
  );
};

export default ButtonArea;
