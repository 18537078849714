import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useInsightAutoSaved, useInsightUnreadResume } from '@repository/insight/useInsight';
import { useAccCenterApplyRecent } from '@repository/acc';
import { useConditionsLastModified } from '@repository/match/conditions/useMatchConditions';
import useProfileConditions from '@repository/profiles/useProfileConditions';
import PasswordSecureModal from './PasswordSecureModal';
import ResumeProfileNoticeModal from './resumeProfileNoticeModal';
import MatchingProposalModal from './MatchProposalModal';
import AccNeedAdditionalModal from './accNeedAdditionalModal';
import AdPopup from './adPopup';
import UpdatePreferModal from './updatePreferModal';
import AutoSaveProfileModal from './autoSaveProfileModal';
import { ContentRouteUrl, RouteUrl } from '@utils/routeUtil';
import useLocalStorageExpires from '@utils/hooks/useLocalStorageExpires';
import useCheckAuthority from '@utils/hooks/useCheckAuthority';
import DateUtil from '@utils/date/util';

export interface AppContextModalComponentProps {
  contextOpenModal: () => void;
}
/**
 * 팝업 순서(필수)
 * 1.15세 미만이라면 생년월일 입력 페이지로 이동.
 * 2.장기 미접속자라면 장기 해제 페이지로 이동.
 * 3.비밀번호 변경 90일이면 모달 노출.
 *
 *
 * 팝업 규칙
 * 필수가 있으면 선택은 노출되지 않는다.
 * 모달은 1개만 노출된다.
 */

export const ResumeProfileNoticeModalExpiresKey = 'resumeProfileNoticeModalExpires';
export const AccNeedAdditionalModalExpiresKey = 'accNeedAdditionalModalExpires';

export const useRequiredPopup = () => {
  const router = useRouter();
  const { data: userInfo } = useUserInfo();
  const { data: profile } = useProfileConditions(!!userInfo?.id);
  const requiredPopupItem = ['USER_15_TEEN', 'LONG_TERM_INACTIVE_VERIFICATION_REQUIRED'];

  const visiblePreferredPage =
    [RouteUrl.ACCA_TEST_GATE, RouteUrl.INDEX].includes(router.pathname as any) && profile && !profile.allSaveYn;
  const visibleRequiredPopup =
    userInfo?.authorities?.some((authority) => requiredPopupItem.includes(authority)) || visiblePreferredPage;
  const visiblePasswordSecureModal =
    !visibleRequiredPopup && userInfo?.authorities?.includes('PASSWORD_CHANGE_REQUIRED');

  useEffect(() => {
    if (!userInfo || !profile || !router.isReady) return;

    if (userInfo.authorities?.includes('USER_15_TEEN')) {
      if (router.pathname !== ContentRouteUrl.UPDATE_BIRTHDATE) router.push(ContentRouteUrl.UPDATE_BIRTHDATE);
      return;
    }

    if (userInfo.authorities?.includes('LONG_TERM_INACTIVE_VERIFICATION_REQUIRED')) {
      if (router.pathname !== ContentRouteUrl.UPDATE_ACCOUNT) router.push(ContentRouteUrl.UPDATE_ACCOUNT);
      return;
    }

    if (visiblePreferredPage) {
      router.push(RouteUrl.PREFERRED);
      return;
    }
  }, [userInfo, router, profile]);

  return { visibleRequiredPopup, visiblePasswordSecureModal };
};

const AppContextModalComponent = () => {
  const { pathname } = useRouter();
  const { data: userInfo, isLoading } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { getIsExpired } = useLocalStorageExpires();
  const { visibleRequiredPopup, visiblePasswordSecureModal } = useRequiredPopup();

  //AutoSaveProfileModal view 여부
  const visibleAutoSaveProfileModalPath = [RouteUrl.PROFILE].includes(pathname as any);
  const enabledInsightAutoSavedApi = !visiblePasswordSecureModal && isLogin && visibleAutoSaveProfileModalPath;
  const { data: insightAutoSavedData, isFetching: fetching4 } = useInsightAutoSaved({
    enabled: isLogin && enabledInsightAutoSavedApi,
  });
  const visibleAutoSaveProfileModal = enabledInsightAutoSavedApi && !!insightAutoSavedData?.autoSaved;

  //ResumeProfileNoticeModal view 여부
  const visibleResumeProfileNoticeModalPath = [RouteUrl.INDEX, RouteUrl.PROFILE, RouteUrl.MY_PAGE_APPLY].includes(
    pathname as any,
  );
  const enabledInsightUnreadResumeApi =
    !visiblePasswordSecureModal &&
    isLogin &&
    !visibleAutoSaveProfileModal &&
    visibleResumeProfileNoticeModalPath &&
    getIsExpired(ResumeProfileNoticeModalExpiresKey);
  const { data: unreadInsightResume, isFetching: fetching1 } = useInsightUnreadResume(enabledInsightUnreadResumeApi);
  const visibleResumeProfileNoticeModal = enabledInsightUnreadResumeApi && !!unreadInsightResume;

  //updatePreferModal view 여부
  const { isMatchingUser } = useCheckAuthority(userInfo);
  const visibleUpdatePreferModalPath = RouteUrl.INDEX === pathname;
  const enabledConditionsLastModifiedApi =
    !visiblePasswordSecureModal && !visibleResumeProfileNoticeModal && isMatchingUser && visibleUpdatePreferModalPath;
  const { data: modifiedData, isFetching: fetching3 } = useConditionsLastModified(enabledConditionsLastModifiedApi);
  const visibleUpdatePreferModal =
    enabledConditionsLastModifiedApi && modifiedData && DateUtil.isPastDate(new Date(modifiedData.nextNudgeDateTime));

  //AccNeedAdditionalModal view 여부
  const visibleAccNeedAdditionalModalPath = [RouteUrl.ACCA_TEST_GATE, RouteUrl.MY_PAGE_APPLY, RouteUrl.INDEX].includes(
    pathname as any,
  );
  const enabledAccCenterApi =
    !visiblePasswordSecureModal &&
    isLogin &&
    visibleAccNeedAdditionalModalPath &&
    getIsExpired(AccNeedAdditionalModalExpiresKey);
  const { data: applyRecentData, isFetching: fetching2 } = useAccCenterApplyRecent(enabledAccCenterApi);
  const visibleAccNeedAdditionalModal =
    !visibleResumeProfileNoticeModal &&
    !visibleUpdatePreferModal &&
    enabledAccCenterApi &&
    !!applyRecentData?.applies?.some((apply) => !apply.isEnd);

  //AdPopup view 여부
  const isAdPopupVisible = !(
    visiblePasswordSecureModal ||
    visibleAutoSaveProfileModal ||
    visibleResumeProfileNoticeModal ||
    visibleAccNeedAdditionalModal ||
    visibleUpdatePreferModal
  );

  if (visibleRequiredPopup || isLoading || fetching1 || fetching2 || fetching3 || fetching4) return null;

  return (
    <>
      {/* 전역에서 뜨는 모달 */}
      <PasswordSecureModal isVisible={visiblePasswordSecureModal} />

      {/* PROFILE에서 노출 */}
      <AutoSaveProfileModal visible={visibleAutoSaveProfileModal} data={insightAutoSavedData} />

      {/* INDEX, PROFILE, MATCH에서 노출 */}
      <ResumeProfileNoticeModal isVisible={visibleResumeProfileNoticeModal} type={'type2'} />

      {/* INDEX페이지에 노출 */}
      <UpdatePreferModal visible={!!visibleUpdatePreferModal} />

      {/*PROFILE, MATCH, ACC_RESULTS에서 노출 */}
      {!visiblePasswordSecureModal && !visibleAutoSaveProfileModal && !visibleResumeProfileNoticeModal && (
        <MatchingProposalModal />
      )}

      {/* INDEX, ACCA_TEST_GATE, APPLY에서 노출 */}
      {<AccNeedAdditionalModal visible={visibleAccNeedAdditionalModal} />}

      {isAdPopupVisible && <AdPopup />}
    </>
  );
};

export default AppContextModalComponent;
