import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useToast } from '@common/toast';
import { useUserInfo } from '@repository/auth/useUserInfo';
import useProfileConditions, {
  useSaveJobGroups,
  useSaveLocations,
  useSaveSalary,
  useSaveWelfare,
  useSaveWorkingPreference,
} from '@repository/profiles/useProfileConditions';
import { useProfilesType } from '@repository/profiles/useAccaPhsInfo';
import profileConditionsSchema from '@components/profile/profileOverlay/overlays/prefer/profileConditionsSchema';
import { PreferTabType } from '@components/profile/profileOverlay/overlays/prefer';
import NotSelect from '@components/profile/profileOverlay/overlays/prefer/contents/notSelect/index';
import JobGroup from '@components/profile/profileOverlay/overlays/prefer/contents/jobGroup';
import Location from '@components/profile/profileOverlay/overlays/prefer/contents/Location';
import Salary from '@components/profile/profileOverlay/overlays/prefer/contents/Salary';
import Welfare from '@components/profile/profileOverlay/overlays/prefer/contents/Welfare';
import WorkingPreference from '@components/profile/profileOverlay/overlays/prefer/contents/WorkingPreference';

import ExitModal from '@components/profile/profileOverlay/exitModal';

import { useInsightResumeLog } from '@repository/insight/useInsight';
import ButtonArea from './items/ButtonArea';
import PreferTabTitle from './items/PreferTabTitle';
import MatchingConditionSaveRq from '@domain/rq/profile/matchingConditions/MatchingConditionSaveRq';
import MatchingConditionJobGroupRq from '@domain/rq/profile/matchingConditions/MatchingConditionJobGroupRq';
import MatchingConditionLocationRq from '@domain/rq/profile/matchingConditions/MatchingConditionLocationRq';
import MatchingConditionSalaryRq from '@domain/rq/profile/matchingConditions/MatchingConditionSalaryRq';
import MatchingConditionWelfareRq from '@domain/rq/profile/matchingConditions/MatchingConditionWelfareRq';
import MatchingConditionWorkingPreferenceRq from '@domain/rq/profile/matchingConditions/MatchingConditionWorkingPreferenceRq';
import ProfileConditionsFormVO from '@domain/vo/profile/ProfileConditionsFormVO';
import useModals from '@utils/hooks/useModals';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const skipBtnLogId = 'btn_skip_resume_conditional_modal';
const saveBtnLogId = 'btn_save_resume_conditional_modal';
interface Props {
  nextStep: (count: number) => void;
}

const Step4 = ({ nextStep }: Props) => {
  const preferDefaultValues: RecursivePartial<ProfileConditionsFormVO> = {
    jobGroups: [],
    locations: [],
    salary: {},
    welfareKeywords: [],
    workingPreference: '',
  };

  const Toast = useToast();
  const { query, pathname } = useRouter();
  const [selectedTabType, setSelectedTabType] = useState<PreferTabType>(null);
  const [errorCheck, setErrorCheck] = useState(false);
  const { modalState, openModal, closeModal, isVisible } = useModals(['exit', 'prev']);
  const { mutateAsync: fetchLog } = useInsightResumeLog();
  const { data: userInfo } = useUserInfo();
  const { data: conditionsData, refetch: conditionsRefetch } = useProfileConditions(!!userInfo?.id);
  const { refetch: profilesTypeRefetch } = useProfilesType(!!userInfo?.id);
  const { mutateAsync: saveJobGroups } = useSaveJobGroups();
  const { mutateAsync: saveLocations } = useSaveLocations();
  const { mutateAsync: saveSalary } = useSaveSalary();
  const { mutateAsync: saveWelfare } = useSaveWelfare();
  const { mutateAsync: saveWorkingPreference } = useSaveWorkingPreference();

  const saveSuccess = async () => {
    await conditionsRefetch();
    //SNb 역량/PHS 정보 활성화 상태 업데이트
    await profilesTypeRefetch();

    setSelectedTabType(null);
    Toast({ type: 'success', iconType: 'info', content: '성공적으로 저장되었습니다.' });
  };

  const methods = useForm<ProfileConditionsFormVO>({
    mode: 'onChange',
    defaultValues: preferDefaultValues as ProfileConditionsFormVO,
    resolver: yupResolver(profileConditionsSchema) as any,
  });

  const {
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const onCloseOverlay = (isExit: boolean) => {
    const jobGroupsField = getValues('jobGroups');
    /* 
      isDirty는 깊은 비교를 하지만 jobGroups의 데이터 형식인 [{string, array[]}]에서 array까지는 깊은 비교를 해주지 않아,
      같은 직군의 직무(array)를 직접 비교해주어야한다.
    */
    const isDirtyJobGroups = jobGroupsField.some(
      ({ jobGroupCode, jobTitleCodes }: { jobGroupCode: any; jobTitleCodes: any }) =>
        conditionsData?.jobGroups.some(
          ({ jobGroupCode: conditionDataJobGroupCode, jobTitles: conditionDataJobTitles }) =>
            jobGroupCode === conditionDataJobGroupCode && jobTitleCodes.length !== conditionDataJobTitles.length,
        ),
    );

    if (isDirty || isDirtyJobGroups) {
      if (isExit) openModal('exit');
      else openModal('prev');
      return;
    }

    if (isExit) handleExit();
    else setSelectedTabType(null);
  };

  const isSelectedTab = (preferTabType: PreferTabType) => preferTabType === selectedTabType;

  const handleExit = () => {
    if (isVisible('prev')) {
      setSelectedTabType(null);
      return;
    }

    setSelectedTabType(null);
  };

  const handleSkip = async () => {
    await fetchLog({
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: skipBtnLogId,
    });
    nextStep(0);
  };

  const handleSave = async () => {
    const { jobGroups, locations, salary, welfareKeywords, workingPreference } = getValues();
    const isFillRequired = jobGroups.length && locations.length && salary?.value;
    if (!isFillRequired) {
      setErrorCheck(true);
      return Toast({ iconType: 'info', type: 'error', content: '필수 정보를 작성해주세요.' });
    }

    await fetchLog({
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: saveBtnLogId,
    });
    const addConditionsLength = [
      !!jobGroups.length,
      !!locations.length,
      !!salary?.value,
      !!welfareKeywords.length,
      !!workingPreference.length,
    ].filter((v) => v).length;

    nextStep(addConditionsLength);
  };

  const isError = (vo: ProfileConditionsFormVO) => {
    if (isSelectedTab('jobGroup') && vo.jobGroups.length < 1) return true;
    if (isSelectedTab('location') && vo.locations.length < 1) return true;
    if (isSelectedTab('salary') && !vo.salary) return true;
    return false;
  };

  const onSubmit = (vo: ProfileConditionsFormVO) => {
    const rq = new MatchingConditionSaveRq(Object.assign(vo, { sourcePage: query.source_page }));
    if (isError(vo)) return Toast({ type: 'error', iconType: 'info', content: '저장에 필요한 정보를 작성해주세요.' });
    if (isSelectedTab('jobGroup'))
      return saveJobGroups(new MatchingConditionJobGroupRq(rq), { onSuccess: saveSuccess });
    if (isSelectedTab('location'))
      return saveLocations(new MatchingConditionLocationRq(rq), { onSuccess: saveSuccess });
    if (isSelectedTab('salary')) return saveSalary(new MatchingConditionSalaryRq(rq), { onSuccess: saveSuccess });
    if (isSelectedTab('welfare')) return saveWelfare(new MatchingConditionWelfareRq(rq), { onSuccess: saveSuccess });
    if (isSelectedTab('working_preference'))
      return saveWorkingPreference(new MatchingConditionWorkingPreferenceRq(rq), { onSuccess: saveSuccess });
  };

  useEffect(() => {
    if (conditionsData)
      reset({
        ...preferDefaultValues, //  defaultValues넣어줘야 isDirty가 제대로 동작함.
        ...new ProfileConditionsFormVO(conditionsData),
      });
  }, [conditionsData]);

  return (
    <FormProvider {...methods}>
      <div className={cx('stepWrap')}>
        <div className={cx('scrollArea')}>
          <div className={cx('scrollInner')}>
            <div className={cx('contents')}>
              <PreferTabTitle selectedTabType={selectedTabType} />
              <NotSelect
                visible={isSelectedTab(null)}
                setSelectedTabType={setSelectedTabType}
                errorCheck={errorCheck}
              />
              {/* 직군, 직무 */}
              <JobGroup name={'jobGroups'} visible={isSelectedTab('jobGroup')} />
              {/* 근무 지역 */}
              <Location name={'locations'} visible={isSelectedTab('location')} />
              {/* 연봉 수준 */}
              <Salary name={'salary'} visible={isSelectedTab('salary')} />
              {/* 복지 */}
              <Welfare name={'welfareKeywords'} visible={isSelectedTab('welfare')} />
              {/* 희망사항*/}
              <WorkingPreference name={'workingPreference'} visible={isSelectedTab('working_preference')} />
            </div>
          </div>
        </div>

        <ButtonArea
          selectedTabType={selectedTabType}
          onClose={() => onCloseOverlay(true)}
          onConfirm={handleSubmit(onSubmit)}
          onNext={handleSkip}
          onSave={handleSave}
        />
      </div>
      {/* 나가기 모달 영역 */}
      <ExitModal visible={!!modalState} onClose={closeModal} onExit={handleExit} />
    </FormProvider>
  );
};

export default Step4;
