import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Icon from '@components/common/assets';
import Checkbox from '@components/common/checkbox';
import { CheckListType, EditViewType } from '../..';
import styles from '../../../commonStyle.module.scss';
import InvalidMessage from '../invalidMessage';
import EducationFormVO from '@domain/vo/insight/resume/EductaionFormVO';
import { EducationStatusType, getEducationDateText } from '@domain/constant/profile/EducationType';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface Props {
  checkList?: number[];
  updateCheckList: (type: CheckListType, index: number) => void;
  openEditView: (type: EditViewType, formName: string) => void;
}

const Education: FC<Props> = ({ checkList, updateCheckList, openEditView }) => {
  const { watch, getFieldState } = useFormContext();
  const data = watch('education') as EducationFormVO;

  const listEls = data?.educations?.map((education, index) => {
    const isChecked = checkList?.includes(index);
    const invalid = getFieldState(`education.educations.${index}`).invalid;
    const handleCheckBtn = () => {
      if (invalid) return;
      updateCheckList('education', index);
    };
    const handleEditBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      openEditView('education', `education.educations.${index}`);
    };
    const startDate = DateFormat.format(new Date(education.startDate), 'yyyy.MM');
    const endDate = DateFormat.format(new Date(education.endDate), 'yyyy.MM');
    const educationTypeText = education.educationType?.label;
    const schoolName = education.schoolName?.label;
    const educationType = education.educationType?.value as EducationStatusType;

    return (
      <button key={index} className={cx('cardWrap', { invalid }, { isChecked })} onClick={handleCheckBtn}>
        <Checkbox
          className={cx('checkbox')}
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
          disabled={invalid}
          width={24}
          height={24}
          margin={0}
        />
        <div className={cx('infoArea')}>
          <div className={cx('textArea')}>
            <span className={cx('listText')}>{schoolName}</span>
            <div className={cx('listDesc')}>
              {invalid ? (
                <InvalidMessage />
              ) : (
                <>
                  <span>{getEducationDateText({ educationType, startDate, endDate, educationTypeText })}</span>
                  {education.major?.majorAlias?.label && (
                    <span className={cx('listLabel')}>{education.major?.majorAlias?.label}</span>
                  )}
                </>
              )}
            </div>
          </div>

          <button className={cx('editBtn', { invalid })} onClick={handleEditBtn}>
            <Icon name="guideLight" width={32} height={32} />
          </button>
        </div>
      </button>
    );
  });

  if (!data?.educations?.length) return null;

  return (
    <div className={cx('listWrap')}>
      <div className={cx('listTitleArea')}>
        <Icon name="universityLight" width={32} height={32} />
        <span className={cx('listTitle')}>학력</span>
      </div>
      <div className={cx('cardList')}>{listEls}</div>
    </div>
  );
};

export default Education;
