import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { RemoteTermsRepo } from '@repository/terms/TermsRepo';
import { RemoteUserTermsAgreesRepo } from '@repository/userTermsAgrees/UserTermsAgreesRepo';
import { TERMS_TYPE } from '@domain/constant/v1/termsType';

export const keys = ['acca', 'test', 'terms'];

const remoteTermsRepo = new RemoteTermsRepo();
const remoteUserTermsAgreesRepo = new RemoteUserTermsAgreesRepo();

const fetchTermsOne = async (termsSn?: number | null) => {
  if (!termsSn) return undefined;

  const { data } = await remoteTermsRepo.fetchTermsOne(termsSn);
  return data.terms;
};

const fetchTermsAgreeForSn = async (termsSn: number) => {
  const { data } = await remoteUserTermsAgreesRepo.fetchTermsAgreeForSn(termsSn);
  return data.result;
};

const fetchTermsCheckNew = async (termType: TERMS_TYPE) => {
  const { data } = await remoteUserTermsAgreesRepo.fetchTermsCheckNew(termType);
  return data;
};

//약고나 본문 조회
export function useTermsOne(termsSn?: number | null, isEnabled = true) {
  return useQuery([...keys, termsSn], () => fetchTermsOne(termsSn), {
    enabled: isEnabled && !!termsSn,
  });
}

export function useTermsCheckNew(termsType: TERMS_TYPE, isEnabled: boolean) {
  return useQuery([...keys, 'checkNew'], () => fetchTermsCheckNew(termsType), {
    enabled: isEnabled,
  });
}

export function useTermsCheckNewMutation() {
  return useMutation((termsType: TERMS_TYPE) => fetchTermsCheckNew(termsType));
}

export function useTermsAgreeForSn(onSuccess?: (data: boolean) => void, onError?: (err: AxiosError) => void) {
  return useMutation((termsSn: number) => fetchTermsAgreeForSn(termsSn), {
    onSuccess,
    onError,
  });
}
