import { ReviewType } from './reviewType';

const IMG_PATH = '/img/v2/components/company/sn/questionType';

export enum IncumbentsQuestionIconTypeEnum {
  star = IMG_PATH + '/star.png',
  heart = IMG_PATH + '/heart.png',
  date = IMG_PATH + '/date.png',
  report = IMG_PATH + '/report.png',
  file = IMG_PATH + '/icon_file_done_green.png',
  group = IMG_PATH + '/icon_do_not_copy.png',
  leave = IMG_PATH + '/leave.png',
  chat = IMG_PATH + '/icon_chat_blue.png',
  laurel = IMG_PATH + '/icon_laurel_wreath_star.png',
  time = IMG_PATH + '/time.png',
  graphic = IMG_PATH + '/graphic.png',
  people_blue = IMG_PATH + '/icon_people_blue.png',
  person_love = IMG_PATH + '/icon_person_love.png',
  money = IMG_PATH + '/icon_money.png',
  person_star = IMG_PATH + '/icon_person_star.png',
  circle_people = IMG_PATH + '/icon_circle_people.png',
  money_balance = IMG_PATH + '/icon_money_balance.png',
  heart_hand = IMG_PATH + '/icon_heart_hand.png',
}

type iconType = keyof typeof IncumbentsQuestionIconTypeEnum;

export interface CultureIncumbentsQuestionTypeProps {
  answer: string;
  iconType?: iconType;
}

const CultureIncumbentsQuestionType: Record<ReviewType, Record<number, CultureIncumbentsQuestionTypeProps>> = {
  //긍정문화 - 물질적 보상
  CASH_REWARD: {
    0: {
      answer: '',
    },
    1: {
      answer: '안정적인 삶의 원동력',
      iconType: 'heart_hand',
    },
    2: {
      answer: '모두에게 공정한 보상',
      iconType: 'money_balance',
    },
    3: {
      answer: '생활에 도움되는 복지제도',
      iconType: 'money',
    },
  },
  //긍정문화 - 동료와의 관계
  COWORKER_RELATIONSHIP: {
    0: {
      answer: '',
    },
    1: {
      answer: '믿고 의지할 수 있는 존재',
      iconType: 'people_blue',
    },
    2: {
      answer: '능력자 동료들',
      iconType: 'person_star',
    },
    3: {
      answer: '터놓고 이야기할 수 있는 존재들',
      iconType: 'circle_people',
    },
    4: {
      answer: '절친한 친구',
      iconType: 'person_love',
    },
  },
  //긍정문화 - 업무환경
  WORK_ENVIRONMENT: {
    0: {
      answer: '',
    },
    1: {
      answer: '업무 환경 200% 대만족',
      iconType: 'star',
    },
    2: {
      answer: '출근이 자율적',
      iconType: 'time',
    },
    3: {
      answer: '퇴근이 자율적',
      iconType: 'leave',
    },
    4: {
      answer: '업무시간 외 업무지시 절대 금지',
      iconType: 'group',
    },
    5: {
      answer: '휴가 사용이 자율적',
      iconType: 'date',
    },
    6: {
      answer: '내가 원할 때까지 정년 보장',
      iconType: 'laurel',
    },
    7: {
      answer: '웃음이 넘치고 따뜻한 곳',
      iconType: 'heart',
    },
    8: {
      answer: '부서간 협업이 활발한 곳',
      iconType: 'graphic',
    },
    9: {
      answer: '품의 및 결재라인이 합리적',
      iconType: 'file',
    },
    10: {
      answer: '보고 프로세스가 합리적',
      iconType: 'report',
    },
    11: {
      answer: '회의 프로세스가 합리적',
      iconType: 'chat',
    },
  },
  //성과문화 - 사회적 보상
  SOCIAL_REWARD: {
    0: {
      answer: '',
    },
    1: {
      answer: '나를 존재감 있는 사람으로 만드는 것',
    },
    2: {
      answer: '모두에게 매우 중요한 문화',
    },
    3: {
      answer: '직급, 직책과 무관한 구성원 그 자체로서의 존중',
    },
  },
  //성과문화 - 리더와의 관계
  LEADER_RELATIONSHIP: {
    0: {
      answer: '',
    },
    1: {
      answer: '믿고 따를만한 존재',
    },
    2: {
      answer: '구성원의 자율을 보장하는 곳',
    },
    3: {
      answer: '건설적인 피드백을 주는 리더',
    },
    4: {
      answer: '팀을 성공시킬 수 있는 전략가',
    },
    5: {
      answer: '실수를 솔직하게 인정하는 쿨한 리더',
    },
  },
  //성과문화 - 성장환경
  GROWTH_ENVIRONMENT: {
    0: {
      answer: '',
    },
    1: {
      answer: '나를 성장할 수 있게 만드는 곳',
    },
    2: {
      answer: '성장 중심 육성체계를 제공하는 곳',
    },
    3: {
      answer: '직급 상관 없이 성장 기회를 주는 곳',
    },
    4: {
      answer: '자기계발 기회를 주는 곳',
    },
    5: {
      answer: '실패를 두려워하지 않는 도전적인 곳',
    },
    6: {
      answer: '중요 이슈를 투명하게 공유하는 곳',
    },
    7: {
      answer: '모두가 공감하는 목표를 수립하는 곳',
    },
    8: {
      answer: '육성 중심 평가체계를 제공하는 곳',
    },
    9: {
      answer: '나이, 성별, 학교에 대한 차별이 없는 곳',
    },
    10: {
      answer: '절대평가를 진행하는 곳',
    },
    11: {
      answer: '다면평가를 진행하는 곳',
    },
    12: {
      answer: '공정/객관적인 인사위원회를 운영하는 곳',
    },
  },
  //가치문화 - 정신적 보상
  MENTAL_REWARD: {
    0: {
      answer: '',
    },
    1: {
      answer: '존재만으로 최고 그 자체',
    },
    2: {
      answer: '업계 브랜드 파워 1위 회사',
    },
    3: {
      answer: '모두가 가고 싶은 꿈의 회사',
    },
    4: {
      answer: '모두에게 자랑스럽게 소개할 수 있는 곳',
    },
  },
  //가치문화 - 세상과의 관계
  WORLD_RELATIONSHIP: {
    0: {
      answer: '',
    },
    1: {
      answer: '신뢰할 수 있는 경영진이 있는 곳',
    },
    2: {
      answer: '사회 공동 이익을 중요하게 생각하는 곳',
    },
    3: {
      answer: '선한 영향력을 행사하는 곳',
    },
    4: {
      answer: '정기적으로 이익을 환원하는 곳',
    },
    5: {
      answer: '자원봉사 프로그램을 운영하는 곳',
    },
  },
  //가치문화 - 통합환경
  TOTAL_ENVIRONMENT: {
    0: {
      answer: '',
    },
    1: {
      answer: '내 사명과 꿈을 이룰 수 있는 곳',
    },
    2: {
      answer: '정직하고 윤리적인 곳',
    },
    3: {
      answer: '명확한 방향성을 지향하는 곳',
    },
  },
};

export default CultureIncumbentsQuestionType;
