import { useMutation, useQuery } from 'react-query';
import Cookies from 'js-cookie';
import { AxiosError } from 'axios';
import { setAccessToken, setAutoLogin, setGAId } from '@repository/AccessToken';
import { RemoteAuthRepo } from '@repository/auth/AuthRepo';
import { LoginFormField } from '@components/loginModal/loginForm';
import { axios } from '@repository/RequestClient';
import LoginRq from '@domain/rq/LoginRq';
import UserVO from '@domain/vo/user/UserVO';
import { RouteUrl } from '@utils/routeUtil';
import AuthUtil from '@utils/AuthUtil';

const remoteAuthRepo = new RemoteAuthRepo();

export const useUserInfo = () => {
  const accessToken = Cookies.get('accessToken');

  return useQuery(
    [accessToken, 'userInfo'],
    async () => {
      if (!axios.defaults.headers.common['Authorization']) {
        setAccessToken(accessToken);
      }

      const { data } = await remoteAuthRepo.getUser();
      return new UserVO(data);
    },
    {
      enabled: !!accessToken,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};

export function useMutateUserInfo() {
  return useMutation(async () => {
    const { data: userRs } = await remoteAuthRepo.getUser();
    return userRs;
  });
}

export function useLogin(isReload = true, onSuccess?: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(
    async (loginInfo: LoginFormField) => {
      const { data: loginRs } = await remoteAuthRepo.login(new LoginRq(loginInfo));
      if (loginInfo.autoLogin) setAutoLogin();
      return { loginInfo, loginRs };
    },
    {
      onSuccess: ({ loginInfo, loginRs }) => {
        const { accessToken } = loginRs;
        setAccessToken(accessToken);
        setGAId(loginInfo.id);
        if (isReload) location.reload();
        else onSuccess?.(); //onSuccess를 사용하려면 isReload가 false여야함.
      },
      onError,
    },
  );
}

export function useLogout(onSuccess?: () => void, onError?: (e: AxiosError) => void) {
  return useMutation(
    async () => {
      await remoteAuthRepo.logout();
    },
    {
      onSuccess: () => {
        AuthUtil.resetAuth();
        if (!onSuccess) location.href = RouteUrl.INDEX;
        else onSuccess();
      },
      onError,
    },
  );
}

export function useRenewAccessTokenRefreshToken() {
  return useMutation(async () => {
    const { data: loginRs } = await remoteAuthRepo.renewAccessTokenRefreshToken();
    const { accessToken } = loginRs;
    setAccessToken(accessToken);
  });
}

export function useRenewAccessToken() {
  return useMutation(async () => {
    const { data: renewAccessTokenRs } = await remoteAuthRepo.renewAccessToken();
    const accessToken = renewAccessTokenRs.accessToken;
    setAccessToken(accessToken);
  });
}
