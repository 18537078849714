import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { handleMonthInput, MonthPicker } from '@common/datePicker';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const EndDate = ({ prefixName }: HookFormProps) => {
  const { trigger, clearErrors } = useFormContext();
  const { field: endDateField, fieldState: endDateState } = useController({ name: `${prefixName}.endDate` });
  const { field: employmentStatusField } = useController({ name: `${prefixName}.employmentStatus` });
  const isEndless = employmentStatusField.value !== 'RESIGN';

  useEffect(() => {
    if (!isEndless) {
      trigger(`${prefixName}.endDate`);
    } else {
      clearErrors(`${prefixName}.endDate`);
      endDateField.onChange('');
    }
  }, [employmentStatusField.value]);

  const handleChange = (date: Date | null) => {
    if (!date) return;
    endDateField.onChange(DateFormat.format(date, 'yyyy-MM'));
  };

  return (
    <div>
      <MonthPicker
        selected={endDateField.value ? new Date(endDateField.value) : null}
        onChange={handleChange}
        onChangeRaw={handleMonthInput}
        className={cx('periodInput', { error: endDateState.invalid }, { disabled: isEndless })}
        placeholderText="연도. 월"
        maxDate={new Date('9999-12')}
        disabled={isEndless}
      />
      <input {...endDateField} className={cx('hidden')} />
      {endDateState.invalid && <div className={cx('errorMessage')}>{endDateState.error?.message}</div>}
    </div>
  );
};

export default EndDate;
