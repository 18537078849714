import DateFormat from '@utils/date/format';

export interface IJobOpeningTextListItemVO {
  adSn?: number;
  sn: number;
  companyName: string;
  title: string;
  closingDateTime: string;
  logoImageUrl: string;
}

export class JobOpeningTextListItemVO {
  adSn?: number;
  sn: number;
  companyName: string;

  title: string;

  dDay: string;
  closingDateTime: string | null;
  logoImageUrl: string;

  constructor(dto: IJobOpeningTextListItemVO) {
    this.adSn = dto.adSn;
    this.sn = dto.sn;
    this.companyName = dto.companyName;

    this.title = dto.title;

    this.dDay = DateFormat.formatPositionDDayText(dto.closingDateTime ? new Date(dto.closingDateTime) : null);
    this.closingDateTime = dto.closingDateTime
      ? DateFormat.format(new Date(dto.closingDateTime), 'yyyy.MM.dd(week) HH:mm')
      : null;
    this.logoImageUrl = dto.logoImageUrl;
  }
}
