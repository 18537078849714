import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useInsightResume, useInsightResumeLog, useSaveInsightResume } from '@repository/insight/useInsight';
import useProfileEducation from '@repository/profiles/useProfileEducation';
import useProfileCareer from '@repository/profiles/useProfileCareer';
import useProfileProjects from '@repository/profiles/useProfileProject';
import useProfileSkill from '@repository/profiles/useProfileSkill';
import useProfileExperience from '@repository/profiles/useProfileExperience';
import useProfilePrize from '@repository/profiles/useProfilePrize';
import editFormSchema from './schema';
import EditView from './editView';
import ListView from './listView';
import { InsightResumeGetFormVO } from '@domain/vo/insight/resume/InsightResumeGetFormVO';

interface Step3Props {
  onClose: () => void;
  nextStep: (totalResumeCount: number) => void;
  prevStep: () => void;
  selected: number | null;
  isFillConditions: boolean;
}

export type CheckListType = 'education' | 'career' | 'project' | 'experience' | 'prize';

export type EditViewType =
  | 'education'
  | 'career'
  | 'project'
  | 'experience'
  | 'foreign_experience'
  | 'prize'
  | 'license'
  | null;

const ComponentLogId = 'modal_edit_insight_resume';
const ComponentLogId2 = 'btn_save_insight_resume';

const Step3: FC<Step3Props> = ({ onClose, nextStep, prevStep, selected, isFillConditions }) => {
  const { pathname } = useRouter();
  const { mutateAsync: fetchLog } = useInsightResumeLog();
  //지원서 항목들 refetch
  const { refetch: refetchEducation } = useProfileEducation(false);
  const { refetch: refetchCareer } = useProfileCareer(false);
  const { refetch: refetchProject } = useProfileProjects(false);
  const { refetch: refetchSkill } = useProfileSkill(false);
  const { refetch: refetchExperience } = useProfileExperience(false);
  const { refetch: refetchPrize } = useProfilePrize(false);

  const refetchProfile = () => {
    refetchEducation();
    refetchCareer();
    refetchProject();
    refetchSkill();
    refetchExperience();
    refetchPrize();
  };

  const { data: resumeData } = useInsightResume(selected as number);
  const { mutateAsync: saveResume } = useSaveInsightResume();
  const methods = useForm<InsightResumeGetFormVO>({
    mode: 'all',
    resolver: yupResolver(editFormSchema) as any,
  });

  const { reset, trigger, watch, getFieldState } = methods;

  const [viewData, setviewData] = useState(new Map<EditViewType, string>(null));
  //가장 최근에 저장된 지원서 데이터
  const [latestResumeData, setLatestResumeData] = useState<InsightResumeGetFormVO | null>(null);
  //Check된 컴포넌트 리스트
  const [checkList, setCheckList] = useState(new Map<CheckListType, number[]>(null));
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const isEditViewOpen = viewData.size > 0;
  const openEditView = (type: EditViewType, formName: string) => {
    setviewData(new Map<EditViewType, string>([[type, formName]]));
  };

  //지원서 데이터에서 값이 있는지 확인
  const findResumeItemValue: any = (data: any) => {
    if (!data) return false;
    if (Array.isArray(data)) {
      return data.some((el) => findResumeItemValue(el));
    } else {
      return Object.values(data).some((e) => {
        if (e === true || undefined) return false;
        else if (typeof e === 'object') {
          return findResumeItemValue(e);
        }
        return !!e;
      });
    }
  };

  //초기 체크리스트 설정
  const initCheckList = () => {
    const initMap = new Map<CheckListType, number[]>([
      ['education', []],
      ['career', []],
      ['project', []],
      ['experience', []],
      ['prize', []],
    ]);

    const mapNameList = ['education', 'career', 'project', 'experience', 'prize'];
    const fieldsNameList = [
      'education.educations',
      'career.careers',
      'project.projects',
      'experience.activitiesAndForeignExperiences',
      'prize.prizes',
    ];

    fieldsNameList.forEach((fieldName, index) => {
      const fieldData = watch(fieldName as keyof InsightResumeGetFormVO) as any;
      const checkedList = fieldData?.map((_: any, idx: number) => {
        const fieldState = getFieldState(`${fieldName}.${idx}` as keyof InsightResumeGetFormVO);
        return fieldState.invalid ? null : idx;
      });
      initMap.set(
        mapNameList[index] as CheckListType,
        checkedList?.filter((el: any) => el !== null),
      );
    });

    setCheckList(initMap);
  };

  //선택된 항목 개수
  const getTotalResumeCount = () => {
    const examinationCount = watch('knowledgeAndSkill.examinations')?.length;
    const licenseCount = watch('knowledgeAndSkill.licenses')?.length;
    const skillCount = watch('knowledgeAndSkill.skills')?.length;
    let count = 0;
    checkList.forEach((value) => {
      count += value.length;
    });

    return count + examinationCount + licenseCount + skillCount;
  };

  //체크리스트 추가
  const addCheckList = (type: CheckListType, index: number) => {
    const newCheckList = new Map(checkList);
    const list = newCheckList.get(type) ?? [];
    list.push(index);
    const removeList = Array.from(new Set(list));
    newCheckList.set(type, removeList);
    setCheckList(newCheckList);
  };

  //체크리스트 추가 & 제거
  const updateCheckList = (type: CheckListType, index: number) => {
    const newCheckList = new Map(checkList);
    const list = newCheckList.get(type) ?? [];
    const isAdded = list.includes(index);

    if (isAdded) {
      const newList = list.filter((el) => el !== index);
      newCheckList.set(type, newList);
    } else {
      list.push(index);
      const newList = Array.from(new Set(list));
      newCheckList.set(type, newList);
    }
    setCheckList(newCheckList);
  };

  //지원서 불러오기 함수
  const onSubmit = async () => {
    await saveResume(
      {
        resumeSn: selected as number,
        resumeData: InsightResumeGetFormVO.convertRqFormVO(latestResumeData as InsightResumeGetFormVO, checkList),
      },
      {
        onSuccess: () => {
          refetchProfile();
          fetchLog({
            action: 'CLICK',
            actionSource: pathname,
            actionComponent: ComponentLogId2,
            data: {
              insightResumeSn: selected,
            },
          });
        },
      },
    );
    nextStep(getTotalResumeCount());
  };

  const awaitTrigger = async () => {
    setIsValid(await trigger());
  };

  //Log쌓기
  useEffect(() => {
    fetchLog({
      action: 'VIEW',
      actionSource: pathname,
      actionComponent: ComponentLogId,
      data: {
        insightResumeSn: selected,
      },
    });
  }, []);

  useEffect(() => {
    if (!resumeData) return;
    const newData = JSON.parse(JSON.stringify(new InsightResumeGetFormVO(resumeData)));
    reset(newData);
    setLatestResumeData(new InsightResumeGetFormVO(resumeData));
    awaitTrigger();
  }, [resumeData]);

  useEffect(() => {
    if (isValid === null) return;
    initCheckList();
  }, [isValid]);

  if (!resumeData) return null;

  return (
    <FormProvider {...methods}>
      {isEditViewOpen ? (
        <EditView
          addCheckList={addCheckList}
          viewData={viewData}
          latestResumeData={latestResumeData}
          setLatestResumeData={setLatestResumeData}
          onPrev={() => setviewData(new Map(null))}
        />
      ) : (
        <ListView
          isEmptyResumeItem={!findResumeItemValue(resumeData)}
          checkList={checkList}
          updateCheckList={updateCheckList}
          onClose={onClose}
          nextStep={onSubmit}
          prevStep={prevStep}
          openEditView={openEditView}
          isFillConditions={isFillConditions}
        />
      )}
    </FormProvider>
  );
};

export default Step3;
