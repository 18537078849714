import CompanyEmployeeStoryRs, { CompanyEmployeeStoryDto } from '@domain/rs/company/sn/CompanyEmployeeStoryRs';
import { YearTypeEnum } from '@domain/constant/company/sn/yearType';

export default class CompanyEmployeeStoriesVO {
  title: string; //현직자 이야기 타이틀
  stories: CompanyEmployeeStoryVO[];

  constructor(dto: CompanyEmployeeStoryRs) {
    this.title = dto.title;
    this.stories = dto.stories.map((story) => new CompanyEmployeeStoryVO(story));
  }
}

export class CompanyEmployeeStoryVO {
  experience: string;
  reviewText: string;

  constructor(dto: CompanyEmployeeStoryDto) {
    this.experience = dto.experience;
    this.reviewText = this.getReviewInfoText(dto);
  }

  private getReviewInfoText = (dto: CompanyEmployeeStoryDto) => {
    return `${dto.jobGroupName} - 입사 ${YearTypeEnum[dto.yearType]}`;
  };
}
