import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { AgreeTermsThirdPartyPrivacyProvisionRq } from '@domain/rq/terms/AgreeTermsThirdPartyPrivacyProvisionRq';
import { ThirdPartyProvideTermsGetRq } from '@domain/rq/terms/ThirdPartyProvideTermsGetRq';
import { TermsOneRs, TermsListRs, TermsRs } from '@domain/rs/terms/TermsRs';
import { ThirdPartyProvideTermsGetRs } from '@domain/rs/terms/ThirdPartyProvideTermsGetRs';
import { ThirdPartyTermsAgreementRs } from '@domain/rs/terms/ThirdPartyTermsAgreementRs';
import { resultSubmitTermsType, TERMS_TYPE, THIRD_PARTY_TERMS_TYPE } from '@domain/constant/termsType';

export default interface TermsRepo {
  // 사용자 목록 조회
  fetchTermsList(termType: TERMS_TYPE): Promise<AxiosResponse<TermsListRs>>;

  //사용자 약관 본문 조회
  fetchTermsOne(termsSn: number): Promise<AxiosResponse<TermsOneRs>>;

  //약관 미리보기 본문 조회
  fetchTermsPreview(termsSn: number): Promise<AxiosResponse<TermsOneRs>>;

  //역량검사/개발자 역량검사/개구검 결과 제출하기 약관 조회
  fetchTermsResultPrivacyProvision(
    companySn: number,
    termsType: resultSubmitTermsType,
  ): Promise<AxiosResponse<TermsRs>>;

  //역량검사/개발자 역량검사/개구검 결과 제출하기 동의문 생성
  fetchAgreeTermsResultPrivacyProvision(companySn: number, termsSn: number): Promise<AxiosResponse>;

  //개인정보 제3자 제공 약관 내용 조회(공용)
  fetchTermsThirdPartyPrivacyProvision(
    rq: ThirdPartyProvideTermsGetRq,
  ): Promise<AxiosResponse<ThirdPartyProvideTermsGetRs>>;

  //개인정보 제3자 제공 약관 내용 조회(공용) 생성
  fetchAgreeTermsThirdPartyPrivacyProvision(termsSn: AgreeTermsThirdPartyPrivacyProvisionRq): Promise<AxiosResponse>;

  //개인정보 제3자 제공 약관 동의 여부 조회(공용)
  fetchTermsThirdPartyPrivacyProvisionAgreement(
    termsType: THIRD_PARTY_TERMS_TYPE,
    companyName: string,
  ): Promise<AxiosResponse<ThirdPartyTermsAgreementRs>>;
}

export class RemoteTermsRepo implements TermsRepo {
  fetchTermsList(termType: TERMS_TYPE): Promise<AxiosResponse<TermsListRs>> {
    return axios.get<TermsListRs>(`/terms`, { params: { termType } });
  }

  fetchTermsOne(termsSn: number): Promise<AxiosResponse<TermsOneRs>> {
    return axios.get(`/terms/${termsSn}`);
  }

  fetchTermsPreview(termsSn: number): Promise<AxiosResponse<TermsOneRs>> {
    return axios.get(`/terms/preview/${termsSn}`);
  }

  fetchTermsResultPrivacyProvision(companySn: number, termsType: resultSubmitTermsType) {
    return axios.get<TermsRs>(`/terms/result-privacy-provision`, { params: { companySn, termsType } });
  }

  fetchAgreeTermsResultPrivacyProvision(companySn: number, termsSn: number): Promise<AxiosResponse> {
    return axios.post(`/terms/result-privacy-provision`, {
      companySn,
      termsSn,
    });
  }

  fetchTermsThirdPartyPrivacyProvision(
    rq: ThirdPartyProvideTermsGetRq,
  ): Promise<AxiosResponse<ThirdPartyProvideTermsGetRs>> {
    return axios.get(`/terms/third-party/privacy-provision`, {
      params: rq,
    });
  }

  fetchAgreeTermsThirdPartyPrivacyProvision(rq: AgreeTermsThirdPartyPrivacyProvisionRq): Promise<AxiosResponse> {
    return axios.post(`/terms/third-party/privacy-provision`, rq);
  }

  fetchTermsThirdPartyPrivacyProvisionAgreement(
    termsType: THIRD_PARTY_TERMS_TYPE,
    companyName: string,
  ): Promise<AxiosResponse<ThirdPartyTermsAgreementRs>> {
    return axios.get(`/terms/third-party/privacy-provision/agreement`, {
      params: { termsType, companyName },
    });
  }
}
