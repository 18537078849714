import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { useToast } from '@common/toast';
import { RemoteProfilesRepo } from './ProfilesRepo';
import { ProfileEducationUpdateVO } from '@domain/rq/profile/ProfileEducationRq';
import { SourcePage } from '@domain/rq/DataLog';
import ProfileEducationVO from '@domain/vo/profile/ProfileEducationVO';
import { EducationLevel, EducationLevelType } from '@domain/constant/profile/EducationType';

export const keys = ['profile', 'education'];

const remoteProfilesRepo = new RemoteProfilesRepo();
// const profileRepo = new MockProfileRepo();

const fetchProfileEducation = async () => {
  const { data } = await remoteProfilesRepo.fetchProfileEducation();
  return new ProfileEducationVO(data);
};

export default function useProfileEducation(isEnabled: boolean) {
  return useQuery(keys, fetchProfileEducation, {
    enabled: isEnabled,
  });
}

export const useSaveProfileEducation = (onSuccess: () => void) => {
  const Toast = useToast();
  const getEducationLevel = (educations: ProfileEducationUpdateVO[]): EducationLevelType => {
    let max = 0;
    educations.forEach((edu) => {
      max = Math.max(
        max,
        Object.keys(EducationLevel).findIndex(
          (level) => level === edu.schoolLevel && edu.educationType === 'GRADUATION',
        ),
      );
    });

    if (max && Object.keys(EducationLevel)[max]) return Object.keys(EducationLevel)[max] as EducationLevel;
    else return 'HIGHSCHOOL';
  };

  return useMutation(
    ({ educations, sourcePage }: { educations: ProfileEducationUpdateVO[]; sourcePage?: SourcePage }) => {
      return remoteProfilesRepo.fetchSaveProfileEducation({
        educationLevel: getEducationLevel(educations),
        educations,
        sourcePage: sourcePage,
      });
    },
    {
      onSuccess,
      onError: (err: AxiosError) => {
        Toast({ type: 'error', iconType: 'info', content: err.response?.data.message });
      },
    },
  );
};
