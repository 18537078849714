import React, { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useJobGroupsWithTitles } from '@repository/codes/useCodes';
import JobTitleCheckboxList, {
  JobGroupJobTitle,
} from '@components/profile/profileOverlay/overlays/prefer/contents/jobGroup/jobTitleCheckboxList';
import styles from '../../prefer.module.scss';
import { SelectedJobGroup } from '@domain/vo/profile/ProfileConditionsFormVO';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

interface JobGroupProps extends UseControllerProps {
  visible: boolean;
}

const JobGroup: FC<JobGroupProps> = (props) => {
  const { field: jobGroupsField } = useController(props);
  const { data: jobGroupsWithTitles, isLoading } = useJobGroupsWithTitles();

  if (!jobGroupsWithTitles || isLoading || !props.visible) return null;

  const flattedTitles =
    jobGroupsWithTitles?.flatMap((jobTitles) => {
      if (!jobGroupsField.value) return [];
      const fieldTitleCodes: number[] = jobGroupsField.value?.flatMap(
        (jobGroup: SelectedJobGroup) => jobGroup.jobTitleCodes,
      );
      return jobTitles.jobTitles
        .filter((item) => {
          return fieldTitleCodes.find((code) => code === item.code);
        })
        .map((item) => {
          return {
            jobGroupCode: jobTitles.code,
            jobGroupName: jobTitles.name,
            jobTitleCode: item.code,
            jobTitleName: item.name,
          };
        });
    }) || [];

  const addJobTitleCode = (jobGroupCode: number, jobTitleCode: number) => {
    const index = jobGroupsField.value.findIndex((fieldEl: SelectedJobGroup) => fieldEl.jobGroupCode === jobGroupCode);
    const isNew = index === -1;
    const jobTitleCodesField = !isNew ? jobGroupsField.value[index].jobTitleCodes : [];

    let newJobGroupsField;
    if (isNew) {
      newJobGroupsField = [...jobGroupsField.value, { jobGroupCode, jobTitleCodes: [jobTitleCode] }];
    } else {
      const newJobTitleCodes = [...jobTitleCodesField, jobTitleCode];
      newJobGroupsField = [...jobGroupsField.value];
      newJobGroupsField[index].jobTitleCodes = newJobTitleCodes;
    }
    jobGroupsField.onChange(newJobGroupsField);
  };

  const deleteJobTitleCode = (jobGroupCode: number, jobTitleCode: number) => {
    const index = jobGroupsField.value.findIndex((fieldEl: SelectedJobGroup) => fieldEl.jobGroupCode === jobGroupCode);
    const isNew = index === -1;
    const jobTitleCodesField = !isNew ? jobGroupsField.value[index].jobTitleCodes : [];

    let newJobGroupsField;
    if (jobTitleCodesField.length === 1) {
      // [{}] 형태에서 filter로 {}가 안 지워져서 slice 사용.
      newJobGroupsField = [...jobGroupsField.value.slice(0, index), ...jobGroupsField.value.slice(index + 1)];
    } else {
      const newJobTitleCode = jobTitleCodesField.filter(
        (jobTitleCodeField: number) => jobTitleCodeField !== jobTitleCode,
      );
      newJobGroupsField = [...jobGroupsField.value];
      newJobGroupsField[index].jobTitleCodes = newJobTitleCode;
    }
    jobGroupsField.onChange(newJobGroupsField);
  };

  const handleOnChange = (jobGroupJobTitle: JobGroupJobTitle, isChecked: boolean) => {
    if (isChecked) addJobTitleCode(jobGroupJobTitle.jobGroupCode, jobGroupJobTitle.jobTitleCode);
    else deleteJobTitleCode(jobGroupJobTitle.jobGroupCode, jobGroupJobTitle.jobTitleCode);
  };

  return (
    <div className={cx('selectArea')}>
      <JobTitleCheckboxList initValue={flattedTitles} onChange={handleOnChange} />
    </div>
  );
};

export default JobGroup;
