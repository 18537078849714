import { useQuery } from 'react-query';
import { RemotePopupRepo } from '@repository/popup/PopupRepo';

export const key = ['popup', 'view'];

const remotePopupRepo = new RemotePopupRepo();

const fetchPopupContents = async (currentLocationUrl: string) => {
  const { data } = await remotePopupRepo.fetchPopup(currentLocationUrl);
  return data;
};

export default function usePopContents(currentLocationUrl: string, enabled: boolean) {
  return useQuery([...key, currentLocationUrl], () => fetchPopupContents(currentLocationUrl), {
    enabled,
  });
}
