import { ReactNode } from 'react';

export enum Competence {
  ENERGIZER = 'ENERGIZER',
  SOCIALIZER = 'SOCIALIZER',
  SUPPORTER = 'SUPPORTER',
  CHALLENGER = 'CHALLENGER',
  PASSIONIST = 'PASSIONIST',
  FAIR_GAMER = 'FAIR_GAMER',
  TALENTED = 'TALENTED',
  SMARTS = 'SMARTS',
  GROWTH_PERSON = 'GROWTH_PERSON',
  CAREFUL = 'CAREFUL',
  SOLVER = 'SOLVER',
  HARMONIST = 'HARMONIST',
}

export type CompetenceType = keyof typeof Competence;

export interface ICompetenceProfile {
  type: string;
  name: string;
  def: string;
  str: string[];
  weak: string[];
  img: string;
  catchPhrase: string;
  description: string;
  synergy: { [key in 'leader' | 'follower' | 'coworker']: CompetenceType };
  standOutComment: ReactNode;
  cautionComment: ReactNode;
  quotes: { quote: string; author: string }[];
}

const SOCIALIZER: ICompetenceProfile = {
  type: 'Socializer',
  name: '긍정적인 사교형',
  def: '동료를 세심하게 살피고 긍정 에너지를 선물할 수 있는 자신감 넘치는 사교계의 꽃!',
  str: ['눈치 백단', '친절의 아이콘', '마당발'],
  weak: ['꾸준함 유지', '객관적 피드백 필요', '효율성 UP'],
  catchPhrase: '커뮤니케이션은 제게 맡겨주세요!',
  description:
    '당신은 당당한 태도로 다른 사람들과 능숙하게 의사소통할 줄 아는 사교형 인재예요. 자신의 마음을 잘 헤아려주고 공감해 주는 당신 앞에서, 주변 사람들은 닫혀 있던 마음의 문을 자기도 모르게 스르르 열게 되곤 한답니다. 스스로의 판단에 자신감을 가지고 소통을 해나갈 줄 아는 당신은 효과적인 커뮤니케이션이 필요한 상황에서 능력을 십분 발휘할 수 있어요. 하지만 공감 능력이 뛰어난 만큼 객관적인 태도를 요구받는 상황을 즐기기는 쉽지 않을 수 있죠. 가령, 예상치 못한 변수가 나타나 문제를 해결해야 하는 어려움에 봉착했을 때 당신은 비교적 쉽게 의지가 약해지게 될지도 몰라요. 자신보다 남을 배려할 줄 아는 당신! 조금의 의지만 더한다면 어떤 일도 잘 해낼 수 있을 거예요.',
  standOutComment: (
    <>
      당신은 <strong>고객에게 회사와 제품을 소개하는 순간</strong>에 가장 빛이 날 수 있어요. 능수능란한 말솜씨를 뽐내는
      동시에 고객의 마음을 읽어내어 반응을 살피는 그 어려운 일을 당신은 몇 번이고 해낼 수 있죠! 당신은 오고 가는
      눈빛만으로 분위기를 파악하고, 마지막 말 몇 마디만으로 이제까지의 대화의 맥락을 파악해낼 수 있는 사람이니까요. 다른
      사람들은 어떤 상황에서도 유연하게 대처하며 민망한 상황을 물 흐르듯 넘겨버릴 줄 아는 당신과 함께 있는 걸 편하게
      생각해요. 그건 고객들 역시 마찬가지일 테죠.
    </>
  ),
  cautionComment: (
    <>
      동료들과 함께 즐기며 일하는 당신이지만, <strong>중장기 프로젝트에서는</strong> 다소 집중력이 약해지는 모습을
      보여요. 호흡이 긴 프로젝트일수록 예상치 못한 여러 변수들을 맞닥뜨리기 쉬운데, 그런 상황에서 당신은 그만 흥미를
      잃어버리고 지치게 될지도 몰라요. 만약 회사에서 이러한 프로젝트를 맡게 된다면, 커뮤니케이션에 강한 당신의 장점을
      살려서 팀원들과 함께 미리미리 변수를 예측하고 준비해보세요. 그 과정에서 팀원들에게 객관적인 피드백을 구하는 것도
      당신에게 큰 도움이 될 거랍니다. 이러한 노력을 통해 팀원들과 꽃길을 걸어나갈 수 있을 거예요!
    </>
  ),
  img: '/iconJoy10.png',
  synergy: { leader: 'CAREFUL', coworker: 'SMARTS', follower: 'TALENTED' }, // 신중 스마트 재능
  quotes: [
    {
      quote:
        '남의 나라를 내 나라처럼 대하고, 남의 집을 내 집처럼\n대하며, 남의 몸을 내 몸처럼 대하면\n세상의 모든 혼란과 다툼, 전쟁이 사라진다.',
      author: '묵자 - 중국 전국시대 초기의 사상가',
    },
    {
      quote:
        '이 세상에서 성공의 비결이란 것이 있다면\n그것은 타인의 관점을 잘 포착해 그들의 입장에서\n사물을 볼 수 있는 재능, 바로 그것이다.',
      author: '헨리 포드 - 기업인, 공학기술자',
    },
    {
      quote:
        '성공은 당신이 아는 지식 덕분이 아니라,\n당신이 아는 사람들과 그들에게 비춰지는\n당신의 이미지를 통해 찾아온다.',
      author: '리 아이아코카 - 미국 자동차 산업의 귀재',
    },
  ],
};

const SUPPORTER: ICompetenceProfile = {
  type: 'Supporter',
  name: '겸손한 지지형',
  def: '긍정적이고 겸손한 자세로 주변 사람들에게 힘을 실어주는 든든한 지지자!',
  str: ['협력을 잘하는', '커뮤니케이션 스킬', '조력자'],
  weak: ['안정추구', '적극적인 태도 필요', '지속성 UP'],
  catchPhrase: '다른 사람의 행복이 곧 나의 행복!\n모두가 행복한 세상을 만들어 가요.',
  description:
    '뛰어난 공감 능력의 소유자인 당신은 다른 사람들의 얘기를 귀 기울여 듣고, 먼저 손을 내밀어 도울 줄 아는 사람이에요. 당신의 겸손한 성격은 협동해야 하는 상황에서 빛을 발해요. 커뮤니케이션 스킬이 좋고 적재적소에 도움을 제공할 줄 아는 당신에겐 동료들의 긍정적인 피드백이 줄줄이 따른답니다. 하지만 문제 상황에 봉착했을 때 당신은 새로운 해결책을 적극적으로 모색하려고 노력하기보다는 안정을 추구하는 성향이 강한 편이에요. 열정적으로 추진하려는 동기가 약하다 보니 일을 끝까지 완수를 못할 때도 있어 보다 노력이 필요하죠. 모두가 주인공이 되려 한다면 드라마는 감동을 줄 수 없어요. 당신은 스스로 주인공이 되어 빛나기보다는 주위의 사람들에게 힘을 실어줌으로써 든든한 조력자의 역할을 해내는 꼭 필요한 사람이랍니다.',
  standOutComment: (
    <>
      당신은 <strong>타 부서와의 협력 프로젝트 등 여러 사람과 협동하며 일해야 하는 상황에서</strong> 더 빛나는
      사람이에요. 커뮤니케이션 스킬이 뛰어나고 협동심이 강하기 때문에 당신과 함께 일하는 사람들의 만족도는 굉장히 높은
      편이랍니다. 당신은 동료들의 다양한 아이디어를 이해하고 수렴하는 역할에 강점을 보이죠. 그뿐만 아니라, 당신은 리더의
      피드백을 이해하는 능력 또한 좋은 편이에요. 내용을 객관적으로 정리하여 이해할 줄 알고, 더 나아가 내용을 둘러싼
      맥락을 읽는 눈 역시 발달되어 있답니다.
    </>
  ),
  cautionComment: (
    <>
      당신은 <strong>스스로의 의견이나 조직의 입장을 고객에게 설득시키는 상황</strong>에서 다소 어려움을 느낄 수 있어요.
      특히 당신이 회사나 팀을 대표하는 역할을 맡아야 한다면, 책임이 막중한 그 상황을 피할 수 있다면 피하고 싶죠. 또한,
      당신은 다른 사람의 주목을 받는 걸 그리 즐기지 않는 편이기에, 회의 에서 아이디어를 내는 일에 그리 적극적으로
      나서지는 않는 편이에요. 하지만 당신에겐 뛰어난 커뮤니케이션 역량이 있기 때문에 조금만 용기 내어 추진력 있게
      나선다면 필요한 역할을 누구보다 잘 수행할 수 있을 거예요.
    </>
  ),
  img: '/iconJoy2.png',
  synergy: { leader: 'CHALLENGER', coworker: 'HARMONIST', follower: 'SOLVER' }, // 도전 화합 해결
  quotes: [
    {
      quote: '예의와 타인에 대한 배려는\n푼돈을 투자해 목돈으로 돌려받는 것이다.',
      author: '토머스 소웰 - 경제학자',
    },
    {
      quote:
        '겸손이란 자기 자신을 낮추는 것이 아니라\n자신을 덜 생각하고 남을 더 생각하는 것이다.\n겸손 없이 다른 사람들을 이끌고 격려하는 것은 불가능하다.',
      author: '릭 워렌 - 목사',
    },
    {
      quote: '아무런 대가도 바라지 않고 시간과 힘을 쏟아\n남을 돕는 것만큼 큰 재능은 없을 것이다.',
      author: '넬슨 만델라 - 인권운동가',
    },
  ],
};

const CHALLENGER: ICompetenceProfile = {
  type: 'Challenger',
  name: '도전적인 전략가형',
  def: '기꺼이 도전에 뛰어들어 목표를 향한 최적의 길을 반드시 찾아내는 타고난 전략가!',
  str: ['도전 빼면 시체', '두뇌 풀가동', '지고는 못 살아'],
  weak: ['네 자신을 알라', '역지사지 명심', '함께하는 자세'],
  catchPhrase: '원하는 건 무조건 달성할 수 있어!\n난 똑똑하니까!',
  description:
    '당신은 매사에 적극적이며, 목표가 생기면 물불 가리지 않고 도전하여 이뤄내는 모험가적 기질을 가지고 있어요. 동시에 무모하게 뛰어들기보다 전략적으로 목표 달성을 위한 최적의 길을 찾아낼 줄 아는 훌륭한 능력을 갖추고 있어요. 이왕 시작한 일이라면 그 분야 1등이 되어 세상의 인정을 받고 싶은 당신! 집요한 노력으로 남들이 주저했던 일을 성공시킨 짜릿한 경험은 당신을 프로 도전러로 만든답니다. 다만, 열정으로 가득 찬 당신은 간혹 앞만 보고 달리다 주위를 살피는데 소홀할 수 있어요. 성과를 위해 ‘함께 한 걸음’보다 ‘혼자 열 걸음’을 택할지도 모르죠. 하지만 주변의 마음을 헤아리고, 함께 성공할 수 있는 길도 고민해 본다면 어떨까요? 당신은 함께 열 걸음을 갈 방법을 충분히 찾아낼 수 있는 사람이랍니다.',
  standOutComment: (
    <>
      당신은 <strong>번뜩이는 아이디어를 내거나 신규 시장을 공략하는 전략을 구상하는 상황</strong>에서 재미와 성과를
      만들고 보다 적극적으로 그 일에 뛰어들 수 있어요. 전략적인 계획을 바탕으로 여러 돌발 변수를 고려하여 아이디어를
      제시, 현실화 시키는 과정을 즐기기 때문에 이런 상황에서 보다 빛나는 활약을 하게 될 거예요. 단순하고 반복적인 문제
      여러 개보다는 복잡하고 어려운 한 문제를 가지고 씨름할 때의 희열을 즐길 줄 아는 당신! 당신은 좀처럼 답이 보이지
      않는 상황에서도 불안보다는 기대를 안고 임할 수 있는 사람이랍니다.
    </>
  ),
  cautionComment: (
    <>
      당신은 원하는 목표를 해결할 수 있는 탁월한 역량을 가지고 있어요. 하지만 다양한 이해관계자가 모여 협업을 하는
      상황에서는 해결책 외에도 고려해야 할 점들이 많아 살짝 어려움을 겪기도 하죠. 특히, 당신은{' '}
      <strong>조직이 함께 만든 그라운드 룰</strong>을 지키는데 다소 서툰 모습을 보일 수 있어요. 회사의 업무 중에는
      다양한 사람들이 모여서 머리를 맞댈 때 훨씬 효과적인 것들이 꽤 있어요. 바로 팀워크 말이에요! 당신이 협업의 중요성을
      다시 한번 마음에 되새기고, 조금 더 상대의 입장을 헤아리려 노력한다면 훨씬 더 좋은 성과가 따르게 될 거예요.
    </>
  ),
  img: '/iconJoy7.png',
  synergy: { leader: 'SOLVER', coworker: 'TALENTED', follower: 'SUPPORTER' }, // 해결 재능 지지
  quotes: [
    {
      quote: '이 세상에 위대한 사람은 없다.\n단지 평범한 사람들이 일어나 맞서는\n위대한 도전이 있을 뿐이다.',
      author: '윌리엄 프레데릭 홀시 - 미국 제독',
    },
    {
      quote: '세상의 중요한 업적 중 대부분은,\n희망이 보이지 않는 상황에서도\n끊임없이 도전한 사람들이 이룬 것이다.',
      author: '데일 카네기 - 미국 작가',
    },
    {
      quote: '아무런 위험을 감수하지 않는다면\n더 큰 위험을 감수하게 될 것이다.',
      author: '에리카 종 - 미국의 소설가',
    },
  ],
};

const PASSIONIST: ICompetenceProfile = {
  type: 'Passionist',
  name: '성취추구 열정형',
  def: '어려운 상황에서도 추진력을 잃지 않는 에너지 넘치는 불도저!',
  str: ['빠른 추진력', '불타는 열정', '성과를 향한 집요함'],
  weak: ['피드백 수용', '계획 수립', '넘치는 솔직함'],
  catchPhrase: '할 수 있다! 할수 있다! 할 수 있다!',
  description:
    '당신은 어떤 일을 맡으면 자신감 넘치는 태도로 열정적으로 임하는 편이에요. 에너지를 끌어모아 불도저처럼 추진력 있게 일을 끌고 나가죠. 당신은 그 과정에서 다른 사람들과 활발하게 커뮤니케이션하기를 즐기는 편입니다. 다만, 빠르게 추진하려고 하는 욕심이 크다 보니 다른 사람의 의견을 귀 기울여 듣고 수용하는데 들이는 시간이 조금 부족할 때가 있어요. 또한 디테일하게 계획을 수립하여 일을 추진하기 보다 일단 해보자는 식으로 접근하는 경우가 있기도 하죠. 뛰어난 행동력을 가진 당신이니, 조금만 더 차분하게 객관적인 업무의 진행 상황을 살펴보고 세부적으로 업무를 계획하는 습관을 들인다면 훨씬 더 좋은 성과를 만들어낼 수 있을 거예요!',
  standOutComment: (
    <>
      당신은 주어진 업무의 종류와 상관없이 어떤 것이든 추진력 있게 끝까지 해결해내고자 하는 편이에요. 워낙 업무에 대한
      열정이 크기 때문에 일상적인 대화에서조차 업무 이야기를 즐기기도 하죠. 끈기 있고 악바리 정신이 있는 당신은{' '}
      <strong>난이도가 높아 여러 시행착오를 겪어야 하는 프로젝트</strong>에서 더 빛날 수 있어요. 긍정적인 시각을 가지고
      있기에 문제 상황에 맞닥뜨려도 칠전팔기, 금방 털고 일어날 수 있죠. 빠른 추진력을 발휘하여 끝까지 최선을 다한다면
      언제나 그랬듯 만족할 만한 성과를 만들어낼 수 있을 거예요!
    </>
  ),
  cautionComment: (
    <>
      열정적인 당신은 때론 목표를 향해 나아가야 한다는 생각에 과하게 몰입하여 동료들의 의견을 수용하려는 노력이 부족할
      때가 있어요. 특히, <strong>여러 이해관계가 상충하는 상황에서 모두가 수긍할 수 있는 방향</strong>을 제시하고
      이끌어나가야 하는 상황에서 어려움을 겪을 수 있죠. 빨리 결승선에 도착하는 것도 중요하지만, 어떨 때는 나와 다른
      의견이 생각지도 못한 전환점을 만들어낼 수 있어요. 조직에서는 혼자서는 다 해낼 수 없는 일이 많기 때문에 동료들의
      피드백을 진정성 있는 태도로 수용하고 일을 추진한다면 훨씬 좋은 결과가 뒤따를 거예요.
    </>
  ),
  img: '/iconJoy8.png',
  synergy: {
    leader: 'FAIR_GAMER',
    coworker: 'ENERGIZER',
    follower: 'GROWTH_PERSON',
  }, // 승부사 자신감 성장
  quotes: [
    {
      quote:
        '누구든 열정에 불타는 때가 있다.\n어떤 사람은 30분 동안, 또 어떤 사람은 30일 동안,\n인생에 성공하는 사람은 30년 동안 열정을 갖는다.',
      author: '노만 빈센트 필 - 목사',
    },
    {
      quote:
        '아무 하는 일 없이 시간을 허비하지 않겠다고 맹세하라.\n우리가 항상 뭔가를 한다면\n놀라우리만치 많은 일을 해낼 수 있다.',
      author: '토머스 제퍼슨 - 미국의 정치가·교육자·철학자',
    },
    {
      quote:
        '당신의 열정을 따르고, 열심히 노력하라.\n그리고 절대 다른 누군가가 당신의 가능성에\n한계를 정하도록 두지 마라.',
      author: '도너번 베일리 - 캐나다 육상선수',
    },
  ],
};

const FAIR_GAMER: ICompetenceProfile = {
  type: 'Fair Gamer',
  name: '공정한 승부사형',
  def: '자신의 부족한 면을 발전시키면서 집요하게 성과를 내려 노력하며 성장하는 공정한 승부사!',
  str: ['성찰을 잘하는', '성장지향', '이뤄내고자 하는 열정'],
  weak: ['자기비판적인', '과한 겸손', '가끔 주저하는'],
  catchPhrase: '정정당당하게 겨뤄보자,\n난 나를 잘 알지!',
  description:
    '당신은 자신에게 어떤 강점과 약점이 있는지 스스로 잘 알고 있는 사람이에요. 특히, 자신에게 부족한 점에 대해서는 어떻게든 개선해내려고 많은 시간과 노력을 쏟기도 해요. 겸손한 당신은 훌륭한 성과를 이뤄냈을 때에도 이는 나의 능력 덕이 아니라는 태도를 보이기 때문에 주변에서는 당신을 능력 있는 사람보다 성실한 노력파라 여길 수도 있어요. 너무 자신을 낮추기만 한다면 오히려 당신의 진짜 모습이 가려질 수도 있으니, 스스로 잘한 점을 더 확실하게 칭찬해 주세요. 그렇게 생겨난 여유를 가지고 주변까지 살핀다면, 지피지기면 백전백승! 당신은 더 많은 것들을 이뤄낼 수 있을 거예요. 원하는 모습을 향해 한 걸음 한 걸음 꾸준히 나아가는 당신! 언젠가는 원하는 곳에서 웃고 있는 당신을 발견할 거예요.',
  standOutComment: (
    <>
      당신은 업무 모니터링 결과에 따라 피드백을 받았을 때, 그것을 반영하여 스스로의 업무 방식을 개선하고 변화시키는
      능력이 탁월해요! 자기 의견만 옳다고 믿는 것이 아니라, 회사 전체의 관점에서 어떤 방향이 더 나을지 고민하고 그에
      맞춰 행동할 수 있는 사람이지요. 그래서 당신의 상사나 주변 동료들은 당신을 <strong>애자일 문화</strong>에 잘
      적응하는 사람이라고 여길 거예요. 이렇게 끊임없이 다방면에 노력하는 모습을 보여준다면, 당신은 언젠가 낭중지추,
      주머니 속의 송곳처럼 회사가 계속해서 함께 해야 할 사람으로 진가를 인정받을 수밖에 없을 거예요.
    </>
  ),
  cautionComment: (
    <>
      <strong>커다란 책임과 권한이 주어지는 리더</strong>가 되면, 당신은 의사결정을 내려야 하는 상황에 자주 놓이게 될
      거예요. 모든 일에 100% 확실한 것은 없지만, 만약 이럴 때 리더로서 자신감이나 확신을 보여주지 못한다면, 당신에 대한
      팀원들의 신뢰에 영향을 줄 수도 있어요. 신중한 태도도 중요하지만 한 번 결정을 내렸다면 자기 자신을 믿고 힘껏 노를
      저어보세요. 당신이 스스로 확신을 가져야 다른 동료나 팔로워들도 당신을 믿고 따르며 열정을 불태울 수 있을 거예요.
      당신은 생각보다 훨씬 더 근사하고 능력 있는 선장이니 스스로를 더 믿어봐요!
    </>
  ),
  img: '/iconJoy3.png',
  synergy: {
    leader: 'SMARTS',
    coworker: 'SOLVER',
    follower: 'PASSIONIST',
  }, // 스마트 해결 열정
  quotes: [
    {
      quote:
        '진정으로 자유로운 생활은 스스로를 멋대로 내던지는 것이\n아니라 자신을 적당히 억누르고 견제하는데서\n출발하는 것이다.',
      author: '장 자크 루소 - 프랑스 사상가·소설가',
    },
    {
      quote: '행동만이 삶에 힘을 주고,\n절제만이 삶에 매력을 준다.',
      author: '장 폴 리히터 - 독일 소설가',
    },
    {
      quote: '욕망을 이성의\n지배 하에 두어라.',
      author: '키케로 - 고대 로마의 정치가·작가',
    },
  ],
};

const TALENTED: ICompetenceProfile = {
  type: 'Talented',
  name: '집요한 재능형',
  def: '내 사전에 포기란 없다! 누구보다 앞장 서서 명쾌한 해답을 찾아내는 해결사!',
  str: ['언제나 1등', '악착같은', '철두철미한'],
  weak: ['눈치 챙기기', '맥락 놓침 주의', '자기주장이 강한'],
  catchPhrase: '둘 중 무엇이 더 효과적일까?\n어떻게든 가장 좋은 방법을 찾아 내고야 말겠어!',
  description:
    "당신은 우수한 두뇌를 바탕으로 어떠한 문제 상황에서든 훌륭한 해결안을 찾으려 노력하는 사람이에요. 당신이 세운 치밀한 전략은 높은 확률로 만족스러운 결과를 가져다 주죠! 이러한 결과를 만들기까지 당신이 보여준 열정과 노력을 아는 주변 사람들은 당신을 참 멋진 사람이라 생각하곤 한답니다. 하지만 정작 당신은 주변 사람들이 어떤 생각을 가지고 있는지 잘 파악하지 못할 때가 있어요. 당신의 머릿속은 복잡한 생각들로 가득 차 있을 때가 많고, 그래서인지 간혹 주변 사람들에게 눈치가 부족하다는 말을 듣기도 하죠. 가끔은 주위로 시선을 돌려 다른 사람들은 어떤 생각과 행동을 하는지 관심 있게 들여다보면 어떨까요? 그럼 당신은 일과 사람 둘 다 놓치지 않는 '양손잡이 능력자'가 될 수 있을 거예요!",
  standOutComment: (
    <>
      당신은 문제 상황에 대한 치열한 고민을 통해 가장 좋은 해결책을 제시할 수 있는 능력을 가진 사람이에요! 그래서{' '}
      <strong>세밀하고 꼼꼼하게 이것저것 따져보고 중요한 의사결정을 내려야 하는 기획 업무</strong>에서 좋은 모습을 보일
      가능성이 높습니다. 주위 사람들이 여러 업무 성과를 통해 이러한 당신의 속성을 파악하게 된다면, 누구나 쉽게 해결하기
      힘든 중요한 문제가 발생할 때마다 당신을 떠올리고 당신의 생각을 경청하고 싶어 할 거예요. 당신은 누구보다 집요하게
      문제를 해결하려 노력함으로써 기대를 저버리지 않을 테니까요!
    </>
  ),
  cautionComment: (
    <>
      회사에서는 개인 업무만큼 다른 사람들과 협력하여 일을 진행하는 경우가 많습니다.{' '}
      <strong>다른 팀과의 협업을 하거나, 같은 팀 팀원들과 의견을 조율하며 진행하는 업무</strong>들이 대표적인 예입니다.
      그럴 때는 어떤 상황에서 대화가 이루어지고 있는지 맥락을 이해하는 것이 중요해요. 만약 그걸 놓치고 당신이 생각한
      것만 주장한다면 다른 사람들의 따가운 눈총을 받게 될 수도 있답니다. 하나의 성과를 이루기 위해 많은 사람들이
      노력하고 있다는 것을 기억하고, 함께 협동하고자 노력한다면 당신의 능력을 더 인정받을 수 있을 거예요!
    </>
  ),
  img: '/iconJoy11.png',
  synergy: {
    leader: 'SOCIALIZER',
    coworker: 'CHALLENGER',
    follower: 'HARMONIST',
  }, // 사교 도전 화합
  quotes: [
    {
      quote: '어디를 가든지\n마음을 다해 가라.',
      author: '공자 - 중국 사상가',
    },
    {
      quote: '지성을 다하는 것이\n곧 천도(天道)다.',
      author: '맹자 - 동주의 유교 사상가',
    },
    {
      quote:
        '성공은 그 사람이 현재 오른 위치로 평가되는 것이\n아니라 성공을 위해 노력하는 동안\n얼마나 많은 장애물을 극복했는가로 평가된다.',
      author: '부커 워싱턴 - 미국 교육자',
    },
  ],
};

const SMARTS: ICompetenceProfile = {
  type: 'Smarts',
  name: '당당한 스마트형',
  def: '머리 회전이 빨라 신속한 자료 처리에 재능이 있으며 자신이 생각한 방향이 맞다는 강한 믿음을 원동력으로 달려가는 브레인!',
  str: ['빠른 일처리', '만능 척척박사', '자신을 믿는'],
  weak: ['자존심 센', '큰 욕심은 없는', '열정 한 스푼만 더'],
  catchPhrase: '급한 일이야?\n내가 처리해줄게!',
  description:
    '당신은 처음 맡는 업무라 할지라도 필요한 정보들을 빠르게 습득하고 효과적으로 처리함으로써 기대 이상의 성과를 낼 수 있는 능력을 갖추고 있어요. 또한 자신의 판단을 의심하기보다는 옳을 거라는 믿음을 가지고 자신감 있게 일하기 때문에, 당신의 스마트한 면모는 더욱 빛을 발하지요. 하지만 아무리 충분히 멋진 당신이라 해도, 가끔은 현재 그대로의 모습에 만족하기 보다 여기서 한 뼘 더 성장해 보이겠다는 욕심을 부려봐도 좋을 것 같아요. 스스로를 유심히 들여다보고, 강점은 키우고 약점을 보완하기 위해 무엇이 필요할지 고민해 보는 시간을 가져보는 거죠. 그렇게 한 단계 더 발전된 모습으로 매일매일 찾아오는 도전에 임한다면, 당당하고 능력 있는 당신을 향한 신뢰는 더욱더 두터워질 거예요.',
  standOutComment: (
    <>
      예상치 못한 변수 등장으로 조직이 위험에 처할지도 모르는 급박한 상황에서 당신의 빠르고 효과적인 업무 처리 능력은
      빛을 내지요! 주변 사람들은 혼자서도 침착하게 일을 처리하는 당신을 점점 더 찾게 될 거예요.{' '}
      <strong>출시한 제품에 갑자기 돌발적인 이슈가 발생하거나 고객의 급한 요청 시</strong>, 당신 은 문제 해결을 위해
      무엇이 필요하고, 어떤 것이 가장 효과적일지 해결책 모색에 누구보다 뛰어납니다. 믿음직한 브레인으로서 회사에 꼭
      필요한 존재가 될 당신은 바로 어문당!(어차피 문제 해결은 당당한 스마트형!)
    </>
  ),
  cautionComment: (
    <>
      당신은 일을 처리하는 기술이 뛰어나며, 그런 자신의 능력을 향한 믿음 역시 단단한 편이에요. 현 상황에서 주어진 자원을
      가장 효과적으로 활용해 만족할 만한 결과를 이끌어내는데 능한 사람이죠. 하지만{' '}
      <strong>집요하게 한 단계 더 파고들어 기대를 뛰어넘는 성과</strong>를 내려는 욕심이 크지 않은 편이라, 다른 사람들은
      당신을 조금 소극적인 편이라고 생각할 수도 있어요. 능력이 뛰어난 만큼 여러 가지 업무들을 맡게 될 텐데, 그럴 때
      당신의 열정과 적극성을 한 스푼만 더해본다면 훨씬 좋은 기회들이 줄줄이 따라오게 될 거예요!
    </>
  ),
  img: '/iconJoy6.png',
  synergy: {
    leader: 'GROWTH_PERSON',
    coworker: 'SOCIALIZER',
    follower: 'FAIR_GAMER',
  }, // 성장 사교 승부사
  quotes: [
    {
      quote:
        '태도는 사소한 것이지만 그것이 만드는 차이는 엄청나다.\n즉 어떤 마음가짐을 갖느냐가 어떤 일을 하느냐보다\n더 큰 가치를 만들 수 있다.',
      author: '윈스턴 처칠 - 전 영국의 총리',
    },
    {
      quote: '지속적인 긍정적 사고는\n능력을 배로 높인다.',
      author: '콜린 파월 - 전 미국 외무장관',
    },
    {
      quote: '삶에 대한 태도만큼은\n자신이 선택할 수 있는 자유이다.',
      author: '빅토르 프랑클 - 오스트리아 신경학자·심리학자',
    },
  ],
};

const GROWTH_PERSON: ICompetenceProfile = {
  type: 'Growth Person',
  name: '따뜻한 성장지향형',
  def: '성장을 위해 누구에게나 배우려 하고, 성과를 내는데 도움을 줄 수 있는 사람이 되기 위해 매일 같이 노력하는 성장 드라마 주인공!',
  str: ['위기를 기회로', '모두가 내 스승', '자기계발러'],
  weak: ['선입견 조심', '융통성', '엄격한 리더'],
  catchPhrase: '길 위에서 만나는 모두가 나의 스승이요,\n성장의 기회지요~',
  description:
    '당신은 성과를 내기 위한 전략적 사고를 하면서도 동시에 타인의 마음 또한 헤아릴 줄 아는 사람이에요. 또한 꾸준한 노력으로 매일 조금씩 성장하는 사람이 되고 싶어 하죠. 일과 사람 모두에게서 성장에 필요한 에너지를 쉬지 않고 흡수하는 성장 드라마 주인공 같은 당신! 주위 사람들을 존중할 줄 아는 당신은 그들 각자의 고민과 노력을 높게 평가하고, 그 안에서 배울 점을 발견해 또 한 뼘 성장할 수 있는 기회로 만들고자 최선을 다한답니다. 그러나 성장하고 싶은 마음이 뚜렷한 만큼, 주변에 노력이 부족하다고 느껴지는 사람을 보면 내색은 않지만 그들과 함께하기는 어려워해요. 성장을 위해서라면 모두가 무서워하는 엄격한 사람이나 강도 높은 일 일지라도 피하지 않는 사람, 바로 당신이에요!',
  standOutComment: (
    <>
      한 사람은 한 권의 책이라는 말처럼, 당신은 다른 사람들에게서 배울 것이 무궁무진하다고 생각해요. 또한 내가 배움을
      얻고자 하는 사람을 믿고, 그 사람이 중요하게 여기는 가치를 따라서 함께 지켜나가기 위해 최선을 다한답니다. 직장에서
      당신의 성장을 책임지고 있는 사람은 당신이 속한 조직의 리더인데요. 당신은{' '}
      <strong>리더가 제시하는 방향에 집중하고, 지혜롭게 그 길을 따라 걸으려고 노력하는 팔로워십</strong>에서 강점이
      드러낼 거예요. 그리고 그 강점을 바탕으로 더 많은 인정과 기회를 얻어 성장할 거예요.
    </>
  ),
  cautionComment: (
    <>
      성장에 확고한 가치를 둔 당신은 매일 한 뼘 더 자라기 위해 노력하는 게 당연하다고 여겨요. 그래서 간혹 노력할 의지를
      덜 갖추고 있는 사람을 보면, 당신은 머리로는 충분히 이해하면서도 그 사람에게 적극적으로 도움을 주기는 어렵겠다고
      느낄 수 있어요. 특히, 당신은 <strong>책임과 권한을 받은 리더가 되었 을 때</strong>, 팔로워의 성장을 위한 세심한
      동기부여에 서툰 모습을 보일 수 있어요. 하지만 공감을 넘어 적극적인 소통으로 상대의 변화를 함께 이끌어내려는 노력을
      시작한다면 당신은 이렇게 외칠 자격이 있어요. {`"나 또 성장했네!"`}
    </>
  ),
  img: '/iconJoy9.png',
  synergy: {
    leader: 'PASSIONIST',
    coworker: 'CAREFUL',
    follower: 'SMARTS',
  }, // 열정 신중 스마트
  quotes: [
    {
      quote: '고통은 성장의 법칙이요.\n우리의 인격은 이 세계의 폭풍우와\n긴장 속에서 만들어지는 것이다.',
      author: '마더 테레사 - 수녀',
    },
    {
      quote: '성장은 뜻밖의 어둠 속에서도\n도약할 때 이루어진다.\n',
      author: '헨리 밀러 - 미국 소설가',
    },
    {
      quote: '인내와 끈기와 피나는 노력은\n성공을 안겨주는 무적불패의 조합이다.',
      author: '나폴레온 힐 - 미국 작가',
    },
  ],
};

const CAREFUL: ICompetenceProfile = {
  type: 'Careful',
  name: '꾸준한 신중형',
  def: '과업을 달성하기 위해 치밀한 계획을 세우고 성실히 달려가는 의지의 마라토너!',
  str: ['꾸준함이 무기', '몰입', '계획을 실천으로'],
  weak: ['무뚝뚝함', '차가운 스타일', '공감 부족'],
  catchPhrase: '선입견은 금물!\n꾸준히 달려보겠어!',
  description:
    '당신은 전략적으로 상황을 파악하고 탄탄한 계획을 세움으로써 장기적인 과제에서 좋은 결과를 낼 수 있는 인재예요. 잘 짜인 계획으로 효과적인 실천을 만들어내요. 당신은 새로운 일이나 환경에 대해 섣부르게 판단하고 선입견을 갖기보다는 먼저 마주한 상황을 이해하고 그에 맞는 목표와 계획을 세워 꾸준히 실천하고자 한답니다. 그걸 지켜본 주변 사람들은 아무리 힘들지라도 기어코 완주를 해내는 장거리 마라토너와 같은 비유로 당신의 능력에 박수를 보내요. 하지만 당신은 깊이 몰입하고 실천하느라 이따금 주변에 소홀해져 다소 차가운 느낌을 받게 하기도 해요. 신중하게 생각해낸 아이디어를 꾸준하게 실천하여 결국 성공해내고야 마는 당신! 함께 달리는 사람들까지 챙겨서 결승점을 통과하면 보람과 기쁨은 배가 될 거랍니다!',
  standOutComment: (
    <>
      기업에서 하는 모든 일에서 속전속결로 아웃풋이 나오지는 않아요! 신중히 준비한 계획을 바탕으로 꾸준한 실천을 통해
      장기 과제를 달성해낼 수 있는 당신은 <strong>회사의 미래 비전을 담은 신사업 프로젝트</strong>에서 제 역량을 발휘할
      수 있을 거예요. 신사업의 경우, 시작할 때는 불꽃같은 열정으로 타올랐다가 긴 준비 기간과 우여곡절을 겪으며 동기가
      저하되고 열정이 식기 쉬워요. 하지만 당신은 신중하게 나아갈 방향을 정하고 쉽게 일희일비하지 않고 계획을
      실천해나감으로써, 훗날 기업의 비전을 실현하고 성장을 이끌어낸 주역으로 우뚝 설 수 있을 거예요!
    </>
  ),
  cautionComment: (
    <>
      전략적 사고력에 성실한 태도까지 갖춘 당신은 멋진 장거리 레이스를 펼칠 준비가 되어 있어요. 하지만 ‘빨리 가려면 혼자
      가고, 멀리 가려면 함께 가라’는 말 들어 보셨죠? 나의 달리기에만 집중한 나머지 어느 순간 주변에 아무도 보이지
      않는다면 어떻게 될까요? 아무래도 더 좋은 기록을 내기가 훨씬 힘들 거예요.{' '}
      <strong>팀원 별 현황과 생각을 공유하는 회의 또는 대화 시간</strong>에서 동료의 의견과 마음 상태에 좀 더 주의를
      기울이고 공감하기 위해 노력한다면 당신의 강점에 한 가지 단어가 더 따라올 거예요. ‘꾸준히’ 그리고 ‘함께’가 말이죠!
    </>
  ),
  img: '/iconJoy1.png',
  synergy: {
    leader: 'ENERGIZER',
    coworker: 'GROWTH_PERSON',
    follower: 'SOCIALIZER',
  }, // 자신감 성장 사교
  quotes: [
    {
      quote: '하루하루를 그저 보내는 것이 아니고,\n하루하루를 자신이 가진 그 무엇으로 채워야 한다.',
      author: '존 러스킨 - 예술 평론가',
    },
    {
      quote:
        '당신이 하기를 원하고 하려고 하는 의지가 있고\n오랜 시간 동안 충분히 노력한다면, 그 일은 날마다\n조금씩 함으로서 반드시 성취해 낼 수 있다.',
      author: '윌리엄 제임스 홀 - 선교사',
    },
    {
      quote:
        '성공한 사람들이 도달한 높은 고지는 단번에\n오른 것이 아니다. 경쟁자들이 밤에 잠을 자는 동안\n한 발짝 한 발짝 기어오른 것이다.',
      author: '헨리 워즈워스 롱펠로 - 미국 시인',
    },
  ],
};

const SOLVER: ICompetenceProfile = {
  type: 'Solver',
  name: '눈치빠른 해결형',
  def: '타인의 의도를 빠르고 정확하게 파악하고, 차근차근 행동하여 문제를 해결하는 현대판 셜록홈즈!',
  str: ['눈치백단', '몰입과 집중', '소리 없이 강한 실천력'],
  weak: ['자신감 부족', '타인의 시선에 예민', '내겐 너무 어려운 비판'],
  catchPhrase: '너의 마음 이해 완료!\n차근차근 다가갈게!',
  description:
    '당신은 다른 이들이 처한 상황을 빠르고 정확하게 파악할 수 있는 눈을 가지고 있으며, 상대가 문제를 해결할 수 있도록 돕는 문제 해결계의 셜록홈즈 같은 사람이에요. 당신은 타인의 감정과 상태를 관심 있게 살피고, 나와 그 사람 사이의 공통점과 차이점을 빠르게 찾아낼 줄도 알죠. 대화의 흐름을 놓치지 않는 당신은 그 속에서 얻어낸 유용한 정보들을 재료로 멋진 해결책을 떠올려 내요. 그리고 꾸준한 실천을 통해 긍정적인 결과를 이끌어낸답니다. 하지만 타인의 시선에 예민하고 자신감이 부족한 당신은 주변으로부터 소극적인 편이라는 피드백을 받기도 해요. 빠른 분위기 파악과 실천력이라는 당신의 강점을 믿어보세요! 해피엔딩은 당신의 것이 될 거예요!',
  standOutComment: (
    <>
      빠른 상황 판단 능력과 성실한 자세를 갖춘 당신은 동료들 사이에서 함께 일하고 싶은 사람, 꼭 우리 팀에 함께 했으면
      하는 사람 1순위라고 할 수 있어요. 상대의 의도를 빠르게 간파하고 대화의 핵심을 파악할 줄 당신의 능력은 단기/장기
      프로젝트를 가리지 않는 성실한 태도와 시너지를 일으켜 <strong>팀 간 협업 업무 또는 회의</strong>에서 빛을 낼
      거랍니다. 주위에선 모범적인 동료이자 솔선수범의 상징인 당신을 닮고 싶고 따르고 싶어 하는 사람들이 점점 많아질
      거예요!
    </>
  ),
  cautionComment: (
    <>
      당신을 향한 사람들의 신뢰가 쌓이면 쌓일수록, 사람들은 당신에게 어떻게 하면 성장할 수 있을지 와 같은 발전적인
      조언을 요청할 수 있어요. 하지만 당신에게는 함께 일하는 동료, 후배, 팔로워의 피드백 요청이 조금 부담스럽게 느껴질
      수도 있고, 그건 당신 스스로가 피드백을 할 만한 사람이 아니라고 생각하기 때문이에요. 이미 사람들은 당신을 충분히
      인정하고 신뢰한다는 사실을<strong> 믿고 다른 사람들의 의견을 열린 마음으로 받아들여보려</strong> 노력해보면
      어떨까요? 사람들은 당신이 용기 내어 건네준 조언에 무척 고마워할 거예요.
    </>
  ),
  img: '/iconJoy12.png',
  synergy: {
    leader: 'SUPPORTER',
    coworker: 'FAIR_GAMER',
    follower: 'CHALLENGER',
  }, // 지지 승부사 도전
  quotes: [
    {
      quote: '행동하는 사람 2%가\n행동하지 않는 사람 98%를 지배한다.',
      author: '지그 지글러 - 미국 작가',
    },
    {
      quote: '시작하는 방법은 이제 그만 말하고\n행동하는 것이다.',
      author: '월트 디즈니 - 미국 애니메이터',
    },
    {
      quote: '꿈이 있다면 작은 일이라도 시작하라.\n새로운 일을 하는 용기 속에\n당신의 능력과 기적이 모두 들어있다.',
      author: '요한 볼프강 폰 괴테 - 작가',
    },
  ],
};

const HARMONIST: ICompetenceProfile = {
  type: 'Harmonist',
  name: '공감하는 화합형',
  def: '주변 사람들에게 오랜 시간 일관된 모습을 보여주는 부드럽고 친절한 신뢰와 화합의 아이콘!',
  str: ['일희일비NO', '성실함은 나의무기', '혼자 열 걸음보다 함께 한 걸음'],
  weak: ['주목 당하면 얼음', '리더는 어려워', '주도성 부족'],
  catchPhrase: '걱정 마!\n내가 항상 뒤에서 지켜볼게!',
  description:
    '당신은 다른 사람에게 부드럽고 친절한 인상을 주며, 상대가 좋은 감정을 오래 유지할 수 있게 일관된 마음과 자세를 보여주는 화합의 아이콘이에요. 당신은 다른 사람이 기분 상해 할지도 모를 일이 일어나는 걸 불편해하고, 자신으로 인해 그런 일이 생기지 않도록 무척이나 노력해요. 그런 당신에게는 타인의 말을 경청하고 편견 없이 대하려 노력하는 태도가 몸에 배어 있죠. 기분에 따라 좌지우지되지 않고 한결같은 모습을 보여주는 당신을 주변 사람들은 엄마, 최고의 상담가 등으로 칭하며 박수를 보내요. 간혹 당신의 과한 배려심을 걱정한 나머지 좀 더 스스로가 중심이 되어 살아보면 어떠냐는 조언을 하기도 하지만요. 상대에 대한 공감을 바탕으로 주변 사람들을 오랫동안 손 잡아 줄 수 있는 사람, 바로 당신이에요!',
  standOutComment: (
    <>
      세상 모든 일이 예상대로 흘러간다면 걱정과 고민이 설 자리는 없을 거예요. 하지만 현실에선 생각지도 못한 변수가
      끼어드는 급박한 상황이 많이 발생하지요. 당신은 이럴 때 함께 있는 사람들을 안심시키고, 해내야 하는 업무에 묵묵하게
      몰입하는 능력을 가지고 있어요. 특히, <strong>돌발 상황이 일어나기 쉬운 현장 업무</strong>에서 당신은 침착히 주변을
      살피고 고객들이 필요로 하는 도움을 능숙하게 제공할 수 있을 거예요. 당신의 성실하고 사려 깊은 태도는 긍정적인
      피드백으로 이어져 빛을 낼 수 있을 거예요.
    </>
  ),
  cautionComment: (
    <>
      당신은 누군가가 먼저 닦아 놓은 길을 성실히 따라가는 일에 자신 있어요. 하지만 언제나 만들어진 길만을 갈 수는
      없어요. 특히 성장하고자 하는 욕망이 클수록 새로운 길을 찾아 파이를 넓힐 줄 아는 능력이 중요해질 거랍니다. 당신이{' '}
      <strong>업무 책임자로서 미래 비전과 계획을 수립하는 과제</strong>를 받게 되는 상황을 상상해본다면, 어쩐지 잘 해낼
      자신이 없고 부담스러워 다른 사람에게 기대고 싶은 마음이 들지도 몰라요. 하지만 더 멀리 내다보면, 당신이 용기를 내어
      개척한 신대륙에서 동료들과 더 큰 화합을 이룰 수 있는 기회를 얻을 수 있을지도 몰라요.
    </>
  ),
  img: '/iconJoy4.png',
  synergy: {
    leader: 'TALENTED',
    coworker: 'SUPPORTER',
    follower: 'ENERGIZER',
  }, // 재능 지지 자신감
  quotes: [
    {
      quote:
        '나는 당신이 할 수 없는 일을 할 수 있고,\n당신은 내가 할 수 없는 일을 할 수 있다.\n따라서 우 리는 함께 큰일을 할 수 있다.',
      author: '마더 테레사 - 수녀',
    },
    {
      quote: '성공한 사람이 되려하기보다는\n가치있는 사람이 되려고 노력하라.',
      author: '알버트 아인슈타인 - 독일-미국-스위스 이론 물리학자',
    },
    {
      quote: '재능은 게임을 이기게 한다.\n그러나 팀워크와 이해력은 챔피언을 만든다.',
      author: '마이클 조던 - 농구 선수',
    },
  ],
};

const ENERGIZER: ICompetenceProfile = {
  type: 'Energizer',
  name: '씩씩한 자신감형',
  def: '새로운 일에 주저 없이 뛰어들어 마음 먹은 대로 해내고야 마는 지칠 줄 모르는 에너자이저!',
  str: ['자신감 빼면 시체', '행동파', '아무도 못 말려'],
  weak: ['섬세함 필요', '콧대 높음', '경청 부족'],
  catchPhrase: '해봤어? 해보자! 해낸다!!',
  description:
    '당신은 다른 사람에게 부드럽고 친절한 인상을 주며, 상대가 좋은 감정을 오래 유지할 수 있게 일관된 마음과 자세를 보여주는 화합의 아이콘이에요. 당신은 다른 사람이 기분 상해 할지도 모를 일이 일어나는 걸 불편해하고, 자신으로 인해 그런 일이 생기지 않도록 무척이나 노력해요. 그런 당신에게는 타인의 말을 경청하고 편견 없이 대하려 노력하는 태도가 몸에 배어 있죠. 기분에 따라 좌지우지되지 않고 한결같은 모습을 보여주는 당신을 주변 사람들은 엄마, 최고의 상담가 등으로 칭하며 박수를 보내요. 간혹 당신의 과한 배려심을 걱정한 나머지 좀 더 스스로가 중심이 되어 살아보면 어떠냐는 조언을 하기도 하지만요. 상대에 대한 공감을 바탕으로  주변 사람들을 오랫동안 손 잡아 줄 수 있는 사람, 바로 당신이에요!',
  standOutComment: (
    <>
      새로운 과제와 업무가 주어졌을 때, 사람들은 {`'그것이 잘 될까? 우리가 잘할 수 있을까?'`} 하는 고민과 걱정을 하기
      마련이에요. 하지만 그럴 때 {`"해봤어?, 한 번 해보자!"`}라는 말을 던질 줄 아는 당신은 넘치는 패기로 분위기를 단숨에
      반전시킬 수 있는 사람이에요.{' '}
      <strong>새로운 업무를 시작할 때 처음 목적을 공유하고 그 중요성을 공감하는 자리인 킥오프 미팅</strong>에서,
      긍정적인 자신감과 의욕으로 무장한 당신은 분위기 메이커로서 함께 일하는 동료들의 불안은 가라앉히고 동기와 의지는
      강화시키는 터닝 포인트를 만들어내게 될 거예요!
    </>
  ),
  cautionComment: (
    <>
      시작이 반이라지만, 시작해서 끝까지 가지 못한다면 그건 영원한 절반일 뿐이에요. 긍정적인 마음과 적극적인 자세를 지닌
      당신은 분명 멋진 시작을 디딤돌 삼아 반 이상의 의미를 만들어 낼 수 있는 사람이랍니다. 하지만 시작이 좋았다 해도{' '}
      <strong>구성원이 함께 시너지를 내면서 나아가야 하는 협력 프로젝트</strong>의 경우, 건강하고 이로운 상호작용이
      일어나지 않으면 문제가 생기기 쉽죠. 당신에게 용기를 선물 받아 멋진 시작을 함께한 동료들에게 좀 더 주의를 기울이고,
      경청을 기반으로 섬세한 소통을 해낸다면 모든 사람들은 당신과 함께 일하고 싶을 거예요!
    </>
  ),
  img: '/iconJoy5.png',
  synergy: {
    leader: 'HARMONIST',
    coworker: 'PASSIONIST',
    follower: 'CAREFUL',
  },
  quotes: [
    {
      quote: '자신을 내 보여라.\n그러면 재능이 드러날 것이다.',
      author: '발타사르 그라시안 - 17세기 예수회의 사제이자 신학교수',
    },
    {
      quote: '자신을 믿어라. 자신의 능력을 신뢰하라.\n겸손하지만 합리적인 자신감 없이는\n성공할 수도 생복할 수도 없다.',
      author: '노먼 빈센트 필 - 미국의 목사이자 작가',
    },
    {
      quote: '자신의 능력을 감추지 마라.\n재능은 쓰라고 주어진 것이다.\n그늘 속의 해시계가 무슨 소용이랴.',
      author: '벤자민 프랭클린 - 미국의 정치인, 과학자, 저술가',
    },
  ],
};

export const competenceProfileMap: Record<CompetenceType, ICompetenceProfile> = {
  ENERGIZER,
  TALENTED,
  GROWTH_PERSON,
  SOLVER,
  SUPPORTER,
  CHALLENGER,
  FAIR_GAMER,
  SOCIALIZER,
  SMARTS,
  PASSIONIST,
  HARMONIST,
  CAREFUL,
};
