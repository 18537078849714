import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { ReactSelectStyles } from '@components/profile/profileOverlay/overlays/ReactSelectStyles';
import { ContributionOptions, Project } from '@domain/constant/profile/ProjectType';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const ProjectType = ({ prefixName }: HookFormProps) => {
  const { trigger } = useFormContext();
  const { field: teamTypeField, fieldState: teamTypeState } = useController({ name: `${prefixName}.teamType` });
  const { field: contributionField, fieldState: contributionState } = useController({
    name: `${prefixName}.contribution`,
  });
  const isCheckTeamType = teamTypeField.value === Project['TEAM'];
  const isCheckPersonalType = teamTypeField.value === Project['PERSONAL'];

  useEffect(() => {
    // 개인 프로젝트 선택 시, 기여도 값 초기화
    if (teamTypeField.value === Project['PERSONAL']) contributionField.onChange(null);
    else trigger(`${prefixName}.contribution`);
  }, [teamTypeField.value]);

  return (
    <div className={cx('projectTypeArea')}>
      <div className={cx('projectTypeInner')}>
        <div className={cx('projectTypeWrap')}>
          <label className={cx('label', { error: teamTypeState.invalid })}>
            <input
              {...teamTypeField}
              name="projectType"
              type="radio"
              className={cx('input', 'hidden')}
              value={Project['TEAM']}
              checked={isCheckTeamType}
            />
            <span className={cx('mark')} />팀 프로젝트
          </label>

          <div className={cx('selectArea')}>
            <Select
              {...contributionField}
              className={cx('select', { error: contributionState.invalid })}
              placeholder={'기여도'}
              styles={ReactSelectStyles}
              isSearchable={false}
              options={ContributionOptions}
              isDisabled={!isCheckTeamType}
            />
          </div>
        </div>
        <div className={cx('projectTypeWrap')}>
          <label className={cx('label', { error: teamTypeState.invalid })}>
            <input
              {...teamTypeField}
              name="projectType"
              type="radio"
              className={cx('input', 'hidden')}
              value={Project['PERSONAL']}
              checked={isCheckPersonalType}
            />
            <span className={cx('mark')} />
            개인 프로젝트
          </label>
        </div>
      </div>

      {teamTypeState.invalid && <div className={cx('errorMessage')}>{teamTypeState.error?.message}</div>}
      {contributionState.invalid && <div className={cx('errorMessage')}>{contributionState.error?.message}</div>}
    </div>
  );
};

export default ProjectType;
