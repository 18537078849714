import { AxiosResponse } from 'axios';
import PageableRq from '@domain/rq/common/PageableRq';
import { axios } from '@repository/RequestClient';
import {
  MatchingApplicationStatusType,
  MatchingHomeApplicationPositionRs,
  MatchingHomeWritingPositionRs,
} from '@domain/rs/match/MatchingHomeApplicationPositionRs';
import ReplyStatusRs from '@domain/rs/match/ReplyStatusRs';
import MissionCompleteStatusRs from '@domain/rs/match/MissionCompleteStatusRs';
import MatchingAlimTalkRs from '@domain/rs/match/MatchingAlimTalkRs';
import BookmarkPositionListRs from '@domain/rs/match/BookmarkPositionListRs';
import { MatchingHomeHiddenPositionRs } from '@domain/rs/match/MatchingHomeHiddenPositionRs';
import { MatchingHomeApplicationStatusRs } from '@domain/rs/match/MatchingHomeApplicationStatusRs';

export default interface MatchHomeRepo {
  //지원 현황 > 전형 별 포지션 목록 조회
  fetchApplicationPositionList(
    tabType: MatchingApplicationStatusType,
  ): Promise<AxiosResponse<MatchingHomeApplicationPositionRs>>;

  //지원 현황 > 사용자 응답 현황 조회 (응답률, 응답속도)
  fetchResponseStatus(): Promise<AxiosResponse<ReplyStatusRs>>;

  //미션 완료 여부
  fetchMissionCompleteStatus(): Promise<AxiosResponse<MissionCompleteStatusRs>>;

  //매칭 알림톡 존재 여부 조회
  fetchMatchingAlimTalkStatus(): Promise<AxiosResponse<MatchingAlimTalkRs>>;

  // 포지션 북마크 조회
  fetchBookmarkPositionList(rq: PageableRq): Promise<AxiosResponse<BookmarkPositionListRs>>;

  //지원현황 > 숨김 포지션 목록 조회
  fetchHiddenPositions(): Promise<AxiosResponse<MatchingHomeHiddenPositionRs>>;

  //지원 현황 > 포지션 숨김
  fetchPositionsHide(positionSn: number): Promise<AxiosResponse>;

  //지원 현황 > 포지션 숨김 해제
  fetchPositionsShow(positionSns: number[]): Promise<AxiosResponse>;

  //지원 현황 > 전형 종료
  fetchStatusEnd(positionSn: number): Promise<AxiosResponse>;

  //지원 전형 현황 조회
  getApplicationStatus(): Promise<AxiosResponse<MatchingHomeApplicationStatusRs>>;

  //작성중인 지원서
  getWritingResumePositions(): Promise<AxiosResponse<MatchingHomeWritingPositionRs>>;
}

export class RemoteMatchHomeRepo implements MatchHomeRepo {
  fetchApplicationPositionList(tabType: MatchingApplicationStatusType) {
    return axios.get<MatchingHomeApplicationPositionRs>('/v2/match/home/application/positions', {
      params: { tabType },
    });
  }

  fetchResponseStatus() {
    return axios.get<ReplyStatusRs>('/match/home/application/response-status');
  }

  fetchMissionCompleteStatus() {
    return axios.get<MissionCompleteStatusRs>('/match/home/mission-complete');
  }

  fetchMatchingAlimTalkStatus() {
    return axios.get<MatchingAlimTalkRs>('/match/home/matching-alimtalk');
  }

  fetchBookmarkPositionList(rq: PageableRq) {
    return axios.get<BookmarkPositionListRs>('/position/bookmarks', {
      params: rq,
    });
  }

  fetchHiddenPositions(): Promise<AxiosResponse<MatchingHomeHiddenPositionRs>> {
    return axios.get('/match/home/application/hidden-positions');
  }

  fetchPositionsHide(positionSn: number) {
    return axios.put('/match/home/application/positions/hide', { positionSn });
  }

  fetchPositionsShow(positionSns: number[]): Promise<AxiosResponse> {
    return axios.put('/match/home/application/positions/show', { positionSns });
  }

  fetchStatusEnd(positionSn: number): Promise<AxiosResponse> {
    return axios.put('/match/home/application/status/end', { positionSn });
  }

  getApplicationStatus(): Promise<AxiosResponse<MatchingHomeApplicationStatusRs>> {
    return axios.get(`/match/home/application/status`);
  }

  getWritingResumePositions(): Promise<AxiosResponse<MatchingHomeWritingPositionRs>> {
    return axios.get(`/match/home/writing-resume/positions`);
  }
}
