import { PositionDto } from '@domain/rs/position/list/PositionListRs';
import DateFormat from '@utils/date/format';
import DateUtil from '@utils/date/util';

export default class CompanyPositionVO {
  positionSn: number;
  positionName: string;
  closingDateText: string;
  closingDateDdayText: string;

  constructor(dto: PositionDto) {
    this.positionSn = dto.positionSn;
    this.positionName = dto.positionName;
    this.closingDateText = dto.closingDateTime
      ? DateFormat.format(new Date(dto.closingDateTime), 'yyyy.MM.dd(week) HH:mm')
      : '';
    this.closingDateDdayText = this.getDdayText(dto.closingDateTime);
  }

  private getDdayText(date: string | null) {
    if (!date) return '상시';
    const leftDays = DateUtil.getLeftDays(DateUtil.getNow(), new Date(date));
    return `D-${leftDays}`;
  }
}
