import { PATTERN } from '@utils/validators/pattern';
import { ERROR_TEXT } from '@utils/validators/text';

type ERROR = true | string;

export const valueRequired = { required: true };
export const valueNotRequired = { required: false };

export const nameValidator = (name: string): ERROR => {
  if (!PATTERN.NAME.test(name)) return ERROR_TEXT.NAME_PATTERN;
  return true;
};

export const emailValidator = (email: string): ERROR => {
  if (!PATTERN.EMAIL.test(email)) return ERROR_TEXT.EMAIL_PATTERN;
  return true;
};

export const birthDateValidator = (birthDate: string): ERROR => {
  if (!(`${birthDate}`.startsWith('1') || `${birthDate}`.startsWith('2'))) return ERROR_TEXT.BIRTHDATE_PATTERN;
  if (`${birthDate}`.length !== 8) return ERROR_TEXT.BIRTHDATE_LENGTH;
  return true;
};

export const getAtLeast15YearsOldDate = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear() - 15, currentDate.getMonth(), currentDate.getDate());
};

export const birthDate15Validator = (date: number, genderFlag: string) => {
  if (!genderFlag) return true;
  const birthDate = `${date}`;
  const genderNumber = Number(genderFlag);
  // 한국인은 뒷자리 3, 4가 2000년 이후 출생자, 외국인은 뒷자리 7, 8이 2000년 이후 출생자
  const isBirthDateYear2000 = genderNumber === 3 || genderNumber === 4 || genderNumber === 7 || genderNumber === 8;
  const year = Number(birthDate.substring(0, 2)) + (isBirthDateYear2000 ? 2000 : 1900);
  const month = Number(birthDate.substring(2, 4)) - 1;
  const day = Number(birthDate.substring(4, 6));
  const dob = new Date(year, month, day);

  if (dob > getAtLeast15YearsOldDate()) return ERROR_TEXT.BIRTHDATE_15_PATTERN;
  return true;
};

export const genderFlagValidator = (genderFlag: number) => {
  if (!PATTERN.GENDER.test(`${genderFlag}`)) return ERROR_TEXT.GENDER_PATTERN;
  return true;
};

export const idValidator = (id: string): ERROR => {
  if (id.length < 5) return ERROR_TEXT.ID_LENGTH;
  if (!PATTERN.ID.test(id)) return ERROR_TEXT.ID_PATTERN;
  return true;
};

export const passwordValidator = (password: string, email?: string, id?: string, prevPassword?: string): ERROR => {
  if (password.length < 8) return ERROR_TEXT.PASSWORD_LENGTH;
  if (password.search(/[\s=\=&<>\'\"\|\?\/]/) !== -1) return ERROR_TEXT.PASSWORD_CHAR;

  const tNum = password.search(/[0-9]/g) + 1;
  const tEng = password.search(/[a-z]/gi) + 1;
  const tSpe = password.search(/[^a-z0-9]/gi) + 1;

  if (!((tNum && tEng) || (tEng && tSpe) || (tSpe && tNum))) return ERROR_TEXT.PASSWORD_CHAR_KIND;
  if (email && password.includes(email)) return ERROR_TEXT.PASSWORD_INCLUDES_EMAIL;
  if (id && password.includes(id)) return ERROR_TEXT.PASSWORD_INCLUDES_ID;
  if (prevPassword && password === prevPassword) return ERROR_TEXT.PASSWORD_INCLUDES_PREV;

  for (let i = 0; i < password.length - 2; i++) {
    const t = password.substr(i, 3);
    const tR = t.split('').reverse().join(''); // t는 이번에 검사할 3글자, tR은 역순

    if (~PATTERN.KEY_BOARD.indexOf(t) || ~PATTERN.KEY_BOARD.indexOf(tR)) {
      return ERROR_TEXT.PASSWORD_CONTINUOUS_KEYBOARD;
    }
    if (~PATTERN.SERIAL_NUM.indexOf(t) || ~PATTERN.SERIAL_NUM.indexOf(tR)) {
      return ERROR_TEXT.PASSWORD_CONTINUOUS_NUMBER;
    }
    if (~PATTERN.SERIAL_CHAR.indexOf(t) || ~PATTERN.SERIAL_CHAR.indexOf(tR)) {
      return ERROR_TEXT.PASSWORD_CONTINUOUS_CHAR;
    }
  }

  if (!hasSameChar(password, 3)) return ERROR_TEXT.PASSWORD_SAME_CHAR;
  return true;
};

export const rePasswordValidator = (rePassword: string, password: string): ERROR => {
  if (rePassword !== password) return ERROR_TEXT.RE_PASSWORD_PATTERN;
  return true;
};

export const cellphoneValidator = (cellphone: string): ERROR => {
  const replaceCellphone = cellphone.replace(/[^0-9]/g, '');
  if (replaceCellphone.length < 10 || replaceCellphone.length > 11) return ERROR_TEXT.MOBILE_PATTERN;
  return true;
};

export const onlyNumber = (e: Record<string, any>) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
};

export const scoreNumber = (e: Record<string, any>) => {
  const value = e.target.value;
  e.target.value = value.substring(0, 3).replace('.', '');
};

export const scoreDecimal = (e: Record<string, any>) => {
  const pattern = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;
  const value = e.target.value;

  //학점 정수 한자리만 표시
  if (value.length > 1 && !value.includes('.')) {
    e.target.value = value.substring(0, 1);
  }

  if (value.length > 1 && value.includes('.')) {
    const valArr = value.split('.');

    // 소소점이 있을 경우 정수 부분 최대 1자리, 소수점 부분 최대 2자리만 입력 되도록 함
    if (valArr.length === 2) {
      if (valArr[0].length > 1) {
        e.target.value = Number(`${valArr[0].substring(0, 1)}.${valArr[1]}`);
        return;
      } else if (valArr[1].length > 2) {
        e.target.value = Number(`${valArr[0]}.${valArr[1].substring(0, 2)}`);
        return;
      }
    }
  }

  if (!pattern.test(value)) {
    e.target.value = value.substring(0, value.length - 1);
  }
};

export const onInputPassword = (e: Record<string, any>) => {
  e.target.value = e.target.value.replace(/[^\w~!@#$%^&*()_+-|<>?:;`,{}\]\[/\'\"\\\']/g, '');
};

const hasSameChar = (value: string, sameCharLength: number) => {
  // 같은 값이 연속되어 있는지 찾는 함수
  let i, len, j, t;

  if (value.length < sameCharLength) return true;
  i = value.length - sameCharLength + 1;

  while (i--) {
    t = value[i];
    len = i + sameCharLength;
    for (j = i + 1; j < len; j++)
      if (value[j] === t) continue;
      else break;
    if (j === len) return false;
  }
  return true;
};
