import { MatchingChallengeCardDto, MatchingChallengeListRs } from '@domain/rs/challenge/MatchingChallengeListRs';
import {
  ChallengeStatus,
  ChallengeStatusText,
  ChallengeStatusType,
} from '@domain/constant/challenge/challengeStatusType';
import { ChallengeParticipantType } from '@domain/constant/challenge/challengeParticipantType';
import DateUtil from '@utils/date/util';
import DateFormat from '@utils/date/format';

export const getChallengeStatus = (postStartDateTime: string, postEndDateTime: string, endYn: boolean) => {
  const currentDateTime = DateUtil.getNow();

  // 종료시
  if (endYn) return ChallengeStatus.FINISH;
  // 현재 날짜 > 종료 날짜
  else if (DateUtil.compare(currentDateTime, new Date(postEndDateTime)) > 0) return ChallengeStatus.FINISH;
  //현재 날짜 < 진행 날짜
  else if (DateUtil.compare(currentDateTime, new Date(postStartDateTime)) < 0) return ChallengeStatus.READY;
  else return ChallengeStatus.PROGRESS;
};

export const convertDescription = (description: string) => {
  const tagArray = description.split(',');
  return tagArray.map((tag) => `#${tag}`).join('  ');
};

export class MatchingChallengeCardVO {
  sn: number;
  title: string;
  bannerBackgroundColor: string;
  bannerTextColor: string;
  description: string;
  participants: string;
  type: ChallengeParticipantType;
  postStartDateTime: Date;
  postEndDateTime: Date;
  bannerImageUrl: string;
  challengeStatus: ChallengeStatusType;
  challengeStatusText: string;
  postEndDateTimeMMddForm: string;

  constructor(dto: MatchingChallengeCardDto) {
    this.sn = dto.sn;
    this.title = dto.title;
    this.bannerBackgroundColor = dto.bannerBackgroundColor;
    this.bannerTextColor = dto.bannerTextColor;
    this.description = convertDescription(dto.description);
    this.participants = dto.participants;
    this.type = dto.type;
    this.postStartDateTime = new Date(dto.postStartDateTime);
    this.postEndDateTime = new Date(dto.postEndDateTime);
    this.bannerImageUrl = dto.bannerImageUrl;
    this.challengeStatus = getChallengeStatus(dto.postStartDateTime, dto.postEndDateTime, dto.endYn);
    this.challengeStatusText = ChallengeStatusText[this.challengeStatus];
    this.postEndDateTimeMMddForm = DateFormat.format(this.postEndDateTime, 'MM.dd');
  }
}

export default class MatchingChallengeListVO {
  challenges: MatchingChallengeCardVO[];

  constructor(rs: MatchingChallengeListRs) {
    this.challenges = rs.challenges.map((matchingChallenge) => new MatchingChallengeCardVO(matchingChallenge));
  }
}
