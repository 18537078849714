export const PATTERN = {
  PROTOCOL: /(http:\/\/|https:\/\/).*/g,
  NAME: /^[a-zA-Z가-힣\s]*$/,
  EMAIL: /^[0-9a-z]+([-_.]*[0-9a-z-_])*@[a-z]+(\.[a-z]{2,})+$/,
  GENDER: /[1-8]/,

  BLANK: /\s/,
  SPECIAL_SYMBOL: /[\-$<>\'\"|?\/]/i,
  SEARCH_SPECIAL_SYMBOL: /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,

  ID: /^[0-9a-z-\_]+$/,
  CELLPHONE: /(^010)([0-9]{3,4})([0-9]{4})$/,
  SECRETKEY: /^[0-9]{6}$/,

  NUMBER: /[0-9]/g,
  ENG: /[a-z]/gi,
  SPECIAL_ALL: /[^a-z0-9]/gi,
  DATE: /^\d{4}-\d{2}-\d{2}$/,

  KEY_BOARD: 'qwertyuiop asdfghjkl zxcvbnm QWERTYUIOP ASDFGHJKL ZXCVBNM',
  SERIAL_CHAR: 'abcdefghijklmnopqrstuvwxyz ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  SERIAL_NUM: '01234567890',
};
