import { AxiosResponse } from 'axios';
import { ResultRs } from '@domain/rs/common/SuccessRs';
import { axios } from '@repository/RequestClient';
import { AgreePositionInformationRq } from '@domain/rq/terms/AgreePositionInformationRq';
import { MatchProfileSensitiveAgreeInfoRq } from '@domain/rq/terms/MatchProfileSensitiveAgreeInfoRq';
import { UserTermsAgreeCheckNewRs } from '@domain/rs/terms/UserTermsAgreeCheckNewRs';
import { PositionAgreeCheckRs } from '@domain/rs/terms/PositionAgreeCheckRs';
import { MatchProfileSensitiveAgreeInfoRs } from '@domain/rs/terms/MatchProfileSensitiveAgreeInfoRs';
import { TERMS_TYPE } from '@domain/constant/termsType';

export default interface UserTermsAgreesRepo {
  // 약관 동의 생성
  fetchTermsAgreeForSn(termsSn: number): Promise<AxiosResponse<ResultRs>>;

  //알림톡 > 역량검사 약관 동의 생성 (로그인 없이 동의)
  fetchTermsAccaAgree(id: string): Promise<AxiosResponse<boolean>>;

  // 매칭 프로필 > 민감정보 수집 약관 동의 저장
  saveProfileSensitiveInfoAgree(termsSns: number[]): Promise<AxiosResponse<boolean>>;

  // 약관 동의 생성
  createTermsAgree(termsSn: number): Promise<AxiosResponse<ResultRs>>;

  // 동의해야할 신규 버전 약관 조회
  fetchTermsCheckNew(termType: TERMS_TYPE): Promise<AxiosResponse<UserTermsAgreeCheckNewRs>>;

  // 포지션 파일 제출 약관 생성
  savePositionTerms(rq: AgreePositionInformationRq): Promise<AxiosResponse<boolean>>;

  // 포지션 파일 제출 약관 동의 조회
  fetchPositionAgreeInformation(): Promise<AxiosResponse<PositionAgreeCheckRs>>;

  // 매칭 프로필 > 민감정보 수집 약관 동의
  saveMatchProfileSensitiveInfoAgree(rq: MatchProfileSensitiveAgreeInfoRq): Promise<AxiosResponse<boolean>>;

  // 매칭 프로필 > 민감정보 수집 약관 동의 여부 조회
  fetchMatchProfileSensitiveInfoAgree(): Promise<AxiosResponse<MatchProfileSensitiveAgreeInfoRs>>;
}

export class RemoteUserTermsAgreesRepo implements UserTermsAgreesRepo {
  fetchTermsCheckNew(termType: TERMS_TYPE): Promise<AxiosResponse<UserTermsAgreeCheckNewRs>> {
    return axios.get(`/user-terms-agrees/check/new`, {
      params: {
        termType,
      },
    });
  }

  fetchTermsAgreeForSn(termsSn: number): Promise<AxiosResponse<ResultRs>> {
    return axios.post(`/user-terms-agrees`, null, { params: { termsSn } });
  }

  fetchTermsAccaAgree(id: string): Promise<AxiosResponse<boolean>> {
    return axios.post(`/user-terms-agrees/alimtalk/acc/agree-information`, {
      id,
    });
  }

  saveProfileSensitiveInfoAgree(termsSns: number[]): Promise<AxiosResponse<boolean>> {
    return axios.post(`/user-terms-agrees/profile/sensitive/agree-information`, { termsSns });
  }

  createTermsAgree(termsSn: number): Promise<AxiosResponse<ResultRs>> {
    return axios.post(`/user-terms-agrees`, null, { params: { termsSn } });
  }

  savePositionTerms(rq: AgreePositionInformationRq): Promise<AxiosResponse<boolean>> {
    return axios.post(`/user-terms-agrees/position/agree-information`, rq);
  }

  fetchPositionAgreeInformation(): Promise<AxiosResponse<PositionAgreeCheckRs>> {
    return axios.get(`/user-terms-agrees/position/agree-information-yn`);
  }

  saveMatchProfileSensitiveInfoAgree(rq: MatchProfileSensitiveAgreeInfoRq): Promise<AxiosResponse<boolean>> {
    return axios.post(`/user-terms-agrees/profile/sensitive/agree-information`, rq);
  }

  // 매칭 프로필 > 민감정보 수집 약관 동의 여부 조회
  fetchMatchProfileSensitiveInfoAgree(): Promise<AxiosResponse<MatchProfileSensitiveAgreeInfoRs>> {
    return axios.get(`/user-terms-agrees/profile/sensitive/agree-information-yn `);
  }
}
