import CompanyFinanceRs, { ProfitsDto, RevenuesDto } from '@domain/rs/company/sn/CompanyFinanceRs';
import DateFormat from '@utils/date/format';

export default class CompanyFinanceVO {
  revenues: RevenuesVO[];
  profits: ProfitsVO[];
  lastModifiedDateText: string;

  constructor(rs: CompanyFinanceRs) {
    this.revenues = rs.revenues.map((revenue) => new RevenuesVO(revenue));
    this.profits = rs.profits.map((profit) => new ProfitsVO(profit));
    this.lastModifiedDateText = this.getDateFormat(rs.lastModifiedDate);
  }

  getDateFormat(data: string | null) {
    return data ? `업데이트 일자: ${DateFormat.format(new Date(data), 'yyyy.MM.dd')}` : '';
  }
}

class RevenuesVO {
  value: number; //숫자 금액
  name: string; //한글 금액
  row: string; //년도

  constructor(dto: RevenuesDto) {
    this.value = dto.amount;
    this.name = formatNumberToKO(dto.amount);
    this.row = String(dto.year);
  }
}

class ProfitsVO {
  value: number;
  name: string;
  row: string;

  constructor(dto: ProfitsDto) {
    this.value = dto.amount;
    this.name = formatNumberToKO(dto.amount);
    this.row = String(dto.year);
  }
}

const formatNumberToKO = (amount: number) => {
  const isNegative = amount < 0;
  const roundNumber = getRoundNumber(amount);

  if (roundNumber >= 10 ** 16)
    return `${isNegative ? '-' : ''}${Math.floor(roundNumber / 10 ** 16)}경 ${
      (roundNumber % 10 ** 16) / 10 ** 15 ? `${(roundNumber % 10 ** 16) / 10 ** 15}천조` : ''
    }`;
  else if (roundNumber >= 10 ** 12)
    return `${isNegative ? '-' : ''}${Math.floor(roundNumber / 10 ** 12)}조 ${
      (roundNumber % 10 ** 12) / 10 ** 11 ? `${(roundNumber % 10 ** 12) / 10 ** 11}천억` : ''
    }`;

  if (roundNumber >= 10 ** 8)
    return `${isNegative ? '-' : ''}${Math.floor(roundNumber / 10 ** 8)}억 ${
      (roundNumber % 10 ** 8) / 10 ** 7 ? `${(roundNumber % 10 ** 8) / 10 ** 7}천만` : ''
    }`;
  else if (roundNumber) return `${isNegative ? '-' : ''}${Math.round(Math.abs(roundNumber) / 10 ** 4)}만`;

  return '0원';
};

const getRoundNumber = (amount: number) => {
  if (Math.abs(amount) >= 10 ** 16) return Math.round(Math.abs(amount) / 10 ** 15) * 10 ** 15;
  else if (Math.abs(amount) >= 10 ** 12) return Math.round(Math.abs(amount) / 10 ** 11) * 10 ** 11;
  else if (Math.abs(amount) >= 10 ** 8) return Math.round(Math.abs(amount) / 10 ** 7) * 10 ** 7;
  else if (Math.abs(amount) > 0) return Math.round(Math.abs(amount) / 10 ** 3) * 10 ** 3;

  return 0;
};
