/**
 * 대만, 베트남을 제외
 * (추가 요금, 현지 사업자여야 하는 이슈)
 * */

export enum Country {
  REPUBLIC_OF_KOREA = 'REPUBLIC_OF_KOREA', // 대한민국
  USA = 'USA', // 미국
  CANADA = 'CANADA', // 캐나다
  // TAIWAN = 'TAIWAN', // 대만
  JAPAN = 'JAPAN', // 일본
  CHINA = 'CHINA', // 중국
  SINGAPORE = 'SINGAPORE', // 싱가포르
  HONGKONG = 'HONGKONG', // 홍콩
  // INDONESIA = 'INDONESIA', // 인도네시아
  MALAYSIA = 'MALAYSIA', // 말레이시아
  PHILIPPINES = 'PHILIPPINES', // 필리핀
  THAILAND = 'THAILAND', // 태국
  BRUNEIDARUSSALAM = 'BRUNEIDARUSSALAM', // 브루나이
  // VIETNAM = 'VIETNAM', // 베트남
  LAOS = 'LAOS', // 라오스
  MYANMAR = 'MYANMAR', // 미얀마
  CAMBODIA = 'CAMBODIA', // 캄보디아
  MACAU = 'MACAU', // 마카오
  BANGALADESH = 'BANGALADESH', // 방글라데시
  INDIA = 'INDIA', // 인도
  PAKISTAN = 'PAKISTAN', // 파키스탄
  // SAUDIARABIA = 'SAUDIARABIA', // 사우디아라비아
  // UNITEDARABEMIRATES = 'UNITEDARABEMIRATES', // 아랍에미리트
  ARMENIA = 'ARMENIA', // 아르메니아
  AZERBAIJAN = 'AZERBAIJAN', // 아제르바이잔
  AFHANISTAN = 'AFHANISTAN', // 아프가니스탄
  ALBANIA = 'ALBANIA', // 알바니아
  YEAMEN = 'YEAMEN', // 예멘
  OMAN = 'OMAN', // 오만
  JORDAN = 'JORDAN', // 요르단
  UZBEKISTAN = 'UZBEKISTAN', // 우즈베키스탄
  UKRAINE = 'UKRAINE', // 우크라이나
  ISRALL = 'ISRALL', // 이스라엘
  EGYPT = 'EGYPT', // 이집트
  KUWAIT = 'KUWAIT', // 쿠웨이트
  KYRGYZSTAN = 'KYRGYZSTAN', // 키르기스스탄
  TAJIKISTAN = 'TAJIKISTAN', // 타지키스탄
  TRUKEY = 'TRUKEY', // 튀르키예
  TURKMENISTAN = 'TURKMENISTAN', // 투르크메니스탄
  NEWZEALAND = 'NEWZEALAND', // 뉴질랜드
  AUSTRALIA = 'AUSTRALIA', // 호주
  PAPUANEWGUINEA = 'PAPUANEWGUINEA', // 파푸아뉴기니
  GREECE = 'GREECE', // 그리스
  NETHERLANDS = 'NETHERLANDS', // 네덜란드
  NORWAY = 'NORWAY', // 노르웨이
  DENMARK = 'DENMARK', // 덴마크
  GERMANY = 'GERMANY', // 독일
  LATVIA = 'LATVIA', // 라트비아
  RUSSIA = 'RUSSIA', // 러시아
  LEBANON = 'LEBANON', // 레바논
  ROMANIA = 'ROMANIA', // 루마니아
  LUXEMBOURG = 'LUXEMBOURG', // 룩셈부르크
  LITHUANIA = 'LITHUANIA', // 리투아니아
  MACEONIA = 'MACEONIA', // 마케도니아
  MALTA = 'MALTA', // 몰타
  BAHRAIN = 'BAHRAIN', // 바레인
  BELGIUM = 'BELGIUM', // 벨기에
  BELARUS = 'BELARUS', // 벨라루스
  BOSNIA = 'BOSNIA', // 보스니아
  BULGARIA = 'BULGARIA', // 불가리아
  SERBIA = 'SERBIA', // 세르비아
  SWEDEN = 'SWEDEN', // 스웨덴
  SWITZERLAND = 'SWITZERLAND', // 스위스
  SPAIN = 'SPAIN', // 스페인
  SLOVAKIA = 'SLOVAKIA', // 슬로바키아
  SLOVENIA = 'SLOVENIA', // 슬로베니아
  LRELAND = 'LRELAND', // 아일랜드
  ESTONIA = 'ESTONIA', // 에스토니아
  UNITEDKINGDOM = 'UNITEDKINGDOM', // 영국
  AUSTRIA = 'AUSTRIA', // 오스트리아
  ITALY = 'ITALY', // 이탈리아
  CZECHREPUBLIC = 'CZECHREPUBLIC', // 체코
  CRORATIA = 'CRORATIA', // 크로아티아
  CYPRUS = 'CYPRUS', // 키프로스
  PORTUGAL = 'PORTUGAL', // 포르투갈
  POLAND = 'POLAND', // 폴란드
  FRANCE = 'FRANCE', // 프랑스
  FINLAND = 'FINLAND', // 핀란드
  HUNGARY = 'HUNGARY', // 헝가리
}

export type CountryType = keyof typeof Country;

export const CountryNameMap: Record<CountryType, string> = {
  REPUBLIC_OF_KOREA: '대한민국',
  USA: '미국',
  CANADA: '캐나다',
  // TAIWAN: '대만',
  JAPAN: '일본',
  CHINA: '중국',
  SINGAPORE: '싱가포르',
  HONGKONG: '홍콩',
  // INDONESIA: '인도네시아',
  MALAYSIA: '말레이시아',
  PHILIPPINES: '필리핀',
  THAILAND: '태국',
  BRUNEIDARUSSALAM: '브루나이',
  // VIETNAM: '베트남',
  LAOS: '라오스',
  MYANMAR: '미얀마',
  CAMBODIA: '캄보디아',
  MACAU: '마카오',
  BANGALADESH: '방글라데시',
  INDIA: '인도',
  PAKISTAN: '파키스탄',
  // SAUDIARABIA: '사우디아라비아',
  // UNITEDARABEMIRATES: '아랍에미리트',
  ARMENIA: '아르메니아',
  AZERBAIJAN: '아제르바이잔',
  AFHANISTAN: '아프가니스탄',
  ALBANIA: '알바니아',
  YEAMEN: '예멘',
  OMAN: '오만',
  JORDAN: '요르단',
  UZBEKISTAN: '우즈베키스탄',
  UKRAINE: '우크라이나',
  ISRALL: '이스라엘',
  EGYPT: '이집트',
  KUWAIT: '쿠웨이트',
  KYRGYZSTAN: '키르기스스탄',
  TAJIKISTAN: '타지키스탄',
  TRUKEY: '튀르키예',
  TURKMENISTAN: '투르크메니스탄',
  NEWZEALAND: '뉴질랜드',
  AUSTRALIA: '호주',
  PAPUANEWGUINEA: '파푸아뉴기니',
  GREECE: '그리스',
  NETHERLANDS: '네덜란드',
  NORWAY: '노르웨이',
  DENMARK: '덴마크',
  GERMANY: '독일',
  LATVIA: '라트비아',
  RUSSIA: '러시아',
  LEBANON: '레바논',
  ROMANIA: '루마니아',
  LUXEMBOURG: '룩셈부르크',
  LITHUANIA: '리투아니아',
  MACEONIA: '마케도니아',
  MALTA: '몰타',
  BAHRAIN: '바레인',
  BELGIUM: '벨기에',
  BELARUS: '벨라루스',
  BOSNIA: '보스니아',
  BULGARIA: '불가리아',
  SERBIA: '세르비아',
  SWEDEN: '스웨덴',
  SWITZERLAND: '스위스',
  SPAIN: '스페인',
  SLOVAKIA: '슬로바키아',
  SLOVENIA: '슬로베니아',
  LRELAND: '아일랜드',
  ESTONIA: '에스토니아',
  UNITEDKINGDOM: '영국',
  AUSTRIA: '오스트리아',
  ITALY: '이탈리아',
  CZECHREPUBLIC: '체코',
  CRORATIA: '크로아티아',
  CYPRUS: '키프로스',
  PORTUGAL: '포르투갈',
  POLAND: '폴란드',
  FRANCE: '프랑스',
  FINLAND: '핀란드',
  HUNGARY: '헝가리',
};

export const CountryEnglishNameMap: Record<CountryType, string> = {
  REPUBLIC_OF_KOREA: 'Republic of Korea',
  USA: 'United States of America',
  CANADA: 'Canada',
  // TAIWAN: 'Taiwan',
  JAPAN: 'Japan',
  CHINA: 'China',
  SINGAPORE: 'Singapore',
  HONGKONG: 'Hong Kong',
  // INDONESIA: 'Indonesia',
  MALAYSIA: 'Malaysia',
  PHILIPPINES: 'Philippines',
  THAILAND: 'Thailand',
  BRUNEIDARUSSALAM: 'Brunei Darussalam',
  // VIETNAM: 'Vietnam',
  LAOS: 'Laos',
  MYANMAR: 'Myanmar',
  CAMBODIA: 'Cambodia',
  MACAU: 'Macau',
  BANGALADESH: 'Bangladesh',
  INDIA: 'India',
  PAKISTAN: 'Pakistan',
  // SAUDIARABIA: 'Saudi Arabia',
  // UNITEDARABEMIRATES: 'United Arab Emirates',
  ARMENIA: 'Armenia',
  AZERBAIJAN: 'Azerbaijan',
  AFHANISTAN: 'Afghanistan',
  ALBANIA: 'Albania',
  YEAMEN: 'Yemen',
  OMAN: 'Oman',
  JORDAN: 'Jordan',
  UZBEKISTAN: 'Uzbekistan',
  UKRAINE: 'Ukraine',
  ISRALL: 'Israel',
  EGYPT: 'Egypt',
  KUWAIT: 'Kuwait',
  KYRGYZSTAN: 'Kyrgyzstan',
  TAJIKISTAN: 'Tajikistan',
  TRUKEY: 'Turkey',
  TURKMENISTAN: 'Turkmenistan',
  NEWZEALAND: 'New Zealand',
  AUSTRALIA: 'Australia',
  PAPUANEWGUINEA: 'Papua New Guinea',
  GREECE: 'Greece',
  NETHERLANDS: 'Netherlands',
  NORWAY: 'Norway',
  DENMARK: 'Denmark',
  GERMANY: 'Germany',
  LATVIA: 'Latvia',
  RUSSIA: 'Russia',
  LEBANON: 'Lebanon',
  ROMANIA: 'Romania',
  LUXEMBOURG: 'Luxembourg',
  LITHUANIA: 'Lithuania',
  MACEONIA: 'Macedonia',
  MALTA: 'Malta',
  BAHRAIN: 'Bahrain',
  BELGIUM: 'Belgium',
  BELARUS: 'Belarus',
  BOSNIA: 'Bosnia',
  BULGARIA: 'Bulgaria',
  SERBIA: 'Serbia',
  SWEDEN: 'Sweden',
  SWITZERLAND: 'Switzerland',
  SPAIN: 'Spain',
  SLOVAKIA: 'Slovakia',
  SLOVENIA: 'Slovenia',
  LRELAND: 'Ireland',
  ESTONIA: 'Estonia',
  UNITEDKINGDOM: 'United Kingdom',
  AUSTRIA: 'Austria',
  ITALY: 'Italy',
  CZECHREPUBLIC: 'Czech Republic',
  CRORATIA: 'Croatia',
  CYPRUS: 'Cyprus',
  PORTUGAL: 'Portugal',
  POLAND: 'Poland',
  FRANCE: 'France',
  FINLAND: 'Finland',
  HUNGARY: 'Hungary',
};

export const CountryCodeMap: Record<CountryType, number> = {
  REPUBLIC_OF_KOREA: 82,
  USA: 1,
  CANADA: 1,
  // TAIWAN: 886,
  JAPAN: 81,
  CHINA: 86,
  SINGAPORE: 65,
  HONGKONG: 852,
  // INDONESIA: 62,
  MALAYSIA: 60,
  PHILIPPINES: 63,
  THAILAND: 66,
  BRUNEIDARUSSALAM: 673,
  // VIETNAM: 84,
  LAOS: 856,
  MYANMAR: 95,
  CAMBODIA: 855,
  MACAU: 853,
  BANGALADESH: 880,
  INDIA: 91,
  PAKISTAN: 92,
  // SAUDIARABIA: 966,
  // UNITEDARABEMIRATES: 971,
  ARMENIA: 374,
  AZERBAIJAN: 994,
  AFHANISTAN: 93,
  ALBANIA: 355,
  YEAMEN: 967,
  OMAN: 968,
  JORDAN: 962,
  UZBEKISTAN: 998,
  UKRAINE: 380,
  ISRALL: 972,
  EGYPT: 20,
  KUWAIT: 965,
  KYRGYZSTAN: 996,
  TAJIKISTAN: 992,
  TRUKEY: 90,
  TURKMENISTAN: 993,
  NEWZEALAND: 64,
  AUSTRALIA: 61,
  PAPUANEWGUINEA: 675,
  GREECE: 30,
  NETHERLANDS: 31,
  NORWAY: 47,
  DENMARK: 45,
  GERMANY: 49,
  LATVIA: 371,
  RUSSIA: 7,
  LEBANON: 961,
  ROMANIA: 40,
  LUXEMBOURG: 352,
  LITHUANIA: 370,
  MACEONIA: 389,
  MALTA: 356,
  BAHRAIN: 973,
  BELGIUM: 32,
  BELARUS: 375,
  BOSNIA: 387,
  BULGARIA: 359,
  SERBIA: 381,
  SWEDEN: 46,
  SWITZERLAND: 41,
  SPAIN: 34,
  SLOVAKIA: 421,
  SLOVENIA: 386,
  LRELAND: 353,
  ESTONIA: 372,
  UNITEDKINGDOM: 44,
  AUSTRIA: 43,
  ITALY: 39,
  CZECHREPUBLIC: 420,
  CRORATIA: 385,
  CYPRUS: 357,
  PORTUGAL: 351,
  POLAND: 48,
  FRANCE: 33,
  FINLAND: 358,
  HUNGARY: 36,
};
