import React, { useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { isNumber } from '@utils/typeGuard';

interface CustomImageProps extends ImageProps {
  ErrorImage?: React.ReactNode;
  pointer?: boolean;
}

export const Base64_COOL_GREY_30 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP48uUrAAW9At6EscM1AAAAAElFTkSuQmCC';

const CustomImage = ({ ErrorImage, pointer, ...props }: CustomImageProps) => {
  const [isImgError, setIsImgError] = useState(false);

  const onError = () => {
    setIsImgError(true);
  };

  if (isImgError) {
    return ErrorImage;
  }

  const width = props.width as number;
  const height = props.height as number;
  const src = props.src as string;
  const isPlaceholder = width >= 40 && height >= 40;

  // 로고 등 이미지는 렌더링될 크기의 3배, 그 외 이미지는 2배 크기
  const resizedWidth = isNumber(width) ? (width <= 80 ? width * 3 : width * 2) : null;
  const resizedHeight = isNumber(height) ? (height <= 80 ? height * 3 : height * 2) : null;
  const resizedSource =
    resizedWidth === null && resizedHeight === null ? src : `${src}?w=${resizedWidth}&h=${resizedHeight}`;

  /**
   * fill옵션을 사용하는 경우 width, height는 사용되지 않습니다.
   * fill옵션을 사용할 땐, 부모 태그에 position: relative와 width, height를 꼭 지정해주세요!.
   */
  return (
    <Image
      {...props}
      alt={props.alt}
      unoptimized
      src={resizedSource}
      placeholder={isPlaceholder ? 'blur' : undefined}
      blurDataURL={Base64_COOL_GREY_30}
      onError={onError}
      style={{ cursor: pointer ? 'pointer' : 'default', objectFit: 'cover' }}
    />
  );
};

export default CustomImage;
