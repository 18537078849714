import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import LoggingRq from '@domain/rq/logging/LoggingRq';

export default interface LoggingRepo {
  //로그 저장
  saveLogging(rq: LoggingRq): Promise<AxiosResponse<boolean>>;
}

export class RemoteLoggingRepo implements LoggingRepo {
  saveLogging(rq: LoggingRq) {
    return axios.post<boolean>(`/v1/log/action`, { userActionLog: rq });
  }
}
