import * as yup from 'yup';

const prizeFormSchema = yup.object({
  institution: yup.string().required('수여 기관을 입력해주세요.').max(30, '30자 이내로 입력해주세요.'),
  name: yup.string().required('수상명을 입력해주세요.').max(21, '21자 이내로 입력해주세요.'),
  date: yup.lazy((startDate) => {
    return startDate ? yup.date() : yup.string().required('일자를 입력해주세요.');
  }),
  description: yup.string().nullable().max(1000, '최대 글자수를 초과했습니다.'),
});

export default prizeFormSchema;
