import { FC, useEffect } from 'react';
import Lottie from 'react-lottie';
import { useRouter } from 'next/router';
import Text from '@components/common/text';
import Icon from '@components/common/assets';
import getProfileLottie from '@graphic/getProfileLottie.json';
import { useInsightResumeLog } from '@repository/insight/useInsight';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

const ComponentLogId = 'modal_gate_insight_resume';

const options = {
  loop: true,
  autoplay: true,
  animationData: getProfileLottie,
};
interface Props {
  nextStep: () => void;
}
const Step1: FC<Props> = ({ nextStep }) => {
  const { pathname } = useRouter();
  const { mutateAsync: fetchLog } = useInsightResumeLog();

  //Log쌓기
  useEffect(() => {
    fetchLog({
      action: 'VIEW',
      actionSource: pathname,
      actionComponent: ComponentLogId,
    });
  }, []);

  return (
    <div className={cx('stepWrap')}>
      <div>
        <div className={cx('titleArea')}>
          <Text fontStyle={FontStyle.B2_M} color={Colors.C_GREEN_200}>
            귀찮았던 프로필 작성을 간단하게!
          </Text>
          <Text fontStyle={FontStyle.H5_B}>제출한 지원서를 활용해서 프로필을 채워 보세요.</Text>
        </div>

        <div className={cx('scrollArea')}>
          <div className={cx('scrollInner')}>
            <div className={cx('lottieArea')}>
              <Lottie options={options} />
            </div>

            <Text className={cx('noticeTitle')} fontStyle={FontStyle.B2_B}>
              프로필을 채우면 어떤 점이 좋나요?
            </Text>
            <div className={cx('noticeArea')}>
              <Text className={cx('noticeAreaText')} fontStyle={FontStyle.B3_M} color={Colors.C_WARM_GRAY_70}>
                <Icon name="checkLight" width={24} height={24} />
                정리해놓은 내 프로필로 간편히 지원해 보세요.
              </Text>
              <Text className={cx('noticeAreaText')} fontStyle={FontStyle.B3_M} color={Colors.C_WARM_GRAY_70}>
                <Icon name="checkLight" width={24} height={24} />내 지원서 정보를 한 곳에서 편하게 관리해 보세요.
              </Text>
              <Text className={cx('noticeAreaText')} fontStyle={FontStyle.B3_M} color={Colors.C_WARM_GRAY_70}>
                <Icon name="checkLight" width={24} height={24} />
                프로필 정보가 채워질수록 더 정확한 매칭을 받을 수 있어요.
              </Text>
            </div>
          </div>
        </div>
      </div>

      <div className={cx('btnArea', 'end')}>
        <button className={cx('confirm')} onClick={nextStep}>
          확인
        </button>
      </div>
    </div>
  );
};

export default Step1;
