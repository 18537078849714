import * as yup from 'yup';

const profileConditionsSchema = yup.object({
  jobGroups: yup.array(),
  locations: yup.array(),
  welfareKeywords: yup.array(),
  salary: yup.object().nullable(),
  workingPreference: yup.string().nullable(),
});

export default profileConditionsSchema;
