import { getOptions } from '@domain/constant/ReactSelectOption';

export enum SkillLevel {
  BASIC = 'BASIC',
  BEGINNER = 'BEGINNER',
  MIDDLE = 'MIDDLE',
  ADVANCED = 'ADVANCED',
  PROFESSIONAL = 'PROFESSIONAL',
}

export type SkillLevelType = keyof typeof SkillLevel;

export const SkillLevelNameMap: Record<SkillLevelType, string> = {
  [SkillLevel.BASIC]: '기초',
  [SkillLevel.BEGINNER]: '초급',
  [SkillLevel.MIDDLE]: '중급',
  [SkillLevel.ADVANCED]: '고급',
  [SkillLevel.PROFESSIONAL]: '전문가',
};

export const SkillLevelOptions = getOptions(SkillLevelNameMap);
