export type AccaGnbPath = 'introduce' | 'test' | 'tutorial';

export enum AccaRouteUrl {
  //매칭 관련
  ACCA_INTRODUCE = '/acca/introduce',
  ACCA_TEST_GATE = '/acca/test',
  ACCA_TEST_LIST = '/acca/test/list',
  ACCA_TUTORIAL = '/acca/tutorial/login',
  ACCA_CC_RESULT = '/acca/cc/result',
  ACCA_RESULTS = '/acca/results',
  ACCA_SAMPLE_RESULT = '/acca/sampleResult',
}

export class AccaRouteUtil {
  static getAccaMenuStatus(pathname: string, path: AccaGnbPath) {
    return pathname.includes(path);
  }

  static openChromeDownload() {
    window.open('https://www.google.com/intl/ko/chrome/');
  }

  static getAccaCCResult(code: string) {
    return `${AccaRouteUrl.ACCA_CC_RESULT}?code=${code}`;
  }
}
