import { getOptions } from '@domain/constant/ReactSelectOption';

// 대내외 활동 구분
export enum Experience {
  INTERNAL = 'INTERNAL', // 교내 활동
  EXTERNAL = 'EXTERNAL', // 대외 활동
  EDUCATION = 'EDUCATION', // 교육 이수
  VOLUNTEER = 'VOLUNTEER', // 봉사 활동
  CLUB = 'CLUB', // 동아리
  OVERSEAS = 'OVERSEAS', //해외경험
}

export type ExperienceType = keyof typeof Experience;

export const ExperienceNameMap: Record<ExperienceType, string> = {
  [Experience.INTERNAL]: '교내 활동',
  [Experience.EXTERNAL]: '대외 활동',
  [Experience.EDUCATION]: '교육 이수',
  [Experience.VOLUNTEER]: '봉사 활동',
  [Experience.CLUB]: '동아리',
  [Experience.OVERSEAS]: '해외 경험',
};

export const experienceOptions = getOptions({
  [Experience.INTERNAL]: '교내 활동',
  [Experience.EXTERNAL]: '대외 활동',
  [Experience.EDUCATION]: '교육 이수',
  [Experience.VOLUNTEER]: '봉사 활동',
  [Experience.CLUB]: '동아리',
});
