import Router from 'next/router';
import { V2RouteUrl } from '@utils/routeUtil/V2Route';

type CompanyPath = 'profile' | 'culture' | 'review' | 'recruit' | 'pass';
export type CurationPath = 'match' | 'preset';
export type MyPagePath = 'basic' | 'account' | 'job' | 'notice' | 'premium';

export class ContentRouteUtil {
  static goLoginPage(redirectPath = location.pathname) {
    Router.push(`${ContentRouteUrl.LOGIN}?redirectPath=${encodeURIComponent(redirectPath)}`);
  }

  static goMainPage() {
    Router.push(V2RouteUrl.INDEX);
  }

  static goCurationPreset() {
    Router.push('/company/curation/preset');
  }

  static goCurationMatch(companySn?: number) {
    Router.push(`/company/curation/match${companySn && `?companySn=${companySn}`}`);
  }

  static goRedirectPage(redirectUrl: string) {
    Router.push(`${location.origin}${redirectUrl}`);
  }

  static go404() {
    Router.push(`/404`);
  }

  static goCurationPage(path?: CurationPath) {
    const nextUrl = path ? `/company/curation/${path}` : '/company/curation';
    Router.push(nextUrl + location.search);
  }

  static getCompanyDetailRoute(companySn: number, path: CompanyPath) {
    return `/company/${companySn}/${path}`;
  }

  static getCompanyListRoute(query?: string) {
    return `/company/list${query && `?keyword=${query}`}`;
  }

  static getSearchCompanyListRoute(query?: string) {
    return `/search/result${query && `?keyword=${query}`}`;
  }

  static getThemeDetailRoute(themeSn: number) {
    return `/theme/${themeSn}`;
  }

  static getOpenevent() {
    return '/openevent';
  }

  static getJoinUrl() {
    return '/join';
  }

  static getAITutorialPage(tutorial?: string) {
    if (tutorial) {
      return `/acc/tutorial#${tutorial}`;
    }
    return '/acc/tutorial';
  }

  static getDeclarationPage() {
    return '/declaration';
  }

  static getAccIntroducePage() {
    return '/acc/introduce';
  }

  static getAccaTermsRedirectPath(params: Record<string, string>) {
    return `/acca/terms?${new URLSearchParams(params).toString()}`;
  }

  static isReady() {
    // Router 초기화가 되지않은 경우 Router.query값이 {}로 들어오는 것을 활용합니다.
    // dynamicRoute도 query값에 포함되어 들어오므로 해당 값은 제외합니다.
    const dynamicRouteCount = Router.pathname.split('[').length - 1;
    const routerQueryCount = Object.keys(Router.query).length;
    const pathQueryCount = Router.asPath.split('=').length - 1;
    const pathReady = routerQueryCount - dynamicRouteCount === pathQueryCount;
    // const queryRead

    return routerQueryCount - dynamicRouteCount === pathQueryCount;
  }

  static getInfoDetailPage(infoSn?: number | string) {
    return `/info/${infoSn}`;
  }

  static goInfoDetailsPage(sn: number) {
    Router.push(`/info/${sn}`);
  }

  static getMyPage(path: MyPagePath = 'basic') {
    return `/mypage/${path}`;
  }

  static goMyPage(path: MyPagePath = 'basic') {
    Router.push(`/mypage/${path}`);
  }

  /** 면접을 잡다 **/
  static goInterview(isChangeOnlyUrl = false) {
    if (isChangeOnlyUrl) {
      Router.replace('/pass/interview', '/pass/interview', { shallow: true });
      return;
    }

    Router.push('/pass/interview');
  }

  static goInterviewHistory() {
    Router.push('/pass/interview/history');
  }
  static goInterviewStart() {
    Router.push('/pass/interview/start');
  }

  static openInterviewPracticepage(sn: number) {
    window.open(`/pass/interview/practice?interviewPracticeSn=${sn}`);
  }

  static openPrivacycollectionMypage() {
    window?.open('/profile/privacycollection');
  }

  static getPathnameQueryString() {
    return location.pathname + location.search;
  }

  //제휴 서비스 신청관리 > 우리 학교가 검색되지 않는다면?
  static getNotAffiliatedUniversity() {
    return 'https://forms.gle/8w9zsvC7F3SB4NfP9';
  }

  //제휴 서비스 신청관리 > 메일로 인증코드가 오지 않는다면?
  static getNotReceiveVerificationCode() {
    return 'https://forms.gle/ipxCD3eFgVYcLtg37';
  }
}

export enum ContentRouteUrl {
  LOGIN = '/login',

  UPDATE_ACCOUNT = '/update-account',
  UPDATE_BIRTHDATE = '/update-birthdate',
  JOBDA_CONTENT_MAIN = '/contents', //취업 정보 메인페이지
  JOBDA_DECLARATION = '/declaration',
  JOBDA_FAQ = '/jobdafaq',
  COMPANY_WANT = '/want',

  MY_PAGE_BASIC = '/mypage/basic',
  MY_PAGE_ACCOUNT = '/mypage/account',
  MY_PAGE_JOB = '/mypage/job',
  MY_PAGE_NOTICE = '/mypage/notice',
  MY_PAGE_PREMIUM = '/mypage/premium',
  MY_PAGE_APPLY = '/mypage/apply',
  MY_PAGE_APPLYING = '/mypage/applying',
  MY_PAGE_BOOKMARK = '/mypage/bookmark',

  COMPANY_THEME = '/company/theme',
  COMPANY_LIST = '/company/list',
  COMPANY_INTRODUCE = '/company/introduce',

  ACC_GATE = '/acc/gate',
  ACC_TIP = 'https://deluxe-poppyseed-309.notion.site/005a55c758a84a11b54f50e5b079051b', //역량검사 백서
  ACC_TUTORIAL = '/acc/tutorial',
  ACC_INTRO = '/acc/introduce', //역량을 잡다

  PASS_REVIEW = '/pass/list',
  PASS_INTERVIEW = '/pass/interview', // 면접을 잡다
  PASS_INTERVIEW_START = '/pass/interview/start',
  PASS_INTERVIEW_HISTORY = '/pass/interview/history', // 면접을 잡다

  INFO_LIST = '/info/list', //취업 컨텐츠
  PASSBOARD = '/passboard', // 취업 합격 게시판
  PASSBOARD_NEW = '/passboard/new', //취업 합격 게시판 글쓰기
  INFO_REAL = '/404', //진짜를 잡다

  INTERN = '/404', // 인턴 매칭W
  JOB_INTRO = '/404', //취업을 잡다란

  EVENT = '/event', // 이벤트

  // 리다이렉트 해줘야 하는 기존 Url
  // DEPRECATED 프리픽스 추가하기
  // next.config.js에 싱크 맞춰주기
  DEPRECATED_ACC_TUTORIAL = '/ai/tutorial',
}
